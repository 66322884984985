import React from 'react';
import styled from 'styled-components/macro';

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`;
const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f5f7;
  width: 100vw;
  height: 100vh;
`;

const LoginLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <MainContainer lang="fi">
      <ContentContainer>{children}</ContentContainer>
    </MainContainer>
  );
};

export default LoginLayout;
