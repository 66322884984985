/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  AsyncThunk,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';

import { getAxiosInstance } from 'Api';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

export interface MessageItem {
  content: string;
  conversation: number;
  organization?: number;
}

export interface MessageCreatePayload {
  id: number;
}

interface MessageState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  createdId: number | null;
}

const initialState: MessageState = {
  loading: 'idle',
  createdId: null,
};

const isPendingAction = (action: AnyAction): action is PendingAction => {
  return action.type.endsWith('/pending');
};
const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return action.type.endsWith('/rejected');
};
const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return action.type.endsWith('/fulfilled');
};
export const resetAction = createAction('reset-tracked-loading-state');
const createMessage = createAsyncThunk(
  'message/create',
  async (body: MessageItem) => {
    const response = await getAxiosInstance().post(`/message/`, body);
    return response.data;
  },
);

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetAction, () => initialState)
      .addCase(
        createMessage.fulfilled,
        (state: MessageState, action: PayloadAction<MessageCreatePayload>) => {
          if (!action.payload.id) {
            return;
          }
          state.createdId = action.payload.id;
        },
      )
      .addMatcher(isPendingAction, (state) => {
        state.loading = 'pending';
      })
      .addMatcher(isRejectedAction, (state) => {
        state.loading = 'rejected';
      })
      .addMatcher(isFulfilledAction, (state) => {
        state.loading = 'fulfilled';
      });
  },
});
export default messageSlice.reducer;
export { createMessage };
