/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import actionGenerator from 'Util/actionGenerator';
import { getAxiosInstance } from 'Api';

interface OrganizationState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  data: object;
  images: Array<ImageType>;
}
const initialState: OrganizationState = {
  loading: 'idle',
  data: {},
  images: [],
};
export type ImageType = {
  id: number;
  name: string;
  url: string;
};
type FetchOrganizationImagesResponse = ImageType[];

const fetchPublicImages = createAsyncThunk<
  FetchOrganizationImagesResponse,
  undefined
>('media_bank/images', async () => {
  const { href } = window.location;
  let query = '';
  if (href.indexOf('/education-panel') !== -1) {
    query = '?category=training';
  }
  if (href.indexOf('/admin-panel') !== -1) {
    query = '?category=employment';
  }
  const response =
    await getAxiosInstance().get<FetchOrganizationImagesResponse>(
      `/media_bank/images/${query}`,
    );
  return response.data;
});
type FetchImageByIdRequest = number;
const fetchImageById = createAsyncThunk<
  FetchOrganizationImagesResponse,
  FetchImageByIdRequest
>('media_bank/fetch_by_id', async (id: FetchImageByIdRequest) => {
  const response =
    await getAxiosInstance().get<FetchOrganizationImagesResponse>(
      `/media_bank/images/${id}/`,
    );
  return response.data;
});
const genericActions = actionGenerator('imageBank');

const imageBankSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublicImages.fulfilled, (state, action) => {
        state.images = action.payload;
      })
      .addMatcher(genericActions.isPendingAction, (state) => {
        state.loading = 'pending';
      })
      .addMatcher(genericActions.isRejectedAction, (state) => {
        state.loading = 'rejected';
      })
      .addMatcher(genericActions.isFulfilledAction, (state) => {
        state.loading = 'fulfilled';
      });
  },
});
export default imageBankSlice.reducer;
export { fetchPublicImages, fetchImageById };
