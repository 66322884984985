import React from 'react';
import AdminLayout from 'Components/Layout/AdminLayout';
import styled from 'styled-components';
import AdminJobHistory from 'Components/AdminPage/AdminJobHistory';

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  flex: 1;
`;

const AdminArchivedJobs = () => {
  return (
    <AdminLayout>
      <ContentContainer>
        <AdminJobHistory />
      </ContentContainer>
    </AdminLayout>
  );
};
export default AdminArchivedJobs;
