import { fetchPaginatedEmployees } from 'Features/MemberProfile/memberProfileSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'Store';
import styled from 'styled-components/macro';
import { isAdmin } from 'Util/permissions';
import SearchResult from './SearchResults';

const PaginationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 10px;
  span {
    padding: 5px 10px;
    border-radius: 6px;
    background: #ddd;
    margin-right: 5px;
    margin-bottom: 5px;
    color: #272727;
    cursor: pointer;
    min-width: 40px;
    text-align: center;
    &.ellipsis {
      background: none;
      cursor: default;
    }
    &.active {
      color: white;
      background: #d60c17;
    }
  }
`;

const Pagination = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { filteredProfiles } = useSelector(
    (state: RootState) => state.memberProfile,
  );
  const { filterLocations } = useSelector(
    (state: RootState) => state.memberProfile,
  );
  const { filterGroups } = useSelector(
    (state: RootState) => state.memberProfile,
  );
  const { filterTitles } = useSelector(
    (state: RootState) => state.memberProfile,
  );
  const { filterName, orderBy } = useSelector(
    (state: RootState) => state.memberProfile,
  );
  const { onlyActiveJobSeekers } = useSelector(
    (state: RootState) => state.memberProfile,
  );

  const { user } = useSelector((state: RootState) => state.auth);
  const { itemIndex } = useSelector((state: RootState) => state.admin);
  const {
    data: { results },
  } = useSelector((state: RootState) => state.organization);
  const organizationId =
    results && typeof itemIndex === 'number' ? results[itemIndex]?.id : null;
  const adminUser = user && user.groups ? isAdmin(user.groups) : false;
  const { data } = filteredProfiles;
  const { page } = filteredProfiles;
  const { count } = data;
  const pageCount = Math.ceil(count / 20);
  const pages = [];
  for (let i = page; i < page + 3; i += 1) {
    if (i < pageCount) {
      pages.push(i);
    }
  }
  for (let i = page - 1; i > page - 5; i -= 1) {
    if (i >= 0) {
      pages.push(i);
    }
  }
  return (
    <PaginationContainer>
      {pages.includes(0) ? null : (
        <>
          <span
            key={0}
            onClick={() => {
              const body = {
                page: 1,
                filters: {
                  professional_titles: filterTitles,
                  professional_title_groups: filterGroups,
                  locations: filterLocations,
                  favorites: location.pathname.indexOf('/pankki') !== -1,
                  organization: organizationId || undefined,
                  full_name: filterName,
                  only_job_seekers: true,
                  orderBy,
                  only_active_job_seekers: onlyActiveJobSeekers,
                },
              };
              dispatch(fetchPaginatedEmployees(body));
            }}
            className={page === 1 ? 'active' : ''}
          >
            {1}
          </span>
          <span className="ellipsis">...</span>
        </>
      )}
      {pages
        .sort((a, b) => (b < a ? 1 : -1))
        .map((index: number) => (
          <span
            key={index}
            onClick={() => {
              const body = {
                page: index + 1,
                filters: {
                  professional_titles: filterTitles,
                  professional_title_groups: filterGroups,
                  locations: filterLocations,
                  favorites: location.pathname.indexOf('/pankki') !== -1,
                  organization: organizationId || undefined,
                  full_name: filterName,
                  only_job_seekers: !adminUser,
                  orderBy,
                  only_active_job_seekers: onlyActiveJobSeekers,
                },
              };
              dispatch(fetchPaginatedEmployees(body));
            }}
            className={page === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </span>
        ))}
      {pages.includes(pageCount - 1) || Number.isNaN(pageCount) ? null : (
        <>
          <span className="ellipsis">...</span>
          <span
            key={pageCount}
            onClick={() => {
              const body = {
                page: pageCount,
                filters: {
                  professional_titles: filterTitles,
                  professional_title_groups: filterGroups,
                  locations: filterLocations,
                  favorites: location.pathname.indexOf('/pankki') !== -1,
                  organization: organizationId || undefined,
                  full_name: filterName,
                  only_job_seekers: !adminUser,
                  orderBy,
                  only_active_job_seekers: onlyActiveJobSeekers,
                },
              };
              dispatch(fetchPaginatedEmployees(body));
            }}
            className={page === pageCount ? 'active' : ''}
          >
            {pageCount}
          </span>
        </>
      )}
      <SearchResult count={count} />
    </PaginationContainer>
  );
};

export default Pagination;
