import { breakpoints } from 'Components/Layout/MediaQuery';

import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  borderRadius: '0px',
  colors: {
    primary: '#2D3264',
    text: '#183E6F',
    background: '#2A4B6B',
    accent: '#099BD9',
    link: '#099BD9',
  },
  breakpoints: {
    xs: `(min-width: ${breakpoints.xs}px)`,
    sm: `(min-width: ${breakpoints.sm}px)`,
    md: `(min-width: ${breakpoints.md}px)`,
    lg: `(min-width: ${breakpoints.lg}px)`,
    xl: `(min-width: ${breakpoints.xl}px)`,
  },
};

export default theme;
