import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import Select from 'react-select';

// #region Styling
const ObjectTableStyles = {
  ObjectTableBackground: styled.div`
    width: 100%;
    margin-top: 10px;
  `,
  ObjectTableContainer: styled.div`
    display: inline-block;
    width: 100%;
    min-width: 1100px;
  `,
  ObjectTableTitle: styled.div`
    color: #252525;
    font-family: Barlow;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    margin-right: 30px;
  `,
  ObjectTableTopComponent: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  `,
  ObjectTableBottomComponent: styled.div`
    white-space: nowrap;
  `,
  ObjectTableTop: styled.div`
    display: flex;
  `,
  ObjectTableTopNewObject: styled(Link)`
    font-family: Barlow;
    font-size: 14px;
    font-weight: 600;
    &:hover {
      cursor: pointer;
    }
    text-decoration: none;
    color: #252525;
  `,
  ObjectTableTopNewObjectDiv: styled.div`
    font-family: Barlow;
    font-size: 14px;
    font-weight: 600;
    &:hover {
      cursor: pointer;
    }
    color: #252525;
  `,
  ObjectTableHeader: styled.div`
    user-select: none;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #d6dce2;
    background: #fff;
    display: flex;
    padding-left: 20px;
  `,
  ObjectTableContent: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
  `,
  ObjectTableItem: styled.div`
    height: 44px;
    width: 100%;
    line-height: 44px;
    display: flex;
    padding-left: 20px;
    color: #252525;
    font-family: Barlow;
    font-size: 14px;
    border: 2px solid #fff;
    &:nth-child(odd) {
      background: #f9f9f9;
      border: 2px solid #f9f9f9;
    }
    &:hover {
      border: 2px solid #a6ceff;
      background-color: #f4f8ff;
    }
  `,
  ObjectTableFooter: styled.div`
    height: 100px;
    padding: 28px 0px 28px 20px;
    background: #fff;
  `,
  ObjectTableHeaderItem: styled.div`
    width: 15%;
    color: #252525;
    font-family: Barlow;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    &:first-child {
      width: 15%;
    }
  `,
  ObjectTableHeaderOptionsBlock: styled.div`
    width: 10%;
    color: #252525;
  `,
  ObjectTableObjectTitle: styled.div`
    font-size: 14px;
    font-weight: 600;
    width: 15%;
    font-family: Barlow;
    color: #0a4ea2;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  ObjectTableStatusIndicator: styled(FontAwesomeIcon)`
    margin-right: 7px;
    vertical-align: 0px;
    font-size: 0.7em;
    color: #70e764;
  `,
  ObjectTableApplicationNumber: styled.div`
    color: #0a4ea2;
    width: 15%;
    font-family: Barlow;
    font-size: 14px;
  `,
  ObjectTableJobStatus: styled.div`
    width: 15%;
    color: #252525;
    font-family: Barlow;
    font-size: 14px;
  `,
  ObjectTableOptions: styled.div`
    white-space: nowrap;
    width: 10%;
    display: flex;
    justify-content: space-evenly;
  `,
  ObjectTableCustomLink: styled(Link)`
    text-decoration: none;
    color: #0a4ea2;
    font-family: Barlow;
    font-size: 14px;
    &:hover {
      font-weight: 600;
      svg {
        font-size: 1.2em;
      }
    }
  `,
  ObjectTableBottomNewObject: styled(Link)`
    width: 25%;
    height: 44px;
    line-height: 44px;
    border: 1px solid #d6dce2;
    outline: none;
    border-radius: 22px;
    background-color: #f4f5f7;
    display: flex;
    justify-content: space-between;
    padding: 0px 22px 0px 22px;
    font-size: 16px;
    font-family: Barlow;
    font-weight: 600;
    text-decoration: none;
    color: #252525;
    &:hover {
      cursor: pointer;
    }
  `,
  ObjectTableBottomNewObjectButton: styled.div`
    width: 25%;
    height: 44px;
    line-height: 44px;
    border: 1px solid #d6dce2;
    outline: none;
    border-radius: 22px;
    background-color: #f4f5f7;
    display: flex;
    justify-content: space-between;
    padding: 0px 22px 0px 22px;
    font-size: 16px;
    font-family: Barlow;
    font-weight: 600;
    text-decoration: none;
    color: #252525;
    &:hover {
      cursor: pointer;
    }
  `,
  ObjectTableBottomNewObjectDiv: styled.div`
    width: 25%;
    height: 44px;
    line-height: 44px;
    border: 1px solid #d6dce2;
    outline: none;
    border-radius: 22px;
    background-color: #f4f5f7;
    display: flex;
    justify-content: space-between;
    padding: 0px 22px 0px 22px;
    font-size: 16px;
    font-family: Barlow;
    font-weight: 600;
    text-decoration: none;
    color: #252525;
    &:hover {
      cursor: pointer;
    }
  `,
  ObjectTableSortCaretIcon: styled(FontAwesomeIcon)`
    margin-left: 6px;
  `,
  ObjectTableAngleRightIcon: styled(FontAwesomeIcon)`
    color: #0a4ea2;
    margin: 0px 0px 0px 10px;
  `,
  ObjectTableCompanySelect: styled(Select)`
    width: 204px;
  `,
};

export default ObjectTableStyles;
