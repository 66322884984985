/* eslint-disable no-console */
import { fetchPaginatedJobAdvertisements } from 'Features/JobAdvertisements/advertisementSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'Store';
import styled from 'styled-components/macro';
import SearchResult from './SearchResults';

const PaginationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  span {
    padding: 5px 10px;
    border-radius: 6px;
    background: #ddd;
    margin-right: 5px;
    margin-bottom: 5px;
    color: #272727;
    cursor: pointer;
    min-width: 40px;
    text-align: center;
    &.ellipsis {
      background: none;
      cursor: default;
    }
    &.active {
      color: white;
      background: #d60c17;
    }
  }
`;

const Pagination = ({
  status,
  itemIndex,
}: {
  status: string;
  itemIndex?: number;
}) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state: RootState) => state.organization);
  const { results } = data;
  const organizationId =
    results && itemIndex ? results[itemIndex].id : undefined;
  const {
    currentPage,
    orderBy,
    advertisementData: { count },
  } = useSelector((state: RootState) => state.advertisement);
  const pageCount = Math.ceil(count / 20);
  const pages = [];
  for (let i = currentPage; i < currentPage + 3; i += 1) {
    if (i < pageCount) {
      pages.push(i);
    }
  }
  for (let i = currentPage - 1; i > currentPage - 5; i -= 1) {
    if (i >= 0) {
      pages.push(i);
    }
  }
  return (
    <PaginationContainer>
      {pages.includes(0) ? null : (
        <>
          <span
            key={0}
            onClick={() => {
              const body = {
                page: 1,
                organizationId: organizationId || undefined,
                status,
                orderBy,
              };
              dispatch(fetchPaginatedJobAdvertisements(body));
              dispatch({
                type: 'jobs/changePage',
                payload: body.page,
              });
            }}
            className={currentPage === 1 ? 'active' : ''}
          >
            {1}
          </span>
          <span className="ellipsis">...</span>
        </>
      )}
      {pages
        .sort((a, b) => (b < a ? 1 : -1))
        .map((index: number) => (
          <span
            key={index}
            onClick={() => {
              const body = {
                page: index + 1,
                organizationId,
                status,
                orderBy,
              };
              dispatch(fetchPaginatedJobAdvertisements(body));
              dispatch({
                type: 'jobs/changePage',
                payload: body.page,
              });
            }}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </span>
        ))}
      {pages.includes(pageCount - 1) || Number.isNaN(pageCount) ? null : (
        <>
          <span className="ellipsis">...</span>
          <span
            key={pageCount}
            onClick={() => {
              const body = {
                page: pageCount,
                organizationId,
                status,
                orderBy,
              };
              dispatch(fetchPaginatedJobAdvertisements(body));
              dispatch({
                type: 'jobs/changePage',
                payload: body.page,
              });
            }}
            className={currentPage === pageCount ? 'active' : ''}
          >
            {pageCount}
          </span>
        </>
      )}
      <SearchResult count={count} />
    </PaginationContainer>
  );
};

Pagination.defaultProps = {
  itemIndex: null,
};

export default Pagination;
