import React from 'react';
import styled from 'styled-components/macro';
import Navbar from 'Components/EducationPage/EducationNavbar';
import Sidebar from 'Components/EducationPage/EducationSidebar';
import LoadingIndicator from 'Components/LoadingIndicator';
import { useSelector } from 'react-redux';
import { RootState } from 'Store';
import { useHistory } from 'react-router-dom';
import { BaseState } from '../AdminLayout';

const MainContainer = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  margin-top: 60px;
`;
const ContentContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
  display: flex;
`;

const EducationLayout = ({ children }: { children: React.ReactNode }) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const history = useHistory();
  if (user.is_employer && !user.is_trainer) {
    history.push('/admin-panel');
  }
  const isLoading = useSelector((state: RootState) =>
    Object.values(state).some((partialState) => {
      const castedState = partialState as BaseState;
      return castedState.loading === 'pending';
    }),
  );
  return (
    <MainContainer>
      <Navbar />
      <ContentContainer>
        <Sidebar />
        {children}
      </ContentContainer>
      {isLoading && <LoadingIndicator />}
    </MainContainer>
  );
};

export default EducationLayout;
