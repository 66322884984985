/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Logo from 'Assets/rakennusliitto-logo.png';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { passwordReset } from 'Features/Auth/authSlice';
import { toast } from 'react-toastify';

const Container = styled.div`
  text-align: center;
  border-radius: 3px;
  background-color: #fff;
  margin: 0px auto;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  width: 400px;
  align-items: center;
`;
const LoginTitle = styled.div`
  color: #222;
  margin: 30px 0px;
  font-style: Barlow;
  font-size: 16px;
`;
const LoginForm = styled.form`
  width: 100%;
`;
const LoginLabel = styled.label`
  font-size: 14px;
  font-family: Barlow;
  color: #222;
  display: block;
  margin-bottom: 7px;
  text-transform: capitalize;
  font-weight: bold;
  text-align: left;
  margin-left: 15px;
`;
const LoginInput = styled.input`
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 4px;
  border: none;
  background: #fff;
  border: 1px solid #d6dce2;
  color: #222;
  &:last-of-type {
    margin-bottom: 5px;
  }
`;
const LoginButton = styled.button`
  cursor: pointer;
  width: 100%;
  border: none;
  background-color: #d60c17;
  color: #ffffff;
  padding: 10px;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 15px;
`;

const AdminRequestPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>('');
  const submitReset = async () => {
    const { error } = await dispatch<any>(passwordReset(email));
    if (error) {
      const errorObj: {
        [key: string]: { [key: string]: any };
      } = JSON.parse(error.message);
      const errors: Array<string> = [];
      Object.keys(errorObj).map((k: string) => {
        errors.push(`${t(k)}`);
      });
      // eslint-disable-next-line no-undef
      toast.error(
        <>
          <strong>{t('fill-missing-fields')}:</strong>
          {errors.map((e: string) => (
            <p key={e}>
              <strong>{e.split(' - ')[0]}</strong>
            </p>
          ))}
        </>,
      );
    } else {
      toast.success(t('password-reset-email-sent'));
    }
  };
  return (
    <Container>
      <img src={Logo} alt="Rakennusliitto logo" />
      <LoginTitle>{t('forgot-password-title')}</LoginTitle>
      <LoginForm onSubmit={(e) => e.preventDefault()}>
        <LoginLabel>{t('email')}</LoginLabel>
        <LoginInput
          name="email"
          value={email}
          placeholder={t('write-email')}
          onChange={(e) => setEmail(e.target.value)}
        />
        <LoginButton onClick={submitReset}>{t('submit')}</LoginButton>
      </LoginForm>
    </Container>
  );
};

export default AdminRequestPassword;
