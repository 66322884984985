import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'Store';
import Select, { StylesConfig } from 'react-select';
import { Fields } from '../styles';
import { Selectable, SelectableContainer } from './Phase2';
import { AddButton } from './Phase3';
import RecommenderForm from '../FormComponents/RecommenderForm';

const locationSelectStyling: StylesConfig = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#252525',
    fontFamily: 'Barlow',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '17px',
  }),
  input: (provided) => ({
    ...provided,
    color: '#252525',
    fontFamily: 'Barlow',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '17px',
  }),
};

const Phase5 = ({
  values,
  setValue,
  updateProfile,
}: {
  values: { [key: string]: any };
  setValue: Function;
  updateProfile: () => void;
}) => {
  const { t } = useTranslation();
  const {
    data: { results },
  } = useSelector((state: RootState) => state.drivingLicense);
  const { locations } = useSelector(
    (state: RootState) => state.newAdvertisement,
  );
  const locationOptions = locations.map((loc) => ({
    value: loc.id,
    label: loc.name,
  }));
  const addBlankRecommender = () => {
    const newRecommenders = [...(values.recommenders || [])];
    newRecommenders.push({
      id: Date.now(),
    });
    setValue('recommenders', newRecommenders);
  };
  const renderRecommender = () =>
    (values.recommenders || []).map((rec: any, index: number) => (
      <RecommenderForm
        key={rec.id}
        recommender={rec}
        deleteRecommender={() => {
          const newRecommenders = values.recommenders.filter(
            (rec2: any, index2: number) => index !== index2,
          );
          setValue('recommenders', newRecommenders);
        }}
        onChange={(newRec: any) => {
          const newRecommenders = [...values.recommenders];
          newRecommenders[index] = newRec;
          setValue('recommenders', newRecommenders);
        }}
      />
    ));
  return (
    <Fields>
      <div className="rows">
        <label>{t('own-description')}</label>
        <textarea
          placeholder={t('own-description-placeholder')}
          className="form-select"
          value={values.ownDescription || ''}
          rows={4}
          onChange={(e) => setValue('ownDescription', e.target.value)}
        />
        <label className="top-margin">{t('vehicle')}</label>
        <div className="checkbox">
          <input
            id="can-use-own-car"
            type="checkbox"
            checked={values.possibility_to_use_own_car || false}
            onChange={(e) =>
              setValue('possibility_to_use_own_car', e.target.checked)
            }
          />
          <label htmlFor="can-use-own-car">{t('can-use-own-car')}</label>
        </div>
        <label>{t('choose-driving-licences')}</label>
        <SelectableContainer style={{ marginTop: 10 }}>
          {/* eslint-disable-next-line camelcase */}
          {results.map((license: { license_type: string; id: number }) => (
            <Selectable
              key={license.id}
              selected={(values.drivingLicenses || []).includes(license.id)}
              onClick={() => {
                let newLicenses = [...(values.drivingLicenses || [])];
                if (newLicenses.includes(license.id)) {
                  newLicenses = newLicenses.filter(
                    (id: number) => id !== license.id,
                  );
                } else {
                  newLicenses.push(license.id);
                }
                setValue('drivingLicenses', newLicenses);
              }}
            >
              {license.license_type}
            </Selectable>
          ))}
        </SelectableContainer>
        <label>{t('working-areas')}</label>
        <Select
          placeholder={t('select')}
          isMulti
          options={locationOptions}
          value={locationOptions.filter(({ value }: { value: number }) =>
            (values.locations || []).includes(value),
          )}
          styles={locationSelectStyling}
          onChange={(selection: any) => {
            setValue(
              'locations',
              (selection || []).map(
                (s: { value: number; label: string }) => s.value,
              ),
            );
          }}
        />
        <label style={{ marginTop: 20 }}>{t('recommenders')}</label>
        {renderRecommender()}
        <AddButton onClick={addBlankRecommender}>
          <span>{t('add-new-recommender')}</span>{' '}
          <FontAwesomeIcon icon={['fal', 'plus-circle']} />
        </AddButton>
      </div>
      <div className="additional-info">
        <div className="selection-details">
          {(values.recommenders || []).length > 0 ? (
            <>
              <label>{t('added-recommenders')}</label>
              {values.recommenders.map((r: any) => (
                <div key={r.id} className="selection">
                  <span>{r.name || t('untitled-recommender')}</span>
                  <FontAwesomeIcon
                    icon={['fal', 'times-circle']}
                    onClick={() => {
                      const newRecommenders = values.recommenders.filter(
                        ({ id }: { id: number }) => id !== r.id,
                      );
                      setValue('recommenders', newRecommenders);
                    }}
                  />
                </div>
              ))}
            </>
          ) : null}
          {(values.drivingLicenses || []).length > 0 ? (
            <>
              <label>{t('added-driving-licenses')}</label>
              {values.drivingLicenses.map((r: any) => {
                const licenseObj = results.find(
                  ({ id }: { id: number }) => id === r,
                );
                if (licenseObj) {
                  return (
                    <div key={licenseObj.id} className="selection">
                      <span>
                        {licenseObj.license_type}
                        {licenseObj.license_alias
                          ? ` - ${licenseObj.license_alias}`
                          : ''}
                      </span>
                    </div>
                  );
                }
                return null;
              })}
            </>
          ) : null}
        </div>
        <button
          type="button"
          onClick={updateProfile}
          style={{ marginBottom: 0 }}
        >
          {t('update-profile')}
        </button>
      </div>
    </Fields>
  );
};

export default Phase5;
