import React from 'react';

import { useMediaQuery } from 'react-responsive';

const breakpoints = {
  xs: 420,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

const Desktop = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? <>{children}</> : <></>;
};

const Tablet = ({ children }: { children: React.ReactNode }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? <>{children}</> : <></>;
};

const Mobile = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? <>{children}</> : <></>;
};

const Default = ({ children }: { children: React.ReactNode }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? <>{children}</> : <></>;
};

export { breakpoints, Desktop, Tablet, Mobile, Default };
