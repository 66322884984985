import React from 'react';
import styled from 'styled-components/macro';
import Navbar from 'Components/Navbar';
import Footer from 'Components/Layout/Footer';

const MainContainer = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  margin-top: 60px;
`;
const ContentContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
`;

const MainLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <MainContainer lang="fi">
      <Navbar />
      <ContentContainer>
        {children}
        <Footer />
      </ContentContainer>
    </MainContainer>
  );
};

export default MainLayout;
