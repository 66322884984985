import { SearchParams } from 'Components/Search';
import { JobAdvertisementFilters } from 'Features/JobAdvertisement/jobAdvertisementSlice';

const generateJobAdvertisementSearchBody = (
  searchParams: SearchParams,
  selectedFilters: JobAdvertisementFilters,
  page?: number,
) => {
  let bodyLocations: number[] = [];
  if (searchParams.location?.value) {
    bodyLocations = [searchParams.location?.value];
  }
  if (selectedFilters.locations.length) {
    bodyLocations = selectedFilters.locations;
  }

  const body = {
    ...selectedFilters,
    free_text_search: selectedFilters.free_text_search || searchParams.search,
    locations: bodyLocations,
  };

  if (page) {
    body.page = page;
  }

  return body;
};

export default generateJobAdvertisementSearchBody;
