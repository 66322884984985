/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import DropdownMenu from 'Components/DropdownMenu';
import ObjectTableStyles from 'Components/ObjectTable';
import { useTranslation } from 'react-i18next';
import {
  deleteTraining,
  fetchPaginatedFilteredTrainings,
  upcomingStatus,
  historyStatus,
  ongoingStatus,
  toggleHiddenTraining,
} from 'Features/Training/trainingSlice';
import { TrainingPagination } from 'Components/FrontendPagination';
import { RootState, useAppDispatch } from 'Store';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';

const {
  ObjectTableBackground,
  ObjectTableContainer,
  ObjectTableTitle,
  ObjectTableTopComponent,
  ObjectTableBottomComponent,
  ObjectTableTop,
  ObjectTableHeader,
  ObjectTableContent,
  ObjectTableItem,
  ObjectTableHeaderItem,
  ObjectTableObjectTitle,
  ObjectTableCustomLink,
  ObjectTableSortCaretIcon,
  ObjectTableAngleRightIcon,
} = ObjectTableStyles;

const AlignedOptionsBlock = styled(ObjectTableStyles.ObjectTableOptions)`
  margin-left: auto;
`;
const ObjectTableFooter = styled(ObjectTableStyles.ObjectTableFooter)`
  display: flex;
  justify-content: space-between;
  padding: 28px 20px 28px 20px;
`;

const EducationTrainingsComponent = ({ status }: { status: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { paginatedFilteredData } = useSelector(
    (state: RootState) => state.training,
  );

  const fetchFiltered = () => {
    const body = {
      page: 1,
      filters: {
        order: 'subject',
        status,
      },
    };
    dispatch(fetchPaginatedFilteredTrainings(body));
  };

  useEffect(() => {
    fetchFiltered();
  }, [dispatch]);

  const [listOrder, setListOrder] = useState({
    organization__name: 1,
    subject: 1,
    start_time: 1,
    created_at: 1,
  });
  const [sortedBy, setSortedBy] = useState('subject');
  type ColumnType =
    | 'organization__name'
    | 'subject'
    | 'start_time'
    | 'created_at';

  const changeSort = (newSort: string) => {
    dispatch({
      type: 'jobs/changePage',
      payload: 1,
    });
    const body = {
      page: 1,
      status,
      filters: {
        order: newSort,
      },
    };
    dispatch(fetchPaginatedFilteredTrainings(body));
  };
  const sortList = (column: ColumnType) => {
    setSortedBy(column);
    setListOrder({ ...listOrder, [column]: listOrder[column] * -1 });
    if (listOrder[column] * -1 > 0) {
      changeSort(`${column}`);
    } else {
      changeSort(`-${column}`);
    }
  };

  type CaretProps = {
    columnName: ColumnType;
  };
  const CaretIcon = ({ columnName }: CaretProps) => {
    if (sortedBy === columnName) {
      if (listOrder[columnName] === 1) {
        return <ObjectTableSortCaretIcon icon={['fas', 'caret-up']} />;
      }
      return <ObjectTableSortCaretIcon icon={['fas', 'caret-down']} />;
    }
    return null;
  };
  const history = useHistory();
  const advertisementOptions = [
    {
      icon: 'pen',
      title: t('edit'),
      action: (objectId: number) => {
        history.push(`/super-admin-panel/trainings/muokkaa/${objectId}`);
      },
    },
    {
      icon: 'eye',
      title: t('toggle-visibility'),
      action: (objectId: number) => {
        dispatch(toggleHiddenTraining(objectId)).then(() => fetchFiltered());
      },
    },
    {
      icon: 'trash',
      title: t('remove-training'),
      action: (objectId: number) => {
        dispatch(deleteTraining(objectId)).then(() => fetchFiltered());
      },
    },
  ];
  const parseTrainingDate = (startDate: string, endDate: string) => {
    if (startDate && endDate) {
      const start = format(parseISO(startDate), 'dd.MM.yyyy.');
      const end = format(parseISO(endDate), 'dd.MM.yyyy');
      return `${start} - ${end}`;
    }
    return undefined;
  };
  const parseReleaseDate = (releaseDate: string) => {
    if (releaseDate) {
      return format(parseISO(releaseDate), 'dd.MM.yyyy');
    }
    return undefined;
  };
  const renderTrainingsTitle = () => {
    switch (status) {
      case historyStatus:
        return t('past-trainings');
      case upcomingStatus:
        return t('upcoming-educations');
      case ongoingStatus:
        return t('ongoing-trainings');
      default:
        return t('upcoming-educations');
    }
  };
  const activeTrainingItems = paginatedFilteredData
    ? paginatedFilteredData.map(
        (
          item,
          // eslint-disable-next-line consistent-return
        ) => {
          return (
            <ObjectTableItem key={item.id}>
              <ObjectTableObjectTitle>
                {item.organization?.name || ''}
              </ObjectTableObjectTitle>
              <ObjectTableObjectTitle>{item.subject}</ObjectTableObjectTitle>
              <ObjectTableHeaderItem>
                {item.continuous_training
                  ? t('continuous')
                  : parseTrainingDate(item.start_time, item.end_time)}
              </ObjectTableHeaderItem>
              <ObjectTableHeaderItem>
                {parseReleaseDate(item.created_at)}
              </ObjectTableHeaderItem>
              <ObjectTableHeaderItem>
                {item.hidden ? t('hidden') : t('visible')}
              </ObjectTableHeaderItem>
              <AlignedOptionsBlock>
                <ObjectTableCustomLink
                  to={`/super-admin-panel/trainings/${item.id}`}
                >
                  {t('open')}
                  <ObjectTableAngleRightIcon icon={['far', 'angle-right']} />
                </ObjectTableCustomLink>
                <DropdownMenu
                  options={advertisementOptions}
                  objectId={parseInt(item.id.toString(), 10)}
                />
              </AlignedOptionsBlock>
            </ObjectTableItem>
          );
        },
      )
    : null;

  return (
    <ObjectTableBackground>
      <ObjectTableTopComponent>
        <ObjectTableTop>
          <ObjectTableTitle>{renderTrainingsTitle()}</ObjectTableTitle>
        </ObjectTableTop>
      </ObjectTableTopComponent>
      <ObjectTableBottomComponent>
        <ObjectTableContainer>
          <ObjectTableHeader>
            <ObjectTableHeaderItem
              onClick={() => sortList('organization__name')}
            >
              {t('organization-name')}
              <CaretIcon columnName="organization__name" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderItem onClick={() => sortList('subject')}>
              {t('educations')}
              <CaretIcon columnName="subject" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderItem onClick={() => sortList('start_time')}>
              {t('education-period')}
              <CaretIcon columnName="start_time" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderItem onClick={() => sortList('created_at')}>
              {t('publish-date')}
              <CaretIcon columnName="created_at" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderItem>{t('visibility')}</ObjectTableHeaderItem>
          </ObjectTableHeader>
          <ObjectTableContent>{activeTrainingItems}</ObjectTableContent>
          <ObjectTableFooter>
            <TrainingPagination status={status} />
          </ObjectTableFooter>
        </ObjectTableContainer>
      </ObjectTableBottomComponent>
    </ObjectTableBackground>
  );
};

export default EducationTrainingsComponent;
