import React from 'react';
import styled from 'styled-components/macro';
import DoorKeyIcon from 'Assets/door-key-icon.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openLoginModal } from 'Features/Auth/authSlice';
import { SerializedError } from '@reduxjs/toolkit';

const UnauthenticatedContainer = styled.div`
  padding: 70px 20px;
  background: #fff;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .door-key-icon {
      align-self: flex-start;
      @media screen and ${(props) => props.theme.breakpoints.lg} {
        align-self: none;
      }
    }
    img {
      height: 143px;
      width: 132px;
      align-self: flex-start;
    }
    h1 {
      color: #252525;
      font-family: Barlow;
      font-size: 32px;
      letter-spacing: 0;
      line-height: 38px;
      font-weight: 200;
      margin-top: 0;
      max-width: 412px;
      margin-bottom: 25px;
    }
    p {
      color: #252525;
      font-family: Barlow;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 27px;
      max-width: 951px;
      margin-top: 0;
      margin-bottom: 25px;
    }
    .text-content {
      @media screen and ${(props) => props.theme.breakpoints.lg} {
        margin-left: 50px;
      }
    }
    .actions {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      a,
      button {
        border: none;
        max-width: 332px;
        border-radius: 6px;
        background-color: #d60c17;
        padding: 10px 15px;
        color: #ffffff;
        font-family: Barlow;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        text-decoration: none;
        text-align: center;
        line-height: 1;
        &:not(a) {
          cursor: pointer;
          background-color: #0a4ea2;
          margin-top: 20px;
          @media screen and ${(props) => props.theme.breakpoints.lg} {
            margin-top: 0;
            margin-left: 35px;
          }
        }
      }
      @media screen and ${(props) => props.theme.breakpoints.lg} {
        flex-direction: row;
      }
    }
    @media screen and ${(props) => props.theme.breakpoints.lg} {
      flex-direction: row;
    }
  }
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 70px;
  }
`;

const ErrorMessage = styled.div`
  max-width: 717px;
  font-weight: 300;
  background-color: #af0606;
  border-radius: 4px;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px;
`;

const JobsUnauthenticated = ({
  salaryPayment,
  error,
}: {
  salaryPayment?: boolean;
  error?: SerializedError;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <UnauthenticatedContainer>
      {error?.message?.includes('500') ? (
        <ErrorMessage>{t('jobmill-server-error-500')}</ErrorMessage>
      ) : null}
      <div className="content">
        <div className="door-key-icon">
          <img src={DoorKeyIcon} alt="Key" />
        </div>
        <div className="text-content">
          <h1>
            {salaryPayment
              ? t('salary-payment-unauthenticated-title')
              : t('employment-unauthenticated-title')}
          </h1>
          <p>{t('employment-unauthenticated-description')}</p>
          <div className="actions">
            <a href="https://rakennusliitto.fi">
              {t('employment-unauthenticated-readmore')}
            </a>
            <button
              type="button"
              onClick={() => dispatch(openLoginModal(true))}
            >
              {t('move-to-login')}
            </button>
          </div>
        </div>
      </div>
    </UnauthenticatedContainer>
  );
};

JobsUnauthenticated.defaultProps = {
  salaryPayment: false,
  error: undefined,
};

export default JobsUnauthenticated;
