/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  AsyncThunk,
  createAction,
} from '@reduxjs/toolkit';

import {
  Competences,
  ProfessionalTitles,
} from 'Features/MemberProfile/memberProfileSlice';
import { getAxiosInstance } from 'Api';
import { JobApplicationType } from 'Features/JobApplication/jobApplicationSlice';
import {
  LanguageType,
  DrivingLicenseType,
} from 'Components/NewAdvertisement/ModifyAdInitializers';
import { ImageType } from 'Features/ImageBank/imageBankSlice';
import { OrganizationType } from 'Features/Organization/organizationSlice';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

export type AdvertisementUpdateDataType = {
  archived?: boolean;
  automatic_archiving?: boolean;
  competences?: Array<Competences>;
  created_at?: string;
  description?: string;
  driving_licenses?: Array<DrivingLicenseType>;
  duration?: string;
  employment_type?: string;
  end_date?: string;
  id?: number;
  images?: Array<ImageType>;
  job_applications?: Array<JobApplicationType>;
  job_type?: string;
  language_skills?: Array<LanguageType>;
  locations?: Array<JobAdLocation>;
  office?: string;
  organization?: number;
  positions?: number;
  professional_titles?: Array<ProfessionalTitles>;
  search_end_date?: string;
  search_start_date?: string;
  start_date?: string;
  title?: string;
  updated_at?: string;
  website?: string;
  ad_status?: string;
  likeit_id?: string;
};
export type SingleAdDataType = {
  archived: boolean;
  automatic_archiving: boolean;
  competences: Array<Competences>;
  created_at: string;
  description: string;
  driving_licenses: Array<DrivingLicenseType>;
  duration: string;
  employment_type: string;
  end_date: string;
  id: number;
  images: Array<ImageType>;
  job_applications: Array<JobApplicationType>;
  job_type: string;
  language_skills: Array<LanguageType>;
  locations: Array<JobAdLocation>;
  office: string;
  organization: OrganizationType;
  positions: number;
  professional_titles: Array<ProfessionalTitles>;
  search_end_date: string;
  search_start_date: string;
  start_date: string;
  title: string;
  updated_at: string;
  website: string;
  ad_status: string;
  likeit_id: string;
};
export type JobAdLocation = {
  name: string;
  id: number;
};
export type JobAdOrganization = {
  id: number;
  name: string;
};
export type ListableAd = {
  id: number;
  title: string;
  locations: Array<JobAdLocation>;
  ad_status: string;
  search_end_date: string;
  search_start_date: string;
  job_applications: Array<JobApplicationType>;
  organization: OrganizationType;
};

export interface AdvertisementProps {
  id: number;
  ad_status: string;
  title: string;
  office: string;
  locations: Array<JobAdLocation>;
  search_end_date: string;
  search_start_date: string;
  organization: JobAdOrganization;
  job_applications: Array<JobApplicationType>;
  likeit_id?: string;
}
interface AdvertisementData {
  count: number;
  results: Array<SingleAdDataType>;
  next: string | null;
  previous: string | null;
}

interface AdvertisementState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  currentPage: number;
  status: string;
  orderBy: string;
  advertisementData: AdvertisementData;
  singleAdData: SingleAdDataType | undefined;
}
const initialState: AdvertisementState = {
  loading: 'idle',
  currentPage: 1,
  status: '',
  orderBy: 'title',
  advertisementData: {
    results: [],
    count: 0,
    next: null,
    previous: null,
  },
  singleAdData: undefined,
};
const isPendingAction = (action: AnyAction): action is PendingAction => {
  return action.type.endsWith('/pending');
};
const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return action.type.endsWith('/rejected');
};
const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return action.type.endsWith('/fulfilled');
};
export const resetAction = createAction('reset-tracked-loading-state');
const fetchAdvertisements = createAsyncThunk('jobs/fetch', async () => {
  const response = await getAxiosInstance().get(`/jobs/`);
  return response.data;
});
type PaginatedJobAdvertisementsRequest = {
  page: number;
  organizationId?: number;
  status: string;
  orderBy: string;
};
const fetchPaginatedJobAdvertisements = createAsyncThunk(
  'jobAdvertisement/paginate_search',
  async (body: PaginatedJobAdvertisementsRequest) => {
    const { page } = body;
    const { status } = body;
    const params = {
      page,
      organization_id: body.organizationId,
      order_by: body.orderBy,
      status: status || initialState.status,
    };
    const response = await getAxiosInstance().get(`/jobs/`, { params });
    return { data: response.data, page };
  },
);

const duplicateAd = createAsyncThunk(
  'jobs/duplicate_ad',
  async (adId: number) => {
    const response = await getAxiosInstance().post(`/jobs/${adId}/copy/`);
    return response.data;
  },
);
const deleteAd = createAsyncThunk('jobs/delete_ad', async (adId: number) => {
  const response = await getAxiosInstance().delete(`/jobs/${adId}/`);
  return response.data;
});
type FetchWithIdRequest = number;
type FetchWithIdResponse = SingleAdDataType;
const fetchWithId = createAsyncThunk<FetchWithIdResponse, FetchWithIdRequest>(
  'jobs/fetch_with_id',
  async (adId: FetchWithIdRequest) => {
    const response = await getAxiosInstance().get<FetchWithIdResponse>(
      `/jobs/${adId}/`,
    );
    return response.data;
  },
);
type BodyType = {
  id: number;
  bodyData: AdvertisementUpdateDataType;
};
const adPartialUpdate = createAsyncThunk(
  'jobs/partial_update',
  async (body: BodyType) => {
    const response = await getAxiosInstance().patch(
      `/jobs/${body.id}/`,
      body.bodyData,
    );
    return response.data;
  },
);
const advertisementSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    // fill in primary logic here
    changePage(state, action) {
      return {
        ...state,
        currentPage: action.payload,
      };
    },
    changeStatus(state, action) {
      return {
        ...state,
        status: action.payload,
      };
    },
    changeOrderBy(state, action) {
      return {
        ...state,
        orderBy: action.payload,
      };
    },
    resetAdvertisementState() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetAction, () => initialState)
      .addCase(fetchAdvertisements.fulfilled, (state, action) => {
        state.advertisementData = action.payload;
      })
      .addCase(fetchWithId.fulfilled, (state, action) => {
        state.singleAdData = action.payload;
      })
      .addCase(fetchPaginatedJobAdvertisements.fulfilled, (state, action) => {
        state.advertisementData = action.payload.data;
      })
      .addCase(adPartialUpdate.fulfilled, (state, action) => {
        state.advertisementData.results =
          state.advertisementData.results.filter(
            (ad) => ad.id !== action.payload.id,
          );
      })
      .addMatcher(isPendingAction, (state) => {
        state.loading = 'pending';
      })
      .addMatcher(isRejectedAction, (state) => {
        state.loading = 'rejected';
      })
      .addMatcher(isFulfilledAction, (state) => {
        state.loading = 'fulfilled';
      });
  },
});
export default advertisementSlice.reducer;
export {
  fetchAdvertisements,
  adPartialUpdate,
  fetchPaginatedJobAdvertisements,
  duplicateAd,
  fetchWithId,
  deleteAd,
};
