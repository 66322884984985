import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RootState } from 'Store';

import JobExperinceForm, {
  getDurationOptions,
} from '../FormComponents/JobExperinceForm';
import { Fields } from '../styles';

export const AddButton = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #d6dce2;
  border-radius: 22px;
  background-color: #f4f5f7;
  padding: 10px 30px;
  color: #252525;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  cursor: pointer;
  span {
    flex-grow: 1;
  }
  svg {
    height: 21px;
    color: #0a4ea2;
    font-size: 21px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 21px;
    text-align: right;
  }
`;

const Phase3 = ({
  values,
  setValue,
  nextForm,
  savePartial,
}: {
  values: { [key: string]: any };
  setValue: Function;
  nextForm: () => void;
  savePartial: () => void;
}) => {
  const { t } = useTranslation();
  const { profile } = useSelector((state: RootState) => state.memberProfile);
  const addBlankJobExperience = () => {
    const newJobExperiences = [...(values.jobExperiences || [])];
    newJobExperiences.push({
      id: Date.now(),
      employer: '',
      duration: '',
      durationType: '',
      startYear: new Date().getFullYear(),
      workAssignment: '',
    });
    setValue('jobExperiences', newJobExperiences);
  };
  const renderJobExperiences = () =>
    (values.jobExperiences || []).map((jb: any, index: number) => (
      <JobExperinceForm
        key={jb.id}
        experience={jb}
        deleteExperience={() => {
          const newJobExperiences = values.jobExperiences.filter(
            (jb2: any, index2: number) => index !== index2,
          );
          setValue('jobExperiences', newJobExperiences);
        }}
        onChange={(newJb: any) => {
          const newJobExperiences = [...values.jobExperiences];
          newJobExperiences[index] = newJb;
          setValue('jobExperiences', newJobExperiences);
        }}
      />
    ));
  return (
    <Fields>
      <div className="rows">
        {renderJobExperiences()}
        <AddButton onClick={addBlankJobExperience}>
          <span>{t('add-new-job-experience')}</span>{' '}
          <FontAwesomeIcon icon={['fal', 'plus-circle']} />
        </AddButton>
      </div>
      <div className="additional-info">
        <div className="selection-details">
          {(values.jobExperiences || []).length > 0 ? (
            <>
              <label>{t('added-job-experiences')}</label>
              {values.jobExperiences.map((jb: any, i: number) => {
                const durationType = getDurationOptions(t)
                  .find((dt) => dt.value === jb.durationType)
                  ?.label.toLowerCase();
                const employer = jb.employer || t('untitled-employer');
                const workAssignment =
                  jb.workAssignment || t('untitled-assignment');
                const duration =
                  jb.durationType && jb.duration
                    ? `${jb.duration} ${durationType}`
                    : null;
                const experienceString = [employer, workAssignment, duration]
                  .filter((str: string) => str)
                  .join(', ');
                return (
                  <div
                    className="work-exp-side"
                    onClick={() => {
                      const newJobExperiences = values.jobExperiences.filter(
                        (jb2: any, i2: number) => i2 !== i,
                      );
                      setValue('jobExperiences', newJobExperiences);
                    }}
                  >
                    <span>{experienceString}</span>
                    <FontAwesomeIcon icon={['fal', 'times-circle']} />
                  </div>
                );
              })}
            </>
          ) : null}
        </div>
        <button type="button" onClick={nextForm}>
          {t('next')}
        </button>
        {!profile && (
          <a onClick={savePartial} href="#top">
            {t('save-and-continue-later')}
          </a>
        )}
      </div>
    </Fields>
  );
};

export default Phase3;
