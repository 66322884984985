/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select, { StylesConfig } from 'react-select';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from 'Store';
import { getLanguages } from 'Features/MemberProfile/memberProfileSlice';
import { useSelector } from 'react-redux';

export const Languages = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    &:first-of-type {
      margin-top: 5px;
    }
    input {
      margin-bottom: 0 !important;
    }
    span {
      color: #252525;
      font-family: Barlow;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
      margin-right: 15px;
    }
    svg {
      color: #a8aeb4;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 21px;
      cursor: pointer;
    }
  }
`;
type Language = {
  id: number;
  level: number;
};
export const getLanguageSkills = (t: (k: string) => string) => [
  { value: 0, label: t('basics') },
  { value: 1, label: t('good') },
];
const selectStyling: StylesConfig = {
  container: (provided) => ({
    ...provided,
    width: '205px',
  }),
  control: (provided) => ({
    ...provided,
    border: '1px solid #D6DCE2',
    borderRadius: '3px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#252525',
    fontFamily: 'Barlow',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '17px',
  }),
};
const languageSelectStyling: StylesConfig = {
  container: (provided) => ({
    ...provided,
    width: '130px',
  }),
  control: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#252525',
    fontFamily: 'Barlow',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '17px',
  }),
  input: (provided) => ({
    ...provided,
    color: '#252525',
    fontFamily: 'Barlow',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '17px',
  }),
};
const LanguageProficiencySelect = ({
  values,
  setValue,
}: {
  values: { [key: string]: any };
  setValue: Function;
}) => {
  const { t } = useTranslation();
  const {
    memberProfile: { languages: profileLanguages },
  } = useSelector((state: RootState) => state);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getLanguages());
  }, [dispatch]);
  const profileLanguageOptions = profileLanguages.map((l) => ({
    value: l.id,
    label: l.full_name,
  }));
  const [languageOptions, setLanguageOptions] = React.useState<{
    id: number;
    level: number;
  }>({ id: 0, level: 0 });
  return (
    <>
      <Languages>
        {(values.languages || []).map(
          (l: { id: number; level: number }, i: number) => (
            <div key={l.id}>
              <span>
                {profileLanguageOptions.find((l2) => l2.value === l.id)?.label}
              </span>
              <Select
                placeholder={t('select')}
                options={getLanguageSkills(t)}
                value={getLanguageSkills(t).find(
                  ({ value }: { value: number }) => value === l.level,
                )}
                styles={languageSelectStyling}
                onChange={(selection: any) => {
                  const newLanguages: Language[] = values.languages.map(
                    (lang: Language) => {
                      const langObj = { level: lang.level, id: lang.id };
                      return langObj;
                    },
                  );
                  newLanguages[i].level = selection.value;
                  setValue('languages', newLanguages);
                }}
              />
              <FontAwesomeIcon
                icon={['fal', 'times-circle']}
                onClick={() => {
                  const newLanguages = values.languages.filter(
                    (l2: any, i2: number) => i2 !== i,
                  );
                  setValue('languages', newLanguages);
                }}
              />
            </div>
          ),
        )}
      </Languages>
      <div className="search">
        <Select
          placeholder={t('select')}
          options={profileLanguageOptions}
          value={profileLanguageOptions.find(
            ({ value }: { value: number }) => value === languageOptions.id,
          )}
          styles={selectStyling}
          onChange={(value: any) => {
            const newLanguageOption = { ...languageOptions };
            newLanguageOption.id = value.value;
            setLanguageOptions(newLanguageOption);
          }}
        />
        <Select
          placeholder={t('select')}
          options={getLanguageSkills(t)}
          value={getLanguageSkills(t).find(
            ({ value }: { value: number }) => value === languageOptions.level,
          )}
          styles={selectStyling}
          onChange={(value: any) => {
            const newLanguageOption = { ...languageOptions };
            newLanguageOption.level = value.value;
            setLanguageOptions(newLanguageOption);
          }}
        />
        <div
          className="add-skill"
          onClick={() => {
            const languages = [...(values.languages || [])];
            languages.push(languageOptions);
            setValue('languages', languages);
          }}
        >
          <span>{t('add-new-language-skill')}</span>
          <FontAwesomeIcon icon={['fal', 'plus-circle']} />
        </div>
      </div>
    </>
  );
};
export default LanguageProficiencySelect;
