/* eslint-disable no-console */
/* eslint-disable camelcase */
import { format, parse } from 'date-fns';
import { fetchWithId } from 'Features/JobAdvertisements/advertisementSlice';
import { updateApplicationStatus } from 'Features/JobApplication/jobApplicationSlice';
import React, { useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'Store';
import styled from 'styled-components';
import AdDetails from './AdDetails';
import ApplicationDetailsModal from './ApplicationDetailsModal';
import Column from './TableComponents/ApplicationColumn';

type RejectedApplicationType = {
  id: number;
  applicationId: number;
  name: string;
  profile_features: object;
  received: string;
  status: string;
};
type RejectedApplicationObject = {
  inRejected: {
    id: string;
    list: Array<RejectedApplicationType>;
  };
};
type ApplicationItemProps = {
  name: string;
  received: string;
  id: number;
  applicationId: number;
};
type ItemTypes = {
  id: string;
  list: Array<ApplicationItemProps>;
};
type ColumnTypes = {
  [key: string]: ItemTypes;
};

const ApplicationGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 24px auto;
  gap: 8px;
  min-height: 60vh;
`;
const DragDisableOverlay = styled.div`
  z-index: 100;
  height: 60vh;
  width: calc(100% - 196px);
  position: absolute;
`;

const AdList = ({ adId }: { adId: string }) => {
  const { singleAdData } = useSelector(
    (state: RootState) => state.advertisement,
  );
  const { statusChanging, selectedApplicationId } = useSelector(
    (state: RootState) => state.jobApplication,
  );
  const dispatch = useAppDispatch();
  const jobAdData = singleAdData
    ? {
        id: singleAdData.id,
        title: singleAdData.title,
        locations: singleAdData.locations,
        search_end_date: format(
          parse(singleAdData.search_end_date, 'yyyy-mm-dd', new Date()),
          'dd.mm.yyyy',
        ),
        search_start_date: format(
          parse(singleAdData.search_start_date, 'yyyy-mm-dd', new Date()),
          'dd.mm.yyyy',
        ),
        organization: singleAdData.organization,
        job_applications: singleAdData.job_applications,
        status: singleAdData.ad_status,
      }
    : undefined;
  useEffect(() => {
    dispatch(fetchWithId(parseInt(adId, 10)));
  }, [dispatch]);
  const applications = jobAdData ? jobAdData.job_applications : undefined;
  type MemberProfile = {
    user: {
      first_name: string;
      last_name: string;
    };
  };
  const applicantsList = applications
    ? applications.map(
        (
          {
            id,
            cover_letter,
            member_profile,
            status,
            created_at,
          }: {
            id: number;
            cover_letter: string;
            member_profile: MemberProfile;
            status: string;
            created_at: string;
          },
          index: number,
        ) => ({
          applicationId: id,
          cover_letter,
          name: `${member_profile.user.first_name} ${member_profile.user.last_name}`,
          received: format(new Date(created_at), 'dd.MM.yyyy'),
          id: index,
          status,
          profile_features: member_profile,
        }),
      )
    : [];

  const initialColumns: ColumnTypes = {
    receivedApplications: {
      id: 'receivedApplications',
      list: [],
    },
    inReview: {
      id: 'inReview',
      list: [],
    },
    inInterview: {
      id: 'inInterview',
      list: [],
    },
    inHired: {
      id: 'inHired',
      list: [],
    },
  };
  const rejectedApplications: RejectedApplicationObject = {
    inRejected: {
      id: 'rejected',
      list: [],
    },
  };

  const [columns, setColumns] = useState(initialColumns);
  const [rejected, setRejected] = useState(rejectedApplications);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(-1);
  useEffect(() => {
    if (selectedApplicationId && applications) {
      const correctApplication = applications.findIndex(
        (application: any) => application.id === selectedApplicationId,
      );
      setSelectedApplication(correctApplication);
      setModalVisible(true);
      dispatch({
        type: 'jobApplication/changeSelectedApplication',
        payload: undefined,
      });
    }
  }, [applications]);
  function sortApplications() {
    applicantsList.map((item: any) => {
      switch (item.status) {
        case 'received':
          initialColumns.receivedApplications.list.push(item);
          break;
        case 'review':
          initialColumns.inReview.list.push(item);
          break;
        case 'interview':
          initialColumns.inInterview.list.push(item);
          break;
        case 'hired':
          initialColumns.inHired.list.push(item);
          break;
        case 'rejected':
          rejectedApplications.inRejected.list.push(item);
          break;
        default:
      }
    });
    setColumns(initialColumns);
    setRejected(rejectedApplications);
  }
  useEffect(() => {
    sortApplications();
  }, [singleAdData]);
  const newDestinationStatus = (destination: string | undefined) => {
    switch (destination) {
      case 'receivedApplications':
        return 'received';
      case 'inReview':
        return 'review';
      case 'inInterview':
        return 'interview';
      case 'inHired':
        return 'hired';
      default:
        return 'received';
    }
  };
  // eslint-disable-next-line consistent-return
  const onDragEnd = ({ source, destination, draggableId }: DropResult) => {
    dispatch(
      updateApplicationStatus({
        id: applicantsList[parseInt(draggableId, 10)].applicationId,
        body: { status: newDestinationStatus(destination?.droppableId) },
      }),
    ).then(() => dispatch(fetchWithId(parseInt(adId, 10))));
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // If the source and destination columns are the same
    // AND if the index is the same, the item isn't moving
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;

    // Set start and end variables
    const start = columns[source.droppableId];
    const end = columns[destination.droppableId];

    // If start is the same as end, we're in the same column
    if (start === end) {
      // Move the item within the list
      // Start by making a new list without the dragged item
      const newList = start.list.filter(
        (_: any, idx: number) => idx !== source.index,
      );

      // Then insert the item at the right location
      newList.splice(destination.index, 0, start.list[source.index]);

      // Then create a new copy of the column object
      const newCol = {
        id: start.id,
        list: newList,
      };

      // Update the state
      setColumns((state) => ({ ...state, [newCol.id]: newCol }));
    } else {
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before
      const newStartList = start.list.filter(
        (_: any, idx: number) => idx !== source.index,
      );

      // Create a new start column
      const newStartCol = {
        id: start.id,
        list: newStartList,
      };

      // Make a new end list array
      const newEndList = end.list;

      // Insert the item into the end list
      newEndList.splice(destination.index, 0, start.list[source.index]);

      // Create a new end column
      const newEndCol = {
        id: end.id,
        list: newEndList,
      };
      // Update the state
      setColumns((state) => ({
        ...state,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol,
      }));
      return null;
    }
  };
  return (
    <>
      {modalVisible ? (
        <ApplicationDetailsModal
          modalToggle={setModalVisible}
          applicantId={selectedApplication}
          applicantDetailsList={applicantsList}
        />
      ) : null}
      <AdDetails adDetails={jobAdData} />
      <DragDropContext onDragEnd={onDragEnd}>
        <ApplicationGrid>
          {statusChanging ? <DragDisableOverlay /> : null}
          {Object.values(columns).map((col, index) => (
            <Column
              col={col}
              adId={adId}
              key={col.id}
              columnIndex={index}
              modalToggle={setModalVisible}
              setSelectedApplication={setSelectedApplication}
              rejectedApplications={rejected.inRejected.list}
            />
          ))}
        </ApplicationGrid>
      </DragDropContext>
    </>
  );
};

export default AdList;
