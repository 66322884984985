import React from 'react';
import styled from 'styled-components';
import { SuperAdminLayout } from 'Components/Layout/SuperAdminLayout';
import SuperAdminUserManagementComponent from 'Components/SuperAdminPage/SuperAdminUserManagement';

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`;

const SuperAdminUserManagement = () => {
  return (
    <SuperAdminLayout>
      <ContentContainer>
        <SuperAdminUserManagementComponent />
      </ContentContainer>
    </SuperAdminLayout>
  );
};
export default SuperAdminUserManagement;
