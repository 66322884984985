import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocationOption } from 'Components/JobGuard/SavedSearches';
import { Selectable } from 'Components/Survey/FieldComponents/Phase2';
import { format, parseISO } from 'date-fns';
import { getJobAdvertisement } from 'Features/JobAdvertisement/jobAdvertisementSlice';
import {
  fetchJobApplications,
  JobApplicationType,
  sendJobApplication,
} from 'Features/JobApplication/jobApplicationSlice';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'Store';
import styled from 'styled-components/macro';
import JobImage from './JobImage';
import { JobAdvertisement } from './JobSearch/JobSearchResults';

const AdvertisementContainer = styled.div`
  .job-container {
    background-color: #ffffff;
    box-shadow: 0 7px 7px -10px rgba(0, 0, 0, 0.41);
    padding: 20px 25px;
    margin-top: 21px;
    .job-info-container {
      flex-direction: column;
      @media screen and ${(props) => props.theme.breakpoints.xs} {
        flex-direction: row;
      }
      display: flex;
      .job-info {
        flex-grow: 1;
        margin-right: 15px;
        h1 {
          color: #2a2a2a;
          font-family: Barlow;
          font-size: 30px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 36px;
          margin: 0;
        }
        .service-provider-info-small {
          display: flex;
          margin-top: 11px;
          align-items: center;
          p {
            margin: 0;
            padding: 0 16px;
            color: #3e3e3e;
            font-family: Barlow;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 1;
            border-right: 1px solid #474747;
            &:first-of-type {
              padding-left: 0;
            }
            &:last-of-type {
              padding-right: 0;
              border-right: none;
            }
          }
        }
        .description {
          padding: 30px 0;
          color: #303030;
          font-family: Barlow !important;
          * {
            font-family: Barlow !important;
          }
          font-size: 16px;
          letter-spacing: 0;
          line-height: 24px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .service-provider-info {
        min-width: 280px;
        margin-bottom: 20px;
        .logo {
          height: 90px;
          background: url(${(props: { job: JobAdvertisement }) =>
            (props.job.organization || {}).logo});
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
        .professional-titles-container {
          display: flex;
          flex-direction: column;
          h3 {
            color: #0a4ea2;
            font-family: Barlow;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;
          }
          .professional-titles {
            display: flex;
            flex-wrap: wrap;
            span {
              color: #252525;
              font-family: Barlow;
              font-size: 14px;
              letter-spacing: 0;
              line-height: 17px;
              text-align: center;
              padding: 3px 15px;
              border-radius: 9.5px;
              background: #e7eaef;
              margin-right: 6px;
              margin-bottom: 6px;
            }
          }
        }
        .info-box {
          margin-top: 20px;
          padding: 16px;
          background-color: #f8f8f8;
          p {
            display: flex;
            justify-content: space-between;
            color: #252525;
            font-family: Barlow;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
            margin: 0;
            &:not(:last-of-type) {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
    .job-apply-form {
      padding-top: 30px;
      border-top: 1px solid #979797;
      h6 {
        color: #474747;
        font-family: Barlow;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
        margin: 0 0 16px 0;
        &.nomargin {
          margin: 0;
        }
      }
      .apply-box {
        background-color: #f8f8f8;
        padding: 8px 17px 25px 17px;
        &.center {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 25px 17px;
        }
        button {
          height: 37px;
          width: 255px;
          border-radius: 6px;
          background-color: #d60c17;
          color: #ffffff;
          font-family: Barlow;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 18px;
          text-align: center;
          border: none;
          text-transform: uppercase;
          &:disabled {
            background-color: #ddd;
          }
        }
        textarea {
          border: 1px solid #e7eaef;
          background-color: #ffffff;
          width: 100%;
          color: #474747;
          font-family: Barlow;
          font-size: 16px;
          font-style: italic;
          letter-spacing: 0;
          line-height: 27px;
          margin: 6px 0;
          padding: 8px 13px;
          outline: none;
        }
        span.thank-you-text {
          color: #474747;
          font-family: Barlow;
          font-size: 16px;
          font-style: italic;
          letter-spacing: 0;
          line-height: 27px;
          margin: 0;
          padding: 8px 13px;
        }
        .agreement-checkbox {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          input {
            height: 16px;
            width: 16px;
            border: 1px solid #dcdddf;
            border-radius: 2px;
            background-color: #ffffff;
          }
          span {
            color: #474747;
            font-family: Barlow;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 1;
            margin-left: 10px;
          }
        }
      }
    }
  }
  > a {
    color: #0663b5;
    font-family: Barlow;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    text-decoration: none;
    svg {
      margin-right: 5px;
    }
  }
`;

const JobAdvertisementDetails = ({ jobId }: { jobId: string }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { current }: { current: JobAdvertisement } = useSelector(
    (state: any) => state.jobAdvertisement,
  );
  const { filters } = useSelector((state: RootState) => state.jobAdvertisement);
  const {
    list: { results },
  } = useSelector((state: RootState) => state.jobApplication);
  const [applyAgree, setApplyAgree] = useState<boolean>(false);
  const [coverLetter, setCoverLetter] = useState<string>('');
  const [applicationSent, setApplicationSent] = useState<boolean>(false);
  useEffect(() => {
    dispatch(getJobAdvertisement(jobId));
  }, [jobId]);
  const applyToJob = async () => {
    const body = {
      job_advertisement: current.id,
      cover_letter: coverLetter,
    };
    await dispatch(sendJobApplication(body));
    setApplicationSent(true);
    setCoverLetter('');
    dispatch(fetchJobApplications());
  };
  if (current.id !== parseInt(jobId, 10)) {
    return <AdvertisementContainer job={current} />;
  }
  const professionalTitles = current.professional_titles
    .map((p) => {
      const titleObj = filters.professional_titles.find((p2) => p2.id === p);
      return titleObj;
    })
    .filter((p) => p);

  return (
    <AdvertisementContainer job={current}>
      <Link to="/jobs">
        <FontAwesomeIcon icon={['fal', 'angle-left']} /> {t('back-to-results')}
      </Link>
      <div className="job-container">
        <JobImage images={current.images} />
        <div className="job-info-container">
          <div className="job-info">
            <h1>{current.title}</h1>
            <div className="service-provider-info-small">
              <p>{(current.organization || {}).name}</p>
              <p>
                {(current.locations || []).map((location) => {
                  return (
                    <Selectable selected={false} key={location.id}>
                      {location.name}
                    </Selectable>
                  );
                })}
              </p>
            </div>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: current.description }} // eslint-disable-line
            />
          </div>
          <div className="service-provider-info">
            <div className="logo" />
            <div className="info-box">
              <p>
                <strong>{t('location')}:</strong>
                <span>
                  {(current.locations || [])
                    .map((location: LocationOption) => {
                      return location.name;
                    })
                    .join(', ')}
                </span>
              </p>
              <p>
                <strong>{t('job-duration')}:</strong>
                <span>{current.duration}</span>
              </p>
              <p>
                <strong>{t('search-ends')}:</strong>
                <span>
                  {format(parseISO(current.search_end_date), 'dd.MM.yyyy')}
                </span>
              </p>
              <p>
                <strong>{t('advertisement-left')}:</strong>
                <span>
                  {format(parseISO(current.search_start_date), 'dd.MM.yyyy')}
                </span>
              </p>
              {current.website !== 'https://' ? (
                <p>
                  <strong>{t('www-site')}:</strong>
                  <a href={current.website} target="_blank" rel="noreferrer">
                    {current.website}
                  </a>
                </p>
              ) : null}
            </div>
            {professionalTitles.length ? (
              <div className="professional-titles-container">
                <h3>{t('professional-titles')}</h3>
                <div className="professional-titles">
                  {professionalTitles
                    .filter(
                      (item, index) =>
                        professionalTitles
                          .map((p) => p?.title)
                          .indexOf(item?.title) === index,
                    )
                    .map((p) => (
                      <span key={p?.id}>{p?.title}</span>
                    ))}
                </div>
              </div>
            ) : null}
            {current.competences.length ? (
              <div className="professional-titles-container">
                <h3>{t('competences')}</h3>
                <div className="professional-titles">
                  {current.competences.map((p) => (
                    <span key={p?.id}>{p?.title}</span>
                  ))}
                </div>
              </div>
            ) : null}
            {current.driving_licenses.length ? (
              <div className="professional-titles-container">
                <h3>{t('driving-license')}</h3>
                <div className="professional-titles">
                  {current.driving_licenses.map((p) => (
                    <span key={p?.id}>{p?.license_type}</span>
                  ))}
                </div>
              </div>
            ) : null}
            {current.language_skill_requirements.length ? (
              <div className="professional-titles-container">
                <h3>{t('language-skills')}</h3>
                <div className="professional-titles">
                  {current.language_skill_requirements.map((p) => (
                    <span key={p?.id}>{p?.language.full_name}</span>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {!current.likeit_id && (
          <>
            {applicationSent ||
            results.some(
              (application: JobApplicationType) =>
                application.job_advertisement === current.id,
            ) ? (
              <div className="job-apply-form center">
                <div className="apply-box center">
                  <span className="thank-you-text">
                    {t('applying-successful')}
                  </span>
                </div>
              </div>
            ) : (
              <div className="job-apply-form">
                <h6>{t('interested-in-job')}</h6>
                <div className="apply-box">
                  <h6 className="nomargin">{t('covering-letter')}</h6>
                  <textarea
                    rows={2}
                    placeholder={t('apply-form-placeholder')}
                    onChange={(e) => setCoverLetter(e.target.value)}
                    value={coverLetter}
                  />
                  <div className="agreement-checkbox">
                    <input
                      type="checkbox"
                      onChange={(e) => setApplyAgree(e.target.checked)}
                    />
                    <span>{t('job-apply-agreement-text')}</span>
                  </div>
                  <button
                    disabled={!applyAgree}
                    type="button"
                    onClick={applyToJob}
                  >
                    {t('apply-for-the-job')}
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </AdvertisementContainer>
  );
};

export default JobAdvertisementDetails;
