import { differenceInMonths } from 'date-fns';

const jobExperienceManager = (jobExperience: any) => {
  const durationInMonths = differenceInMonths(
    new Date(jobExperience.end_date),
    new Date(jobExperience.start_date),
  );
  let durationType = 'month';
  let duration = durationInMonths;

  if (durationInMonths < 1) {
    const durationInDays = Math.ceil(
      (new Date(jobExperience.end_date).getTime() -
        new Date(jobExperience.start_date).getTime()) /
        (1000 * 60 * 60 * 24),
    );
    durationType = 'day';
    duration = durationInDays;
  } else if (durationInMonths % 12 === 0) {
    durationType = 'year';
    duration = durationInMonths / 12;
  }

  return {
    employer: jobExperience.employer,
    workAssignment: jobExperience.work_assignment,
    startYear: parseInt(jobExperience.start_date.split('-')[0], 10),
    duration,
    durationType,
    workDescription: jobExperience.description,
  };
};

export default jobExperienceManager;
