/* eslint-disable no-alert */
/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const TransparentBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
`;
const CloseContainer = styled.div`
  display: flex;
  color: #ffffff;
  font-family: Barlow;
  font-size: 14px;
  align-items: center;
  width: 100%;

  justify-content: flex-end;
  min-height: 40px;
  max-height: 40px;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 60%;
    height: 60%;
  }
`;
const TopCloseButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  color: #ffffff;
  font-size: 1.5em;
  margin-left: 10px;
`;
const ModalBackground = styled.div`
  background: #ffffff;
  width: 100%;
  margin-bottom: 20px;
  overflow: auto;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 60%;
  }
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  label {
    color: #252525;
    font-weight: 600;
    font-family: Barlow;
    font-size: 14px;
    margin-bottom: 10px;
  }
  span {
    color: red;
  }
  .form-select {
    border: 1px solid #d6dce2;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 30px;
    outline: none;
  }
`;
const Title = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 20px;
  margin-bottom: 30px;
`;

const MissingOfficeNotification = ({
  modalToggle,
}: {
  modalToggle: (arg0: boolean) => void;
}) => {
  const { t } = useTranslation();

  return (
    <TransparentBackground>
      <CloseContainer>
        <TopCloseButton onClick={() => modalToggle(false)}>
          <div>{t('close')}</div>
          <CloseIcon icon={['fal', 'times-circle']} />
        </TopCloseButton>
      </CloseContainer>
      <ModalBackground>
        <ModalContent>
          <Title> {t('add-office-for-organization')}</Title>
          <div>
            {t('add-office-for-organization-body1')}{' '}
            <Link to="/admin-panel/asetukset/tiedot">
              {t('organization-details')}
            </Link>{' '}
            {t('add-office-for-organization-body2')}
          </div>
        </ModalContent>
      </ModalBackground>
    </TransparentBackground>
  );
};
export default MissingOfficeNotification;
