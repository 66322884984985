import React from 'react';
import styled from 'styled-components';
import AdminLayout from 'Components/Layout/AdminLayout';
import AdminUserManagementComponent from 'Components/AdminPage/AdminUserManagement';

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`;

const AdminUserManagement = () => {
  return (
    <AdminLayout>
      <ContentContainer>
        <AdminUserManagementComponent />
      </ContentContainer>
    </AdminLayout>
  );
};
export default AdminUserManagement;
