/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'Store';
import { useTranslation } from 'react-i18next';
import {
  fetchTrainingWithId,
  NewTrainingValuesType,
} from 'Features/Training/trainingSlice';
import {
  initLocations,
  initMemberStatus,
  initTrainingType,
} from 'Components/EducationPage/NewTraining/ModifyTrainingInitializers';
import draftToHtml from 'draftjs-to-html';
import NewTrainingSummary from './NewTrainingSummary';
import NewTrainingForm from './NewTrainingForm';

const ContentContainer = styled.div`
  width: 100%;
  flex: 1;
`;
const SurveyContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    flex-direction: row;
  }
`;
const SurveyContent = styled.div`
  width: 100%;
  max-width: 850px;
  padding: 10px;
  background-color: #f4f5f7;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 20px 40px;
  }
`;

const NewTraining = ({ adId }: { adId: string }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [currentFormIndex, setCurrentFormIndex] = React.useState<number>(0);
  const { data } = useSelector((state: any) => state.organization);
  const { results } = data;
  const { singleTrainingData, values } = useSelector(
    (state: RootState) => state.training,
  );
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const companyId =
    values.organization && values.organization.id
      ? values.organization.id
      : undefined;
  const companyName =
    values.organization && values.organization.name
      ? values.organization.name
      : undefined;
  const companyLogo =
    results && companyId
      ? results.find((organization: any) => organization.id === companyId)
      : undefined;
  useEffect(() => {
    dispatch({ type: 'training/resetNewAd' });
    if (adId) {
      dispatch(fetchTrainingWithId(parseInt(adId, 10)));
    }
  }, []);
  useEffect(() => {
    if (singleTrainingData && adId) {
      const newState: NewTrainingValuesType = { ...values };

      newState.training_type = initTrainingType(
        singleTrainingData?.training_type,
        t,
      );
      newState.id = singleTrainingData.id;
      newState.subject = singleTrainingData.subject;
      newState.start_time = singleTrainingData.start_time;
      newState.end_time = singleTrainingData.end_time;
      newState.continuous_training = singleTrainingData.continuous_training;
      newState.enrollment_deadline = singleTrainingData.enrollment_deadline;
      newState.positions = singleTrainingData.positions;
      newState.price = singleTrainingData.price;
      newState.images = singleTrainingData.images;
      newState.member_price = singleTrainingData.member_price;
      newState.target_audience = singleTrainingData.target_audience;
      newState.trainer = singleTrainingData.trainer;
      newState.web_link = singleTrainingData.web_link;
      newState.only_for_members = initMemberStatus(
        singleTrainingData?.only_for_members,
        t,
      );
      newState.subject_areas = singleTrainingData.subject_areas;
      newState.locations = initLocations(
        singleTrainingData?.training_location?.location,
      );

      newState.address = singleTrainingData?.training_location?.address;
      if (singleTrainingData?.description) {
        const desc = convertFromHTML(singleTrainingData.description);
        const content = ContentState.createFromBlockArray(
          desc.contentBlocks,
          desc.entityMap,
        );
        setEditorState(EditorState.createWithContent(content));
        newState.description = draftToHtml(convertToRaw(content));
      }
      newState.organization = singleTrainingData.organization;
      dispatch({ type: 'training/updateNewAd', payload: newState });
    }
  }, [singleTrainingData]);

  return (
    <ContentContainer>
      <SurveyContainer>
        <SurveyContent>
          {companyId ? (
            <NewTrainingForm
              companyId={companyId}
              formIndex={currentFormIndex}
              companyName={companyName}
              onFormIndexChange={(index: number) => setCurrentFormIndex(index)}
              companyLogo={companyLogo?.logo}
              editorState={editorState}
              setEditorState={setEditorState}
              adId={adId}
            />
          ) : null}
        </SurveyContent>
        <NewTrainingSummary companyName={companyName} />
      </SurveyContainer>
    </ContentContainer>
  );
};

export default NewTraining;
