import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

interface TabProps {
  active: boolean;
}

const TabContainer = styled.div`
  display: flex;
  background: transparent;
  user-select: none;
  position: relative;
  top: -27px;
  overflow-x: auto;
  overflow-y: hidden;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    top: -35px;
  }
`;
const Tab = styled.span`
  position: relative;
  bottom: -2px;
  cursor: pointer;
  padding: 4px 3px;
  color: #5c5f63;
  font-family: Barlow;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  background: ${(props: TabProps) => (props.active ? '#fff' : '#e7eaef')};
  border: 1px solid #d6dce2;
  border-radius: 3px 3px 0 0;
  border-bottom: none;
  white-space: nowrap;
  margin-right: 5px;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    font-size: 14px;
    padding: 8px 13px;
  }
`;
const TabIcon = styled(FontAwesomeIcon)`
  color: #a8aeb4;
  margin-right: 10px;
`;

const FormTabs = ({
  currentTab,
  onTabChange,
}: {
  currentTab: number;
  onTabChange: Function;
}) => {
  const { t } = useTranslation();
  return (
    <TabContainer>
      <Tab active={currentTab === 0} onClick={() => onTabChange(0)}>
        {t('application-profile')}
      </Tab>
      <Tab active={currentTab === 1} onClick={() => onTabChange(1)}>
        <TabIcon icon={['far', 'briefcase']} fontSize="1.1em" />
        {t('sent-applications')}
      </Tab>
      <Tab active={currentTab === 2} onClick={() => onTabChange(2)}>
        <TabIcon icon={['far', 'envelope']} fontSize="1.1em" />
        {t('conversation-requests')}
      </Tab>
      <Tab active={currentTab === 3} onClick={() => onTabChange(3)}>
        <TabIcon icon={['far', 'envelope']} fontSize="1.1em" />
        {t('messages')}
      </Tab>
    </TabContainer>
  );
};

export default FormTabs;
