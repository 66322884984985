import React from 'react';
import LoginLayout from 'Components/Layout/LoginLayout';
import AdminRequestPasswordConfirmComponent from 'Components/AdminPage/AdminRequestPasswordConfirm';

const AdminRequestPassword = () => {
  return (
    <LoginLayout>
      <AdminRequestPasswordConfirmComponent />
    </LoginLayout>
  );
};
export default AdminRequestPassword;
