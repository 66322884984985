import React from 'react';
import VaultFieldContent from 'Components/AdminSettings/AdminDetails/PhotoVaultComponents/VaultFieldContent';

const PhotoVaultComponent = () => {
  const [currentFormIndex, setCurrentFormIndex] = React.useState<number>(0);

  return (
    <VaultFieldContent
      formIndex={currentFormIndex}
      onFormIndexChange={(index: number) => setCurrentFormIndex(index)}
    />
  );
};
export default PhotoVaultComponent;
