/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import DropdownMenu from 'Components/DropdownMenu';
import ObjectTableStyles from 'Components/ObjectTable';
import { useTranslation } from 'react-i18next';
import {
  deleteTraining,
  fetchPaginatedFilteredTrainings,
  upcomingStatus,
  historyStatus,
  ongoingStatus,
} from 'Features/Training/trainingSlice';
import { TrainingPagination } from 'Components/FrontendPagination';
import { RootState, useAppDispatch } from 'Store';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';

const {
  ObjectTableBackground,
  ObjectTableContainer,
  ObjectTableTitle,
  ObjectTableTopComponent,
  ObjectTableBottomComponent,
  ObjectTableTop,
  ObjectTableTopNewObject,
  ObjectTableHeader,
  ObjectTableContent,
  ObjectTableItem,
  ObjectTableHeaderItem,
  ObjectTableObjectTitle,
  ObjectTableCustomLink,
  ObjectTableBottomNewObject,
  ObjectTableSortCaretIcon,
  ObjectTableAngleRightIcon,
} = ObjectTableStyles;

const AlignedOptionsBlock = styled(ObjectTableStyles.ObjectTableOptions)`
  margin-left: auto;
`;
const ObjectTableFooter = styled(ObjectTableStyles.ObjectTableFooter)`
  display: flex;
  justify-content: space-between;
  padding: 28px 20px 28px 20px;
`;

const EducationTrainingsComponent = ({ status }: { status: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { paginatedFilteredData } = useSelector(
    (state: RootState) => state.training,
  );
  const { itemIndex } = useSelector((state: RootState) => state.admin);
  const { data } = useSelector((state: RootState) => state.organization);
  const { results } = data;
  const organizationId = results ? results[itemIndex].id : undefined;
  const body = {
    page: 1,
    filters: {
      organizations: organizationId ? [organizationId] : [],
      status,
    },
  };
  useEffect(() => {
    if (organizationId) {
      dispatch(fetchPaginatedFilteredTrainings(body));
    }
  }, [dispatch, organizationId]);
  const [listOrder, setListOrder] = useState({
    trainingName: 1,
    trainingDate: 1,
    publishDate: 1,
    status: 1,
  });
  const [sortedBy, setSortedBy] = useState('title');
  type ColumnType = 'trainingName' | 'trainingDate' | 'publishDate' | 'status';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const changeSort = (newSort: string) => {
    dispatch({
      type: 'jobs/changePage',
      payload: 1,
    });
    dispatch(fetchPaginatedFilteredTrainings(body));
  };
  const sortList = (column: ColumnType) => {
    setSortedBy(column);
    setListOrder({ ...listOrder, [column]: listOrder[column] * -1 });
    if (listOrder[column] * -1 > 0) {
      changeSort(`${column}`);
    } else {
      changeSort(`-${column}`);
    }
  };

  type CaretProps = {
    columnName: 'trainingName' | 'trainingDate' | 'publishDate' | 'status';
  };
  const CaretIcon = ({ columnName }: CaretProps) => {
    if (sortedBy === columnName) {
      if (listOrder[columnName] === 1) {
        return <ObjectTableSortCaretIcon icon={['fas', 'caret-up']} />;
      }
      return <ObjectTableSortCaretIcon icon={['fas', 'caret-down']} />;
    }
    return null;
  };
  const history = useHistory();
  const advertisementOptions = [
    {
      icon: 'pen',
      title: t('edit'),
      action: (objectId: number) => {
        history.push(`/education-panel/ilmoitukset/muokkaa/${objectId}`);
      },
    },
    {
      icon: 'trash',
      title: t('remove-training'),
      action: (objectId: number) => {
        dispatch(deleteTraining(objectId)).then(() =>
          dispatch(fetchPaginatedFilteredTrainings(body)),
        );
      },
    },
  ];
  const parseTrainingDate = (startDate: string, endDate: string) => {
    if (startDate && endDate) {
      const start = format(parseISO(startDate), 'dd.MM.yyyy.');
      const end = format(parseISO(endDate), 'dd.MM.yyyy');
      return `${start} - ${end}`;
    } else if (endDate === null) {
      const start = format(parseISO(startDate), 'dd.MM.yyyy.');
      const end = t('continuous');
      return `${start} - ${end}`;
    }
    return undefined;
  };
  const parseReleaseDate = (releaseDate: string) => {
    if (releaseDate) {
      return format(parseISO(releaseDate), 'dd.MM.yyyy');
    }
    return undefined;
  };
  const renderTrainingsTitle = () => {
    switch (status) {
      case historyStatus:
        return t('past-trainings');
      case upcomingStatus:
        return t('upcoming-educations');
      case ongoingStatus:
        return t('ongoing-trainings');
      default:
        return t('upcoming-educations');
    }
  };
  const activeTrainingItems = paginatedFilteredData
    ? paginatedFilteredData.map(
        (
          item,
          // eslint-disable-next-line consistent-return
        ) => {
          return (
            <ObjectTableItem key={item.id}>
              <ObjectTableObjectTitle>{item.subject}</ObjectTableObjectTitle>
              <ObjectTableHeaderItem>
                {parseTrainingDate(item.start_time, item.end_time)}
              </ObjectTableHeaderItem>
              <ObjectTableHeaderItem>
                {parseReleaseDate(item.created_at)}
              </ObjectTableHeaderItem>
              <AlignedOptionsBlock>
                <ObjectTableCustomLink to={`/education-panel/${item.id}`}>
                  {t('open')}
                  <ObjectTableAngleRightIcon icon={['far', 'angle-right']} />
                </ObjectTableCustomLink>
                <DropdownMenu
                  options={advertisementOptions}
                  objectId={item.id}
                />
              </AlignedOptionsBlock>
            </ObjectTableItem>
          );
        },
      )
    : null;

  return (
    <ObjectTableBackground>
      <ObjectTableTopComponent>
        <ObjectTableTop>
          <ObjectTableTitle>{renderTrainingsTitle()}</ObjectTableTitle>
        </ObjectTableTop>
        <ObjectTableTopNewObject to="/education-panel/uusi">
          {t('add-new-training')}
          <FontAwesomeIcon
            style={{ marginTop: '14px', marginLeft: '8px' }}
            color="#0A4EA2"
            icon={['fal', 'plus-circle']}
          />
        </ObjectTableTopNewObject>
      </ObjectTableTopComponent>
      <ObjectTableBottomComponent>
        <ObjectTableContainer>
          <ObjectTableHeader>
            <ObjectTableHeaderItem onClick={() => sortList('trainingName')}>
              {t('educations')}
              <CaretIcon columnName="trainingName" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderItem onClick={() => sortList('trainingDate')}>
              {t('education-period')}
              <CaretIcon columnName="trainingDate" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderItem onClick={() => sortList('publishDate')}>
              {t('publish-date')}
              <CaretIcon columnName="publishDate" />
            </ObjectTableHeaderItem>
          </ObjectTableHeader>
          <ObjectTableContent>{activeTrainingItems}</ObjectTableContent>
          <ObjectTableFooter>
            <ObjectTableBottomNewObject to="/education-panel/uusi">
              {t('add-new-training')}
              <FontAwesomeIcon
                style={{ marginTop: '14px' }}
                color="#0A4EA2"
                icon={['fal', 'plus-circle']}
              />
            </ObjectTableBottomNewObject>
            <TrainingPagination
              organizationId={organizationId}
              status={status}
            />
          </ObjectTableFooter>
        </ObjectTableContainer>
      </ObjectTableBottomComponent>
    </ObjectTableBackground>
  );
};

export default EducationTrainingsComponent;
