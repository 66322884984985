import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { fetchCompetences } from 'Features/Competence/competenceSlice';
import { fetchProfessionalTitleGroups } from 'Features/ProfessionalTitle/professionalTitleSlice';
import {
  fetchDrivingLicenses,
  DrivingLicenseType,
} from 'Features/DrivingLicense/drivingLicenseSlice';
import {
  createMemberProfile,
  MemberProfileProfessionalTitle,
  updateMemberProfile,
  getLanguages,
  Recommender,
  MemberProfileLocation,
} from 'Features/MemberProfile/memberProfileSlice';
import {
  addDays,
  addMonths,
  addYears,
  differenceInMonths,
  formatISO,
} from 'date-fns';
import { checkActiveSession, updateUser } from 'Features/Auth/authSlice';
import { fetchLocations } from 'Features/NewAdvertisement/newAdvertisementSlice';
import { toast } from 'react-toastify';
import jobExperienceManager from 'Util/jobExperienceManager';
import FormFields from './FormFields';
import FormTabs from './FormTabs';

export const getEndDate = (
  startYear: number,
  duration: number,
  durationType: string,
) => {
  const start = new Date(startYear, 1, 1);
  let date = start;
  switch (durationType) {
    case 'year':
      date = addYears(start, duration);
      break;
    case 'month':
      date = addMonths(start, duration);
      break;
    case 'day':
      date = addDays(start, duration);
      break;
    default:
      break;
  }
  return formatISO(date, { representation: 'date' });
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const TitleArea = styled.div`
  display: flex;
  align-items: center;
  h3 {
    color: #252525;
    font-family: Barlow;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 29px;
    font-weight: 200;
    margin: 0 0 0 15px;
  }
  span {
    border-radius: 6px;
    background-color: #d60c17;
    color: #ffffff;
    font-family: Barlow;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
    padding: 8px 15px;
    font-weight: 600;
    white-space: nowrap;
  }
`;
const Form = styled.div`
  background: #fff;
  flex-grow: 1;
  height: 100%;
  margin-top: 10px;
`;
const SkipForm = styled.div`
  text-align: center;
  margin-top: 16px;
  a {
    display: inline-block;
    color: #0a4ea2;
    margin-bottom: 10px;
  }
  p {
    color: #5c5f63;
    margin: 0;
  }
  a,
  p {
    font-family: Barlow;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
  }
`;

const SurveyForm = ({
  formIndex,
  onFormIndexChange,
}: {
  formIndex: number;
  onFormIndexChange: Function;
}) => {
  const [values, setValues] = useState<{ [key: string]: any }>({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    user: { member_profile: profile, id: userId },
  } = useSelector((state: any) => state.auth);
  const { user, partialProfile } = useSelector((state: any) => state.auth);
  const history = useHistory();
  const location = useLocation();
  React.useEffect(() => {
    dispatch(checkActiveSession());
    dispatch(fetchProfessionalTitleGroups());
    dispatch(fetchCompetences());
    dispatch(fetchDrivingLicenses());
    dispatch(getLanguages());
    dispatch(fetchLocations());
  }, [dispatch]);
  React.useEffect(() => {
    let newValues = {};
    if (profile) {
      newValues = {
        possibility_to_use_own_car: profile.possibility_to_use_own_car,
        drivingLicenses: profile.driving_licenses.map(
          (dl: DrivingLicenseType) => dl.id,
        ),
        competences: profile.competences.map(({ id }: { id: number }) => id),
        languages: profile.language_skills.map((lang: any) => ({
          id: lang.language.id,
          level: lang.level_of_competence,
        })),
        jobExperiences: profile.job_experiences.map((je: any) => {
          return jobExperienceManager(je);
        }),
        ownDescription: profile.additional_info,
        educations: profile.educations.map((ed: any) => ({
          educationInstitute: ed.education_institute,
          educationProgram: ed.education_program,
          educationDescription: ed.description,
          startYear: parseInt(ed.start_date.split('-')[0], 10),
          duration: differenceInMonths(
            new Date(ed.end_date),
            new Date(ed.start_date),
          ),
          durationType: 'month',
        })),
        professionalTitles: profile.professional_titles.map(
          (p: MemberProfileProfessionalTitle) => p.id,
        ),
        locations: profile.locations.map((p: MemberProfileLocation) => p.id),
        recommenders: profile.recommenders.map((r: Recommender) => ({
          name: r.name,
          phone: r.phone,
          email: r.email,
        })),
      };
    }
    if (user) {
      newValues = {
        ...newValues,
        firstName: user.first_name,
        lastName: user.last_name,
        phone: user.phone,
        email: user.email,
      };
    }
    if (!profile && partialProfile) {
      newValues = partialProfile;
    }
    setValues(newValues);
  }, [profile, user, partialProfile]);
  const setMemberProfileValue = (key: string, value: string) => {
    const newState = { ...values };
    newState[key] = value;
    setValues(newState);
  };
  const renderTitle = () => {
    switch (formIndex) {
      case 0:
        return t('title-section-0');
      case 1:
        return t('title-section-1');
      case 2:
        return t('title-section-2');
      case 3:
        return t('title-section-3');
      case 4:
        return t('title-section-4');
      default:
        return t('title-section-0');
    }
  };

  const createOrUpdateProfile = async () => {
    const profileExists = profile !== null;
    const functionToUse: (body: any) => any = profileExists
      ? updateMemberProfile
      : createMemberProfile;
    const body = {
      is_open_profile: true,
      possibility_to_use_own_tools: false,
      possibility_to_use_own_car: values.possibility_to_use_own_car,
      driving_licenses: values.drivingLicenses,
      driving_license_ids: values.drivingLicenses,
      competence_ids: values.competences,
      language_skills: (values.languages || []).map((skill: any) => ({
        language_id: skill.id,
        level_of_competence: skill.level,
      })),
      job_experiences: (values.jobExperiences || []).map((je: any) => ({
        employer: je.employer,
        work_assignment: je.workAssignment,
        start_date: formatISO(new Date(je.startYear, 1, 1), {
          representation: 'date',
        }),
        end_date: getEndDate(je.startYear, je.duration, je.durationType),
        description: je.workDescription,
      })),
      educations: (values.educations || []).map((ed: any) => ({
        education_institute: ed.educationInstitute,
        education_program: ed.educationProgram,
        description: ed.educationDescription,
        start_date: formatISO(new Date(ed.startYear, 1, 1), {
          representation: 'date',
        }),
        end_date: getEndDate(ed.startYear, ed.duration, ed.durationType),
      })),
      additional_info: values.ownDescription,
      professional_title_ids: values.professionalTitles,
      recommenders: values.recommenders,
      location_ids: values.locations,
      user: user.id,
    };
    const requestBody = profile ? { id: profile.id, body } : body;
    const { error }: { error: Error } = await dispatch<any>(
      functionToUse(requestBody),
    );
    if (error) {
      const errorObj: { [key: string]: { [key: string]: any } } = JSON.parse(
        error.message,
      );
      const errors: Array<string> = [];
      Object.keys(errorObj).map((k: string) => {
        Object.keys(errorObj[k]).map((k2: string) => {
          const errorArray: Array<string> = errorObj[k][k2];
          if (k2.indexOf('date') !== -1) {
            if (errors.includes(`${t(k)} - ${t('date')} - ${errorArray[0]}`)) {
              return;
            }
            errors.push(`${t(k)} - ${t('date')} - ${errorArray[0]}`);
            return;
          }
          errors.push(`${t(k)} - ${t(k2)} - ${errorArray[0]}`);
        });
      });
      toast.error(
        <>
          <strong>{t('errors')}:</strong>
          {errors.map((e: string) => (
            <p>
              <strong>{e.split(' - ')[0]}</strong> - {e.split(' - ')[1]} -{' '}
              {e.split(' - ')[2]}
            </p>
          ))}
        </>,
      );
      return;
    }
    const { error: userError }: { error: Error } = await dispatch<any>(
      updateUser({
        id: userId,
        first_name: values.firstName,
        last_name: values.lastName,
        phone: values.phone,
        email: values.email,
      }),
    );
    if (userError) {
      const errorObj: { [key: string]: string } = JSON.parse(userError.message);
      const errors: Array<string> = [];
      Object.keys(errorObj).map((k: string) => {
        Object.keys(errorObj[k]).map((k2: string) => {
          const errorString: string = errorObj[k];
          if (k2.indexOf('date') !== -1) {
            if (errors.includes(`${t(k)} - ${t('date')} - ${errorString}`)) {
              return;
            }
            errors.push(`${t(k)} - ${t('date')} - ${errorString}`);
            return;
          }
          errors.push(`${t(k)} - ${errorString}`);
        });
      });
      toast.error(
        <>
          <strong>{t('errors')}:</strong>
          {errors.map((e: string) => (
            <p>
              <strong>{e.split(' - ')[0]}</strong> - {e.split(' - ')[1]}
            </p>
          ))}
        </>,
      );
      return;
    }
    toast.success(
      profileExists ? t('member-profile-updated') : t('member-profile-created'),
    );
    const callback = new URLSearchParams(location.search).get('callback');
    history.push(callback || '/jobs');
  };
  return (
    <FormContainer>
      <TitleArea>
        <span>{formIndex + 1} / 5</span>
        <h3>{renderTitle()}</h3>
      </TitleArea>
      <Form>
        <FormTabs
          currentTab={formIndex}
          onTabChange={(index: number) => onFormIndexChange(index)}
        />
        <FormFields
          currentTab={formIndex}
          values={values}
          setValue={setMemberProfileValue}
          nextForm={() => onFormIndexChange(formIndex + 1)}
          createProfile={createOrUpdateProfile}
        />
      </Form>
      {!profile && (
        <SkipForm>
          <Link to="/jobs">{t('skip-form-link-text')}</Link>
          <p>{t('skip-form-description')}</p>
        </SkipForm>
      )}
    </FormContainer>
  );
};

export default SurveyForm;
