import React from 'react';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select, { StylesConfig } from 'react-select';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'Store';
import { ProfessionalTitleType } from 'Features/ProfessionalTitle/professionalTitleSlice';
import { Fields } from '../styles';

export const getLanguageSkills = (t: (k: string) => string) => [
  { value: 0, label: t('basics') },
  { value: 1, label: t('good') },
];

export const SelectableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  user-select: none;
  &.margin-small {
    margin-top: 10px;
  }
  & + label {
    margin-top: 28px;
  }
`;
export const Selectable = styled.div`
  border-radius: 9.5px;
  background-color: ${(props: { selected: boolean }) =>
    props.selected ? '#D60C17' : '#E7EAEF'};
  color: ${(props: { selected: boolean }) =>
    props.selected ? '#FFFFFF' : '#252525'};
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  padding: 5px 10px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 12px;
  margin-bottom: 5px;
`;
const Languages = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    &:first-of-type {
      margin-top: 5px;
    }
    input {
      margin-bottom: 0 !important;
    }
    span {
      color: #252525;
      font-family: Barlow;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
      margin-right: 15px;
    }
    svg {
      color: #a8aeb4;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 21px;
      cursor: pointer;
    }
  }
`;

const Phase2 = ({
  values,
  setValue,
  nextForm,
  savePartial,
}: {
  values: { [key: string]: any };
  setValue: Function;
  nextForm: () => void;
  savePartial: () => void;
}) => {
  const {
    professionalTitle: {
      groups: { results: groupResults },
    },
    competence: {
      data: { results: competenceResults },
    },
    memberProfile: { languages: profileLanguages },
  } = useSelector((state: RootState) => state);
  const { profile } = useSelector((state: any) => state.memberProfile);
  const [selectedTitleGroup, setSelectedTitleGroup] = React.useState<{
    value: any;
    label: string;
  } | null>(null);
  const [languageOptions, setLanguageOptions] = React.useState<{
    id: number;
    level: number;
  }>({ id: 0, level: 0 });
  const [titleFilter, setTitleFilter] = React.useState<String>('');
  React.useEffect(() => {
    if (selectedTitleGroup === null && groupResults && groupResults.length) {
      setSelectedTitleGroup({
        value: groupResults[0].id,
        label: groupResults[0].title,
      });
    }
  }, [groupResults, selectedTitleGroup]);
  const { t } = useTranslation();
  const profileLanguageOptions = profileLanguages.map((l) => ({
    value: l.id,
    label: l.full_name,
  }));
  const selectStyling: StylesConfig = {
    container: (provided) => ({
      ...provided,
      width: '205px',
    }),
    control: (provided) => ({
      ...provided,
      border: '1px solid #D6DCE2',
      borderRadius: '3px',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#252525',
      fontFamily: 'Barlow',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '17px',
    }),
  };
  const languageSelectStyling: StylesConfig = {
    container: (provided) => ({
      ...provided,
      width: '130px',
    }),
    control: (provided) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#252525',
      fontFamily: 'Barlow',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '17px',
    }),
    input: (provided) => ({
      ...provided,
      color: '#252525',
      fontFamily: 'Barlow',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '17px',
    }),
  };
  const allProfessionalTitles: ProfessionalTitleType[] = [];
  groupResults.forEach((g) => {
    g.professional_titles.forEach((p) => {
      const newP = { ...p };
      newP.title = `${newP.title} / ${g.title}`;
      allProfessionalTitles.push(newP);
    });
  });
  return (
    <Fields>
      <div className="rows">
        <label>{t('search-professional-titles')}</label>
        <div className="search">
          <div className="search-bar">
            <input
              className="borderless form-select"
              placeholder={t('search-professional-titles-placeholder')}
              onChange={(e) => setTitleFilter(e.target.value)}
            />
            <FontAwesomeIcon icon={['fas', 'search']} />
          </div>
          <Select
            placeholder={t('select')}
            defaultInputValue={t('select')}
            options={groupResults.map((r) => ({
              value: r.id,
              label: r.title,
            }))}
            value={selectedTitleGroup}
            onChange={(value: any) =>
              setSelectedTitleGroup({
                value: value.value,
                label: value.label,
              })
            }
            styles={selectStyling}
          />
        </div>
        <SelectableContainer>
          {titleFilter.length
            ? allProfessionalTitles
                .filter(
                  (pt: any) =>
                    pt.title
                      .toLowerCase()
                      .indexOf(titleFilter.toLowerCase()) !== -1,
                )
                .map((pt: any) => (
                  <Selectable
                    key={pt.id}
                    selected={(values.professionalTitles || []).includes(pt.id)}
                    onClick={() => {
                      let newTitles = [...(values.professionalTitles || [])];
                      if (newTitles.includes(pt.id)) {
                        newTitles = newTitles.filter((n) => n !== pt.id);
                      } else {
                        newTitles.push(pt.id);
                      }
                      setValue('professionalTitles', newTitles);
                    }}
                  >
                    {pt.title}
                  </Selectable>
                ))
            : (
                groupResults.find(
                  (r: any) => r.id === (selectedTitleGroup || {}).value,
                ) || { professional_titles: [] }
              ).professional_titles
                .filter(
                  (pt: any) =>
                    pt.title
                      .toLowerCase()
                      .indexOf(titleFilter.toLowerCase()) !== -1,
                )
                .map((pt: any) => (
                  <Selectable
                    key={pt.id}
                    selected={(values.professionalTitles || []).includes(pt.id)}
                    onClick={() => {
                      let newTitles = [...(values.professionalTitles || [])];
                      if (newTitles.includes(pt.id)) {
                        newTitles = newTitles.filter((n) => n !== pt.id);
                      } else {
                        newTitles.push(pt.id);
                      }
                      setValue('professionalTitles', newTitles);
                    }}
                  >
                    {pt.title}
                  </Selectable>
                ))}
        </SelectableContainer>
        <label>{t('competences-title')}</label>
        <SelectableContainer className="margin-small">
          {competenceResults.map((c: any) => (
            <Selectable
              key={c.id}
              selected={(values.competences || []).includes(c.id)}
              onClick={() => {
                let newCompetences = [...(values.competences || [])];
                if (newCompetences.includes(c.id)) {
                  newCompetences = newCompetences.filter((n) => n !== c.id);
                } else {
                  newCompetences.push(c.id);
                }
                setValue('competences', newCompetences);
              }}
            >
              {c.title}
            </Selectable>
          ))}
        </SelectableContainer>
        <label>{t('add-language-competence')}</label>
        <Languages>
          {(values.languages || []).map(
            (l: { id: number; level: number }, i: number) => (
              <div key={l.id}>
                <span>
                  {
                    profileLanguageOptions.find((l2) => l2.value === l.id)
                      ?.label
                  }
                </span>
                <Select
                  placeholder={t('select')}
                  options={getLanguageSkills(t)}
                  value={getLanguageSkills(t).find(
                    ({ value }: { value: number }) => value === l.level,
                  )}
                  styles={languageSelectStyling}
                  onChange={(selection: any) => {
                    const newLanguages = [...values.languages];
                    newLanguages[i].level = selection.value;
                    setValue('languages', newLanguages);
                  }}
                />
                <FontAwesomeIcon
                  icon={['fal', 'times-circle']}
                  onClick={() => {
                    const newLanguages = values.languages.filter(
                      (l2: any, i2: number) => i2 !== i,
                    );
                    setValue('languages', newLanguages);
                  }}
                />
              </div>
            ),
          )}
        </Languages>
        <div className="search">
          <Select
            placeholder={t('select')}
            options={profileLanguageOptions}
            value={profileLanguageOptions.find(
              ({ value }: { value: number }) => value === languageOptions.id,
            )}
            styles={selectStyling}
            onChange={(value: any) => {
              const newLanguageOption = { ...languageOptions };
              newLanguageOption.id = value.value;
              setLanguageOptions(newLanguageOption);
            }}
          />
          <Select
            placeholder={t('select')}
            options={getLanguageSkills(t)}
            value={getLanguageSkills(t).find(
              ({ value }: { value: number }) => value === languageOptions.level,
            )}
            styles={selectStyling}
            onChange={(value: any) => {
              const newLanguageOption = { ...languageOptions };
              newLanguageOption.level = value.value;
              setLanguageOptions(newLanguageOption);
            }}
          />
          <div
            className="add-skill"
            onClick={() => {
              const languages = [...(values.languages || [])];
              languages.push(languageOptions);
              setValue('languages', languages);
            }}
          >
            <span>{t('add-new-language-skill')}</span>
            <FontAwesomeIcon icon={['fal', 'plus-circle']} />
          </div>
        </div>
      </div>
      <div className="additional-info">
        <div className="selection-details">
          {(values.professionalTitles || []).length > 0 ? (
            <>
              <label>{t('added-professional-titles')}</label>
              {values.professionalTitles.map((pt: any) => {
                let title: { id: number; title: string } | null = null;
                groupResults.forEach((g: any) => {
                  if (g && g.professional_titles) {
                    title =
                      g.professional_titles.find((pt2: any) => pt2.id === pt) ||
                      title;
                  }
                });
                return (
                  <div key={(title || { id: pt }).id} className="selection">
                    <span>{(title || { title: '' }).title}</span>
                    <FontAwesomeIcon
                      icon={['fal', 'times-circle']}
                      onClick={() => {
                        const newPts = values.professionalTitles.filter(
                          (id: number) => id !== pt,
                        );
                        setValue('professionalTitles', newPts);
                      }}
                    />
                  </div>
                );
              })}
            </>
          ) : null}
          {(values.competences || []).length > 0 ? (
            <>
              <label>{t('added-competences')}</label>
              {values.competences.map((c: any) => {
                const competence = competenceResults.find(
                  (c2: any) => c2.id === c,
                );
                return (
                  <div key={(competence || { id: c }).id} className="selection">
                    <span>{(competence || { title: '' }).title}</span>
                    <FontAwesomeIcon
                      icon={['fal', 'times-circle']}
                      onClick={() => {
                        const newCompetences = values.competences.filter(
                          (id: number) => id !== c,
                        );
                        setValue('competences', newCompetences);
                      }}
                    />
                  </div>
                );
              })}
            </>
          ) : null}
          {(values.languages || []).length > 0 ? (
            <>
              <label>{t('added-language-skills')}</label>
              {values.languages.map((l: { id: number; level: number }) => {
                const levelString = getLanguageSkills(t).find(
                  ({ value }: { value: number }) => value === l.level,
                )?.label;
                const nameString = profileLanguageOptions.find(
                  (l2) => l2.value === l.id,
                )?.label;
                return (
                  <div key={l.id} className="selection">
                    <span>
                      {nameString} - {levelString}
                    </span>
                    <FontAwesomeIcon
                      icon={['fal', 'times-circle']}
                      onClick={() => {
                        const newLanguages = values.languages.filter(
                          ({ id }: { id: number }) => id !== l.id,
                        );
                        setValue('languages', newLanguages);
                      }}
                    />
                  </div>
                );
              })}
            </>
          ) : null}
        </div>
        <button type="button" onClick={nextForm}>
          {t('next')}
        </button>
        {!profile && (
          <a onClick={savePartial} href="#top">
            {t('save-and-continue-later')}
          </a>
        )}
      </div>
    </Fields>
  );
};

export default Phase2;
