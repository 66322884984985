import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  fetchSingleOrganization,
  OfficeType,
} from 'Features/Organization/organizationSlice';
import { breakpoints } from 'Components/Layout/MediaQuery';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'Store';
import { Link } from 'react-router-dom';
import {
  AdditionalCompanyDetailsComponent,
  EmptyAdditionalLocationObject,
} from 'Components/AdminSettings/AdminDetails/AdditionalLocationDetails';
import ModifyOrganizationModal from 'Components/AdminSettings/AdminDetails/ModifyOrganizationModal';
import AddNewLocationModal from 'Components/AdminSettings/AdminDetails/AddNewLocationModal';
import OrganizationUserManagement from './OrganizationUsers';
import OrganizationDetails from './OrganizationDetails';

const DetailsBackground = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 30px;
  }
`;
const DetailsContainer = styled.div`
  background: #fff;
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 23px;
  flex-direction: column;
  overflow: auto;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    flex-direction: row;
  }
`;
const DetailsTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const EditProfileButton = styled.div`
  height: 37px;
  border-radius: 6px;
  background-color: #d60c17;
  border: none;
  outline: none;
  color: #ffffff;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  padding: 0px 20px 0px 20px;
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 0px 30px 0px 30px;
  }
`;
const EditIcon = styled(FontAwesomeIcon)`
  margin-right: 0px;
  font-size: 0.8em;
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    margin-right: 8px;
  }
`;
const ReturnContainer = styled.div`
  margin-bottom: 20px;
`;
const ReturnLink = styled(Link)`
  text-decoration: none;
  color: #0a4ea2;
  font-family: Barlow;
  font-weight: 600;
  font-size: 14px;
  margin-right: auto;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
`;
const AngleLeftIcon = styled(FontAwesomeIcon)`
  color: #0a4ea2;
  margin: 0px 10px 0px 0px;
`;
const DetailsTitle = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 24px;
`;

const CompanyDetails = ({ organizationId }: { organizationId: number }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { singleOrganizationData } = useSelector(
    (state: RootState) => state.organization,
  );

  const [addNewLocationModalOpen, setAddNewLocationModalOpen] = useState(false);
  const [editOrganizationModalOpen, setEditOrganizationModalOpen] =
    useState(false);
  useEffect(() => {
    dispatch(fetchSingleOrganization(organizationId));
  }, [dispatch, editOrganizationModalOpen]);
  const offices = singleOrganizationData
    ? singleOrganizationData.offices
    : undefined;
  const companyDetails = {
    companyLogo: singleOrganizationData.logo,
    companyName: singleOrganizationData.name,
    companyVat: singleOrganizationData.vat_number,
  };
  const determineMainOffice = () => {
    const mainOffice = offices
      ? offices.find((office: OfficeType) => {
          return office.is_main_office;
        })
      : undefined;
    return mainOffice;
  };
  const determineAdditionalLocation = () => {
    const additionalOffices = offices
      ? offices.filter((office: OfficeType) => {
          return !office.is_main_office;
        })
      : [];
    return additionalOffices as Array<OfficeType>;
  };
  const mainOffice = determineMainOffice();

  return (
    <>
      {editOrganizationModalOpen ? (
        <ModifyOrganizationModal
          modalToggle={setEditOrganizationModalOpen}
          organizationDetails={singleOrganizationData}
          currentMainOffice={mainOffice}
          offices={offices}
          singleOffice
        />
      ) : null}
      {addNewLocationModalOpen ? (
        <AddNewLocationModal
          modalToggle={setAddNewLocationModalOpen}
          companyId={organizationId}
          companyName={singleOrganizationData.name}
          singleOffice
        />
      ) : null}
      <ReturnContainer>
        <ReturnLink to="/super-admin-panel/organisaatiot" replace>
          <AngleLeftIcon icon={['far', 'angle-left']} />
          {t('return-to-organization-listing')}
        </ReturnLink>
      </ReturnContainer>
      <DetailsTopBar>
        <DetailsTitle>{t('organization-details')}</DetailsTitle>
        <EditProfileButton onClick={() => setEditOrganizationModalOpen(true)}>
          <div>
            {window.innerWidth > breakpoints.xs ? (
              <>
                <EditIcon icon={['fas', 'pen']} /> {t('modify-profile')}
              </>
            ) : (
              <>
                <EditIcon icon={['fas', 'pen']} />
              </>
            )}
          </div>
        </EditProfileButton>
      </DetailsTopBar>
      <DetailsBackground>
        <DetailsContainer>
          <OrganizationDetails
            companyDetails={singleOrganizationData}
            mainOffice={determineMainOffice()}
          />
          {determineAdditionalLocation().length ? (
            <AdditionalCompanyDetailsComponent
              additionalOffices={determineAdditionalLocation()}
              modalToggle={setAddNewLocationModalOpen}
              companyId={organizationId}
              companyName={singleOrganizationData.name}
              singleOffice
            />
          ) : (
            <EmptyAdditionalLocationObject
              modalToggle={setAddNewLocationModalOpen}
            />
          )}
        </DetailsContainer>
      </DetailsBackground>
      <OrganizationUserManagement
        organizationId={organizationId}
        organizationName={companyDetails.companyName}
      />
    </>
  );
};
export default CompanyDetails;
