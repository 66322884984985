import React from 'react';
import styled from 'styled-components/macro';
import DashboardBackground from 'Assets/dashboard-background.jpg';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const Background = styled.div`
  background: url(${DashboardBackground});
  background-size: cover;
  background-position: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    height: 60vh;
    background-position: 50% 35%;
  }
`;
const HeroContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: auto;
  .overlay {
    padding: 30px 10px;
    border-radius: 0 30px 30px 0;
    max-width: 1035px;
    @media screen and ${(props) => props.theme.breakpoints.md} {
      padding: 35px 104px;
    }
  }
`;
const WelcomeText = styled.div`
  color: #ffffff;
  font-family: Barlow;
  font-size: 45px;
  font-weight: bold;
  text-align: center;
  text-shadow: 1px 2px rgb(0 0 0 / 50%);
  text-transform: uppercase;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    font-size: 55px;
  }
`;

const LinkBoxHeader = styled.div<{ alignment: string }>`
  align-self: flex-start;
  height: 48px;
  width: 80%;
  border-radius: 8px 8px 0 0;
  background-color: #d60c17;
  display: flex;
  align-items: center;
  padding-left: 20px;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    align-self: ${({ alignment }) => alignment};
  }
`;
const LinkBoxHeaderText = styled(Link)`
  color: #ffffff;
  font-family: Barlow;
  font-size: 26px;
  font-weight: bold;
`;
const LinkBoxObject = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    max-width: 380px;
    width: 30%;
  }
`;
const LinkBox = styled.div`
  height: 115px;
  width: 80%;
  background: #ffffff;
  display: flex;
  align-items: flex-start;
  padding: 16px 20px 0px 20px;
  .header {
    font-weight: bold;
    margin-bottom: 12px;
  }
  .links {
    display: flex;
    flex-direction: column;
  }
`;

const LinkBoxBase = styled.div<{ alignment: string }>`
  background: #ffffff;
  display: flex;
  justify-content: flex-start;
  border-right: 1px solid #e7eaef;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    justify-content: ${({ alignment }) => alignment};
  }
`;

const BoxLink = styled(Link)`
  text-decoration: none;
  margin-bottom: 8px;
  color: #0a4ea2;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
  .arrow-icon {
    margin-left: 5px;
  }
`;

const BoxAnchorLink = styled.a`
  text-decoration: none;
  margin-bottom: 8px;
  color: #0a4ea2;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
  .arrow-icon {
    margin-left: 5px;
  }
`;

const LinkBoxContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: auto;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    flex-direction: row;
  }
`;

type LinkBoxComponentProps = {
  alignment: 'flex-start' | 'center' | 'flex-end';
  title: string;
  linkPath: string;
  subTitle: string;
  linkTitles: string[];
  linkElements: any[];
};
type LinkElementProps = {
  linkTitle: string;
  linkUrl: string;
};

const LinkBoxComponent = (options: LinkBoxComponentProps) => {
  const { t } = useTranslation();
  return (
    <LinkBoxObject>
      <LinkBoxHeader alignment={options.alignment}>
        <LinkBoxHeaderText to={`/mylly/${options.linkPath}`}>
          {t(options.title)}
        </LinkBoxHeaderText>
      </LinkBoxHeader>
      <LinkBoxBase alignment={options.alignment}>
        <LinkBox>
          <div>
            <div className="header">{t(options.subTitle)}</div>
            <div className="links">
              {options?.linkElements.map((linkElement, index) => {
                // eslint-disable-next-line react/no-array-index-key
                return <div key={index}>{linkElement}</div>;
              })}
            </div>
          </div>
        </LinkBox>
      </LinkBoxBase>
    </LinkBoxObject>
  );
};

const LinkElement = (options: LinkElementProps) => {
  const { t } = useTranslation();
  return (
    <BoxLink to={options.linkUrl}>
      {t(options.linkTitle)}
      <FontAwesomeIcon className="arrow-icon" icon={['fas', 'arrow-right']} />
    </BoxLink>
  );
};

const AnchorElement = (options: LinkElementProps) => {
  const { t } = useTranslation();
  return (
    <BoxAnchorLink href={options.linkUrl}>
      {t(options.linkTitle)}
      <FontAwesomeIcon className="arrow-icon" icon={['fas', 'arrow-right']} />
    </BoxAnchorLink>
  );
};

const FrontPageHero = () => {
  const { t } = useTranslation();
  return (
    <Background>
      <HeroContent>
        <div className="overlay">
          <WelcomeText>{t('front-page-text')}</WelcomeText>
        </div>
      </HeroContent>
      <LinkBoxContainer>
        <LinkBoxComponent
          alignment="flex-start"
          title="job-mill"
          linkPath="duunimylly"
          subTitle="rakennusliitto-emplyment-service"
          linkTitles={[
            'login-as-member',
            'login-as-employer',
            'login-as-household',
          ]}
          linkElements={[
            <LinkElement linkTitle="login-as-member" linkUrl="/jobs" />,
            <LinkElement
              linkTitle="login-as-employer"
              linkUrl="/admin-panel"
            />,
            <LinkElement
              linkTitle="login-as-household"
              linkUrl="/admin-panel"
            />,
          ]}
        />
        <LinkBoxComponent
          alignment="center"
          title="salary-mill"
          linkPath="palkkamylly"
          subTitle="rakennusliitto-salary-service"
          linkTitles={['login-as-member', 'login-as-house-builder']}
          linkElements={[
            <LinkElement linkTitle="login-as-member" linkUrl="/palkanmaksu" />,
            <AnchorElement
              linkTitle="login-as-house-builder"
              linkUrl="https://palkkamylly.palkkaus.fi/household"
            />,
          ]}
        />
        <LinkBoxComponent
          alignment="center"
          title="skill-mill"
          linkPath="taitomylly"
          subTitle="rakennusliitto-training-service"
          linkTitles={['looking-for-training', 'offering-training']}
          linkElements={[
            <LinkElement
              linkTitle="looking-for-training"
              linkUrl="/trainings"
            />,
            <LinkElement
              linkTitle="offering-training"
              linkUrl="/education-panel"
            />,
          ]}
        />
        <LinkBoxComponent
          alignment="flex-end"
          title="direction-mill"
          linkPath="suuntamylly"
          subTitle="rakennusliitto-direction-mill"
          linkTitles={['read-more-direction-mill']}
          linkElements={[
            <LinkElement
              linkTitle="read-more-direction-mill"
              linkUrl="/mylly/suuntamylly"
            />,
          ]}
        />
      </LinkBoxContainer>
    </Background>
  );
};

export default FrontPageHero;
