import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EducationForm from '../FormComponents/EducationForm';
import { getDurationOptions } from '../FormComponents/JobExperinceForm';
import { Fields } from '../styles';
import { AddButton } from './Phase3';

const Phase4 = ({
  values,
  setValue,
  nextForm,
}: {
  values: { [key: string]: any };
  setValue: Function;
  nextForm: () => void;
}) => {
  const { t } = useTranslation();
  const addBlankEducation = () => {
    const newEducations = [...(values.educations || [])];
    newEducations.push({
      id: Date.now(),
      educationInstitute: '',
      educationProgram: '',
      educationDescription: '',
      startYear: '',
      duration: '',
      durationType: '',
    });
    setValue('educations', newEducations);
  };
  const renderEducations = () =>
    (values.educations || []).map((ed: any, index: number) => (
      <EducationForm
        key={ed.id}
        education={ed}
        deleteEducation={() => {
          const newEducations = values.educations.filter(
            (ed2: any, index2: number) => index !== index2,
          );
          setValue('educations', newEducations);
        }}
        onChange={(newEd: any) => {
          const newEducations = [...values.educations];
          newEducations[index] = newEd;
          setValue('educations', newEducations);
        }}
      />
    ));
  return (
    <Fields>
      <div className="rows">
        {renderEducations()}
        <AddButton onClick={addBlankEducation}>
          <span>{t('add-new-education')}</span>{' '}
          <FontAwesomeIcon icon={['fal', 'plus-circle']} />
        </AddButton>
      </div>
      <div className="additional-info">
        <div className="selection-details">
          {(values.educations || []).length > 0 ? (
            <>
              <label>{t('added-educations')}</label>
              {values.educations.map((education: any, i: number) => {
                const durationType = getDurationOptions(t)
                  .find((dt) => dt.value === education.durationType)
                  ?.label.toLowerCase();
                const institution =
                  education.educationInstitute || t('untitled-institute');
                const program =
                  education.educationProgram || t('untitled-program');
                const duration =
                  education.durationType && education.duration
                    ? `${education.duration} ${durationType}`
                    : null;
                const educationString = [institution, program, duration]
                  .filter((str: string) => str)
                  .join(', ');
                return (
                  <div
                    className="work-exp-side"
                    onClick={() => {
                      const newEducations = values.educations.filter(
                        (jb2: any, i2: number) => i2 !== i,
                      );
                      setValue('educations', newEducations);
                    }}
                  >
                    <span>{educationString}</span>
                    <FontAwesomeIcon icon={['fal', 'times-circle']} />
                  </div>
                );
              })}
            </>
          ) : null}
        </div>
        <button type="button" onClick={nextForm}>
          {t('next')}
        </button>
      </div>
    </Fields>
  );
};

export default Phase4;
