import React from 'react';
import FrontPageMillContentComponent from 'Components/FrontPage/FrontPageMillContent';
import MainLayout from 'Components/Layout/MainLayout';
import { useParams } from 'react-router-dom';

const FrontPageMillContent = () => {
  const { contentPage } = useParams<{ contentPage: string }>();
  return (
    <MainLayout>
      <FrontPageMillContentComponent contentPage={contentPage} />
    </MainLayout>
  );
};
export default FrontPageMillContent;
