/* eslint-disable react/require-default-props */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'Store';
import styled from 'styled-components';
import { isTrainingTypeSelected, setAdValues } from 'Util/trainingUtils';
import {
  IsContinuousInput,
  ContinuousInput,
  handleChange,
} from 'Components/EducationPage/NewTraining/NewTraining';
import { Fields } from '../CommonTrainingCreationProps';

import { TrainingType } from '../ModifyTrainingInitializers';

export const SelectableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  user-select: none;
  &.margin-small {
    margin-top: 10px;
  }
  & + label {
    margin-top: 40px;
  }
`;
export const Selectable = styled.div`
  border-radius: 9.5px;
  background-color: ${(props: { selected: boolean }) =>
    props.selected ? '#D60C17' : '#E7EAEF'};
  color: ${(props: { selected: boolean }) =>
    props.selected ? '#FFFFFF' : '#252525'};
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  padding: 5px 10px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 12px;
  margin-bottom: 12px;
`;
const TitleBlock = styled.input`
  border: 1px solid #d6dce2;
  border-radius: 3px;
  padding: 15px;
  outline: none;
`;
const CompanyLogoContainer = styled.div`
  border: 1px solid #d6dce2;
  border-radius: 3px;
  padding: 10px;
`;
const CompanyLogo = styled.div`
  width: 90%;
  height: 90%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
`;
const LeftBlock = styled.div`
  width: 65%;
  padding-right: 20px;
`;
const RightBlock = styled.div`
  width: 35%;
  .logo-info {
    font-family: Barlow;
    font-size: 14px;
    padding-top: 11px;
  }
  .additional-info {
    width: 100%;
  }
`;
export const InfoTooltip = styled(FontAwesomeIcon)`
  margin-left: 10px;
`;
export const TooltipBackground = styled.span`
  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(42, 46, 59, 0.2);
    color: #222;
    text-align: center;
    border: 1px solid #d6dce2;
    font-family: Barlow;
    padding: 5px 5px;
    border-radius: 6px;
    position: absolute;
    font-weight: 500;
    z-index: 1;
    margin-left: 10px;
  }

  .tooltip .tooltiptext::after {
    content: ' ';
    position: absolute;
    top: 15px;
    color: #222;
    right: 100%;
    margin-top: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #00000040 transparent transparent;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;

type TrainingPhase1Props = {
  logoUrl: string;
  companyName?: string;
  nextForm: () => void;
};

const Phase1 = ({ logoUrl, companyName, nextForm }: TrainingPhase1Props) => {
  const { t } = useTranslation();
  const { values } = useSelector((state: RootState) => state.training);
  const trainingTypes: TrainingType[] = [
    {
      id: 0,
      title: t('educations'),
      type: 'training',
    },
    {
      id: 1,
      title: t('web-course'),
      type: 'web',
    },
    {
      id: 2,
      title: t('hybrid'),
      type: 'hybrid',
    },
  ];

  return (
    <Fields style={{ flexDirection: 'column' }}>
      <div className="rows" style={{ width: '100%' }}>
        <label>
          {t('ad-title')} <span>*</span>
        </label>
        <TitleBlock
          value={values.subject || ''}
          placeholder={t('type-ad-title')}
          onChange={(e) => setAdValues('subject', e.target.value)}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: '32px',
          }}
        >
          <LeftBlock>
            <label>
              {t('informant')} <span>*</span>
              <TooltipBackground>
                <span className="tooltip">
                  <InfoTooltip color="#0859AC" icon={['fal', 'info-circle']} />
                  <span className="tooltiptext">
                    {t('informant-information')}
                  </span>
                </span>
              </TooltipBackground>
            </label>
            <p>{companyName}</p>
            <label>
              {t('select-execution-type')} <span>*</span>
            </label>
            <SelectableContainer className="margin-small">
              {trainingTypes
                ? trainingTypes.map((c: TrainingType) => (
                    <Selectable
                      key={c.id}
                      selected={isTrainingTypeSelected(values, c)}
                      onClick={() => {
                        setAdValues('training_type', c);
                      }}
                    >
                      {c.title}
                    </Selectable>
                  ))
                : null}
            </SelectableContainer>
            <IsContinuousInput>
              <ContinuousInput
                key="continuous_training"
                type="checkbox"
                checked={
                  values.continuous_training
                    ? values.continuous_training
                    : false
                }
                onChange={handleChange}
              />
              <label htmlFor="continuous-training">
                {t('continuous-training')}
                <TooltipBackground>
                  <span className="tooltip">
                    <InfoTooltip
                      color="#0859AC"
                      icon={['fal', 'info-circle']}
                    />
                    <span className="tooltiptext">
                      {t('information-continuous')}
                    </span>
                  </span>
                </TooltipBackground>
              </label>
            </IsContinuousInput>
          </LeftBlock>
          <RightBlock>
            <CompanyLogoContainer>
              <CompanyLogo
                style={{
                  backgroundImage: `url(${logoUrl})`,
                  height: '100px',
                }}
              />
            </CompanyLogoContainer>
            <div className="logo-info">
              {t('ad-logo-tooltip')}
              <Link to="/admin-panel/asetukset/tiedot">
                {t('ad-logo-tooltip-link')}
              </Link>
            </div>
            <div className="additional-info">
              <button type="button" onClick={nextForm}>
                {t('next')}
              </button>
            </div>
          </RightBlock>
        </div>
      </div>
    </Fields>
  );
};

export default Phase1;
