/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import actionGenerator from 'Util/actionGenerator';
import { getAxiosInstance } from 'Api';

interface TermsOfService {
  created_at: string;
  employer_terms: string;
  id: number;
  job_seeker_terms: string;
  training_provider_terms: string;
  updated_at: string;
}
interface TermsOfServiceState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  termsOfService: Array<TermsOfService>;
}
const initialState: TermsOfServiceState = {
  loading: 'idle',
  termsOfService: [],
};
const fetchTermsOfService = createAsyncThunk(
  'terms_of_service/fetch_terms_of_service',
  async () => {
    const response = await getAxiosInstance().get(`/terms_of_service/`);
    return response.data;
  },
);
const genericActions = actionGenerator('termsOfService');
const termsOfServiceSlice = createSlice({
  name: genericActions.name,
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder
      .addCase(genericActions.ResetAction, () => initialState)
      .addCase(fetchTermsOfService.fulfilled, (state, action) => {
        state.termsOfService = action.payload;
      })
      .addMatcher(genericActions.isPendingAction, (state) => {
        state.loading = 'pending';
      })
      .addMatcher(genericActions.isRejectedAction, (state) => {
        state.loading = 'rejected';
      })
      .addMatcher(genericActions.isFulfilledAction, (state) => {
        state.loading = 'fulfilled';
      });
  },
});

export default termsOfServiceSlice.reducer;
export { fetchTermsOfService };
