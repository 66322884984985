import React from 'react';
import MainLayout from 'Components/Layout/MainLayout';
import NewHouseholdOrganizationComponent from 'Components/NewHouseholdOrganization';

const NewHouseholdOrganization = () => {
  return (
    <MainLayout>
      <NewHouseholdOrganizationComponent />
    </MainLayout>
  );
};
export default NewHouseholdOrganization;
