import { EditorState } from 'draft-js';
import React from 'react';
import styled from 'styled-components/macro';
import { OfficeType } from 'Features/Organization/organizationSlice';
import { Phase1, Phase2, Phase3, Phase4 } from './FieldComponents';

const FormFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 7px 7px -10px rgba(0, 0, 0, 0.41);
  label {
    color: #222;
    font-family: Barlow;
    font-size: 16px;
    margin-top: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 10px;
    span {
      color: #d60c17;
    }
    &.top-margin {
      margin-top: 10px;
    }
  }
  input.form-select,
  textarea.form-select {
    border: 1px solid #d6dce2;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 30px;
    outline: none;
    &:last-of-type {
      margin-bottom: 10px;
    }
    &.borderless {
      width: 100%;
      max-width: none;
      border-left: none;
      border-right: none;
      border-top: none;
      margin-bottom: 0;
      padding: 15px 0;
    }
  }
  .search {
    display: flex;
    align-items: center;
    user-select: none;
    .add-skill {
      white-space: nowrap;
      margin-left: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        margin-right: 10px;
        color: #252525;
        font-family: Barlow;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 17px;
      }
      svg {
        color: #0a4ea2;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 0;
      }
    }
  }
  .search-bar {
    flex-grow: 1;
    margin-right: 10px;
    position: relative;
    svg {
      right: 0;
      width: 15px;
      height: 15px;
      top: calc(50% - 7.5px);
      right: 10px;
    }
  }
`;

const FormFields = ({
  adId,
  groupResults,
  competenceResults,
  drivingLicenseResults,
  currentTab,
  companyLogo,
  companyName,
  offices,
  values,
  setValue,
  editorState,
  setEditorState,
  nextForm,
  createProfile,
}: {
  adId: string | undefined;
  groupResults: { [key: string]: any };
  competenceResults: { [key: string]: any };
  drivingLicenseResults: { [key: string]: any };
  currentTab: number;
  companyLogo: string;
  companyName: string;
  offices: Array<OfficeType>;
  values: { [key: string]: any };
  setValue: Function;
  editorState: EditorState;
  setEditorState: any;
  nextForm: () => void;
  createProfile: () => void;
}) => {
  const renderFields = () => {
    switch (currentTab) {
      case 0:
        return (
          <Phase1
            values={values}
            setValue={setValue}
            logoUrl={companyLogo}
            offices={offices}
            companyName={companyName}
            nextForm={nextForm}
          />
        );
      case 1:
        return (
          <Phase2 values={values} nextForm={nextForm} setValue={setValue} />
        );
      case 2:
        return (
          <Phase3
            groupResults={groupResults}
            competenceResults={competenceResults}
            drivingLicenseResults={drivingLicenseResults}
            values={values}
            nextForm={nextForm}
            setValue={setValue}
          />
        );
      case 3:
        return (
          <Phase4
            adId={adId}
            values={values}
            setValue={setValue}
            editorState={editorState}
            setEditorState={setEditorState}
            createProfile={createProfile}
          />
        );
      default:
        return (
          <Phase1
            values={values}
            setValue={setValue}
            logoUrl={companyLogo}
            offices={offices}
            companyName={companyName}
            nextForm={nextForm}
          />
        );
    }
  };
  return <FormFieldsContainer>{renderFields()}</FormFieldsContainer>;
};

export default FormFields;
