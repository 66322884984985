import React from 'react';
import LoginLayout from 'Components/Layout/LoginLayout';
import AdminLoginComponent from 'Components/AdminPage/AdminLogin';

const AdminLogin = () => {
  return (
    <LoginLayout>
      <AdminLoginComponent />
    </LoginLayout>
  );
};
export default AdminLogin;
