import React from 'react';
import LoginLayout from 'Components/Layout/LoginLayout';
import AdminRequestPasswordComponent from 'Components/AdminPage/AdminRequestPassword';

const AdminRequestPassword = () => {
  return (
    <LoginLayout>
      <AdminRequestPasswordComponent />
    </LoginLayout>
  );
};
export default AdminRequestPassword;
