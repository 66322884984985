/* eslint-disable camelcase */
/* eslint-disable consistent-return */
import { OfficeType } from 'Features/Organization/organizationSlice';

type LocationType = {
  id: number;
  name: string;
};
export const initLocations = (currentLocations: Array<LocationType>) => {
  const modifiedLocations = currentLocations?.map((location: LocationType) => {
    return {
      id: location.id,
      value: location.name,
      label: location.name,
    };
  });

  return modifiedLocations;
};
export const initOffices = (
  currentOffice: Array<number>,
  officesList: Array<OfficeType>,
) => {
  const officesObject = officesList
    ? officesList.filter((office: OfficeType) =>
        currentOffice.includes(office.id),
      )
    : undefined;
  const modifiedLocations = officesObject?.map((office: OfficeType) => {
    return {
      id: office.id,
      value: office.name,
      label: office.name,
    };
  });
  return modifiedLocations;
};
export const initEmploymentType = (currentEmploymentType: string, t: any) => {
  const employmentType = [
    {
      id: 0,
      title: t('full-time'),
      type: 'full-time',
    },
    {
      id: 1,
      title: t('part-time'),
      type: 'part-time',
    },
    {
      id: 2,
      title: t('practice'),
      type: 'practice',
    },
  ];
  switch (currentEmploymentType) {
    case 'full-time':
      return employmentType[0];
    case 'part-time':
      return employmentType[1];
    case 'practice':
      return employmentType[2];
    default:
      return undefined;
  }
};
export const initJobType = (currentJobType: string, t: any) => {
  const jobTypes = [
    {
      id: 0,
      title: t('work-type-continuous'),
      type: 'continuous',
    },
    {
      id: 1,
      title: t('work-type-fixed'),
      type: 'fixed',
    },
  ];
  switch (currentJobType) {
    case 'continuous':
      return jobTypes[0];
    case 'fixed':
      return jobTypes[1];
    default:
      return undefined;
  }
};
type CompetenceType = {
  id: number;
  title: string;
};
export const initCompetences = (currentCompetences: Array<CompetenceType>) => {
  const competencesObject = currentCompetences
    ? currentCompetences.map((competence: CompetenceType) => {
        return competence.id;
      })
    : [];
  return competencesObject;
};
type DrivingLicenseType = {
  id: number;
  license_type: string;
  license_alias: string;
};
export const initDrivingLicenses = (
  currentLicenses: Array<DrivingLicenseType>,
) => {
  const licenseObject = currentLicenses.map((license: DrivingLicenseType) => {
    return license.id;
  });
  return licenseObject;
};
type LanguageObject = {
  id: number;
  name: string;
  full_name: string;
};
type LanguageType = {
  id: number;
  language: LanguageObject;
  level_of_competence: number;
};
export const initLanguageSkills = (currentLanguages: Array<LanguageType>) => {
  const languagesObject = currentLanguages?.map((lang: LanguageType) => {
    return {
      id: lang.language.id,
      level: lang.level_of_competence,
    };
  });
  return languagesObject;
};
