import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components/macro';

const LoadingIndicatorContainer = styled.div`
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  background: ${({ noBg }: { noBg?: boolean }) =>
    noBg ? 'transparent' : '#33333344'};
  svg {
    animation: rotate 1s linear infinite;
    path {
      fill: #d60c17;
    }
  }
`;

const LoadingIndicator = ({ noBg }: { noBg?: boolean }) => {
  return (
    <LoadingIndicatorContainer noBg={noBg}>
      <FontAwesomeIcon icon={['fas', 'spinner']} />
    </LoadingIndicatorContainer>
  );
};

LoadingIndicator.defaultProps = {
  noBg: false,
};

export default LoadingIndicator;
