/* eslint-disable camelcase */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duplicateAd } from 'Features/JobAdvertisements/advertisementSlice';
import { MemberProfileLocation } from 'Features/MemberProfile/memberProfileSlice';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'Store';
import styled from 'styled-components';
import AdDeleteModal from './AdDeleteModal';

type StatusIndicatorProps = {
  color: string;
};
// #region styles
const AdDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const ItemList = styled.div`
  display: flex;
  min-width: max-content;
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  min-width: max-content;
  justify-content: space-between;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 50px;
`;
const AdTitle = styled.div`
  color: #0a4ea2;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
`;
const AdSubTitle = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
`;
const ActionItem = styled.div`
  user-select: none;
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  padding-right: 20px;
  &:hover {
    cursor: pointer;
  }
`;
const LocationItem = styled.div`
  border-radius: 9.5px;
  background-color: #e8e8e8;
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  margin-left: 4px;
`;
const LocationName = styled.div`
  padding: 1px 10px 1px 10px;
`;

const DetailsItem = styled(ActionItem)`
  display: flex;
  &:hover {
    cursor: default;
  }
`;
const ActionSymbol = styled(FontAwesomeIcon)`
  margin-right: 11px;
  color: #a8aeb4;
`;
const StatusIndicator = styled(FontAwesomeIcon)`
  margin-right: 7px;
  vertical-align: 0px;
  font-size: 0.7em;
  color: ${(props: StatusIndicatorProps) => props.color};
`;
// #endregion
const AdDetails = ({ adDetails }: { adDetails: any }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const superAdmin = pathname.indexOf('super-admin-panel') !== -1;
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useAppDispatch();
  const duplicateAdAction = (adId: number) => {
    dispatch(duplicateAd(adId)).then(() =>
      toast.success(
        <>
          <strong>{t('ad-copy-successfull')}:</strong>
          <p>
            <strong>{adDetails.title}</strong>
          </p>
        </>,
      ),
    );
  };
  const StatusIdicatorObject = ({ status }: { status: any }) => {
    let color = '#70e764';
    let statusText = t('ad-status-closed');
    switch (status) {
      case 'open':
        color = '#70e764';
        statusText = t('ad-status-open');
        break;
      case 'closed':
        color = '#ffcb00';
        statusText = t('ad-status-closed');
        break;
      case 'archived':
        color = '#818181';
        statusText = t('ad-status-archived');
        break;
      default:
        color = '#ffcb00';
        statusText = t('ad-status-open');
        break;
    }
    return (
      <div>
        {t('status')}:
        <StatusIndicator icon={['fas', 'circle']} color={color} />
        {statusText}
      </div>
    );
  };
  return (
    <>
      {showModal ? (
        <AdDeleteModal modalToggle={setShowModal} adDetails={adDetails} />
      ) : null}
      <AdDetailsContainer>
        {adDetails ? (
          <>
            <TopContainer>
              <TitleContainer>
                <AdTitle>{adDetails.title}</AdTitle>
                <AdSubTitle>{adDetails.professional_titles}</AdSubTitle>
              </TitleContainer>
              <ItemList>
                <ActionItem
                  onClick={() =>
                    history.push(
                      `/${
                        superAdmin ? 'super-' : ''
                      }admin-panel/ilmoitukset/muokkaa/${adDetails.id}`,
                    )
                  }
                >
                  <ActionSymbol icon={['far', 'pen']} />
                  {t('modify-ad')}
                </ActionItem>
                <ActionItem onClick={() => duplicateAdAction(adDetails.id)}>
                  <ActionSymbol icon={['far', 'copy']} />
                  {t('make-copy')}
                </ActionItem>
                <ActionItem onClick={() => setShowModal(true)}>
                  <ActionSymbol icon={['far', 'trash']} />
                  {t('remove-ad')}
                </ActionItem>
              </ItemList>
            </TopContainer>
            <ItemList>
              <DetailsItem>
                {t('location')}:
                <LocationItem>
                  {adDetails.locations.map(
                    (location: MemberProfileLocation) => (
                      <LocationName key={location.id}>
                        {location.name}
                      </LocationName>
                    ),
                  )}
                </LocationItem>
              </DetailsItem>
              <DetailsItem>
                {t('publish-date')}: {adDetails.search_start_date}
              </DetailsItem>
              <DetailsItem>
                {t('search_end_date')}: {adDetails.search_end_date}
              </DetailsItem>
              <DetailsItem>
                <StatusIdicatorObject status={adDetails.status} />
              </DetailsItem>
            </ItemList>
          </>
        ) : null}
      </AdDetailsContainer>
    </>
  );
};

export default AdDetails;
