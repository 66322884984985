/* eslint-disable no-console */
import React from 'react';
import styled from 'styled-components';
import AdminNewJobComponent from 'Components/SuperAdminPage/SuperAdminModifyJobAd/ModifyJobAd';
import { SuperAdminLayout } from 'Components/Layout/SuperAdminLayout';
import { useParams } from 'react-router-dom';

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`;

const SuperAdminModifyAd = () => {
  const { adId } = useParams<{ adId: string }>();
  return (
    <SuperAdminLayout>
      <ContentContainer>
        <AdminNewJobComponent adId={adId} />
      </ContentContainer>
    </SuperAdminLayout>
  );
};
export default SuperAdminModifyAd;
