import React, { useEffect, useState } from 'react';
import { SingleAdDataType } from 'Features/JobAdvertisements/advertisementSlice';
import {
  ConversationListPayload,
  fetchConversationDetails,
} from 'Features/Conversation/conversationSlice';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'Store';
import { useTranslation } from 'react-i18next';
import Conversation from './Conversation';

interface MessageDetailsProps {
  advertisement: SingleAdDataType;
  show: boolean;
  conversations: ConversationListPayload | undefined;
  organizationId: number | null;
}

interface MessageDetailsContainerProps {
  show: boolean;
  conversationSelected: boolean;
}

const MessageDetailsContainer = styled.div`
  display: flex;
  visibility: ${(props: MessageDetailsContainerProps) =>
    props.show ? 'visible' : 'hidden'};
  width: 100%;
  min-height: ${(props: MessageDetailsContainerProps) =>
    props.show ? '80vh' : '0'};
  max-height: ${(props: MessageDetailsContainerProps) =>
    props.show ? '80vh' : '0'};
  transition: max-height 0.3s ease-in-out, min-height 0.3s ease-in-out;
  background-color: #f9f9f9;
  overflow-y: hidden;
  .conversations {
    width: ${(props: MessageDetailsContainerProps) =>
      props.conversationSelected ? '40%' : '100%'};
    padding: 20px;
    display: flex;
    flex-direction: column;
    .conversation {
      display: flex;
      border-radius: 7px;
      background-color: #ffffff;
      box-shadow: 0 7px 7px -7px rgba(37, 37, 37, 0.5);
      padding: 15px;
      color: #0a4ea2;
      font-family: Barlow;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 17px;
      cursor: pointer;
      width: 100%;
      &.active {
        border: 2px solid #A6CEFF;
        border-radius: 7px;
        background-color: #F4F8FF;
        box-shadow: 0 7px 7px -7px rgba(37,37,37,0.5);
      }
      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
      span {
        width: calc(100% / 4);
        &:not(:first-of-type) {
          color: #252525;
          font-family: Barlow;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 17px;
          font-weight: 400;
        }
        &:last-of-type {
          padding-left: 15px;
        }
      }
    }
    .headers {
      display: flex;
      margin-bottom: 10px;
      padding 0 15px;
      span {
        width: calc(100% / 4);
        color: #252525;
        font-family: Barlow;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 17px;
        text-align: left;
      }
    }
  }
  .messages {
    width: ${(props: MessageDetailsContainerProps) =>
      props.conversationSelected ? '60%' : '0'};
  }
`;

export default function MessageDetails({
  advertisement, // eslint-disable-line
  show,
  conversations,
  organizationId,
}: MessageDetailsProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { conversationDetails } = useSelector(
    (state: RootState) => state.conversation,
  );
  const [selectedConversation, setSelectedConversation] = useState<
    number | null
  >(null);
  useEffect(() => {
    if (!conversations) {
      return;
    }
    conversations.results.forEach((c) => {
      dispatch(fetchConversationDetails(c.id));
    });
  }, [conversations]);
  if (!conversations) {
    return null;
  }
  const conversationObjs = conversations.results
    .map((c) => conversationDetails[c.id])
    .filter((c) => typeof c !== 'undefined');
  return (
    <MessageDetailsContainer
      show={show}
      conversationSelected={selectedConversation !== null}
    >
      <div className="conversations">
        <div className="headers">
          <span>{t('applicant-name')}</span>
          <span>{t('subject')}</span>
          <span>{t('application-status')}</span>
          <span>{t('messages')}</span>
        </div>
        {conversationObjs.map((c) => {
          if (!advertisement) {
            return null;
          }
          if (!advertisement.job_applications) {
            return null;
          }
          const applications = advertisement.job_applications.filter((app) =>
            c.targeted_audience.users?.includes(app.member_profile.user.id),
          );
          if (!applications.length) {
            return null;
          }
          return (
            <div
              className={`conversation${
                selectedConversation === c.id ? ' active' : ''
              }`}
              key={c.id}
              onClick={() => setSelectedConversation(c.id)}
            >
              <span>
                {applications.length > 1
                  ? `${t('multiple')} (${applications.length})`
                  : `${applications[0].member_profile.user.first_name} ${applications[0].member_profile.user.last_name}`}
              </span>
              <span>{c.subject}</span>
              <span>
                {applications.length > 1
                  ? Array.from(new Set(applications.map((app) => app.status)))
                      .map((status) => t(status))
                      .join(', ')
                  : t(applications[0].status)}
              </span>
              <span>{c.messages.length}</span>
            </div>
          );
        })}
      </div>
      <div className="messages">
        <Conversation
          conversation={conversationObjs.find(
            (c) => c.id === selectedConversation,
          )}
          advertisement={advertisement}
          organizationId={organizationId}
        />
      </div>
    </MessageDetailsContainer>
  );
}
