/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import DashboardBackground from 'Assets/dashboard-background.jpg';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
  ContentType,
  fetchContentManagement,
} from 'Features/ContentManagement/contentManagementSlice';
import { useAppDispatch } from 'Store';
import { unwrapResult } from '@reduxjs/toolkit';

const Background = styled.div`
  background: url(${DashboardBackground});
  background-size: cover;
  background-position: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    background-position: 50% 35%;
  }
`;
const ContentArea = styled.div`
  width: 70%;
  min-height: 80vh;
  padding: 50px;
  background: #ffffff;
`;

const FrontPageHero = ({ contentPage }: { contentPage: string }) => {
  const [currentContent, setCurrentContent] = useState<ContentType>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const findCorrectContent = (contentArray: ContentType[]) => {
    if (contentArray) {
      contentArray.forEach((contentObject) => {
        if (contentObject.name.toUpperCase() === contentPage?.toUpperCase()) {
          setCurrentContent(contentObject);

          return contentObject;
        }
        return undefined;
      });
    }
  };
  useEffect(() => {
    if (contentPage) {
      dispatch(fetchContentManagement())
        .then(unwrapResult)
        .then((res: any) => {
          findCorrectContent(res.results);
        });
    }
  }, [dispatch, contentPage]);

  return (
    <Background>
      <ContentArea>
        <Link to="/">
          <FontAwesomeIcon icon={['fal', 'angle-left']} />{' '}
          {t('return-to-front-page')}
        </Link>
        {currentContent?.content_text && (
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: currentContent?.content_text,
            }}
          />
        )}
      </ContentArea>
    </Background>
  );
};

export default FrontPageHero;
