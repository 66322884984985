import React from 'react';
import styled from 'styled-components/macro';
import Summary from 'Components/SuperAdminPage/SuperAdminFrontpage/Summary';
import RegistrationRequests from 'Components/SuperAdminPage/OrganizationManagement/RegistrationRequests';

const FrontpageBackground = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FrontpageTopItems = styled.div`
  width: 100%;
  height: 252px;
  display: flex;
`;
const FrontpageBottomItems = styled.div`
  padding: 0px 20px 0px 20px;
`;

const SuperFrontpageComponent = () => {
  return (
    <FrontpageBackground>
      <FrontpageTopItems>
        <Summary />
      </FrontpageTopItems>
      <FrontpageBottomItems>
        <RegistrationRequests />
      </FrontpageBottomItems>
    </FrontpageBackground>
  );
};

export default SuperFrontpageComponent;
