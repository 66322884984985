import React from 'react';
import MainLayout from 'Components/Layout/MainLayout';
import Search from 'Components/Search';
import TrainingsResults from 'Components/Trainings/TrainingsResults';

const Trainings = () => {
  return (
    <MainLayout>
      <Search type="training" />
      <TrainingsResults />
    </MainLayout>
  );
};

export default Trainings;
