import TrainingDetails from 'Components/Trainings/TrainingAdvertisementDetails';
import { SuperAdminLayout } from 'Components/Layout/SuperAdminLayout';
import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`;
const SingleTrainingAdvertisement = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <SuperAdminLayout>
      <ContentContainer>
        <TrainingDetails trainingId={id} adminPanel />
      </ContentContainer>
    </SuperAdminLayout>
  );
};

export default SingleTrainingAdvertisement;
