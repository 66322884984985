import React, { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RootState } from 'Store';
import { GroupItem } from 'Util/permissions';
import { checkActiveSession } from './authSlice';

interface PermissionProviderProps {
  permissionCheckFunction: (groups: Array<GroupItem>) => boolean;
  children: ReactNode;
}

export default function PermissionProvider({
  permissionCheckFunction,
  children,
}: PermissionProviderProps) {
  const dispatch = useDispatch();
  const {
    user: { groups },
  } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    dispatch(checkActiveSession());
  }, []);
  if (!groups) {
    return null;
  }

  return permissionCheckFunction(groups) ? (
    <>{children}</>
  ) : (
    <Redirect to="/" />
  );
}
