/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
// no-unused-vars is used for managing try catch at validateCode
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import Logo from 'Assets/rakennusliitto-logo.png';
import { householdOrganizationRegistrationRequest } from 'Features/Organization/organizationRegistrationSlice';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'Store';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { netsDiscovery } from 'Features/Auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';

const Container = styled.div`
  text-align: center;
  border-radius: 3px;
  background-color: #fff;
  margin: 0px auto;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
`;
const LoginTitle = styled.div`
  color: #222;
  margin: 30px 0px;
  font-style: Barlow;
  font-size: 16px;
`;
const LoginForm = styled.form`
  width: 100%;
`;
const LoginLabel = styled.label`
  font-size: 14px;
  font-family: Barlow;
  color: #222;
  display: block;
  margin-bottom: 7px;
  font-weight: bold;
  text-align: left;
  first-letter {
    text-transform: capitalize;
  }
`;
const LoginButton = styled.button`
  cursor: pointer;
  width: 280px;
  border: none;
  background-color: #d60c17;
  color: #ffffff;
  padding: 10px;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 15px;
`;

const LoginInput = styled.input`
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 4px;
  border: none;
  background: #fff;
  border: 1px solid #d6dce2;
  color: #222;
  &:disabled {
    background: #dddddd;
  }
  &:last-of-type {
    margin-bottom: 5px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    flex-direction: row;
  }
`;
const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    margin: 0px 30px;
  }
`;
const SuccessfulRegistration = styled.div`
  width: 100%;
  height: 380px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ReturnButton = styled(LoginButton)``;

type UpdateRegistrationRequestDataType = {
  dataKey: string;
  data: string | number;
};
const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};
const isString = (x: unknown) => {
  return Object.prototype.toString.call(x) === '[object String]';
};

const NewOrganization = () => {
  const query = useQuery();
  const eidentAuthCode = query.get('code');
  const eidentError = query.get('error');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const { isAuthenticated, netsResponse } = useSelector(
    (state: RootState) => state.auth,
  );
  const initialData = {
    is_household: true,
    username: undefined,
  };

  const [registrationRequestData, setRegistrationRequestData] =
    useState(initialData);
  const [identificationError, setIdentificationError] = useState(false);
  const updateRegistrationRequestData = ({
    dataKey,
    data,
  }: UpdateRegistrationRequestDataType) => {
    setRegistrationRequestData({ ...registrationRequestData, [dataKey]: data });
  };

  const failedIdentification = () => {
    setIdentificationError(true);
  };

  const validateCode = async () => {
    if (eidentAuthCode) {
      const encodedAuthCode = encodeURIComponent(eidentAuthCode);
      dispatch(netsDiscovery(encodedAuthCode))
        .then(unwrapResult)
        .catch((_discoveryResponseError: any) => {
          failedIdentification();
        });
    }
  };

  useEffect(() => {
    if (eidentAuthCode && isString(eidentAuthCode)) {
      validateCode();
    }
    if (eidentError) {
      failedIdentification();
    }
  }, [eidentAuthCode, eidentError]);

  const [requestSuccessful, setRequestSuccessful] = useState(false);

  const createNewOrganization = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(
      householdOrganizationRegistrationRequest({
        body: {
          name: `${netsResponse?.given_name} ${netsResponse?.family_name}`,
          first_name: netsResponse?.given_name,
          last_name: netsResponse?.family_name,
          is_household: true,
          username: registrationRequestData.username,
          nets_pid: netsResponse?.pid,
          id_token: netsResponse?.nets_id_token,
        },
      }),
    ).then((data: any) => {
      if (data.payload.status) {
        const errorObj = data.payload.data;
        const errors: Array<string> = [];
        Object.entries(errorObj).map(([key, value]) => {
          errors.push(`${value}`);
        });
        toast.error(
          <>
            <strong>{t('fill-missing-fields')}:</strong>
            {errors.map((e: string) => (
              <p key={e}>
                <strong>{e.split(' . ')[0]}</strong>
              </p>
            ))}
          </>,
        );
      } else {
        setRequestSuccessful(true);
        toast.success(
          <>
            <strong>{t('registration-successful')}</strong>
          </>,
        );
      }
    });
  };

  return (
    <Container>
      <img src={Logo} alt="Rakennusliitto logo" />
      {identificationError ? (
        <SuccessfulRegistration>
          {t('eident-error')}
          <ReturnButton onClick={() => history.push('')}>
            {t('return-to-front-page')}
          </ReturnButton>
        </SuccessfulRegistration>
      ) : (
        <>
          {!requestSuccessful ? (
            <>
              <LoginTitle>{t('fill-household-details')}</LoginTitle>
              <LoginForm onSubmit={createNewOrganization}>
                <ContentContainer>
                  <ContentColumn>
                    <LoginLabel>{t('first-name')}</LoginLabel>
                    <LoginInput
                      type="text"
                      placeholder={t('input-first-name')}
                      disabled
                      value={netsResponse ? netsResponse.given_name : ''}
                      onChange={(e) =>
                        updateRegistrationRequestData({
                          dataKey: 'first_name',
                          data: e.target.value,
                        })
                      }
                    />
                    <LoginLabel>{t('last-name')}</LoginLabel>
                    <LoginInput
                      type="text"
                      placeholder={t('input-last-name')}
                      disabled
                      value={netsResponse ? netsResponse.family_name : ''}
                      onChange={(e) =>
                        updateRegistrationRequestData({
                          dataKey: 'last_name',
                          data: e.target.value,
                        })
                      }
                    />
                  </ContentColumn>
                  <ContentColumn>
                    <LoginLabel>{t('email')}</LoginLabel>
                    <LoginInput
                      type="text"
                      placeholder={t('input-email')}
                      disabled={isAuthenticated}
                      value={
                        isAuthenticated
                          ? initialData.username
                          : registrationRequestData.username
                      }
                      onChange={(e) =>
                        updateRegistrationRequestData({
                          dataKey: 'username',
                          data: e.target.value,
                        })
                      }
                    />
                  </ContentColumn>
                </ContentContainer>
                <LoginButton type="submit">
                  {t('send-household-registration')}
                </LoginButton>
              </LoginForm>
            </>
          ) : (
            <SuccessfulRegistration>
              {t('thank-you-for-household-registration')}
              <ReturnButton onClick={() => history.push('')}>
                {t('return-to-front-page')}
              </ReturnButton>
            </SuccessfulRegistration>
          )}
        </>
      )}
    </Container>
  );
};

export default NewOrganization;
