import React from 'react';
import styled from 'styled-components';
import AdminDetailsComponent from 'Components/AdminSettings/AdminDetails';
import EducationLayout from 'Components/Layout/EducationLayout';

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`;

const EducationDetails = () => {
  return (
    <EducationLayout>
      <ContentContainer>
        <AdminDetailsComponent />
      </ContentContainer>
    </EducationLayout>
  );
};
export default EducationDetails;
