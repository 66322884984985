import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const InformationList = styled.div`
  border-bottom: 3px solid #d6dce2;
  margin-top: 40px;
  margin-right: 108px;
  span {
    color: #a8aeb4;
    font-family: Barlow;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
  }
`;
const InformationListTitle = styled.div`
  color: #a8aeb4;
  font-family: Barlow;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  margin-left: 32px;
`;
const InformationListComponent = ({ listTitle }: { listTitle: string }) => {
  const { t } = useTranslation();

  return (
    <InformationList>
      <InformationListTitle>{t(listTitle)}</InformationListTitle>
    </InformationList>
  );
};

export default InformationListComponent;
