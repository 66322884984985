import React from 'react';
import styled from 'styled-components/macro';
import Navbar from 'Components/Navbar';

const MainContainer = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  margin-top: 60px;
`;

const IframeLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <MainContainer lang="fi">
      <Navbar />
      {children}
    </MainContainer>
  );
};

export default IframeLayout;
