/* eslint-disable no-console */
import React from 'react';
import styled from 'styled-components';
import EducationLayout from 'Components/Layout/EducationLayout';
import NewTraining from 'Components/EducationPage/NewTraining/NewTraining';
import { useParams } from 'react-router-dom';

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`;

const ModifyTrainingAd = () => {
  const { adId } = useParams<{ adId: string }>();
  return (
    <EducationLayout>
      <ContentContainer>
        <NewTraining adId={adId} />
      </ContentContainer>
    </EducationLayout>
  );
};
export default ModifyTrainingAd;
