/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  deleteOrganizationUser,
  fetchUsers,
} from 'Features/UserManagement/userManagementSlice';
import ObjectTableStyles from 'Components/ObjectTable';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UserDeleteConfirmationModal from 'Components/AdminPage/AdminUserManagement/UserDeleteConfirmationModal';
import AddNewUserModal from 'Components/AdminPage/AdminUserManagement/AddNewUserModal';
import { RootState, useAppDispatch } from 'Store';

type UserProps = {
  username: string;
  first_name: string;
  last_name: string;
  id: number;
};

const {
  ObjectTableBackground,
  ObjectTableContainer,
  ObjectTableTitle,
  ObjectTableTopComponent,
  ObjectTableBottomComponent,
  ObjectTableTopNewObjectDiv,
  ObjectTableHeader,
  ObjectTableContent,
  ObjectTableItem,
  ObjectTableFooter,
  ObjectTableBottomNewObjectDiv,
  ObjectTableHeaderItem,
} = ObjectTableStyles;
const HeaderItem = styled(ObjectTableHeaderItem)`
  width: 20%;
  &:first-child {
    width: 25%;
  }
`;
const UserName = styled.div`
  font-size: 14px;
  font-weight: 600;
  width: 25%;
  font-family: Barlow;
  color: #0a4ea2;
`;
const Options = styled.div`
  white-space: nowrap;
  margin-left: auto;
  margin-right: 20px;
  display: flex;
  justify-content: space-evenly;
`;
const DeleteUser = styled.div`
  text-decoration: none;
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  margin-right: 20px;
  &:hover {
    cursor: pointer;
  }
`;
const TrashCanIcon = styled(FontAwesomeIcon)`
  color: #252525;
  margin: 0px 0px 0px 10px;
`;
// #endregion

const OrganizationUsers = ({
  organizationId,
  organizationName,
}: {
  organizationId: number;
  organizationName: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userData } = useSelector((state: RootState) => state.userManagement);
  const organizationUsers = userData
    ? userData.map(({ username, first_name, last_name, id }: UserProps) => ({
        username,
        first_name,
        last_name,
        id,
      }))
    : undefined;
  const [newUserModalOpen, setNewUserModalOpen] = useState(false);
  useEffect(() => {
    dispatch(fetchUsers(organizationId));
  }, [dispatch, organizationId]);
  const UserComponent = ({ user }: { user: UserProps }) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    return (
      <ObjectTableItem>
        {deleteModalOpen ? (
          <UserDeleteConfirmationModal
            modalToggle={setDeleteModalOpen}
            deleteUser={deleteOrganizationUser}
            userDetails={user}
          />
        ) : null}
        <UserName>{user.username}</UserName>
        <HeaderItem>{user.first_name}</HeaderItem>
        <HeaderItem>{user.last_name}</HeaderItem>
        <Options>
          <DeleteUser onClick={() => setDeleteModalOpen(true)}>
            {t('remove')}
            <TrashCanIcon icon={['far', 'trash']} />
          </DeleteUser>
        </Options>
      </ObjectTableItem>
    );
  };
  const organizationUsersList = organizationUsers
    ? organizationUsers.map((user: UserProps) => {
        return <UserComponent key={user.id} user={user} />;
      })
    : [];

  return (
    <>
      {newUserModalOpen ? (
        <AddNewUserModal
          modalToggle={setNewUserModalOpen}
          companyName={organizationName}
          companyId={organizationId}
        />
      ) : null}
      <ObjectTableBackground>
        <ObjectTableTopComponent>
          <ObjectTableTitle>{t('organization-users')}</ObjectTableTitle>
          <ObjectTableTopNewObjectDiv onClick={() => setNewUserModalOpen(true)}>
            {t('add-new-user')}
            <FontAwesomeIcon
              style={{ marginTop: '14px', marginLeft: '8px' }}
              color="#0A4EA2"
              icon={['fal', 'plus-circle']}
            />
          </ObjectTableTopNewObjectDiv>
        </ObjectTableTopComponent>
        <ObjectTableBottomComponent>
          <ObjectTableContainer>
            <ObjectTableHeader>
              <HeaderItem>{t('user')}</HeaderItem>
              <HeaderItem>{t('first-name')}</HeaderItem>
              <HeaderItem>{t('last-name')}</HeaderItem>
            </ObjectTableHeader>
            <ObjectTableContent>{organizationUsersList}</ObjectTableContent>
            <ObjectTableFooter>
              <ObjectTableBottomNewObjectDiv
                onClick={() => setNewUserModalOpen(true)}
              >
                {t('add-new-user')}
                <FontAwesomeIcon
                  style={{ marginTop: '14px' }}
                  color="#0A4EA2"
                  icon={['fal', 'plus-circle']}
                />
              </ObjectTableBottomNewObjectDiv>
            </ObjectTableFooter>
          </ObjectTableContainer>
        </ObjectTableBottomComponent>
      </ObjectTableBackground>
    </>
  );
};

export default OrganizationUsers;
