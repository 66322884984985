import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { StylesConfig } from 'react-select';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
  EmploymentTypeOption,
  getJobAdvertisementSearchFilters,
  ProfessionalTitleOption,
  searchJobAdvertisements,
  ServiceProviderOption,
  JobAdvertisementFilters,
  setSelectedFilters,
} from 'Features/JobAdvertisement/jobAdvertisementSlice';
import { Selectable } from 'Components/Survey/FieldComponents/Phase2';
import { Desktop, Mobile, Tablet } from 'Components/Layout/MediaQuery';
import { LocationOption } from 'Components/JobGuard/SavedSearches';
import { RootState } from 'Store';
import generateJobAdvertisementSearchBody from 'Util/jobAdvertisementSearchBody';

const selectStyles: StylesConfig = {
  container: (provided) => ({
    ...provided,
    minHeight: '44px',
  }),
  control: (provided) => ({
    ...provided,
    border: '1px solid #D6DCE2',
    borderRadius: '3px',
    minHeight: 41,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#252525',
    fontFamily: 'Barlow',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '17px',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#000000',
    fontFamily: 'Barlow',
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '19px',
  }),
};

const SearchFiltersContainer = styled.div`
  display: 'flex';
  flex-direction: column;
  position: ${(props: { mobile?: boolean }) =>
    props.mobile ? 'fixed' : 'relative'};
  left: ${(props: { mobile?: boolean; minimized?: boolean }) =>
    props.mobile && props.minimized ? '-80%' : '0'};
  top: ${(props: { mobile?: boolean }) => (props.mobile ? '60px' : '0')};
  transition: left 0.3s ease-in-out;
  ${(props: { mobile?: boolean; minimized?: boolean }) =>
    props.mobile
      ? `
  width: 80%;
  ${props.minimized ? '' : 'box-shadow: 10px 10px 10px -10px rgba(0,0,0,0.41);'}
  `
      : ''}
  h2 {
    color: #252525;
    font-weight: 100;
    font-family: Barlow;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0;
  }
  .selectables {
    display: flex;
    flex-wrap: wrap;
    > div {
      margin-bottom: 10px;
    }
  }
  .filters {
    margin-top: ${(props: { mobile?: boolean }) =>
      props.mobile ? '0' : '20px'};
    padding: 15px;
    background-color: ${(props: { mobile?: boolean }) =>
      props.mobile ? '#f4f5f7' : '#fff'};
    .single-filter {
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
      label {
        display: block;
        color: #2a2a2a;
        font-family: Barlow;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 1;
        padding-bottom: 10px;
      }
      .text-input {
        position: relative;
        input {
          border: none;
          border-bottom: 1px solid #d6dce2;
          width: 100%;
          outline: none;
          padding: 5px;
          background-color: ${(props: { mobile?: boolean }) =>
            props.mobile ? '#f4f5f7' : '#fff'};
        }
        > svg {
          position: absolute;
          right: 5px;
          top: calc(50% - 7.5px);
          width: 15px;
          height: 15px;
          color: #d60c17;
          font-size: 19px;
          letter-spacing: 0;
          line-height: 19px;
          text-align: center;
        }
      }
    }
    button {
      width: 100%;
      height: 37px;
      border: none;
      border-radius: 6px;
      background-color: #d60c17;
      outline: none;
      color: #ffffff;
      font-family: Barlow;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      margin-top: 25px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
      &:hover {
        background-color: #b60c17;
      }
    }
  }
`;
const MobileContainer = styled.div`
  position: fixed;
  .extend-icon {
    position: absolute;
    color: white;
    font-size: 16px;
    padding: 10px;
    top: 20px;
    right: -36px;
    border-radius: 0 6px 6px 0;
    background-color: #d60c17;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.41);
    cursor: pointer;
  }
`;

const JobSearchFilters = () => {
  const dispatch = useDispatch();
  const { filters, selectedFilters, searchParams } = useSelector(
    (state: RootState) => state.jobAdvertisement,
  );
  const [mobileMinimized, setMobileMinimized] = useState<boolean>(true);
  const { t } = useTranslation();

  const setFilters = (filtersToSelect: JobAdvertisementFilters) => {
    dispatch(setSelectedFilters(filtersToSelect));
  };

  useEffect(() => {
    dispatch(getJobAdvertisementSearchFilters());
  }, []);

  const search = () => {
    const body = generateJobAdvertisementSearchBody(
      searchParams,
      selectedFilters,
    );

    dispatch(searchJobAdvertisements(body));
    setMobileMinimized(true);
  };

  const renderFreeTextSearch = () => (
    <div className="single-filter">
      <label>{t('free-text-search')}</label>
      <div className="text-input">
        <input
          type="text"
          onChange={(e) =>
            setFilters({
              ...selectedFilters,
              free_text_search: e.target.value,
            })
          }
        />
        <FontAwesomeIcon icon={['fal', 'search']} />
      </div>
    </div>
  );

  const renderLocationSearch = () => (
    <div className="single-filter">
      <label>{t('location-filter-title')}</label>
      <div className="text-input">
        <Select
          isMulti
          placeholder={t('location-placeholder')}
          styles={selectStyles}
          options={filters.locations.map((p: LocationOption) => ({
            label: p.name,
            value: p.id,
          }))}
          onChange={(locs: any) =>
            setFilters({
              ...selectedFilters,
              locations: (locs || []).map((loc: any) => loc.value),
            })
          }
        />
      </div>
    </div>
  );

  const renderProfessionalTitleSearch = () => (
    <div className="single-filter">
      <label>{t('professional-title-filter-title')}</label>
      <div className="text-input">
        <Select
          placeholder={t('professional-title-placeholder')}
          styles={selectStyles}
          isMulti
          options={filters.professional_titles.map(
            (p: ProfessionalTitleOption) => ({
              label: p.title,
              value: p.id,
            }),
          )}
          onChange={(titles: any) =>
            setFilters({
              ...selectedFilters,
              professional_titles: (titles || []).map(
                (title: any) => title.value,
              ),
            })
          }
        />
      </div>
    </div>
  );

  const renderEmploymentOptions = () => (
    <div className="single-filter">
      <label>{t('employment')}</label>
      <div className="selectables">
        {filters.employment_types.map((e: EmploymentTypeOption) => (
          <Selectable
            key={e.value}
            selected={selectedFilters.employment_types.includes(e.value)}
            onClick={() =>
              setFilters({
                ...selectedFilters,
                employment_types: selectedFilters.employment_types.includes(
                  e.value,
                )
                  ? selectedFilters.employment_types.filter(
                      (et: string) => et !== e.value,
                    )
                  : [...selectedFilters.employment_types, e.value],
              })
            }
          >
            {e.label}
          </Selectable>
        ))}
      </div>
    </div>
  );

  const renderOrganizationSearch = () => (
    <div className="single-filter">
      <label>{t('organization')}</label>
      <div className="text-input">
        <Select
          placeholder={t('organization-placeholder')}
          styles={selectStyles}
          isMulti
          options={filters.organizations.map((s: ServiceProviderOption) => ({
            label: s.name,
            value: s.id,
          }))}
          onChange={(providers: any) =>
            setFilters({
              ...selectedFilters,
              organizations: (providers || []).map((title: any) => title.value),
            })
          }
        />
      </div>
    </div>
  );

  const renderSearch = () => (
    <>
      <div className="filters">
        {renderFreeTextSearch()}
        {renderLocationSearch()}
        {renderProfessionalTitleSearch()}
        {renderEmploymentOptions()}
        {renderOrganizationSearch()}
        <button type="button" onClick={() => search()}>
          {t('search')}
        </button>
      </div>
    </>
  );

  return (
    <>
      <Desktop>
        <SearchFiltersContainer>
          <h2>{t('targeted-search')}</h2>
          {renderSearch()}
        </SearchFiltersContainer>
      </Desktop>
      <Tablet>
        <MobileContainer>
          <SearchFiltersContainer mobile minimized={mobileMinimized}>
            <div className="extend-icon">
              <FontAwesomeIcon
                icon={['fas', mobileMinimized ? 'search-plus' : 'search-minus']}
              />
            </div>
            {renderSearch()}
          </SearchFiltersContainer>
        </MobileContainer>
      </Tablet>
      <Mobile>
        <MobileContainer>
          <SearchFiltersContainer mobile minimized={mobileMinimized}>
            <div
              className="extend-icon"
              onClick={() => setMobileMinimized((state: boolean) => !state)}
            >
              <FontAwesomeIcon
                icon={['fas', mobileMinimized ? 'search-plus' : 'search-minus']}
              />
            </div>
            {renderSearch()}
          </SearchFiltersContainer>
        </MobileContainer>
      </Mobile>
    </>
  );
};

export default JobSearchFilters;
