import {
  createSlice,
  AnyAction,
  AsyncThunk,
  createAction,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';
import { getAxiosInstance } from 'Api';
import { MemberContact } from '../MemberProfile/memberProfileSlice';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

interface MemberContactListPayload {
  results: Array<MemberContact>;
  count?: number;
  next?: string;
  previous?: string;
}

/* eslint-disable camelcase */
export interface PartialMemberContact {
  contact_message?: string;
  created_at?: string;
  id: number;
  member_profile?: number;
  organization?: number;
  status?: 'pending' | 'accepted' | 'rejected';
}

interface MemberContactState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  createdContact: any;
  list: MemberContactListPayload;
}
/* eslint-enable camelcase */

const initialState: MemberContactState = {
  loading: 'idle',
  createdContact: {},
  list: {
    results: [],
  },
};

const createMemberContact = createAsyncThunk(
  'memberContact/createMemberContact',
  async (body: any) => {
    try {
      const response = await getAxiosInstance().post(`/member_contact/`, body);
      return { data: response.data };
    } catch (err) {
      // @ts-ignore
      throw Error(JSON.stringify(err.response.data));
    }
  },
);

const fetchMemberContacts = createAsyncThunk(
  'memberContact/fetch',
  async () => {
    const response = await getAxiosInstance().get(`/member_contact/`);
    return response.data;
  },
);

const partialUpdateMemberContact = createAsyncThunk(
  'memberContact/partialUpdate',
  async (body: PartialMemberContact) => {
    const response = await getAxiosInstance().patch(
      `/member_contact/${body.id}/`,
      body,
    );
    return response.data;
  },
);

const isPendingAction = (action: AnyAction): action is PendingAction => {
  return action.type.endsWith('/pending');
};
const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return action.type.endsWith('/rejected');
};
const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return action.type.endsWith('/fulfilled');
};

export const resetAction = createAction('reset-tracked-loading-state');

/* eslint-disable no-param-reassign */
const memberContactSlice = createSlice({
  name: 'memberContact',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetAction, () => initialState)
      .addCase(
        createMemberContact.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.createdContact = action.payload.data;
        },
      )
      .addCase(
        fetchMemberContacts.fulfilled,
        (state, action: PayloadAction<MemberContactListPayload>) => {
          state.list = action.payload;
        },
      )
      .addMatcher(isPendingAction, (state) => {
        state.loading = 'pending';
      })
      .addMatcher(isRejectedAction, (state) => {
        state.loading = 'rejected';
      })
      .addMatcher(isFulfilledAction, (state) => {
        state.loading = 'fulfilled';
      });
  },
});
/* eslint-enable no-param-reassign */

export default memberContactSlice.reducer;
export { createMemberContact, fetchMemberContacts, partialUpdateMemberContact };
