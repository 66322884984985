/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RootState, useAppDispatch } from 'Store';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { History, Location } from 'history';
import { fetchDetailedConversations } from 'Features/Conversation/conversationSlice';
import { format, parseISO } from 'date-fns';

const MessageInfo = styled.div`
  display: flex;
  width: 100%;
`;
const MessageInfoTitle = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
`;
const ElementIcon = styled(FontAwesomeIcon)`
  color: #a8aeb4;
  margin-right: 4px;
  min-width: 20px;
  max-width: 20px;
`;
const AngleRightIcon = styled(FontAwesomeIcon)`
  color: #0a4ea2;
  margin: 0px 0px 0px 10px;
  font-size: 0.8em;
`;
const IconContainer = styled.div`
  min-width: 40px;
  padding: 5px;
`;
const MessageDetailsContainer = styled.div`
  width: 100%;
  max-width: 300px;
  padding: 10px;
  display: flex;
`;
const MessageDetailsComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;
const ApplicantDetailsParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const ApplicantDetails = styled.div`
  color: ${(props: { receivedMessages: boolean }) =>
    props.receivedMessages ? '#0a4ea2' : '#333'};
  font-family: Barlow;
  font-size: 14px;
  font-weight: ${(props: { receivedMessages: boolean }) =>
    props.receivedMessages ? 600 : 300};
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const OpenMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  white-space: pre;
`;
const OpenMessageButton = styled.div`
  text-decoration: none;
  color: #0a4ea2;
  text-align: end;
  margin-bottom: 10px;
  &:hover {
    cursor: pointer;
  }
`;
const ArrivalDate = styled.div`
  color: #2a2a2a;
  font-family: Barlow;
  font-size: 12px;
  white-space: nowrap;
`;
const openMessage = (
  location: Location<unknown>,
  history: History<unknown> | string[],
  id: number,
) => {
  if (location.pathname.indexOf('admin-panel') !== -1) {
    history.push(`/admin-panel/viestit/organisaatio?open=${id}`);
  } else {
    history.push(`/education-panel/viestit?open=${id}`);
  }
};

const MessageInformation = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { itemIndex } = useSelector((state: RootState) => state.admin);
  const {
    data: { results: organizationResults },
  } = useSelector((state: RootState) => state.organization);
  const organizationId =
    organizationResults && typeof itemIndex === 'number'
      ? organizationResults[itemIndex]?.id
      : null;
  useEffect(() => {
    if (!organizationId) {
      return;
    }
    dispatch(fetchDetailedConversations(organizationId));
  }, [dispatch, organizationId]);
  const {
    detailedData: { results },
  } = useSelector((state: RootState) => state.conversation);
  const { t } = useTranslation();
  return (
    <MessageInfo>
      <IconContainer>
        <ElementIcon icon={['far', 'envelope']} />
      </IconContainer>
      <MessageDetailsContainer>
        <MessageDetailsComponent>
          <MessageInfoTitle>{t('messages')}</MessageInfoTitle>
          <ApplicantDetailsParent>
            <ApplicantDetails
              receivedMessages={typeof results[0] !== 'undefined'}
            >
              {results[0] ? results[0].subject : t('no-messages-received')}
            </ApplicantDetails>
            {results[0] && results[0].messages.length ? (
              <ArrivalDate>
                {t('received')}{' '}
                {format(
                  parseISO(
                    [...results[0].messages].sort((a, b) =>
                      parseISO(a.created_at) > parseISO(b.created_at) ? -1 : 1,
                    )[0].created_at,
                  ),
                  'dd.MM.yyyy HH:mm',
                )}
              </ArrivalDate>
            ) : null}
          </ApplicantDetailsParent>
        </MessageDetailsComponent>
      </MessageDetailsContainer>
      {results[0] ? (
        <>
          <OpenMessageContainer>
            <OpenMessageButton
              onClick={() => openMessage(location, history, results[0].id)}
            >
              <ElementIcon icon={['far', 'file-alt']} />
              {t('open-conversation')}
              <AngleRightIcon icon={['far', 'angle-right']} />
            </OpenMessageButton>
          </OpenMessageContainer>
        </>
      ) : null}
    </MessageInfo>
  );
};

export default MessageInformation;
