import React from 'react';
import { Phase1, Phase2, Phase3, Phase4 } from './FieldComponents';
import {
  FormFieldsContainer,
  TrainingFormFieldsProps,
} from './CommonTrainingCreationProps';

const FormFields = ({
  currentTab,
  companyLogo,
  companyName,
  editorState,
  setEditorState,
  nextForm,
  createTraining,
  adId,
}: TrainingFormFieldsProps) => {
  const renderFields = () => {
    switch (currentTab) {
      case 0:
        return (
          <Phase1
            logoUrl={companyLogo}
            companyName={companyName}
            nextForm={nextForm}
          />
        );
      case 1:
        return (
          <Phase2
            nextForm={nextForm}
            editorState={editorState}
            setEditorState={setEditorState}
          />
        );
      case 2:
        return <Phase3 nextForm={nextForm} />;
      case 3:
        return <Phase4 createTraining={createTraining} adId={adId} />;
      default:
        return (
          <Phase1
            logoUrl={companyLogo}
            companyName={companyName}
            nextForm={nextForm}
          />
        );
    }
  };
  return <FormFieldsContainer>{renderFields()}</FormFieldsContainer>;
};

export default FormFields;
