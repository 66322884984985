import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MessagesContainer,
  TableContainer,
} from 'Components/AdminPage/AdminMessages';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDetailedConversations } from 'Features/Conversation/conversationSlice';
import { RootState } from 'Store';
import { AddConversationModal } from 'Components/Modals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, parseISO } from 'date-fns';
import { fetchOrganizations } from 'Features/Organization/organizationSlice';
import { fetchAllUsers } from 'Features/UserManagement/userManagementSlice';
import Conversation from 'Components/AdminPage/AdminMessages/Conversation';

export default function SuperAdminMessages() {
  const { t } = useTranslation();
  const [openedCoversations, setOpenedConversations] = useState<Array<number>>(
    [],
  );
  const [showConversationAddModal, setShowConversationAddModal] =
    useState<boolean>(false);
  const {
    detailedData: { results },
  } = useSelector((state: RootState) => state.conversation);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDetailedConversations());
    dispatch(fetchOrganizations());
    dispatch(fetchAllUsers());
  }, []);
  return (
    <MessagesContainer>
      <h3>{t('conversations')}</h3>
      <TableContainer colCount={5}>
        <div className="header">
          <div className="col">{t('conversation')}</div>
          <div className="col" />
          <div className="col" />
          <div className="col">{t('sent')}</div>
          <div className="col" />
        </div>

        {results.map((c) => (
          <>
            <div className="row" key={c.id}>
              <div className="col bolded">{c.subject}</div>
              <div className="col" />
              <div className="col" />
              <div className="col">
                {format(parseISO(c.created_at), 'dd.MM.yyyy HH:mm')}
              </div>
              <div
                className="col bolded clickable"
                onClick={
                  openedCoversations.includes(c.id)
                    ? () =>
                        setOpenedConversations((state) =>
                          state.filter((c2) => c2 !== c.id),
                        )
                    : () => setOpenedConversations((state) => [...state, c.id])
                }
              >
                {openedCoversations.includes(c.id)
                  ? t('close-conversation')
                  : t('open-conversation')}
              </div>
            </div>
            {openedCoversations.includes(c.id) && (
              <Conversation conversation={c} />
            )}
          </>
        ))}
        <div
          className="add-new-message"
          onClick={() => setShowConversationAddModal(true)}
        >
          {t('add-new-message')}
          <FontAwesomeIcon
            style={{ marginTop: '14px', marginLeft: '8px' }}
            color="#0A4EA2"
            icon={['fal', 'plus-circle']}
          />
        </div>
      </TableContainer>
      <AddConversationModal
        show={showConversationAddModal}
        onClose={() => {
          setShowConversationAddModal(false);
          dispatch(fetchDetailedConversations());
        }}
        isAdmin
      />
    </MessagesContainer>
  );
}
