/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import {
  createOrganizationUser,
  fetchSingleUser,
  patchSingleUser,
} from 'Features/UserManagement/userManagementSlice';
import { useAppDispatch } from 'Store';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Tooltip } from 'Components/Blocks';

const TransparentBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
`;
const CloseContainer = styled.div`
  display: flex;
  color: #ffffff;
  font-family: Barlow;
  font-size: 14px;
  align-items: center;
  width: 100%;

  justify-content: flex-end;
  min-height: 40px;
  max-height: 40px;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 60%;
    height: 60%;
  }
`;
const TopCloseButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
`;
const CloseButtonContainer = styled.div`
  display: flex;
  margin: 35px;
  justify-content: flex-end;
`;
const CancelButton = styled.div`
  height: 37px;
  width: 175px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: Barlow;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
  color: #bcc2c6;
`;
const AddUserButtom = styled(CancelButton)`
  margin-left: 20px;
  background-color: #d60c17;
  color: #ffffff;
`;
const CloseIcon = styled(FontAwesomeIcon)`
  color: #ffffff;
  font-size: 1.5em;
  margin-left: 10px;
`;
const ModalBackground = styled.div`
  background: #ffffff;
  width: 100%;
  margin-bottom: 20px;
  overflow: auto;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 60%;
  }
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  label {
    color: #252525;
    font-weight: 600;
    font-family: Barlow;
    font-size: 14px;
    margin-bottom: 10px;
  }
  span {
    color: red;
  }
  .form-select {
    border: 1px solid #d6dce2;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 30px;
    outline: none;
  }
`;
const Title = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 20px;
  margin-bottom: 30px;
`;
const NewUserForm = styled.form`
  display: flex;
  flex-direction: column;
`;
const CompanySelect = styled(Select)`
  width: 204px;
`;
const StyledCheckbox = styled.div`
  height: 50px;
  margin-right: auto;
  color: #252525;
  font-size: 14px;
  font-family: Barlow;
`;
type UserType = {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  username: string;
  is_admin: boolean;
  organizations: Array<number>;
  email: string;
};
type OrganizationObjects = {
  id: number;
  label: string;
  value: number;
};
const defaultProps = {
  userId: undefined,
};
const AddNewUserModal = ({
  modalToggle,
  valueLabelPairs,
  userId,
  fetchPaginatedUserWithParams,
}: {
  modalToggle: (arg0: boolean) => void;
  valueLabelPairs: Array<OrganizationObjects>;
  userId?: number;
  fetchPaginatedUserWithParams: () => Promise<any>;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedCompany, setSelectedCompany] =
    useState<Array<OrganizationObjects>>();
  const [companyCache, setCompanyCache] =
    useState<Array<OrganizationObjects>>();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const selectedCompanies = (value: Array<OrganizationObjects> | undefined) => {
    if (value && value.length > 0) {
      const companies = value.map((company: OrganizationObjects) => {
        return company.value;
      });
      return companies;
    }
    return [];
  };
  const initialUserState = {
    username: '',
    first_name: '',
    phone: '',
    id: undefined,
    last_name: '',
    is_admin: false,
    organizations: [],
  };
  const [newUser, setNewUser] = useState<any>(initialUserState);
  const setUserValues = (
    key: string,
    value: string | string[] | undefined | boolean | number[],
  ) => {
    const newState = { ...newUser };
    newState[key] = value;
    setNewUser(newState);
  };
  const updateCompanies = (value: Array<OrganizationObjects> | undefined) => {
    setSelectedCompany(value);
    setUserValues('organizations', selectedCompanies(value));
  };
  const initializeOrganizations = (
    userOrganizations: Array<number> | undefined,
  ) => {
    if (userOrganizations) {
      const orgs: any = userOrganizations.map((item: number) =>
        valueLabelPairs.find(
          (valuePair: OrganizationObjects) => valuePair.value === item,
        ),
      );
      if (orgs) {
        updateCompanies(orgs);
      }
    }
  };
  const populateDataFields = (userData: UserType) => {
    if (userData) {
      initializeOrganizations(userData.organizations);
      setNewUser(userData);
      setIsSuperAdmin(userData.is_admin);
    } else {
      setNewUser(initialUserState);
      setIsSuperAdmin(false);
    }
  };

  useEffect(() => {
    if (userId) {
      dispatch(fetchSingleUser(userId)).then(({ payload }: any) => {
        if (payload) {
          populateDataFields(payload);
        }
      });
    }
  }, [dispatch, userId]);

  const handleSubmission = () => {
    if (userId) {
      dispatch(patchSingleUser(newUser)).then(({ error }: any) => {
        if (error) {
          const errorObj: {
            [key: string]: { [key: string]: any };
          } = JSON.parse(error.message);
          const errors: Array<string> = [];
          Object.entries(errorObj).map(([key, value]) => {
            const errorKey = `newuser-${key}`;
            errors.push(`${t(errorKey)} - ${value}`);
          });
          // eslint-disable-next-line no-undef
          toast.error(
            <>
              <strong>{t('fill-missing-fields')}:</strong>
              {errors.map((e: string) => (
                <p key={e}>
                  <strong>{e.split(' . ')[0]}</strong>
                </p>
              ))}
            </>,
          );
        } else {
          fetchPaginatedUserWithParams().then(() => modalToggle(false));
        }
      });
    } else {
      dispatch(createOrganizationUser({ body: JSON.stringify(newUser) })).then(
        ({ error }: any) => {
          if (error) {
            const errorObj: {
              [key: string]: { [key: string]: any };
            } = JSON.parse(error.message);
            const errors: Array<string> = [];
            Object.entries(errorObj).map(([key, value]) => {
              const errorKey = `newuser-${key}`;
              errors.push(`${t(errorKey)} - ${value}`);
            });
            // eslint-disable-next-line no-undef
            toast.error(
              <>
                <strong>{t('fill-missing-fields')}:</strong>
                {errors.map((e: string) => (
                  <p key={e}>
                    <strong>{e.split(' . ')[0]}</strong>
                  </p>
                ))}
              </>,
            );
          } else {
            fetchPaginatedUserWithParams().then(() => modalToggle(false));
          }
        },
      );
    }
  };

  const updateAdminStatus = (value: boolean) => {
    setIsSuperAdmin(value);
    const newState = { ...newUser };
    if (value) {
      newState.organizations = [];
      setCompanyCache(selectedCompany);
      setSelectedCompany([]);
    } else {
      newState.organizations = selectedCompanies(companyCache);
      setSelectedCompany(companyCache);
    }
    newState.is_admin = value;
    setNewUser(newState);
  };
  return (
    <TransparentBackground>
      <CloseContainer>
        <TopCloseButton onClick={() => modalToggle(false)}>
          <div>{t('close')}</div>
          <CloseIcon icon={['fal', 'times-circle']} />
        </TopCloseButton>
      </CloseContainer>
      <ModalBackground>
        <ModalContent>
          <Title> {t('add-new-user')}</Title>
          <NewUserForm>
            <label>
              {t('email')} <span>*</span>
            </label>
            <input
              className="form-select"
              size={30}
              value={newUser.username}
              placeholder={t('email')}
              onChange={(e) => setUserValues('username', e.target.value)}
            />
            <label>{t('first-name')}</label>
            <input
              className="form-select"
              size={30}
              value={newUser.first_name}
              placeholder={t('first-name')}
              onChange={(e) => setUserValues('first_name', e.target.value)}
            />
            <label>
              {t('phone')} <Tooltip tooltipText="phone-number-tooltip" />
            </label>
            <input
              className="form-select"
              size={30}
              value={newUser.phone}
              placeholder={t('phone-placeholder')}
              onChange={(e) => setUserValues('phone', e.target.value)}
            />
            <label>{t('last-name')}</label>
            <input
              className="form-select"
              size={30}
              value={newUser.last_name}
              placeholder={t('last-name')}
              onChange={(e) => setUserValues('last_name', e.target.value)}
            />
            <label>{t('super-admin')}</label>

            <StyledCheckbox>
              <input
                type="checkbox"
                checked={isSuperAdmin}
                onChange={() => updateAdminStatus(!isSuperAdmin)}
              />
            </StyledCheckbox>
            <label>
              {t('organization')} <span>*</span>
            </label>
            <CompanySelect
              placeholder="Valitse..."
              isMulti
              isDisabled={isSuperAdmin}
              value={selectedCompany}
              options={valueLabelPairs}
              // @ts-ignore
              onChange={(value: Array<OrganizationObjects>) =>
                updateCompanies(value)
              }
            />
          </NewUserForm>
        </ModalContent>
        <CloseButtonContainer>
          <CancelButton onClick={() => modalToggle(false)}>
            {t('cancel')}
          </CancelButton>
          <AddUserButtom onClick={() => handleSubmission()}>
            {t('add-new-user')}
          </AddUserButtom>
        </CloseButtonContainer>
      </ModalBackground>
    </TransparentBackground>
  );
};

AddNewUserModal.defaultProps = defaultProps;
export default AddNewUserModal;
