import React from 'react';
import styled from 'styled-components/macro';
import {
  ConversationDetailsPayload,
  ConversationMessage,
} from 'Features/Conversation/conversationSlice';
import { parseISO, format } from 'date-fns';
import { RootState } from 'Store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MessageContainer = styled.div`
  padding: 0 40px;
  &:nth-of-type(even) {
    border-radius: 6px 6px 0 0;
    background-color: #eef2f6;
  }
  .sender {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    span {
      &:first-of-type {
        color: #0a4ea2;
        font-family: Barlow;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 17px;
      }
      &:last-of-type {
        color: #252525;
        font-family: 'Source Code Pro';
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
        text-align: right;
      }
    }
  }
  .subject {
    color: #252525;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    padding: 15px 0;
    border-bottom: 1px solid #dcdddf;
  }
  .content {
    color: #252525;
    font-family: Barlow;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    padding: 20px 0;
    white-space: pre-wrap;
  }
  .read-by {
    color: #252525;
    font-family: 'Source Code Pro';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    padding-bottom: 20px;
  }
`;

export default function Message({
  message,
  conversation,
  organizationId,
}: {
  message: ConversationMessage;
  conversation: ConversationDetailsPayload;
  organizationId?: number | null;
}) {
  const getMessageSender = () => {
    switch (message.sender.sender_type) {
      case 'user':
        return `${message.sender.user.first_name} ${message.sender.user.last_name}`;
      case 'organization':
        return `${message.sender.organization.name}`;
      default:
        return null;
    }
  };
  const { user } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  return (
    <MessageContainer>
      <div className="sender">
        <span>{getMessageSender()}</span>
        <span>{format(parseISO(message.created_at), 'dd.MM.yyyy HH:mm')}</span>
      </div>
      <div className="subject">{conversation.subject}</div>
      <div className="content">{message.content}</div>
      {((message.sender.organization &&
        organizationId &&
        message.sender.organization.id === organizationId) ||
        (message.sender.user &&
          !organizationId &&
          message.sender.user.id === user.id)) &&
        message.read_by.some(
          (reader) =>
            (!organizationId && reader.user && reader.user.id !== user.id) ||
            (!organizationId && reader.organization) ||
            (organizationId &&
              reader.organization &&
              reader.organization.id !== organizationId) ||
            (organizationId && reader.user),
        ) && (
          <div className="read-by">
            <FontAwesomeIcon icon={['fas', 'check-circle']} /> {t('read')}
          </div>
        )}
    </MessageContainer>
  );
}

Message.defaultProps = {
  organizationId: null,
};
