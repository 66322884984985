import {
  createSlice,
  AnyAction,
  AsyncThunk,
  createAction,
  PayloadAction,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { getAxiosInstance } from 'Api';
import { SearchParams } from 'Components/Search';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

export interface ProfessionalTitleOption {
  id: number;
  title: string;
}

export interface EmploymentTypeOption {
  label: string;
  value: string;
}

export interface ServiceProviderOption {
  id: number;
  name: string;
}

/* eslint-disable camelcase */
export interface JobAdvertisementFilters {
  free_text_search: string;
  professional_titles: Array<number>;
  employment_types: Array<string>;
  organizations: Array<number>;
  locations: Array<number>;
  page?: number;
}

interface JobAdvertisementState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  shrinked: Array<number>;
  data: Object;
  current: Object;
  defaultView: boolean;
  filters: {
    professional_titles: Array<ProfessionalTitleOption>;
    employment_types: Array<EmploymentTypeOption>;
    organizations: Array<ServiceProviderOption>;
    locations: Array<ServiceProviderOption>;
  };
  selectedFilters: JobAdvertisementFilters;
  searchParams: SearchParams;
  currentPage: number;
}

const initialState: JobAdvertisementState = {
  loading: 'idle',
  shrinked: [],
  data: {},
  current: {},
  defaultView: true,
  currentPage: 1,
  filters: {
    professional_titles: [],
    employment_types: [],
    organizations: [],
    locations: [],
  },
  selectedFilters: {
    free_text_search: '',
    professional_titles: [],
    employment_types: [],
    organizations: [],
    locations: [],
  },
  searchParams: {
    location: null,
    search: '',
  },
};

const fetchJobAdvertisements = createAsyncThunk(
  'jobAdvertisement/fetch',
  async () => {
    const response = await getAxiosInstance().get(`/jobs/`);
    return { data: response.data };
  },
);

const getJobAdvertisement = createAsyncThunk(
  'jobAdvertisement/get',
  async (id: string) => {
    const response = await getAxiosInstance().get(`/jobs/${id}/`);
    return { data: response.data };
  },
);

const getJobAdvertisementSearchFilters = createAsyncThunk(
  'jobAdvertisement/fetch-filters',
  async () => {
    const response = await getAxiosInstance().get(
      `/job_search/available_filters/`,
    );
    return { data: response.data };
  },
);

const searchJobAdvertisements = createAsyncThunk(
  'jobAdvertisement/search',
  async (body: JobAdvertisementFilters) => {
    if (body.page) {
      const { page } = body;
      delete body.page; // eslint-disable-line
      const response = await getAxiosInstance().post(
        `/job_search/?page=${page}`,
        body,
      );
      return { data: response.data, page };
    }
    const response = await getAxiosInstance().post(`/job_search/`, body);
    return { data: response.data };
  },
);

const setSelectedFilters = createAction(
  'jobAdvertisement/setSelectedFilters',
  (filters: JobAdvertisementFilters) => ({
    payload: filters,
  }),
);

export const setSearchParams = createAction(
  'jobAdvertisement/setSearchParams',
  (params: SearchParams) => ({
    payload: params,
  }),
);
/* eslint-enable camelcase */

const isPendingAction = (action: AnyAction): action is PendingAction => {
  return action.type.endsWith('/pending');
};
const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return action.type.endsWith('/rejected');
};
const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return action.type.endsWith('/fulfilled');
};

export const resetAction = createAction('reset-tracked-loading-state');

/* eslint-disable no-param-reassign */
const jobAdvertisementSlice = createSlice({
  name: 'jobAdvertisement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetAction, () => initialState)
      .addCase(
        fetchJobAdvertisements.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.data = action.payload.data;
        },
      )
      .addCase(
        getJobAdvertisement.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.current = action.payload.data;
        },
      )
      .addCase(
        getJobAdvertisementSearchFilters.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.filters = action.payload.data;
        },
      )
      .addCase(
        searchJobAdvertisements.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.defaultView = false;
          state.data = action.payload.data;
          state.currentPage = action.payload.page || 1;
        },
      )
      .addCase(setSearchParams, (state, action: PayloadAction<any>) => {
        state.searchParams = action.payload;
      })
      .addCase(setSelectedFilters, (state, action: PayloadAction<any>) => {
        state.selectedFilters = action.payload;
      })
      .addMatcher(isPendingAction, (state) => {
        state.loading = 'pending';
      })
      .addMatcher(isRejectedAction, (state) => {
        state.loading = 'rejected';
      })
      .addMatcher(isFulfilledAction, (state) => {
        state.loading = 'fulfilled';
      });
  },
});
/* eslint-enable no-param-reassign */

export default jobAdvertisementSlice.reducer;
export {
  fetchJobAdvertisements,
  getJobAdvertisement,
  getJobAdvertisementSearchFilters,
  searchJobAdvertisements,
  setSelectedFilters,
};
