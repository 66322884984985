import { Recommender } from 'Features/MemberProfile/memberProfileSlice';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const InformationPanel = styled.div`
  padding-top: 20px;
  margin-right: 108px;
  margin-left: 32px;
  flex-direction: column;
  display: flex;
`;

const RecommenderElement = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const ElementDetail = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
`;
const ElementHeader = styled.div`
  color: #0a4ea2;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 10px;
`;

const RecommendersComponent = ({
  recommenders,
  anonymous,
}: {
  recommenders: any;
  anonymous: boolean;
}) => {
  const { t } = useTranslation();
  if (!anonymous) {
    return (
      <InformationPanel>
        {recommenders
          ? recommenders.map((m: Recommender) => (
              <RecommenderElement key={m.id}>
                <ElementHeader>{m.name}</ElementHeader>
                <ElementDetail>
                  {['phone', 'email']
                    .filter((mKey) => m[mKey as keyof Recommender])
                    .map((mKey) => m[mKey as keyof Recommender])
                    .join(', ')}
                </ElementDetail>
              </RecommenderElement>
            ))
          : null}
      </InformationPanel>
    );
  }
  return (
    <InformationPanel>
      <RecommenderElement>
        <ElementHeader>
          {`${t('recommenders')} (${recommenders.length}
          ${t('pcs')})`}
        </ElementHeader>
        <ElementDetail>
          Suosittelijoiden tiedot näkyvät täydessä profiilissa
        </ElementDetail>
      </RecommenderElement>
    </InformationPanel>
  );
};

export default RecommendersComponent;
