/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import actionGenerator from 'Util/actionGenerator';
import { getAxiosInstance } from 'Api';
import TokenUtil from 'Util/tokenManager';
import { GroupItem } from 'Util/permissions';
import {
  MemberProfileLocation,
  MemberProfileProfessionalTitle,
} from 'Features/MemberProfile/memberProfileSlice';

type JobApplicationInMemberProfile = {
  id: number;
  job_advertisement: number;
  job_advertisement_title: string;
  status: string;
};

type MemberProfileType = {
  additional_info: string;
  competences: Array<object>;
  possibility_to_use_own_car: boolean;
  driving_licenses: Array<object>;
  educations: Array<object>;
  experiences: Array<object>;
  id: number;
  is_open_profile: boolean;
  is_active_job_seeker: boolean;
  job_applications: Array<JobApplicationInMemberProfile>;
  job_experiences: Array<object>;
  language_skills: Array<object>;
  locations: Array<MemberProfileLocation>;
  possibility_to_use_own_tools: boolean;
  professional_titles: Array<MemberProfileProfessionalTitle>;
  recommenders: Array<object>;
};

interface UserState {
  id: number | null;
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  phone: string;
  member_profile: MemberProfileType | null;
  professional_titles: number[];
  groups: Array<GroupItem> | null;
  organizations: Array<number> | null;
  is_employer: boolean;
  is_trainer: boolean;
  is_household: boolean;
  tos_agreed: boolean;
}

interface UpdateUserBody {
  id: number | null;
  first_name?: string;
  last_name?: string;
  username?: string;
  email?: string;
  phone?: string;
}
type NetsDiscoveryResponse = {
  sub: string;
  birthdate: string;
  amr: string;
  iss: string;
  pid: string;
  given_name: string;
  nonce: string;
  aud: string;
  fi_tupas_pid: string;
  fi_tupas_bank: string;
  name: string;
  exp: number;
  iat: number;
  family_name: string;
  jti: string;
  nets_id_token: string;
};
interface AuthState {
  entities: [];
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  isAuthenticated: boolean;
  wrongCredentials: boolean;
  partialProfile: boolean | null;
  openLoginModal: boolean;
  user: UserState;
  netsUrl: string;
  netsResponse: NetsDiscoveryResponse | undefined;
}
const initialState: AuthState = {
  entities: [],
  loading: 'idle',
  isAuthenticated: !TokenUtil.isEmpty(),
  wrongCredentials: false,
  partialProfile: null,
  openLoginModal: false,
  user: {
    id: null,
    phone: '',
    first_name: '',
    last_name: '',
    email: '',
    username: '',
    member_profile: null,
    professional_titles: [],
    groups: null,
    organizations: null,
    is_employer: false,
    is_trainer: false,
    is_household: false,
    tos_agreed: false,
  },
  netsUrl: '',
  netsResponse: undefined,
};
type CheckActiveSessionResponse = {
  is_authenticated: boolean;
  partial_profile: boolean | null;
  user: UserState;
};
const checkActiveSession = createAsyncThunk<
  CheckActiveSessionResponse,
  undefined
>(
  'auth/checkActiveSession',
  // if you type your function argument here
  async () => {
    const response = await getAxiosInstance().get<CheckActiveSessionResponse>(
      `/check_active_session/`,
    );
    return response.data;
  },
);

const authenticateByHash = createAsyncThunk(
  'auth/authenticateByHash',
  // if you type your function argument here
  async (hash: string) => {
    const response = await getAxiosInstance().post(`/login_by_hash/`, {
      hash,
    });
    return response.data;
  },
);

const passwordReset = createAsyncThunk(
  'auth/resetPassword',
  async (email: string) => {
    try {
      const response = await getAxiosInstance().post(`/auth/password/reset/`, {
        email,
      });
      return response.data;
    } catch (err) {
      // @ts-ignore
      throw Error(JSON.stringify(err.response.data));
    }
  },
);

const passwordResetConfirm = createAsyncThunk(
  'auth/resetPassword',
  async (body: {
    uid: string;
    token: string;
    new_password1: string;
    new_password2: string;
  }) => {
    try {
      const response = await getAxiosInstance().post(
        `/auth/password/reset/confirm/`,
        body,
      );
      return response.data;
    } catch (err) {
      // @ts-ignore
      throw Error(JSON.stringify(err.response.data));
    }
  },
);

const updateUser = createAsyncThunk(
  'auth/updateUserDetails',
  async (body: UpdateUserBody) => {
    const { id } = body;
    try {
      const response = await getAxiosInstance().patch(`/user/${id}/`, body);
      return response.data;
    } catch (err) {
      // @ts-ignore
      throw Error(JSON.stringify(err.response.data));
    }
  },
);

const netsLogin = createAsyncThunk('nets/login', async () => {
  try {
    const response = await getAxiosInstance().get(`/nets/create-session`);
    return response.data?.session_url;
  } catch (err) {
    // @ts-ignore
    throw Error(JSON.stringify(err.response.data));
  }
});

const netsDiscovery = createAsyncThunk<NetsDiscoveryResponse, string | null>(
  'nets/discovery',
  async (eidentAuthCode: string | null) => {
    try {
      const response = await getAxiosInstance().get<NetsDiscoveryResponse>(
        `/nets/discovery?code=${eidentAuthCode}`,
      );
      return response.data;
    } catch (err) {
      // @ts-ignore
      throw Error(JSON.stringify(err.response.data));
    }
  },
);

type UserLoginRequestType = {
  username: string;
  password: string;
};
type UserLoginResponseType = {
  expiration: number;
  groups: Array<string>;
  is_admin: boolean;
  key: string;
  organizations: Array<number>;
  username: string;
};
const userLogin = createAsyncThunk<UserLoginResponseType, UserLoginRequestType>(
  'auth/login',
  async (login_credentials: UserLoginRequestType) => {
    const response = await getAxiosInstance().post<UserLoginResponseType>(
      `/auth/login/`,
      login_credentials,
    );
    return response.data;
  },
);

type UserLogoutResponseType = {
  details: string;
};
const userLogout = createAsyncThunk<UserLogoutResponseType>(
  'auth/logout',
  async () => {
    const response =
      await getAxiosInstance().post<UserLogoutResponseType>(`/auth/logout/`);
    return response.data;
  },
);

const genericActions = actionGenerator('auth');
const authSlice = createSlice({
  name: genericActions.name,
  initialState,
  reducers: {
    openLoginModal: (state, action) => {
      state.openLoginModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(genericActions.ResetAction, () => initialState)
      .addCase(checkActiveSession.rejected, (state) => {
        state.isAuthenticated = false;
        TokenUtil.remove();
        localStorage.removeItem('currentlySelectedOrganization');
      })
      .addCase(checkActiveSession.fulfilled, (state, { payload }) => {
        state.isAuthenticated = payload.is_authenticated;
        state.user = payload.user;
        state.partialProfile = payload.partial_profile;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        TokenUtil.set(payload.key);
        state.wrongCredentials = false;
        state.isAuthenticated = true;
      })
      .addCase(userLogin.rejected, (state) => {
        TokenUtil.remove();
        localStorage.removeItem('currentlySelectedOrganization');
        state.wrongCredentials = true;
        state.isAuthenticated = false;
      })
      .addCase(userLogout.fulfilled, (state) => {
        TokenUtil.remove();
        localStorage.removeItem('currentlySelectedOrganization');
        state.wrongCredentials = false;
        state.isAuthenticated = false;
      })
      .addCase(authenticateByHash.fulfilled, (state, { payload }) => {
        if (payload.key) {
          TokenUtil.set(payload.key);
          state.wrongCredentials = false;
          state.isAuthenticated = true;
        }
      })
      .addCase(netsLogin.fulfilled, (state, { payload }) => {
        state.netsUrl = payload;
      })
      .addCase(netsDiscovery.fulfilled, (state, { payload }) => {
        if (payload.pid) {
          state.netsResponse = payload;
        }
      })
      .addMatcher(genericActions.isPendingAction, (state) => {
        state.loading = 'pending';
      })
      .addMatcher(genericActions.isRejectedAction, (state) => {
        state.loading = 'rejected';
      })
      .addMatcher(genericActions.isFulfilledAction, (state) => {
        state.loading = 'fulfilled';
      });
  },
});

export default authSlice.reducer;
export const { openLoginModal } = authSlice.actions;
export {
  checkActiveSession,
  updateUser,
  userLogin,
  userLogout,
  genericActions,
  authenticateByHash,
  passwordReset,
  passwordResetConfirm,
  netsLogin,
  netsDiscovery,
};
