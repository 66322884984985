import React from 'react';
import styled from 'styled-components';
import SuperAdminJobHistory from 'Components/SuperAdminPage/SuperAdminJobHistory';
import { SuperAdminLayout } from 'Components/Layout/SuperAdminLayout';

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`;

const AdminArchivedJobs = () => {
  return (
    <SuperAdminLayout>
      <ContentContainer>
        <SuperAdminJobHistory />
      </ContentContainer>
    </SuperAdminLayout>
  );
};
export default AdminArchivedJobs;
