import React from 'react';
import styled from 'styled-components/macro';
import { Link, withRouter } from 'react-router-dom';
import type { Location } from 'history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Desktop } from 'Components/Layout/MediaQuery';
import { useTranslation } from 'react-i18next';

interface StyledLinkProps {
  isActive: boolean;
}

const SidebarContainer = styled.div`
  width: 196px;
  min-width: 196px;
  background: #232733;
  height: calc(100vh - 60px);
`;
const TopSidebar = styled.div`
  min-height: fit-content;
  background: #2a2e3b;
  padding-bottom: 20px;
`;
const BottomSidebar = styled.div`
  min-height: fit-content;
  background: #232733;
`;
const IconArea = styled.div`
  width: 30px;
  text-align: center;
`;

const MenuItems = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-top: 13px;
  svg {
    margin-right: 10px;
  }
  a {
    font-family: Barlow;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
    &.outside-link {
      color: white;
    }
  }
`;
const SubMenuItems = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 0px 0px 31px;
  a {
    font-family: Barlow;
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
    padding: 4px 0px 8px 10px;
    &.outside-link {
      color: white;
    }
  }
`;

const SidebarMenuItems = styled.div`
  margin: 15px 0px 0px 11px;
`;

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
const StyledLink = styled(({ isActive, ...rest }) => <Link {...rest} />)`
  font-weight: 600;
  display: flex;
  ${(props: StyledLinkProps) =>
    props.isActive
      ? `
      font-weight: bold;
      color: #5C5F63;
  `
      : 'color: white;'}
`;
/* eslint-enable react/jsx-props-no-spreading */
/* eslint-enable @typescript-eslint/no-unused-vars */

const SuperAdminSidebar = ({ location }: { location: Location }) => {
  const { t } = useTranslation();
  return (
    <Desktop>
      <SidebarContainer>
        <TopSidebar>
          <MenuItems>
            <SidebarMenuItems>
              <StyledLink
                isActive={location.pathname === '/super-admin-panel'}
                to="/super-admin-panel"
              >
                <IconArea>
                  <FontAwesomeIcon icon={['far', 'home']} />
                </IconArea>
                {t('front-page')}
              </StyledLink>
            </SidebarMenuItems>
            <SidebarMenuItems>
              <StyledLink
                isActive={false}
                to="/super-admin-panel/ilmoitukset/avoimet"
              >
                <IconArea>
                  <FontAwesomeIcon icon={['far', 'file-alt']} />
                </IconArea>
                {t('ad-management')}
              </StyledLink>
              <SubMenuItems>
                <StyledLink
                  isActive={
                    location.pathname ===
                    '/super-admin-panel/ilmoitukset/avoimet'
                  }
                  to="/super-admin-panel/ilmoitukset/avoimet"
                >
                  {t('open-subtitle')}
                </StyledLink>
                <StyledLink
                  isActive={
                    location.pathname ===
                    '/super-admin-panel/ilmoitukset/historia'
                  }
                  to="/super-admin-panel/ilmoitukset/historia"
                >
                  {t('history')}
                </StyledLink>
              </SubMenuItems>
            </SidebarMenuItems>
            <SidebarMenuItems>
              <StyledLink isActive={false} to="/super-admin-panel/trainings">
                <IconArea>
                  <FontAwesomeIcon icon={['far', 'file-alt']} />
                </IconArea>
                {t('education-management')}
              </StyledLink>
              <SubMenuItems>
                <StyledLink
                  isActive={
                    location.pathname === '/super-admin-panel/trainings'
                  }
                  to="/super-admin-panel/trainings"
                >
                  {t('upcoming-educations')}
                </StyledLink>
                <StyledLink
                  isActive={
                    location.pathname ===
                    '/super-admin-panel/trainings/nykyiset'
                  }
                  to="/super-admin-panel/trainings/nykyiset"
                >
                  {t('ongoing-trainings')}
                </StyledLink>
                <StyledLink
                  isActive={
                    location.pathname === '/super-admin-panel/trainings/menneet'
                  }
                  to="/super-admin-panel/trainings/menneet"
                >
                  {t('past-trainings')}
                </StyledLink>
              </SubMenuItems>
            </SidebarMenuItems>
            <SidebarMenuItems>
              <StyledLink
                isActive={
                  location.pathname === '/super-admin-panel/organisaatiot'
                }
                to="/super-admin-panel/organisaatiot"
              >
                <IconArea>
                  <FontAwesomeIcon icon={['far', 'building']} />
                </IconArea>
                {t('organization-management')}
              </StyledLink>
            </SidebarMenuItems>
            <SidebarMenuItems>
              <StyledLink
                isActive={location.pathname === '/super-admin-panel/jasenet'}
                to="/super-admin-panel/jasenet"
              >
                <IconArea>
                  <FontAwesomeIcon icon={['far', 'hard-hat']} />
                </IconArea>
                {t('member-management')}
              </StyledLink>
            </SidebarMenuItems>
            <SidebarMenuItems>
              <StyledLink
                isActive={location.pathname === '/super-admin-panel/viestit'}
                to="/super-admin-panel/viestit"
              >
                <IconArea>
                  <FontAwesomeIcon icon={['far', 'envelope']} />
                </IconArea>
                {t('messages')}
              </StyledLink>
            </SidebarMenuItems>
            <SidebarMenuItems>
              <StyledLink isActive={false} to="/super-admin-panel/statistics">
                <IconArea>
                  <FontAwesomeIcon icon={['far', 'chart-bar']} />
                </IconArea>
                {t('statistics')}
              </StyledLink>
            </SidebarMenuItems>
            <SidebarMenuItems>
              <StyledLink
                isActive={false}
                to="/super-admin-panel/content-management/duunimylly"
              >
                <IconArea>
                  <FontAwesomeIcon icon={['far', 'chart-bar']} />
                </IconArea>
                {t('content-management')}
              </StyledLink>
              <SubMenuItems>
                <StyledLink
                  isActive={
                    location.pathname ===
                    '/super-admin-panel/content-management/duunimylly'
                  }
                  to="/super-admin-panel/content-management/duunimylly"
                >
                  {t('job-mill')}
                </StyledLink>
                <StyledLink
                  isActive={
                    location.pathname ===
                    '/super-admin-panel/content-management/palkkamylly'
                  }
                  to="/super-admin-panel/content-management/palkkamylly"
                >
                  {t('salary-mill')}
                </StyledLink>
                <StyledLink
                  isActive={
                    location.pathname ===
                    '/super-admin-panel/content-management/taitomylly'
                  }
                  to="/super-admin-panel/content-management/taitomylly"
                >
                  {t('skill-mill')}
                </StyledLink>
                <StyledLink
                  isActive={
                    location.pathname ===
                    '/super-admin-panel/content-management/suuntamylly'
                  }
                  to="/super-admin-panel/content-management/suuntamylly"
                >
                  {t('direction-mill')}
                </StyledLink>
                <StyledLink
                  isActive={
                    location.pathname ===
                    '/super-admin-panel/document-management'
                  }
                  to="/super-admin-panel/document-management"
                >
                  {t('document-management')}
                </StyledLink>
              </SubMenuItems>
            </SidebarMenuItems>
          </MenuItems>
        </TopSidebar>
        <BottomSidebar>
          <MenuItems>
            <SidebarMenuItems>
              <StyledLink
                isActive={false}
                to="/super-admin-panel/asetukset/hallinta"
              >
                <IconArea>
                  <FontAwesomeIcon icon={['far', 'hammer']} />
                </IconArea>
                {t('settings')}
              </StyledLink>
              <SubMenuItems>
                <StyledLink
                  isActive={
                    location.pathname ===
                    '/super-admin-panel/asetukset/hallinta'
                  }
                  to="/super-admin-panel/asetukset/hallinta"
                >
                  {t('user-management')}
                </StyledLink>
              </SubMenuItems>
            </SidebarMenuItems>
          </MenuItems>
        </BottomSidebar>
      </SidebarContainer>
    </Desktop>
  );
};

export default withRouter(SuperAdminSidebar);
