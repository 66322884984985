/* eslint-disable no-console */
import React, { FormEvent, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { uploadContentFile } from 'Features/ContentManagement/contentManagementSlice';
import { useDispatch } from 'react-redux';

const TransparentBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
`;
const CloseContainer = styled.div`
  display: flex;
  color: #ffffff;
  font-family: Barlow;
  font-size: 14px;
  align-items: center;
  width: 100%;

  justify-content: flex-end;
  min-height: 40px;
  max-height: 40px;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 60%;
  }
`;
const TopCloseButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
`;
const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
`;
const CloseButton = styled.div`
  height: 37px;
  width: 136px;
  border-radius: 6px;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #aaafb5;
  font-family: Barlow;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
  margin: 10px 10px 10px 0px;
`;
const UploadImageButton = styled.label`
  && {
    height: 37px;
    width: 136px;
    border-radius: 6px;
    background-color: #d60c17;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #ffffff;
    font-family: Barlow;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
  }
`;
const CloseIcon = styled(FontAwesomeIcon)`
  color: #ffffff;
  font-size: 1.5em;
  margin-left: 10px;
`;
const ModalBackground = styled.div`
  background: #ffffff;
  width: 100%;
  margin-bottom: 20px;
  overflow: auto;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 60%;
  }
`;
const TopBar = styled.div`
  height: 37px;
  background-color: #d60c17;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    text-align: center;
    color: #ffffff;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
  }
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px 0px;
`;
const SelectFileButton = styled.label`
  && {
    background: #0f4ea0;
    width: 100%;
    height: 37px;
    color: #ffffff;
    font-family: Barlow;
    font-size: 15px;
    font-weight: 600;
    border-radius: 6px;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    &:hover {
      cursor: pointer;
    }
  }
`;
const ImageUploadArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 0px;
  min-height: 190px;
  max-height: 190px;
  width: 100%;
  border: 1px solid #d6dce2;
  border-radius: 3px;
  background-color: #e7eaef;
  text-align: center;
`;

const FileUploadModal = ({ modalToggle }: { modalToggle: any }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedFile, setSelectedFile] = useState<File>();
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      setSelectedFile(event.currentTarget.files[0]);
    }
  };

  const handleSubmission = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      await dispatch(uploadContentFile({ body: formData }));
      modalToggle(false);
    }

    // Jos response 200 -> sulje modal, muuten error
    // modalToggle(false)
  };

  return (
    <TransparentBackground>
      <CloseContainer>
        <TopCloseButton onClick={() => modalToggle(false)}>
          <div>{t('close')}</div>
          <CloseIcon icon={['fal', 'times-circle']} />
        </TopCloseButton>
      </CloseContainer>
      <ModalBackground>
        <TopBar>
          <span>{t('add-new-document')} (.pdf)</span>
        </TopBar>
        <ModalContent>
          <ImageUploadArea>
            {selectedFile ? <>{selectedFile.name}</> : 'Ei valittua tiedostoa'}
          </ImageUploadArea>
          <form onSubmit={handleSubmission}>
            <input
              style={{ display: 'none' }}
              id="file-upload"
              type="file"
              name="file"
              accept=".pdf"
              onChange={(e) => changeHandler(e)}
            />
            <input
              style={{ display: 'none' }}
              id="file-submit"
              type="submit"
              value="Lähetä"
            />
          </form>
          <SelectFileButton htmlFor="file-upload">
            {t('select-file')}
          </SelectFileButton>
          <ActionButtonContainer>
            <CloseButton onClick={() => modalToggle(false)}>
              {t('cancel')}
            </CloseButton>
            <UploadImageButton htmlFor="file-submit">
              {t('save')}
            </UploadImageButton>
          </ActionButtonContainer>
        </ModalContent>
      </ModalBackground>
    </TransparentBackground>
  );
};

export default FileUploadModal;
