import React from 'react';
import MainLayout from 'Components/Layout/MainLayout';
import NewOrganizationComponent from 'Components/NewOrganization';

const NewOrganization = () => {
  return (
    <MainLayout>
      <NewOrganizationComponent />
    </MainLayout>
  );
};
export default NewOrganization;
