import React from 'react';
import styled from 'styled-components';
import PhotoObject from './PhotoObject';
import { Fields } from '../styles';

const PhotoGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Phase1 = ({
  imageObjects,
  setAdImage,
  values,
}: {
  imageObjects: Array<object>;
  setAdImage: Function;
  values: any;
}) => {
  return (
    <Fields style={{ flexDirection: 'column' }}>
      <PhotoGrid>
        {imageObjects
          ? imageObjects.map((item: any) => (
              <PhotoObject
                key={item.id}
                photoDetails={item}
                setAdImage={setAdImage}
                values={values}
              />
            ))
          : null}
      </PhotoGrid>
    </Fields>
  );
};

export default Phase1;
