import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { OfficeType } from 'Features/Organization/organizationSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownMenu from 'Components/DropdownMenu';
import DeleteOfficeConfirmationModal from './DeleteOfficeModal';
import ModifyLocationModal from './ModifyLocationModal';

const RightColumn = styled.div`
  flex: 2;
  padding-left: 0px;
  flex-direction: column;
  overflow: auto;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    padding-left: 30px;
  }
`;
const AdditionalLocationsContainer = styled.div`
  display: inline-block;
  width: 100%;
  min-width: 700px;
`;
const AdditionalLocationsHeader = styled.div`
  user-select: none;
  height: 40px;
  line-height: 40px;
  background: #fff;
  display: flex;
`;
const HeaderItem = styled.div`
  width: 25%;
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
`;
const AdditionalLocationsContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
`;
const AdditionalLocationsTitle = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 18px;
  margin-bottom: 14px;
`;
const LocationObject = styled.div`
  height: 44px;
  width: 100%;
  align-items: center;
  display: flex;
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  border: 2px solid #fff;
  &:nth-child(odd) {
    background: #f9f9f9;
    border: 2px solid #f9f9f9;
  }
`;
const LocationTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  width: 25%;
  font-family: Barlow;
  color: #252525;
`;
const AddNewLocation = styled.div`
  width: 100%;
  min-width: fit-content;
  margin-top: 10px;
  height: 44px;
  line-height: 44px;
  border: 1px solid #d6dce2;
  outline: none;
  border-radius: 22px;
  background-color: #f4f5f7;
  display: flex;
  justify-content: space-between;
  padding: 0px 22px 0px 22px;
  font-size: 16px;
  font-family: Barlow;
  font-weight: 600;
  text-decoration: none;
  color: #252525;
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    width: 25%;
  }
  &:hover {
    cursor: pointer;
  }
`;
const defaultProps = {
  singleOffice: false,
};
const LocationRow = ({
  additionalLocation,
  companyId,
  companyName,
  singleOffice,
}: {
  additionalLocation: OfficeType;
  companyName: string;
  companyId: number;
  singleOffice?: boolean;
}) => {
  const { t } = useTranslation();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [modifyModalOpen, setModifyModalOpen] = useState(false);
  const locationOptions = [
    {
      icon: 'pen',
      title: t('edit'),
      action: () => {
        setModifyModalOpen(true);
      },
    },
    {
      icon: 'trash',
      title: t('remove'),
      action: () => {
        setDeleteModalOpen(true);
      },
    },
  ];
  return (
    <>
      {deleteModalOpen ? (
        <DeleteOfficeConfirmationModal
          modalToggle={setDeleteModalOpen}
          officeDetails={additionalLocation}
          singleOffice={singleOffice}
          companyId={companyId}
        />
      ) : null}
      {modifyModalOpen ? (
        <ModifyLocationModal
          modalToggle={setModifyModalOpen}
          officeDetails={additionalLocation}
          companyName={companyName}
          companyId={companyId}
          singleOffice={singleOffice}
        />
      ) : null}

      <LocationObject key={additionalLocation.id}>
        <LocationTitle>{additionalLocation.name}</LocationTitle>
        <HeaderItem>{additionalLocation.location.name}</HeaderItem>
        <HeaderItem>{additionalLocation.email}</HeaderItem>
        <HeaderItem>{additionalLocation.phone_number}</HeaderItem>
        <DropdownMenu
          options={locationOptions}
          menuPosition="leftBottom"
          objectId={additionalLocation.id}
        />
      </LocationObject>
    </>
  );
};
LocationRow.defaultProps = defaultProps;
export const AdditionalCompanyDetailsComponent = ({
  additionalOffices,
  modalToggle,
  companyName,
  companyId,
  singleOffice,
}: {
  additionalOffices: Array<OfficeType>;
  modalToggle: (arg0: boolean) => void;
  companyName: string;
  companyId: number;
  singleOffice?: boolean;
}) => {
  const { t } = useTranslation();

  const additionalLocationObjects = additionalOffices
    ? additionalOffices.map((additionalLocation: OfficeType) => {
        return (
          <>
            <LocationRow
              additionalLocation={additionalLocation}
              companyId={companyId}
              companyName={companyName}
              key={additionalLocation.id}
              singleOffice={singleOffice}
            />
          </>
        );
      })
    : undefined;
  return (
    <RightColumn>
      <AdditionalLocationsTitle>
        {t('additional-locations')}
      </AdditionalLocationsTitle>
      <AdditionalLocationsContainer>
        <AdditionalLocationsHeader>
          <HeaderItem>{t('office-name')}</HeaderItem>
          <HeaderItem>{t('district')}</HeaderItem>
          <HeaderItem>{t('email')}</HeaderItem>
          <HeaderItem>{t('phone')}</HeaderItem>
        </AdditionalLocationsHeader>
        <AdditionalLocationsContent>
          {additionalLocationObjects}
        </AdditionalLocationsContent>
      </AdditionalLocationsContainer>
      <AddNewLocation onClick={() => modalToggle(true)}>
        {t('add-new-office')}
        <FontAwesomeIcon
          style={{ marginTop: '14px', marginLeft: '8px' }}
          color="#0A4EA2"
          icon={['fal', 'plus-circle']}
        />
      </AddNewLocation>
    </RightColumn>
  );
};
AdditionalCompanyDetailsComponent.defaultProps = defaultProps;

export const EmptyAdditionalLocationObject = ({
  modalToggle,
}: {
  modalToggle: (arg0: boolean) => void;
}) => {
  const { t } = useTranslation();
  return (
    <RightColumn>
      <AdditionalLocationsTitle>
        {t('additional-locations')}
      </AdditionalLocationsTitle>
      <AdditionalLocationsContainer>
        <AdditionalLocationsHeader>
          <HeaderItem>{t('office-name')}</HeaderItem>
          <HeaderItem>{t('district')}</HeaderItem>
          <HeaderItem>{t('email')}</HeaderItem>
          <HeaderItem>{t('phone')}</HeaderItem>
        </AdditionalLocationsHeader>
      </AdditionalLocationsContainer>
      <AddNewLocation onClick={() => modalToggle(true)}>
        {t('add-new-office')}
        <FontAwesomeIcon
          style={{ marginTop: '14px', marginLeft: '8px' }}
          color="#0A4EA2"
          icon={['fal', 'plus-circle']}
        />
      </AddNewLocation>
    </RightColumn>
  );
};
