import React from 'react';
import EducationLayout from 'Components/Layout/EducationLayout';
import styled from 'styled-components';
import Messages from 'Components/AdminPage/AdminOrganizationMessages';

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`;

const EducationMessages = () => {
  return (
    <EducationLayout>
      <ContentContainer>
        <Messages />
      </ContentContainer>
    </EducationLayout>
  );
};
export default EducationMessages;
