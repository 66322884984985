import { combineReducers } from '@reduxjs/toolkit';

import authReducer from 'Features/Auth/authSlice';
import frontPageReducer from 'Components/FrontPage/frontPageSlice';
import professionalTitleReducer from 'Features/ProfessionalTitle/professionalTitleSlice';
import competenceReducer from 'Features/Competence/competenceSlice';
import educationReducer from 'Features/Education/educationSlice';
import jobExperienceReducer from 'Features/JobExperience/jobExperienceSlice';
import drivingLicenseSlice from 'Features/DrivingLicense/drivingLicenseSlice';
import recommenderReducer from 'Features/Recommender/recommenderSlice';
import memberProfileReducer from 'Features/MemberProfile/memberProfileSlice';
import jobAdvertisementReducer from 'Features/JobAdvertisement/jobAdvertisementSlice';
import organizationReducer from 'Features/Organization/organizationSlice';
import adminReducer from 'Features/Admin/adminSlice';
import advertisementReducer from 'Features/JobAdvertisements/advertisementSlice';
import newAdvertisementReducer from 'Features/NewAdvertisement/newAdvertisementSlice';
import imageBankReducer from 'Features/ImageBank/imageBankSlice';
import userManagementReducer from 'Features/UserManagement/userManagementSlice';
import organizationRegistrationReducer from 'Features/Organization/organizationRegistrationSlice';
import jobGuardReducer from 'Features/JobGuard/jobGuardSlice';
import jobApplicationReducer from 'Features/JobApplication/jobApplicationSlice';
import officeReducer from 'Features/Office/officeSlice';
import conversationReducer from 'Features/Conversation/conversationSlice';
import messageReducer from 'Features/Message/messageSlice';
import statisticsReducer from 'Features/Statistics/statisticsSlice';
import trainingReducer from 'Features/Training/trainingSlice';
import memberContactReducer from 'Features/MemberContact/memberContactSlice';
import palkkausReducer from 'Features/Palkkaus/palkkausSlice';
import termsOfServiceReducer from 'Features/TermsOfService/termsOfServiceSlice';
import contentManagementReducer from 'Features/ContentManagement/contentManagementSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  frontPage: frontPageReducer,
  professionalTitle: professionalTitleReducer,
  competence: competenceReducer,
  education: educationReducer,
  jobExperience: jobExperienceReducer,
  drivingLicense: drivingLicenseSlice,
  recommender: recommenderReducer,
  memberProfile: memberProfileReducer,
  jobAdvertisement: jobAdvertisementReducer,
  organization: organizationReducer,
  admin: adminReducer,
  advertisement: advertisementReducer,
  newAdvertisement: newAdvertisementReducer,
  imageBank: imageBankReducer,
  userManagement: userManagementReducer,
  organizationRegistration: organizationRegistrationReducer,
  jobGuard: jobGuardReducer,
  jobApplication: jobApplicationReducer,
  office: officeReducer,
  conversation: conversationReducer,
  message: messageReducer,
  statistics: statisticsReducer,
  training: trainingReducer,
  memberContact: memberContactReducer,
  palkkaus: palkkausReducer,
  termsOfService: termsOfServiceReducer,
  contentManagement: contentManagementReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
