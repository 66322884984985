import { searchJobAdvertisements } from 'Features/JobAdvertisement/jobAdvertisementSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import generateJobAdvertisementSearchBody from 'Util/jobAdvertisementSearchBody';

const PaginationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  span {
    padding: 5px 10px;
    border-radius: 6px;
    background: #ddd;
    margin-right: 5px;
    margin-bottom: 5px;
    color: #272727;
    cursor: pointer;
    min-width: 40px;
    text-align: center;
    &.ellipsis {
      background: none;
      cursor: default;
    }
    &.active {
      color: white;
      background: #d60c17;
    }
  }
`;

const Pagination = () => {
  const dispatch = useDispatch();
  const {
    data: { count },
    currentPage,
    selectedFilters,
    searchParams,
  } = useSelector((state: any) => state.jobAdvertisement);

  const pageCount = Math.ceil(count / 20);
  const pages = [];
  for (let i = currentPage; i < currentPage + 3; i += 1) {
    if (i < pageCount) {
      pages.push(i);
    }
  }
  for (let i = currentPage - 1; i > currentPage - 5; i -= 1) {
    if (i >= 0) {
      pages.push(i);
    }
  }

  return (
    <PaginationContainer>
      {pages.includes(0) ? null : (
        <>
          <span
            key={0}
            onClick={() => {
              const page = 1;
              const body = generateJobAdvertisementSearchBody(
                searchParams,
                selectedFilters,
                page,
              );
              dispatch(searchJobAdvertisements(body));
            }}
            className={currentPage === 1 ? 'active' : ''}
          >
            {1}
          </span>
          <span className="ellipsis">...</span>
        </>
      )}
      {pages
        .sort((a, b) => (b < a ? 1 : -1))
        .map((index: number) => (
          <span
            key={index}
            onClick={() => {
              const page = index + 1;
              const body = generateJobAdvertisementSearchBody(
                searchParams,
                selectedFilters,
                page,
              );
              dispatch(searchJobAdvertisements(body));
            }}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </span>
        ))}
      {pages.includes(pageCount - 1) || Number.isNaN(pageCount) ? null : (
        <>
          <span className="ellipsis">...</span>
          <span
            key={pageCount}
            onClick={() => {
              const page = pageCount;
              const body = generateJobAdvertisementSearchBody(
                searchParams,
                selectedFilters,
                page,
              );
              dispatch(searchJobAdvertisements(body));
            }}
            className={currentPage === pageCount ? 'active' : ''}
          >
            {pageCount}
          </span>
        </>
      )}
    </PaginationContainer>
  );
};

export default Pagination;
