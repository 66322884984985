/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import actionGenerator from 'Util/actionGenerator';
import { getAxiosInstance } from 'Api';
import { AxiosError } from 'axios';

interface OrganizationState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  data: object;
  organizationRegistrationRequests: FetchOrganizationJoinRequestResponse;
  singleOrganizationRegistrationRequest: FetchSingleOrganizationRegistrationRequestResponse;
  singleUserJoinRegistrationRequest: FetchSingleUserJoinRegistrationRequestResponse;
  userJoinRequests: FetchUserJoinRequestResponse;
}
const initialState: OrganizationState = {
  loading: 'idle',
  data: {},
  organizationRegistrationRequests: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  userJoinRequests: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  singleOrganizationRegistrationRequest: {
    id: 0,
    name: '',
    description: '',
    url: '',
    vat_number: '',
    is_trainer: false,
    is_employer: false,
    decision: 'pending',
    decision_notes: '',
    first_name: '',
    last_name: '',
    username: '',
  },
  singleUserJoinRegistrationRequest: {
    decision: '',
    decision_notes: null,
    first_name: '',
    id: 0,
    last_name: '',
    username: '',
  },
};
type FetchOrganizationJoinRequestResponse = {
  count: number;
  next: null | string;
  previous: null | string;
  results: Array<FetchOrganizationJoinRequestResult>;
};
export type FetchUserJoinRequestResult = {
  decision: string;
  first_name: string;
  id: number;
  last_name: string;
  username: string;
  organization: number;
};
type FetchUserJoinRequestResponse = {
  count: number;
  next: null | string;
  previous: null | string;
  results: Array<FetchUserJoinRequestResult>;
};
export type FetchOrganizationJoinRequestResult = {
  id: number;
  name: string;
  vat_number: string;
  is_trainer: boolean;
  decision: boolean;
  is_employer: boolean;
};
const fetchOrganizationJoinRequests = createAsyncThunk<
  FetchOrganizationJoinRequestResponse,
  undefined
>('organization/list_organization_request', async () => {
  const response =
    await getAxiosInstance().get<FetchOrganizationJoinRequestResponse>(
      `/organization_request/registration/`,
    );
  return response.data;
});
type FetchOrganizationJoinRequestsByDecisionRequest = string;
const fetchOrganizationJoinRequestsByDecision = createAsyncThunk<
  FetchOrganizationJoinRequestResponse,
  FetchOrganizationJoinRequestsByDecisionRequest
>(
  'organization/list_organization_request_by_decision',
  async (decision: FetchOrganizationJoinRequestsByDecisionRequest) => {
    const response =
      await getAxiosInstance().get<FetchOrganizationJoinRequestResponse>(
        `organization_request/registration/?decision=${decision}`,
      );
    return response.data;
  },
);

type FetchUserJoinRequestsByDecisionRequest = {
  status: string;
  organization?: number;
};
const fetchUserJoinRequestsByDecision = createAsyncThunk<
  FetchUserJoinRequestResponse,
  FetchUserJoinRequestsByDecisionRequest
>(
  'organization/list_organization_join_request_by_decision',
  async ({ status, organization }) => {
    const response = await getAxiosInstance().get<FetchUserJoinRequestResponse>(
      `/organization_request/join/?decision=${status}${
        organization ? `&organization_id=${organization}` : ''
      }`,
    );
    return response.data;
  },
);
type DecisionType = 'pending' | 'denied' | 'approved';
type FetchSingleOrganizationRegistrationRequestResponse = {
  id: number;
  name: string;
  description: string;
  url: string;
  vat_number: string;
  is_trainer: boolean;
  is_employer: boolean;
  decision: DecisionType;
  decision_notes: string;
  first_name: string;
  last_name: string;
  username: string;
};
type FetchSingleUserJoinRegistrationRequestResponse = {
  decision: string;
  decision_notes: string | null;
  first_name: string;
  id: number;
  last_name: string;
  username: string;
};
type FetchSingleOrganizationDetailsRequest = number;
const fetchSingleOrganizationRegistrationRequest = createAsyncThunk<
  FetchSingleOrganizationRegistrationRequestResponse,
  FetchSingleOrganizationDetailsRequest
>(
  'organization/fetch_single_request',
  async (id: FetchSingleOrganizationDetailsRequest) => {
    const response =
      await getAxiosInstance().get<FetchSingleOrganizationRegistrationRequestResponse>(
        `/organization_request/registration/${id}/`,
      );
    return response.data;
  },
);
type FetchSingleUserJoinDetailsRequest = number;
const fetchSingleUserJoinRegistrationRequest = createAsyncThunk<
  FetchSingleUserJoinRegistrationRequestResponse,
  FetchSingleUserJoinDetailsRequest
>(
  'organization/fetch_single_user_request',
  async (id: FetchSingleUserJoinDetailsRequest) => {
    const response =
      await getAxiosInstance().get<FetchSingleUserJoinRegistrationRequestResponse>(
        `/organization_request/join/${id}/`,
      );
    return response.data;
  },
);

type OrganizationJoinRequestResponse = {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  organization_id: number;
};
type OrganizationJoinRequestBodyType = {
  first_name: string;
  last_name: string;
  username: string;
  organization_id: number;
};
type OrganizationJoinRequestRequest = {
  body: OrganizationJoinRequestBodyType;
};
const organizationJoinRequest = createAsyncThunk<
  OrganizationJoinRequestResponse,
  OrganizationJoinRequestRequest
>(
  'organization/organization_join_request',
  async ({ body }: OrganizationJoinRequestRequest) => {
    const response =
      await getAxiosInstance().post<OrganizationJoinRequestResponse>(
        `/organization_request/join/`,
        body,
      );
    return response.data;
  },
);
export type HouseholdOrganizationRegistrationRequestRequestBodyType = {
  name?: string;
  is_household: boolean;
  first_name?: string;
  last_name?: string;
  username?: string;
  nets_pid?: string;
  id_token?: string;
};
export type OrganizationRegistrationRequestRequestBodyType = {
  name: string;
  description: string;
  url: string;
  vat_number: string;
  is_employer: boolean;
  is_trainer: boolean;
  first_name?: string;
  last_name?: string;
  username?: string;
};
interface OrganizationRegistrationRequestResponse
  extends OrganizationRegistrationRequestRequestBodyType {
  id: number;
}
type OrganizationRegistrationRequestRequest = {
  body: OrganizationRegistrationRequestRequestBodyType;
};
interface HouseholdOrganizationRegistrationRequestResponse
  extends HouseholdOrganizationRegistrationRequestRequestBodyType {
  id: number;
}
type HouseholdOrganizationRegistrationRequestRequest = {
  body: HouseholdOrganizationRegistrationRequestRequestBodyType;
};
export interface ValidationErrors {
  [key: string]: string[];
}

const organizationRegistrationRequest = createAsyncThunk<
  OrganizationRegistrationRequestResponse | any,
  OrganizationRegistrationRequestRequest
>(
  'organization/organization_registration_request',
  async ({ body }: OrganizationRegistrationRequestRequest) => {
    try {
      const response =
        await getAxiosInstance().post<OrganizationRegistrationRequestResponse>(
          `/organization_request/registration/`,
          body,
        );
      return response.data;
    } catch (err) {
      // @ts-ignore
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      const errorValue = {
        status: error.response.status,
        data: error.response.data,
      };
      return errorValue;
    }
  },
);
const householdOrganizationRegistrationRequest = createAsyncThunk<
  HouseholdOrganizationRegistrationRequestResponse | any,
  HouseholdOrganizationRegistrationRequestRequest
>(
  'organization/organization_registration_request',
  async ({ body }: HouseholdOrganizationRegistrationRequestRequest) => {
    try {
      const response =
        await getAxiosInstance().post<HouseholdOrganizationRegistrationRequestResponse>(
          `/organization_request/registration/`,
          body,
        );
      return response.data;
    } catch (err) {
      // @ts-ignore
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      const errorValue = {
        status: error.response.status,
        data: error.response.data,
      };
      return errorValue;
    }
  },
);

const deleteOrganizationRegistrationRequest = createAsyncThunk<
  undefined,
  number
>(
  'organization/delete_organization_registration_request',
  async (id: number) => {
    const response = await getAxiosInstance().delete(
      `/organization_request/registration/${id}/`,
    );
    return response.data;
  },
);
type ApproveOrganizationRegistrationRequestResponse = {
  id: number;
  name: string;
  description: string;
  url: string;
  vat_number: string;
  is_trainer: boolean;
  is_employer: boolean;
  decision: string;
  decision_notes: string;
  first_name: string;
  last_name: string;
  username: string;
};
type ApproveOrganizationRegistrationRequestRequest = {
  id: number;
  body: {
    decision_notes: string;
  };
};
const approveOrganizationRegistrationRequest = createAsyncThunk<
  ApproveOrganizationRegistrationRequestResponse,
  ApproveOrganizationRegistrationRequestRequest
>(
  'organization/approve_organization_registration_request',
  async ({ id, body }: ApproveOrganizationRegistrationRequestRequest) => {
    const response =
      await getAxiosInstance().post<ApproveOrganizationRegistrationRequestResponse>(
        `/organization_request/registration/${id}/approve/`,
        body,
      );
    return response.data;
  },
);
type DenyOrganizationRegistrationRequestResponse =
  ApproveOrganizationRegistrationRequestResponse;
type DenyOrganizationRegistrationRequestRequest =
  ApproveOrganizationRegistrationRequestRequest;
const denyOrganizationRegistrationRequest = createAsyncThunk<
  DenyOrganizationRegistrationRequestResponse,
  DenyOrganizationRegistrationRequestRequest
>(
  'organization/deny_organization_registration_request',
  async ({ id, body }: DenyOrganizationRegistrationRequestRequest) => {
    const response =
      await getAxiosInstance().post<DenyOrganizationRegistrationRequestResponse>(
        `/organization_request/registration/${id}/deny/`,
        body,
      );
    return response.data;
  },
);
type ApproveUserJoinRequestRequest = {
  id: number;
  body: {
    decision_notes: string;
  };
};
const approveUserJoinRequest = createAsyncThunk<
  FetchSingleUserJoinRegistrationRequestResponse,
  ApproveUserJoinRequestRequest
>(
  'organization/approve_organization_registration_request',
  async ({ id, body }: ApproveOrganizationRegistrationRequestRequest) => {
    const response =
      await getAxiosInstance().post<FetchSingleUserJoinRegistrationRequestResponse>(
        `/organization_request/join/${id}/approve/`,
        body,
      );
    return response.data;
  },
);
type DenyUserJoinRequestRequest = ApproveUserJoinRequestRequest;
const denyUserJoinRequest = createAsyncThunk<
  FetchSingleUserJoinRegistrationRequestResponse,
  DenyUserJoinRequestRequest
>(
  'organization/deny_organization_registration_request',
  async ({ id, body }: DenyOrganizationRegistrationRequestRequest) => {
    const response =
      await getAxiosInstance().post<FetchSingleUserJoinRegistrationRequestResponse>(
        `/organization_request/join/${id}/deny/`,
        body,
      );
    return response.data;
  },
);

const genericActions = actionGenerator('organization');

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder
      .addCase(genericActions.ResetAction, () => initialState)
      .addCase(fetchOrganizationJoinRequests.fulfilled, (state, action) => {
        state.organizationRegistrationRequests = action.payload;
      })
      .addCase(
        fetchOrganizationJoinRequestsByDecision.fulfilled,
        (state, action) => {
          state.organizationRegistrationRequests = action.payload;
        },
      )
      .addCase(fetchUserJoinRequestsByDecision.fulfilled, (state, action) => {
        state.userJoinRequests = action.payload;
      })
      .addCase(
        fetchSingleOrganizationRegistrationRequest.fulfilled,
        (state, action) => {
          state.singleOrganizationRegistrationRequest = action.payload;
        },
      )
      .addCase(
        fetchSingleUserJoinRegistrationRequest.fulfilled,
        (state, action) => {
          state.singleUserJoinRegistrationRequest = action.payload;
        },
      )
      .addCase(organizationJoinRequest.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(
        deleteOrganizationRegistrationRequest.fulfilled,
        (state, action) => {
          state.organizationRegistrationRequests.results =
            state.organizationRegistrationRequests.results.filter(
              (request) => request.id !== action.meta.arg,
            );
        },
      )
      .addMatcher(genericActions.isPendingAction, (state) => {
        state.loading = 'pending';
      })
      .addMatcher(genericActions.isRejectedAction, (state) => {
        state.loading = 'rejected';
      })
      .addMatcher(genericActions.isFulfilledAction, (state) => {
        state.loading = 'fulfilled';
      });
  },
});
export default organizationSlice.reducer;
export {
  organizationJoinRequest,
  organizationRegistrationRequest,
  householdOrganizationRegistrationRequest,
  fetchOrganizationJoinRequests,
  fetchSingleOrganizationRegistrationRequest,
  deleteOrganizationRegistrationRequest,
  approveOrganizationRegistrationRequest,
  denyOrganizationRegistrationRequest,
  fetchOrganizationJoinRequestsByDecision,
  fetchUserJoinRequestsByDecision,
  fetchSingleUserJoinRegistrationRequest,
  approveUserJoinRequest,
  denyUserJoinRequest,
};
