/* eslint-disable no-unused-expressions */
import React, { FormEvent, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Logo from 'Assets/rakennusliitto-logo.png';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { userLogin } from 'Features/Auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'Store';
import LoadingIndicator from 'Components/LoadingIndicator';
import { BaseState } from 'Components/Layout/AdminLayout';

interface LocationState {
  from: string;
}

const Container = styled.div`
  text-align: center;
  border-radius: 3px;
  background-color: #fff;
  margin: 0px auto;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  width: 400px;
  align-items: center;
`;
const LoginTitle = styled.div`
  color: #222;
  margin: 30px 0px;
  font-style: Barlow;
  font-size: 16px;
`;
const LoginForm = styled.form`
  width: 100%;
`;
const LoginLabel = styled.label`
  font-size: 14px;
  font-family: Barlow;
  color: #222;
  display: block;
  margin-bottom: 7px;
  text-transform: capitalize;
  font-weight: bold;
  text-align: left;
  margin-left: 15px;
`;
const RejectedLoginLabel = styled.label`
  color: red;
  font-size: 14px;
  font-family: Barlow;
  text-align: center;
`;
const LoginInput = styled.input`
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 4px;
  border: none;
  background: #fff;
  border: 1px solid #d6dce2;
  color: #222;
  &:last-of-type {
    margin-bottom: 5px;
  }
`;
const LoginButton = styled.button`
  cursor: pointer;
  width: 100%;
  border: none;
  background-color: #d60c17;
  color: #ffffff;
  padding: 10px;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 15px;
`;
const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const FormLink = styled(Link)`
  margin-top: 10px;
  font-family: Barlow;
`;
const LoginContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    state: { from },
  }: { state: LocationState } = useLocation();
  const isAuthed = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );
  const wrongCredentials = useSelector(
    (state: RootState) => state.auth.wrongCredentials,
  );
  const [loginCredentials, setLoginCredentials] = useState<{
    [key: string]: string;
  }>({
    username: '',
    password: '',
  });
  const isLoading = useSelector((state: RootState) =>
    Object.values(state).some((partialState) => {
      const castedState = partialState as BaseState;
      return castedState.loading === 'pending';
    }),
  );
  useEffect(() => {
    isAuthed && history.push(from);
  }, [isAuthed, history]);

  const updateLoginCredentials = (key: string, value: string) => {
    const newState = { ...loginCredentials };
    newState[key] = value;
    setLoginCredentials(newState);
  };
  const requestUserLogin = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(
      userLogin({
        username: loginCredentials.username,
        password: loginCredentials.password,
      }),
    );
  };

  return (
    <Container>
      <img src={Logo} alt="Rakennusliitto logo" />
      <LoginTitle>{t('login-title')}</LoginTitle>
      <LoginForm onSubmit={requestUserLogin}>
        <LoginLabel>{t('email')}</LoginLabel>
        <LoginInput
          type="text"
          placeholder={t('input-email')}
          onChange={(e) => updateLoginCredentials('username', e.target.value)}
        />
        <LoginLabel>{t('password')}</LoginLabel>
        <LoginInput
          type="password"
          placeholder={t('input-password')}
          onChange={(e) => updateLoginCredentials('password', e.target.value)}
        />
        {wrongCredentials === true ? (
          <RejectedLoginLabel>{t('rejected-login')}</RejectedLoginLabel>
        ) : null}
        <LoginButton type="submit">{t('log-in')}</LoginButton>
        <LinkContainer>
          <FormLink to="/request-password">{t('forgot-password')}</FormLink>
          <FormLink to="/new-organization">
            {t('create-new-organization-user')}
          </FormLink>
          <FormLink to="/household-login">
            {t('create-new-household-user')}
          </FormLink>
        </LinkContainer>
      </LoginForm>
      {isLoading && <LoadingIndicator />}
    </Container>
  );
};

export default LoginContainer;
