/* eslint-disable no-console */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  FetchOrganizationJoinRequestResult,
  fetchOrganizationJoinRequestsByDecision,
  FetchUserJoinRequestResult,
  fetchUserJoinRequestsByDecision,
} from 'Features/Organization/organizationRegistrationSlice';
import ObjectTableStyles from 'Components/ObjectTable';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RegistrationConfirmation from 'Components/SuperAdminPage/OrganizationManagement/RegistrationConfirmation';
import styled from 'styled-components';
import RegistrationRequestModal from 'Components/SuperAdminPage/OrganizationManagement/RegistrationRequestModal';
import RegistrationUserRequestModal from 'Components/SuperAdminPage/OrganizationManagement/RegistrationUserRequestModal';
import { RootState } from 'Store';
import organizationIsHousehold from 'Util/organizationIsHousehold';

const {
  ObjectTableBackground,
  ObjectTableTitle,
  ObjectTableTopComponent,
  ObjectTableBottomComponent,
  ObjectTableTop,
  ObjectTableHeader,
  ObjectTableContent,
  ObjectTableObjectTitle,
  ObjectTableAngleRightIcon,
  ObjectTableSortCaretIcon,
} = ObjectTableStyles;
const Options = styled.div`
  white-space: nowrap;
  margin-left: auto;
  margin-right: 20px;
  display: flex;
  justify-content: space-evenly;
`;
const ShowRequest = styled.div`
  text-decoration: none;
  color: #0a4ea2;
  font-family: Barlow;
  font-size: 14px;
  margin-right: 20px;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
`;
const ObjectTableContainer = styled(ObjectTableStyles.ObjectTableContainer)`
  min-width: 1330px;
`;

const ObjectTableItem = styled(ObjectTableStyles.ObjectTableItem)`
  min-height: 44px;
  height: auto;
`;
const ObjectTableHeaderItem = styled(ObjectTableStyles.ObjectTableHeaderItem)`
  width: 13%;
`;
const RegistrationRequests = ({ organization }: { organization?: number }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOrganizationJoinRequestsByDecision('pending'));
    dispatch(
      fetchUserJoinRequestsByDecision({ status: 'pending', organization }),
    );
  }, [dispatch]);
  const { organizationRegistrationRequests, userJoinRequests } = useSelector(
    (state: RootState) => state.organizationRegistration,
  );
  const {
    data: { results: organizations },
  } = useSelector((state: RootState) => state.organization);
  const { results } = organizationRegistrationRequests;
  const { results: userResults } = userJoinRequests;
  const [showModal, setShowModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<number>(0);
  const [selectedUserRequest, setSelectedUserRequest] = useState<number>(0);
  const isHousehold = organizationIsHousehold();
  const [listOrder, setListOrder] = useState({
    organizationName: 1,
    businessId: 1,
    employmentServices: 1,
    education: 1,
  });

  const [sortedBy, setSortedBy] = useState('organizationName');
  type ColumnType =
    | 'organizationName'
    | 'businessId'
    | 'employmentServices'
    | 'education';

  const sortList = (column: ColumnType) => {
    setSortedBy(column);
    setListOrder({ ...listOrder, [column]: listOrder[column] * -1 });
  };

  type CaretProps = {
    columnName:
      | 'organizationName'
      | 'businessId'
      | 'employmentServices'
      | 'education';
  };
  const CaretIcon = ({ columnName }: CaretProps) => {
    if (sortedBy === columnName) {
      if (listOrder[columnName] === 1) {
        return <ObjectTableSortCaretIcon icon={['fas', 'caret-up']} />;
      }
      return <ObjectTableSortCaretIcon icon={['fas', 'caret-down']} />;
    }
    return null;
  };
  const ShowRequestDetails = (requestId: number) => {
    setShowModal(true);
    setSelectedRequest(requestId);
  };
  const ShowUserRequestDetails = (requestId: number) => {
    setShowUserModal(true);
    setSelectedUserRequest(requestId);
  };
  const activeJobItems = results
    ? results.map(
        (
          request: FetchOrganizationJoinRequestResult,
          // eslint-disable-next-line consistent-return
        ) => {
          return (
            <ObjectTableItem key={request.id}>
              <ObjectTableObjectTitle>{request.name}</ObjectTableObjectTitle>
              <ObjectTableHeaderItem>
                {request.vat_number}
              </ObjectTableHeaderItem>
              <ObjectTableHeaderItem>
                {request.is_trainer ? t('yes') : t('no')}
              </ObjectTableHeaderItem>
              <ObjectTableHeaderItem>
                {request.is_employer ? t('yes') : t('no')}
              </ObjectTableHeaderItem>
              <ObjectTableHeaderItem>
                <RegistrationConfirmation
                  requestId={request.id}
                  isEmployer={request.is_employer}
                  isTrainer={request.is_trainer}
                />
              </ObjectTableHeaderItem>
              <Options>
                <ShowRequest onClick={() => ShowRequestDetails(request.id)}>
                  {t('show-request')}
                  <ObjectTableAngleRightIcon icon={['far', 'angle-right']} />
                </ShowRequest>
              </Options>
            </ObjectTableItem>
          );
        },
      )
    : null;

  const activeJoinRequests = userResults
    ? userResults.map(
        (
          request: FetchUserJoinRequestResult,
          // eslint-disable-next-line consistent-return
        ) => {
          return (
            <ObjectTableItem key={request.id}>
              <ObjectTableObjectTitle>
                {request.first_name} {request.last_name}
              </ObjectTableObjectTitle>
              <ObjectTableHeaderItem>{request.username}</ObjectTableHeaderItem>
              <ObjectTableHeaderItem />
              <ObjectTableHeaderItem>
                {
                  organizations?.find((org) => org.id === request.organization)
                    ?.name
                }
              </ObjectTableHeaderItem>
              <Options>
                <ShowRequest onClick={() => ShowUserRequestDetails(request.id)}>
                  {t('show-request')}
                  <ObjectTableAngleRightIcon icon={['far', 'angle-right']} />
                </ShowRequest>
              </Options>
            </ObjectTableItem>
          );
        },
      )
    : userResults;
  return (
    <>
      {showModal ? (
        <RegistrationRequestModal
          modalToggle={(open: boolean) => {
            setShowModal(open);
            dispatch(fetchOrganizationJoinRequestsByDecision('pending'));
          }}
          requestId={selectedRequest}
        />
      ) : null}
      {showUserModal ? (
        <RegistrationUserRequestModal
          modalToggle={(open: boolean) => {
            setShowUserModal(open);
            dispatch(
              fetchUserJoinRequestsByDecision({
                status: 'pending',
                organization,
              }),
            );
          }}
          requestId={selectedUserRequest}
        />
      ) : null}
      {window.location.href.indexOf('super-admin-panel') !== -1 ? (
        <ObjectTableBackground>
          <ObjectTableTopComponent>
            <ObjectTableTop>
              <ObjectTableTitle>{t('registration-requests')}</ObjectTableTitle>
            </ObjectTableTop>
          </ObjectTableTopComponent>
          <ObjectTableBottomComponent>
            <ObjectTableContainer>
              <ObjectTableHeader>
                <ObjectTableHeaderItem
                  onClick={() => sortList('organizationName')}
                >
                  {t('organization')}
                  <CaretIcon columnName="organizationName" />
                </ObjectTableHeaderItem>
                <ObjectTableHeaderItem onClick={() => sortList('businessId')}>
                  {t('business-id')}
                  <CaretIcon columnName="businessId" />
                </ObjectTableHeaderItem>
                <ObjectTableHeaderItem onClick={() => sortList('education')}>
                  {t('education')}
                  <CaretIcon columnName="education" />
                </ObjectTableHeaderItem>
                <ObjectTableHeaderItem
                  onClick={() => sortList('employmentServices')}
                >
                  {t('employment-services')}
                  <CaretIcon columnName="employmentServices" />
                </ObjectTableHeaderItem>
                <ObjectTableHeaderItem>
                  {t('registration-request')}
                </ObjectTableHeaderItem>
              </ObjectTableHeader>
              <ObjectTableContent>{activeJobItems}</ObjectTableContent>
            </ObjectTableContainer>
          </ObjectTableBottomComponent>
        </ObjectTableBackground>
      ) : null}
      {isHousehold ||
      window.location.href.indexOf('super-admin-panel') !== -1 ? null : (
        <ObjectTableBackground>
          <ObjectTableTopComponent>
            <ObjectTableTop>
              <ObjectTableTitle>{t('join-requests')}</ObjectTableTitle>
            </ObjectTableTop>
          </ObjectTableTopComponent>
          <ObjectTableBottomComponent>
            <ObjectTableContainer>
              <ObjectTableHeader>
                <ObjectTableHeaderItem>{t('name')}</ObjectTableHeaderItem>
                <ObjectTableHeaderItem>{t('username')}</ObjectTableHeaderItem>
                <ObjectTableHeaderItem />
                <ObjectTableHeaderItem>
                  {t('organization')}
                </ObjectTableHeaderItem>
              </ObjectTableHeader>
              <ObjectTableContent>{activeJoinRequests}</ObjectTableContent>
            </ObjectTableContainer>
          </ObjectTableBottomComponent>
        </ObjectTableBackground>
      )}
    </>
  );
};

RegistrationRequests.defaultProps = {
  organization: undefined,
};

export default RegistrationRequests;
