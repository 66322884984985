import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import HelmetImage from 'Assets/helmet.png';
import { useTranslation } from 'react-i18next';
import MemberProfile from 'Components/MemberProfile';
import JobSearch from './JobSearch';

const JobsAuthenticatedContainer = styled.div`
  background: #fff;
`;

const ContentContainer = styled.div`
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1440px;
  margin: auto;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    flex-direction: row;
    padding: 30px 80px;
  }
  > div {
    width: 100%;
    flex-direction: column;
    @media screen and ${(props) => props.theme.breakpoints.lg} {
      flex-direction: row;
    }
    &:first-of-type {
      display: flex;
      padding: 40px 0;
      img {
        height: 126px;
        width: 130px;
        margin-top: 7px;
        margin-bottom: 15px;
        @media screen and ${(props) => props.theme.breakpoints.lg} {
          margin-bottom: 0;
        }
      }
      > div {
        @media screen and ${(props) => props.theme.breakpoints.lg} {
          margin-left: 25px;
        }
      }
      h1 {
        color: #252525;
        font-family: Barlow;
        font-size: 32px;
        letter-spacing: 0;
        line-height: 38px;
        font-weight: 200;
        margin-top: 0;
        margin-bottom: 15px;
      }
      p {
        color: #252525;
        font-family: Barlow;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 27px;
        margin-top: 0;
        margin-bottom: 15px;
      }
      a {
        height: 37px;
        width: 100%;
        max-width: 255px;
        display: inline-block;
        color: #ffffff;
        font-family: Barlow;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 37px;
        text-align: center;
        border-radius: 6px;
        background-color: #d60c17;
        text-decoration: none;
      }
    }
  }
`;

const JobsAuthenticated = ({ jobId }: { jobId?: string | null }) => {
  const { user, partialProfile } = useSelector(
    ({ auth }: { auth: any }) => auth,
  );
  const { t } = useTranslation();
  if (user.member_profile) {
    return (
      <JobsAuthenticatedContainer>
        <MemberProfile />
        <JobSearch jobId={jobId} />
      </JobsAuthenticatedContainer>
    );
  }
  const renderSettings = () => {
    return (
      <>
        <img src={HelmetImage} alt="Helmet" />
        <div>
          <h1>
            {t('hey')} {user.first_name}!
          </h1>
          <p>{t('create-profile-text')}</p>
          <Link to="/create-member-profile">
            {partialProfile
              ? t('continue-profile-button-text')
              : t('create-profile-button-text')}
          </Link>
        </div>
      </>
    );
  };
  return (
    <JobsAuthenticatedContainer>
      <ContentContainer>
        <div>{renderSettings()}</div>
      </ContentContainer>
      <JobSearch jobId={jobId} />
    </JobsAuthenticatedContainer>
  );
};

JobsAuthenticated.defaultProps = {
  jobId: null,
};

export default JobsAuthenticated;
