import {
  createSlice,
  AnyAction,
  AsyncThunk,
  createAction,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';
import { getAxiosInstance } from 'Api';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

/* eslint-disable camelcase */
interface MemberProfileFavorite {
  organization: number;
  member_profile: number;
}

interface MemberProfileFavoriteState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  createdProfileFavorite: {
    id: number;
  } | null;
}
/* eslint-enable camelcase */

const initialState: MemberProfileFavoriteState = {
  loading: 'idle',
  createdProfileFavorite: null,
};

const createMemberProfileFavorite = createAsyncThunk(
  'memberProfileFavorite/createMemberProfileFavorite',
  async (body: MemberProfileFavorite) => {
    try {
      const response = await getAxiosInstance().post(
        `/member_profile_favorite/`,
        body,
      );
      return response.data;
    } catch (err) {
      // @ts-ignore
      throw Error(JSON.stringify(err.response.data));
    }
  },
);

const deleteMemberProfileFavorite = createAsyncThunk(
  'memberProfileFavorite/deleteMemberProfileFavorite',
  async (id: number) => {
    try {
      const response = await getAxiosInstance().delete(
        `/member_profile_favorite/${id}/`,
      );
      return response.data;
    } catch (err) {
      // @ts-ignore
      throw Error(JSON.stringify(err.response.data));
    }
  },
);

const isPendingAction = (action: AnyAction): action is PendingAction => {
  return action.type.endsWith('/pending');
};
const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return action.type.endsWith('/rejected');
};
const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return action.type.endsWith('/fulfilled');
};

export const resetAction = createAction('reset-tracked-loading-state');

/* eslint-disable no-param-reassign */
const memberProfileFavoriteSlice = createSlice({
  name: 'memberProfileFavorite',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetAction, () => initialState)
      .addCase(
        createMemberProfileFavorite.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.createdProfileFavorite = action.payload;
        },
      )
      .addMatcher(isPendingAction, (state) => {
        state.loading = 'pending';
      })
      .addMatcher(isRejectedAction, (state) => {
        state.loading = 'rejected';
      })
      .addMatcher(isFulfilledAction, (state) => {
        state.loading = 'fulfilled';
      });
  },
});
/* eslint-enable no-param-reassign */

export default memberProfileFavoriteSlice.reducer;
export { createMemberProfileFavorite, deleteMemberProfileFavorite };
