/* eslint-disable no-console */
import { fetchOrganizationImages } from 'Features/Organization/organizationSlice';
import { fetchPublicImages } from 'Features/ImageBank/imageBankSlice';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { RootState } from 'Store';
import { Phase1, Phase2 } from './FieldComponents';

const FormFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 7px 7px -10px rgba(0, 0, 0, 0.41);
  label {
    color: #222;
    font-family: Barlow;
    font-size: 16px;
    margin-top: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 10px;
    span {
      color: #d60c17;
    }
    &.top-margin {
      margin-top: 10px;
    }
  }
  input.form-select,
  textarea.form-select {
    border: 1px solid #d6dce2;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 30px;
    outline: none;
    &:last-of-type {
      margin-bottom: 10px;
    }
    &.borderless {
      width: 100%;
      max-width: none;
      border-left: none;
      border-right: none;
      border-top: none;
      margin-bottom: 0;
      padding: 15px 0;
    }
  }
  .search {
    display: flex;
    align-items: center;
    user-select: none;
    .add-skill {
      white-space: nowrap;
      margin-left: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        margin-right: 10px;
        color: #252525;
        font-family: Barlow;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 17px;
      }
      svg {
        color: #0a4ea2;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 0;
      }
    }
  }
  .search-bar {
    flex-grow: 1;
    margin-right: 10px;
    position: relative;
    svg {
      right: 0;
      width: 15px;
      height: 15px;
      top: calc(50% - 7.5px);
      right: 10px;
    }
  }
`;

const FormFields = ({ currentTab }: { currentTab: number }) => {
  const dispatch = useDispatch();
  const { data, images } = useSelector(
    (state: RootState) => state.organization,
  );
  const { results } = data;
  const { itemIndex } = useSelector((state: RootState) => state.admin);
  const publicImages = useSelector((state: RootState) => state.imageBank);
  const publicImageObjects = publicImages.images;
  useEffect(() => {
    if (results !== undefined && itemIndex !== undefined) {
      dispatch(
        fetchOrganizationImages({
          id: results[itemIndex].id,
        }),
      );
      dispatch(fetchPublicImages());
    }
  }, [dispatch, itemIndex, results]);
  const renderFields = () => {
    switch (currentTab) {
      case 0:
        return <Phase1 imageObjects={publicImageObjects} />;
      case 1:
        return (
          <Phase2
            imageObjects={images}
            organizationId={results[itemIndex].id}
          />
        );

      default:
        return <Phase1 imageObjects={publicImageObjects} />;
    }
  };
  return <FormFieldsContainer>{renderFields()}</FormFieldsContainer>;
};

export default FormFields;
