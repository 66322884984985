import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'Store';
import styled from 'styled-components/macro';
import {
  displayDate,
  displayTime,
  displayValue,
  shortenDescription,
  trainingSummaryImageUrl,
  displayMemberLimitations,
  displayPrice,
} from 'Util/trainingUtils';
import { NewTrainingSummaryProps } from './CommonTrainingCreationProps';

const SummaryBackground = styled.div`
    height:100%;
    width 30%;
    min-width:300px;
    max-width:450px;
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    .description {
        font-weight: 600;
        color: #252525;
        font-family: Barlow;
        font-size: 14px;
    }
    .value-text {
        color: #363636;
        font-family: Barlow;
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
    }
    .informant-text {
        color: #363636;
        font-family: Barlow;
        font-size: 14px;
        font-weight: 500;
    }
`;
const SummaryRowList = styled.div`
  padding: 10px 0px 10px 0px;
`;
const SummaryRowItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px 5px 0px;
`;
const SummaryColumnItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .description {
    padding: 10px 0px 5px 0px;
  }
`;

const ImageArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 0px;
  min-height: 190px;
  max-height: 190px;
  border: 1px solid #d6dce2;
  border-radius: 3px;
  background-color: #e7eaef;
  text-align: center;
  .image-placeholder {
    display: flex;
    flex-direction: column;
  }
`;
const Photo = styled.div`
  background-color: #e7eaef;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const NewTrainingSummary = ({ companyName }: NewTrainingSummaryProps) => {
  const { t } = useTranslation();
  const { values } = useSelector((state: RootState) => state.training);
  const photoUrl = trainingSummaryImageUrl(values.images);

  return (
    <SummaryBackground>
      <SummaryRowItem>
        <span className="description">{t('training-description')}</span>
      </SummaryRowItem>
      <SummaryColumnItem>
        <span className="description">{t('event-organizer')}</span>
        <span className="informant-text">{companyName}</span>
      </SummaryColumnItem>
      <SummaryColumnItem>
        <span className="description">{t('ad-image')}</span>
        <ImageArea>
          {values.images ? (
            <Photo style={{ backgroundImage: `url(${photoUrl})` }} />
          ) : (
            <div className="image-placeholder">
              <FontAwesomeIcon
                style={{ width: '100%' }}
                className="fa-2x"
                color="#D6DCE2"
                icon={['far', 'eye-slash']}
              />
            </div>
          )}
        </ImageArea>
      </SummaryColumnItem>

      <SummaryColumnItem>
        <span className="description">{t('training-title')}</span>
        <span className="value-text">{displayValue(values.subject)}</span>
      </SummaryColumnItem>
      <SummaryColumnItem>
        <span className="description">{t('training-demo')}</span>
        <span className="value-text">
          {shortenDescription(values.description)}
        </span>
      </SummaryColumnItem>
      <SummaryRowList>
        <SummaryRowItem>
          <span className="description">{t('execution-type')}:</span>
          <span className="value-text">
            {displayValue(
              values.training_type ? values.training_type.title : undefined,
            )}
          </span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('training-starts')}:</span>
          <span className="value-text">
            {displayValue(displayTime(values.start_time))}
          </span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('training-ends')}:</span>
          <span className="value-text">
            {displayValue(
              values.continuous_training
                ? displayValue(t('continuous'))
                : displayTime(values.end_time),
            )}
          </span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('continuous-training')}:</span>
          <span className="value-text">
            {displayValue(
              values.continuous_training
                ? displayValue(t('selected'))
                : displayValue(t('not-selected')),
            )}
          </span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('enrollment_deadline')}:</span>
          <span className="value-text">
            {displayValue(
              values.continuous_training
                ? displayValue(t('continuous'))
                : displayValue(displayDate(values.enrollment_deadline)),
            )}
          </span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('only-for-members')}:</span>
          <span className="value-text">
            {displayValue(
              values.only_for_members
                ? displayMemberLimitations(values.only_for_members, t)
                : undefined,
            )}
          </span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('open-spots')}:</span>
          <span className="value-text">{displayValue(values.positions)}</span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('price')}:</span>
          <span className="value-text">
            {displayValue(displayPrice(values.price))}
          </span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('member-price')}:</span>
          <span className="value-text">
            {displayValue(displayPrice(values.member_price))}
          </span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('website')}:</span>
          <span className="value-text">{displayValue(values.web_link)}</span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('trainer')}:</span>
          <span className="value-text">{displayValue(values.trainer)}</span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('training-location')}:</span>
          <span className="value-text">
            {displayValue(
              values.locations ? values.locations.value : undefined,
            )}
          </span>
        </SummaryRowItem>
        <SummaryRowItem>
          <span className="description">{t('address')}:</span>
          <span className="value-text">{displayValue(values.address)}</span>
        </SummaryRowItem>
      </SummaryRowList>
    </SummaryBackground>
  );
};

export default NewTrainingSummary;
