import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Phase1 from './FieldComponents/Phase1';
import Phase2 from './FieldComponents/Phase2';
import Phase3 from './FieldComponents/Phase3';
import Phase4 from './FieldComponents/Phase4';
import FormTabs from './FormTabs';

const UserProfileTabsContainer = styled.div`
  max-width: 1440px;
  margin: auto;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 0 25px;
  }
  .container-content {
    position: relative;
    max-width: 846px;
    border: 1px solid #d6dce2;
    border-top: none;
    background-color: #ffffff;
    padding-bottom: 30px;
    margin-bottom: 30px;
    .border {
      position: relative;
      border-top: 1px solid #d6dce2;
      top: -1px;
    }
  }
`;

const UserProfileTabs = () => {
  const getDefaultTabIndex = () => {
    const params = new URLSearchParams(window.location.search);
    const tabIndex = params.get('tabIndex');
    if (tabIndex) {
      document.getElementById('tabs')?.scrollIntoView();
      return parseInt(tabIndex, 10);
    }
    return 0;
  };
  const [tabIndex, setTabIndex] = useState<number>(getDefaultTabIndex());
  useEffect(() => {
    const tab = getDefaultTabIndex();
    setTabIndex(tab);
  }, [getDefaultTabIndex()]);
  const renderPhase = () => {
    switch (tabIndex) {
      case 0:
        return <Phase1 />;
      case 1:
        return <Phase2 />;
      case 2:
        return <Phase3 />;
      case 3:
        return <Phase4 />;
      default:
        return <Phase1 />;
    }
  };
  return (
    <UserProfileTabsContainer>
      <div className="container-content" id="tabs">
        <div className="border" />
        <FormTabs
          currentTab={tabIndex}
          onTabChange={(index: number) => setTabIndex(index)}
        />
        {renderPhase()}
      </div>
    </UserProfileTabsContainer>
  );
};

export default UserProfileTabs;
