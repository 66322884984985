import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  fetchSingleOrganization,
  OfficeType,
} from 'Features/Organization/organizationSlice';
import styled from 'styled-components';
import CompanyDetails from 'Components/AdminSettings/AdminDetails/CompanyDetails';
import {
  AdditionalCompanyDetailsComponent,
  EmptyAdditionalLocationObject,
} from 'Components/AdminSettings/AdminDetails/AdditionalLocationDetails';
import PhotoVault from 'Components/AdminSettings/AdminDetails/PhotoVault';
import { breakpoints } from 'Components/Layout/MediaQuery';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'Store';
import organizationIsHousehold from 'Util/organizationIsHousehold';
import AddNewLocationModal from './AddNewLocationModal';
import ModifyOrganizationModal from './ModifyOrganizationModal';

const DetailsBackground = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 30px;
  }
`;
const DetailsTitle = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 29px;
`;
const DetailsContainer = styled.div`
  background: #fff;
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 23px;
  flex-direction: column;
  overflow: auto;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    flex-direction: row;
  }
`;
const DetailsTopBar = styled.div`
  display: flex;
  justify-content: space-between;
`;
const EditProfileButton = styled.div`
  height: 37px;
  border-radius: 6px;
  background-color: #d60c17;
  border: none;
  outline: none;
  color: #ffffff;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  padding: 0px 20px 0px 20px;
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 0px 30px 0px 30px;
  }
`;
const EditIcon = styled(FontAwesomeIcon)`
  margin-right: 0px;
  font-size: 0.8em;
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    margin-right: 8px;
  }
`;
const AdminDetailsComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const companyInformation = useSelector((state: RootState) => state.admin);
  const {
    data: { results: organizations },
  } = useSelector((state: RootState) => state.organization);
  const [addNewLocationModalOpen, setAddNewLocationModalOpen] = useState(false);
  const [editOrganizationModalOpen, setEditOrganizationModalOpen] =
    useState(false);
  const { singleOrganizationData } = useSelector(
    (state: RootState) => state.organization,
  );
  const offices = singleOrganizationData
    ? singleOrganizationData.offices
    : undefined;
  const isHousehold = organizationIsHousehold();
  const determineMainOffice = () => {
    const mainOffice = offices
      ? offices.find((office: OfficeType) => {
          return office.is_main_office;
        })
      : undefined;
    return mainOffice;
  };
  const determineAdditionalLocation = () => {
    const additionalOffices = offices
      ? offices.filter((office: OfficeType) => {
          return !office.is_main_office;
        })
      : [];
    return additionalOffices as Array<OfficeType>;
  };
  const mainOffice = determineMainOffice();
  useEffect(() => {
    if (organizations && organizations[companyInformation.itemIndex].id)
      dispatch(
        fetchSingleOrganization(organizations[companyInformation.itemIndex].id),
      );
  }, [dispatch, organizations]);

  const companyDetails =
    organizations && companyInformation
      ? {
          companyLogo: singleOrganizationData.logo,
          companyName: singleOrganizationData.name,
          companyVat: singleOrganizationData.vat_number,
          companyEmail: singleOrganizationData.email,
          companyId: singleOrganizationData.id,
        }
      : undefined;
  return (
    <>
      {editOrganizationModalOpen ? (
        <ModifyOrganizationModal
          modalToggle={setEditOrganizationModalOpen}
          organizationDetails={singleOrganizationData}
          currentMainOffice={mainOffice}
          offices={offices}
          singleOffice
        />
      ) : null}
      {addNewLocationModalOpen ? (
        <AddNewLocationModal
          modalToggle={setAddNewLocationModalOpen}
          companyId={singleOrganizationData.id}
          companyName={singleOrganizationData.name}
          singleOffice
        />
      ) : null}
      <DetailsBackground>
        <DetailsTopBar>
          <DetailsTitle>
            {isHousehold ? t('household-details') : t('organization-details')}
          </DetailsTitle>
          {!isHousehold && (
            <EditProfileButton
              onClick={() => setEditOrganizationModalOpen(true)}
            >
              <div>
                {window.innerWidth > breakpoints.xs ? (
                  <>
                    <EditIcon icon={['fas', 'pen']} /> {t('modify-profile')}
                  </>
                ) : (
                  <>
                    <EditIcon icon={['fas', 'pen']} />
                  </>
                )}
              </div>
            </EditProfileButton>
          )}
        </DetailsTopBar>
        <DetailsContainer>
          <CompanyDetails
            companyDetails={companyDetails}
            mainOffice={mainOffice}
          />
          {!isHousehold && (
            <>
              {determineAdditionalLocation().length ? (
                <AdditionalCompanyDetailsComponent
                  additionalOffices={determineAdditionalLocation()}
                  modalToggle={setAddNewLocationModalOpen}
                  companyId={singleOrganizationData.id}
                  companyName={singleOrganizationData.name}
                />
              ) : (
                <EmptyAdditionalLocationObject
                  modalToggle={setAddNewLocationModalOpen}
                />
              )}
            </>
          )}
        </DetailsContainer>
      </DetailsBackground>
      <PhotoVault />
    </>
  );
};
export default AdminDetailsComponent;
