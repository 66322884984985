import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import Logo from 'Assets/rakennusliitto-logo.png';
import { Link, useHistory, withRouter } from 'react-router-dom';
import type { Location } from 'history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Desktop, Mobile, Tablet } from 'Components/Layout/MediaQuery';
import { useTranslation } from 'react-i18next';
import DropdownMenu, { DropdownProps } from 'Components/DropdownMenu';
import {
  isAdmin,
  isHouseholdAdmin,
  isJobSeeker,
  isOrganizationAdmin,
} from 'Util/permissions';
import { genericActions, userLogout } from 'Features/Auth/authSlice';
import LoginModal from 'Features/Auth/LoginModal';
import { RootState } from 'Store';
import NavigationDropdown from './NavigationDropdown';

interface StyledLinkProps {
  isActive: boolean;
}
interface MobileMenuProps {
  open: boolean;
  height: Number;
}

const NavbarContainer = styled.div`
  max-height: 60px;
  min-height: 60px;
  width: 100%;
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 999;
`;
const NavbarContent = styled.div`
  max-height: 60px;
  min-height: 60px;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  img {
    height: 30px;
    width: 30px;
  }
  svg.hamburger {
    position: absolute;
    right: 15px;
    top: calc(50% - 10px);
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
`;
const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 190px;
  }
`;
const MenuItems = styled.div`
  flex-grow: 1;
  display: flex;
  a {
    font-family: Barlow;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    margin-right: 50px;
    text-decoration: none;
    &.outside-link {
      color: #252525;
    }
  }
`;
const MobileMenuItems = styled.div`
  background: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: ${(props: MobileMenuProps) =>
    props.open ? `${props.height}px` : '0px'};
  display: flex;
  flex-direction: column;
  transition: max-height 0.3s ease-in-out;
  overflow-y: hidden;
  a {
    font-family: Barlow;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    margin-right: 50px;
    text-decoration: none;
    padding: 10px 20px;
    &.outside-link {
      color: #252525;
    }
  }
`;
const ActionsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  &:last-of-type {
    padding-bottom: 20px;
  }
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    &:last-of-type {
      padding-bottom: 10px;
    }
  }
  svg {
    margin: 0 20px 0 10px;
    cursor: pointer;
  }
`;
const LoginLink = styled.div`
  color: #5c5f63;
  font-family: Barlow;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  cursor: ${(props: { cursor: string }) => props.cursor};
`;
const SubMenuItems = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  a {
    font-family: Barlow;
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
    padding: 8px 0px 8px 0px;
    &.outside-link {
      color: white;
    }
  }
`;
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
const StyledLink = styled(({ isActive, ...rest }) => <Link {...rest} />)`
  ${(props: StyledLinkProps) =>
    props.isActive
      ? `
      font-weight: bold;
      color: #D60C17;
  `
      : 'color: #252525;'}
`;
/* eslint-enable react/jsx-props-no-spreading */
/* eslint-enable @typescript-eslint/no-unused-vars */

const Navbar = ({ location }: { location: Location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, isAuthenticated } = useSelector(
    (state: RootState) => state.auth,
  );
  const [mobileHeight, setMobileHeight] = React.useState<Number>(0);
  const [isMobileMenuOpen, setMobileMenuOpen] = React.useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = React.useState<boolean>(false);
  const userOptions: Array<DropdownProps> = [];
  if (user.groups && isJobSeeker(user.groups)) {
    userOptions.push({
      icon: 'user-circle',
      title: t('user-profile'),
      action: () => {
        history.push('/profile');
      },
    });
  }
  if (user.groups && isAdmin(user.groups)) {
    userOptions.push({
      icon: 'briefcase',
      title: t('super-admin-panel'),
      action: () => {
        history.push('/super-admin-panel');
      },
    });
  }
  if (user.groups && isOrganizationAdmin(user.groups) && user.is_employer) {
    userOptions.push({
      icon: 'briefcase',
      title: t('admin-panel'),
      action: () => {
        history.push('/admin-panel');
      },
    });
  }
  if (user.groups && isOrganizationAdmin(user.groups) && user.is_trainer) {
    userOptions.push({
      icon: 'briefcase',
      title: t('education-panel'),
      action: () => {
        history.push('/education-panel');
      },
    });
  }
  if (user.groups && isHouseholdAdmin(user.groups) && user.is_household) {
    userOptions.push({
      icon: 'briefcase',
      title: t('admin-panel'),
      action: () => {
        history.push('/admin-panel');
      },
    });
  }

  userOptions.push({
    icon: 'sign-out',
    title: t('log-out'),
    action: () => {
      dispatch(userLogout());
      dispatch(genericActions.ResetAction());
    },
  });
  const wageMillNavigationDropdownOptions = [
    {
      title: t('households'),
      action: () => {
        window.location.href = 'https://palkkamylly.palkkaus.fi/household';
      },
    },
    {
      title: t('employees-members'),
      action: () => {
        history.push('/palkanmaksu');
      },
    },
    {
      title: t('companies'),
      action: () => {
        window.location.href = 'https://palkkamylly.palkkaus.fi/company';
      },
    },
  ];

  const jobMillNavigationDropdownOptions = [
    {
      title: t('households'),
      action: () => {
        history.push('/admin-panel');
      },
    },
    {
      title: t('companies-employers'),
      action: () => {
        history.push('/admin-panel');
      },
    },
    {
      title: t('employees-members'),
      action: () => {
        history.push('/jobs');
      },
    },
  ];
  const renderMobileNav = () => (
    <>
      <MobileMenuItems
        ref={(el: HTMLDivElement) => {
          if (!el) {
            return;
          }

          if (mobileHeight !== el.scrollHeight) {
            setMobileHeight(el.scrollHeight);
          }
        }}
        open={isMobileMenuOpen}
        height={mobileHeight}
      >
        <StyledLink isActive={location.pathname === '/'} to="/">
          {t('front-page')}
        </StyledLink>
        <StyledLink isActive={location.pathname === '/jobs'} to="/jobs">
          {t('job-mill')}
        </StyledLink>
        <StyledLink
          isActive={location.pathname === '/trainings'}
          to="/trainings"
        >
          {t('skill-mill')}
        </StyledLink>
        <StyledLink isActive={false} to="/palkanmaksu">
          {t('wage-payment')}
        </StyledLink>
        <StyledLink
          isActive={location.pathname === '/mylly/suuntamylly'}
          to="/mylly/suuntamylly"
        >
          {t('direction-mill')}
        </StyledLink>
        {!isAuthenticated && (
          <SubMenuItems>
            <>
              <a href="https://palkkamylly.palkkaus.fi/household">
                {t('households')}
              </a>
              <StyledLink
                isActive={location.pathname === '/palkanmaksu'}
                to="/palkanmaksu"
              >
                {t('employees-members')}
              </StyledLink>
              <a href="https://palkkamylly.palkkaus.fi/company">
                {t('companies')}
              </a>
            </>
          </SubMenuItems>
        )}
        <a className="outside-link" href="https://rakennusliitto.fi">
          Rakennusliitto.fi
        </a>
        <ActionsContainer>
          {isAuthenticated ? (
            <>
              <LoginLink cursor="default">
                {t('hey')}, {user.first_name}
              </LoginLink>
              <DropdownMenu
                options={userOptions}
                menuPosition="leftTop"
                objectId={0}
              />
            </>
          ) : (
            <LoginLink cursor="pointer" onClick={() => setShowLoginModal(true)}>
              {t('log-in')}
            </LoginLink>
          )}
        </ActionsContainer>
      </MobileMenuItems>
      <FontAwesomeIcon
        onClick={() => setMobileMenuOpen((state) => !state)}
        icon={['far', 'bars']}
        className="hamburger"
      />
    </>
  );
  return (
    <NavbarContainer>
      <NavbarContent>
        <LogoContainer>
          <img src={Logo} alt="Rakennusliitto logo" />
        </LogoContainer>
        <Desktop>
          <>
            <MenuItems>
              <StyledLink isActive={location.pathname === '/'} to="/">
                {t('front-page')}
              </StyledLink>
              <NavigationDropdown
                options={jobMillNavigationDropdownOptions}
                dropdownLinkTitle="job-mill"
              />
              <StyledLink
                isActive={location.pathname === '/trainings'}
                to="/trainings"
              >
                {t('skill-mill')}
              </StyledLink>
              {isAuthenticated ? (
                <StyledLink isActive={false} to="/palkanmaksu">
                  {t('wage-payment')}
                </StyledLink>
              ) : (
                <NavigationDropdown
                  options={wageMillNavigationDropdownOptions}
                  dropdownLinkTitle="wage-payment"
                />
              )}
              <StyledLink
                isActive={location.pathname === '/mylly/suuntamylly'}
                to="/mylly/suuntamylly"
              >
                {t('direction-mill')}
              </StyledLink>
              <a className="outside-link" href="https://rakennusliitto.fi">
                Rakennusliitto.fi
              </a>
            </MenuItems>
            <ActionsContainer>
              {isAuthenticated ? (
                <>
                  <LoginLink cursor="default">Hei, {user.first_name}</LoginLink>
                  <DropdownMenu
                    options={userOptions}
                    menuPosition="leftBottom"
                    objectId={0}
                  />
                </>
              ) : (
                <LoginLink
                  cursor="pointer"
                  onClick={() => setShowLoginModal(true)}
                >
                  {t('log-in')}
                </LoginLink>
              )}
            </ActionsContainer>
          </>
        </Desktop>
        <Tablet>{renderMobileNav()}</Tablet>
        <Mobile>{renderMobileNav()}</Mobile>
        <LoginModal
          modalOpen={showLoginModal}
          closeModal={() => setShowLoginModal(false)}
        />
      </NavbarContent>
    </NavbarContainer>
  );
};

export default withRouter(Navbar);
