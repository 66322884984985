import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'Store';
import Select from 'react-select';
import { fetchLocations } from 'Features/NewAdvertisement/newAdvertisementSlice';
import { Tooltip } from 'Components/Blocks';
import {
  createNewOffice,
  NewOfficeRequestType,
} from 'Features/Office/officeSlice';
import { toast } from 'react-toastify';
import {
  fetchOrganizations,
  fetchSingleOrganization,
} from 'Features/Organization/organizationSlice';
import { LocationType } from 'Features/MemberProfile/memberProfileSlice';

const TransparentBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
`;
const CloseContainer = styled.div`
  display: flex;
  color: #ffffff;
  font-family: Barlow;
  font-size: 14px;
  align-items: center;
  width: 100%;

  justify-content: flex-end;
  min-height: 40px;
  max-height: 40px;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 60%;
    height: 60%;
  }
`;
const TopCloseButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
`;
const CloseButtonContainer = styled.div`
  display: flex;
  margin: 35px;
  justify-content: flex-end;
`;
const CancelButton = styled.div`
  height: 37px;
  width: 175px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: Barlow;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
  color: #bcc2c6;
`;
const AddUserButtom = styled(CancelButton)`
  margin-left: 20px;
  width: fit-content;
  padding: 5px;
  white-space: nowrap;
  background-color: #d60c17;
  color: #ffffff;
`;
const CloseIcon = styled(FontAwesomeIcon)`
  color: #ffffff;
  font-size: 1.5em;
  margin-left: 10px;
`;
const ModalBackground = styled.div`
  background: #ffffff;
  width: 100%;
  margin-bottom: 20px;
  overflow: auto;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 60%;
  }
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  label {
    color: #252525;
    font-weight: 600;
    font-family: Barlow;
    font-size: 14px;
    margin-bottom: 10px;
  }
  span {
    color: red;
  }
  .form-select {
    border: 1px solid #d6dce2;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 30px;
    outline: none;
  }
`;
const Title = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 20px;
  margin-bottom: 30px;
`;
const InfoTooltip = styled(FontAwesomeIcon)`
  margin-left: 10px;
`;
const TooltipBackground = styled.span`
  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(42, 46, 59, 0.2);
    color: #222;
    text-align: center;
    border: 1px solid #d6dce2;
    font-family: Barlow;
    padding: 5px 5px;
    border-radius: 6px;
    position: absolute;
    font-weight: 500;
    z-index: 1;
    margin-left: 10px;
  }

  .tooltip .tooltiptext::after {
    content: ' ';
    position: absolute;
    top: 15px;
    color: #222;
    right: 100%;
    margin-top: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #00000040 transparent transparent;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;
const OrganizationName = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
`;
const NewUserForm = styled.form`
  display: flex;
  flex-direction: column;
`;
const LocationSelect = styled(Select)`
  width: 100%;
  margin-bottom: 15px;
`;

type UpdateNewOfficeRequest = {
  key: string;
  value: string | number;
};
const defaultProps = {
  singleOffice: false,
};
const AddNewLocationModal = ({
  modalToggle,
  companyName,
  companyId,
  singleOffice,
}: {
  modalToggle: (arg0: boolean) => void;
  companyName: string;
  companyId: number;
  singleOffice?: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [locationValue, setLocationValue] = useState<LocationType>();
  const initialData = {
    name: '',
    address: '',
    phone_number: '',
    email: '',
    is_main_office: false,
    organization: companyId,
    location: undefined,
  };
  const [newOffice, setNewOffice] = useState<NewOfficeRequestType>(initialData);
  const { locations } = useSelector(
    (state: RootState) => state.newAdvertisement,
  );
  useEffect(() => {
    dispatch(fetchLocations());
  }, [dispatch]);
  const jobLocations = locations
    ? locations.map(({ name, id }: { name: string; id: number }) => ({
        id,
        value: name,
        label: name,
      }))
    : undefined;
  const handleSubmission = () => {
    dispatch(createNewOffice({ body: newOffice })).then(({ error }: any) => {
      if (error) {
        const errorObj: {
          [key: string]: { [key: string]: any };
        } = JSON.parse(error.message);
        const errors: Array<string> = [];
        Object.keys(errorObj).map((k: string) => {
          errors.push(`${t(k)}`);
        });
        // eslint-disable-next-line no-undef
        toast.error(
          <>
            <strong>{t('fill-missing-fields')}:</strong>
            {errors.map((e: string) => (
              <p key={e}>
                <strong>{e.split(' - ')[0]}</strong>
              </p>
            ))}
          </>,
        );
      } else if (singleOffice) {
        dispatch(fetchSingleOrganization(companyId)).then(() =>
          modalToggle(false),
        );
      } else {
        dispatch(fetchOrganizations()).then(() => modalToggle(false));
      }
    });
  };
  const setOfficeValues = ({ key, value }: UpdateNewOfficeRequest) => {
    setNewOffice({ ...newOffice, [key]: value });
  };
  const changeLocation = (e: LocationType) => {
    setLocationValue(e);
    setOfficeValues({ key: 'location', value: e.id });
  };
  return (
    <TransparentBackground>
      <CloseContainer>
        <TopCloseButton onClick={() => modalToggle(false)}>
          <div>{t('close')}</div>
          <CloseIcon icon={['fal', 'times-circle']} />
        </TopCloseButton>
      </CloseContainer>
      <ModalBackground>
        <ModalContent>
          <Title> {t('add-new-office')}</Title>
          <NewUserForm>
            <label>
              {t('office-name')} <span>*</span>
            </label>
            <input
              className="form-select"
              size={30}
              value={newOffice.name}
              placeholder={t('office-name')}
              onChange={(e) =>
                setOfficeValues({ key: 'name', value: e.target.value })
              }
            />
            <label>
              {t('address')}
              <span>*</span>
            </label>
            <input
              className="form-select"
              size={30}
              value={newOffice.address}
              placeholder={t('address')}
              onChange={(e) =>
                setOfficeValues({ key: 'address', value: e.target.value })
              }
            />
            <label>
              {t('phone')} <Tooltip tooltipText="phone-number-tooltip" />
            </label>
            <input
              className="form-select"
              size={30}
              value={newOffice.phone_number}
              placeholder={t('phone-placeholder')}
              onChange={(e) =>
                setOfficeValues({ key: 'phone_number', value: e.target.value })
              }
            />
            <label>{t('email')}</label>
            <input
              className="form-select"
              size={30}
              value={newOffice.email}
              placeholder="email@address.com"
              onChange={(e) =>
                setOfficeValues({ key: 'email', value: e.target.value })
              }
            />
            <label>
              {t('location')} <span>*</span>
            </label>
            <LocationSelect
              placeholder={t('select-location')}
              value={locationValue}
              options={jobLocations}
              // @ts-ignore: Type mismatch in onChange handler
              onChange={(e: LocationType) => changeLocation(e)}
            />
            <label>
              {t('organization')} <span>*</span>
              <TooltipBackground>
                <span className="tooltip">
                  <InfoTooltip color="#0859AC" icon={['fal', 'info-circle']} />
                  <span className="tooltiptext">
                    {t('informant-information')}
                  </span>
                </span>
              </TooltipBackground>
            </label>
            <OrganizationName>{companyName}</OrganizationName>
          </NewUserForm>
        </ModalContent>
        <CloseButtonContainer>
          <CancelButton onClick={() => modalToggle(false)}>
            {t('cancel')}
          </CancelButton>
          <AddUserButtom onClick={() => handleSubmission()}>
            {t('add-new-office')}
          </AddUserButtom>
        </CloseButtonContainer>
      </ModalBackground>
    </TransparentBackground>
  );
};

AddNewLocationModal.defaultProps = defaultProps;
export default AddNewLocationModal;
