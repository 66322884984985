/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DropDown = styled.div`
  .dropbtn {
    cursor: pointer;
    width: 15px;
  }
  .horizontal {
    transform: rotate(90deg);
  }
  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    background: #fff;
    position: absolute;
    min-width: 180px;
    overflow: auto;
    border: 1px solid #dcdddf;
    background-color: #ffffff;
    z-index: 1;
    line-height: 16px;
  }
  .leftBottom {
    transform: translateX(-100%);
  }
  .leftTop {
    transform: translateX(-100%) translateY(-100%);
    top: 0px;
  }
  .rightBottom {
  }
  .rightTop {
    transform: translateY(-100%);
    top: 0px;
  }

  .dropdown-content div {
    padding: 4px;
    text-decoration: none;
    display: block;
    text-align: start;
    cursor: default;
    color: #252525;
    font-family: Barlow;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    &:hover {
      background-color: #ddd;
    }
  }
`;
const EllipsisIcon = styled(FontAwesomeIcon)`
  font-size: 1.5em;
  height: 20px;
  color: #5c5f63;
  &:hover {
    cursor: pointer;
  }
`;
const ElementIcon = styled(FontAwesomeIcon)`
  color: #a8aeb4;
  margin-right: 4px;
  min-width: 20px;
  max-width: 20px;
`;

export interface DropdownProps {
  icon: string;
  title: string;
  action: (objectId: number) => void;
  titleFunc?: (objectId: number) => string;
}

const defaultProps = {
  horizontal: false,
  menuPosition: 'leftBottom',
};
const DropdownMenu = ({
  options,
  horizontal,
  menuPosition,
  objectId,
}: {
  options: DropdownProps[];
  horizontal?: boolean;
  objectId: number;
  menuPosition?: 'leftBottom' | 'leftTop' | 'rightBottom' | 'rightTop';
}) => {
  const myRef = useRef<HTMLDivElement>(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const openObjects = () => {
    setSettingsOpen(!settingsOpen);
  };
  const DropdownElement = (elementDetails: DropdownProps) => {
    const onClickAction = () => {
      elementDetails.action(objectId);
      setSettingsOpen(false);
    };
    return (
      <div onClick={onClickAction}>
        <ElementIcon icon={['far', elementDetails.icon as any]} />
        {elementDetails.title}
      </div>
    );
  };
  const handleClickOutside = (e: { target: any }) => {
    const node = myRef.current;
    if (node?.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setSettingsOpen(false);
  };

  useEffect(() => {
    if (settingsOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [settingsOpen]);
  return (
    <DropDown>
      <div className="dropdown" ref={myRef}>
        <EllipsisIcon
          className={`dropbtn ${horizontal ? 'horizontal' : null}`}
          icon={['far', 'ellipsis-v']}
          onClick={() => openObjects()}
        />
        {settingsOpen ? (
          <div
            id="myDropdown"
            className={`dropdown-content ${menuPosition || null}`}
          >
            {options
              ? options.map((opt: DropdownProps) => (
                  <DropdownElement
                    key={opt.title}
                    icon={opt.icon}
                    title={opt.titleFunc ? opt.titleFunc(objectId) : opt.title}
                    action={opt.action}
                  />
                ))
              : null}
          </div>
        ) : null}
      </div>
    </DropDown>
  );
};
DropdownMenu.defaultProps = defaultProps;

export default DropdownMenu;
