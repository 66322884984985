/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  NewTrainingForm,
  NewTrainingSummary,
} from 'Components/EducationPage/NewTraining';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'Store';
import { useTranslation } from 'react-i18next';
import {
  fetchTrainingWithId,
  NewTrainingValuesType,
} from 'Features/Training/trainingSlice';
import {
  initLocations,
  initMemberStatus,
  initTrainingType,
} from 'Components/EducationPage/NewTraining/ModifyTrainingInitializers';
import draftToHtml from 'draftjs-to-html';
import { setAdValues } from 'Util/trainingUtils';

const ContentContainer = styled.div`
  width: 100%;
  flex: 1;
`;
const SurveyContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    flex-direction: row;
  }
`;
const SurveyContent = styled.div`
  width: 100%;
  max-width: 850px;
  padding: 10px;
  background-color: #f4f5f7;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    padding: 20px 40px;
  }
`;
export const IsContinuousInput = styled.div`
  height: 25px;
  width: 180px;
  margin-top: 10px;
  label {
    margin-left: 8px;
  }
`;
export const ContinuousInput = styled.input``;

const defaultProps = {
  adId: undefined,
};

export const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setAdValues('continuous_training', event.target.checked);
  setAdValues('end_time', null);
  setAdValues('enrollment_deadline', null);
};

const NewTraining = ({ adId }: { adId?: string }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [currentFormIndex, setCurrentFormIndex] = useState<number>(0);
  const companyInformation = useSelector((state: RootState) => state.admin);
  const { singleTrainingData, values } = useSelector(
    (state: RootState) => state.training,
  );
  const { data } = useSelector((state: RootState) => state.organization);
  const { results } = data;
  const companyLogo =
    results && companyInformation
      ? results[companyInformation.itemIndex].logo
      : '';
  const companyName =
    results && companyInformation
      ? results[companyInformation.itemIndex].name
      : undefined;
  const companyId =
    results && companyInformation
      ? results[companyInformation.itemIndex].id
      : undefined;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    dispatch({ type: 'training/resetNewAd' });
    if (adId) {
      dispatch(fetchTrainingWithId(parseInt(adId, 10)));
    }
  }, []);

  useEffect(() => {
    if (singleTrainingData && adId) {
      const newState: NewTrainingValuesType = { ...values };

      newState.training_type = initTrainingType(
        singleTrainingData?.training_type,
        t,
      );
      newState.id = singleTrainingData.id;
      newState.subject = singleTrainingData.subject;
      newState.start_time = singleTrainingData.start_time;
      newState.end_time = singleTrainingData.end_time;
      newState.continuous_training = singleTrainingData.continuous_training;
      newState.confirmed = singleTrainingData.confirmed;
      newState.enrollment_deadline = singleTrainingData.enrollment_deadline;
      newState.positions = singleTrainingData.positions;
      newState.price = singleTrainingData.price;
      newState.images = singleTrainingData.images;
      newState.member_price = singleTrainingData.member_price;
      newState.target_audience = singleTrainingData.target_audience;
      newState.trainer = singleTrainingData.trainer;
      newState.web_link = singleTrainingData.web_link;
      newState.only_for_members = initMemberStatus(
        singleTrainingData?.only_for_members,
        t,
      );
      newState.subject_areas = singleTrainingData.subject_areas;
      newState.locations = initLocations(
        singleTrainingData?.training_location?.location,
      );

      newState.address = singleTrainingData?.training_location?.address;
      if (singleTrainingData?.description) {
        const desc = convertFromHTML(singleTrainingData.description);
        const content = ContentState.createFromBlockArray(
          desc.contentBlocks,
          desc.entityMap,
        );
        setEditorState(EditorState.createWithContent(content));
        newState.description = draftToHtml(convertToRaw(content));
      }
      newState.organization = singleTrainingData.organization;
      dispatch({ type: 'training/updateNewAd', payload: newState });
    }
  }, [singleTrainingData]);

  return (
    <ContentContainer>
      <SurveyContainer>
        <SurveyContent>
          <NewTrainingForm
            companyId={companyId}
            formIndex={currentFormIndex}
            companyName={companyName}
            onFormIndexChange={(index: number) => setCurrentFormIndex(index)}
            companyLogo={companyLogo}
            editorState={editorState}
            setEditorState={setEditorState}
            adId={adId}
          />
        </SurveyContent>
        <NewTrainingSummary companyName={companyName} />
      </SurveyContainer>
    </ContentContainer>
  );
};
NewTraining.defaultProps = defaultProps;

export default NewTraining;
