import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from 'Components/Layout/MainLayout';
import FrontPageHero from 'Components/FrontPage/FrontPageHero';
import Carousel from 'Components/FrontPage/FrontPageCarousel';
import { fetchFrontPageSettings } from 'Components/FrontPage/frontPageSlice';

/* eslint-disable camelcase */
export interface CarouselData {
  carousel_groups: Array<CarouselGroupData>;
  embedded_video_url: string;
  id: number;
}
export interface CarouselGroupData {
  carousel_items: Array<CarouselItemData>;
  content_settings: number;
  id: number;
}
export interface CarouselItemData {
  id: number;
  title: string;
  description: string;
  image: string;
  video_url: string;
  action: {
    name: string;
    url: string;
  };
}
/* eslint-enable camelcase */

const FrontPage = () => {
  const dispatch = useDispatch();
  const {
    frontPage: { data },
  } = useSelector((state: any) => state);
  React.useEffect(() => {
    dispatch(fetchFrontPageSettings());
  }, [dispatch]);
  return (
    <MainLayout>
      <FrontPageHero />
      {data.carousel_groups &&
        data.carousel_groups.map((g: CarouselGroupData) => (
          <Carousel key={g.id} data={g.carousel_items} />
        ))}
    </MainLayout>
  );
};

export default FrontPage;
