import React from 'react';
import VaultFieldContent from 'Components/NewAdvertisement/FormComponents/AddPhotoVault/VaultFieldContent';

const PhotoVaultComponent = ({
  setValue,
  values,
}: {
  setValue: Function;
  values: any;
}) => {
  const [currentFormIndex, setCurrentFormIndex] = React.useState<number>(0);

  return (
    <VaultFieldContent
      formIndex={currentFormIndex}
      onFormIndexChange={(index: number) => setCurrentFormIndex(index)}
      setValue={setValue}
      values={values}
    />
  );
};
export default PhotoVaultComponent;
