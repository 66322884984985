import { fetchPaginatedFilteredTrainings } from 'Features/Training/trainingSlice';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'Store';
import styled from 'styled-components/macro';
import SearchResult from './SearchResults';

const PaginationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  span {
    padding: 5px 10px;
    border-radius: 6px;
    background: #ddd;
    margin-right: 5px;
    margin-bottom: 5px;
    color: #272727;
    cursor: pointer;
    min-width: 40px;
    text-align: center;
    &.ellipsis {
      background: none;
      cursor: default;
    }
    &.active {
      color: white;
      background: #d60c17;
    }
  }
`;
const defaultProps = {
  organizationId: undefined,
  status: undefined,
};
const Pagination = ({
  organizationId,
  status,
}: {
  organizationId?: number | undefined;
  status?: string | undefined;
}) => {
  const dispatch = useAppDispatch();
  const { currentPage } = useSelector((state: RootState) => state.training);
  const { trainingCount } = useSelector((state: RootState) => state.training);
  const pageCount = Math.ceil(trainingCount / 20);
  const pages = [];
  for (let i = currentPage; i < currentPage + 3; i += 1) {
    if (i < pageCount) {
      pages.push(i);
    }
  }
  for (let i = currentPage - 1; i > currentPage - 5; i -= 1) {
    if (i >= 0) {
      pages.push(i);
    }
  }
  return (
    <PaginationContainer>
      {pages.includes(0) ? null : (
        <>
          <span
            key={0}
            onClick={() => {
              const body = {
                page: 1,
                filters: {
                  organizations: organizationId ? [organizationId] : undefined,
                  status,
                  order: 'start_time' as 'start_time',
                },
              };
              dispatch(fetchPaginatedFilteredTrainings(body));
              dispatch({
                type: 'training/changePage',
                payload: body.page,
              });
            }}
            className={currentPage === 1 ? 'active' : ''}
          >
            {1}
          </span>
          <span className="ellipsis">...</span>
        </>
      )}
      {pages
        .sort((a, b) => (b < a ? 1 : -1))
        .map((index: number) => (
          <span
            key={index}
            onClick={() => {
              const body = {
                page: index + 1,
                filters: {
                  organizations: organizationId ? [organizationId] : undefined,
                  status,
                  order: 'start_time' as 'start_time',
                },
              };
              dispatch(fetchPaginatedFilteredTrainings(body));
              dispatch({
                type: 'training/changePage',
                payload: body.page,
              });
            }}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </span>
        ))}
      {pages.includes(pageCount - 1) || Number.isNaN(pageCount) ? null : (
        <>
          <span className="ellipsis">...</span>
          <span
            key={pageCount}
            onClick={() => {
              const body = {
                page: pageCount,
                filters: {
                  organizations: organizationId ? [organizationId] : undefined,
                  status,
                  order: 'start_time' as 'start_time',
                },
              };
              dispatch(fetchPaginatedFilteredTrainings(body));
              dispatch({
                type: 'training/changePage',
                payload: body.page,
              });
            }}
            className={currentPage === pageCount ? 'active' : ''}
          >
            {pageCount}
          </span>
        </>
      )}
      <SearchResult count={trainingCount} />
    </PaginationContainer>
  );
};
Pagination.defaultProps = defaultProps;
export default Pagination;
