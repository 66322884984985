/* eslint-disable camelcase */
import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import {
  FetchPaginatedEmployeesResponse,
  MemberProfileProfessionalTitle,
  MemberProfileLocation,
  fetchPaginatedEmployees,
} from 'Features/MemberProfile/memberProfileSlice';
import { DrivingLicenseType } from 'Features/DrivingLicense/drivingLicenseSlice';
import {
  createMemberProfileFavorite,
  deleteMemberProfileFavorite,
} from 'Features/MemberProfileFavorite/memberProfileFavoriteSlice';
import DropdownMenu from 'Components/DropdownMenu';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'Store';
import { MemberSearchPagination } from 'Components/FrontendPagination';
import { ConversationRequestModal } from 'Components/Modals';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import EmployeeDetailsModal from './EmployeeDetailsModal';

const MemberProfilesBackground = styled.div`
  width: 100%;
  margin-top: 10px;
`;
const MemberProfilesContainer = styled.div`
  display: inline-block;
  width: 100%;
  min-width: 1200px;
`;
const MemberProfilesTitle = styled.div`
  height: 50px;
  line-height: 50px;
  color: #252525;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  margin-right: 30px;
`;
const MemberProfilesTopComponent = styled.div`
  display: flex;
  justify-content: space-between;
`;
const MemberProfilesBottomComponent = styled.div`
  white-space: nowrap;
  overflow: auto;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    overflow: inherit;
  }
`;
const MemberProfilesTop = styled.div`
  display: flex;
`;
const MemberProfilesHeader = styled.div`
  user-select: none;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #d6dce2;
  background: #fff;
  display: flex;
  padding-left: 20px;
`;
const MemberProfilesContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
`;
const JobmemberProfile = styled.div`
  height: 44px;
  width: 100%;
  line-height: 44px;
  display: flex;
  padding-left: 20px;
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  border: 2px solid #fff;
  &:nth-child(odd) {
    background: #f9f9f9;
    border: 2px solid #f9f9f9;
  }
  &:hover {
    border: 2px solid #a6ceff;
    background-color: #f4f8ff;
  }
`;
const HeadermemberProfile = styled.div`
  width: 15%;
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  &:first-child {
    width: 15%;
  }
`;
const HeaderOptionsBlock = styled.div`
  width: 15%;
  color: #252525;
`;
const JobTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  width: 15%;
  font-family: Barlow;
  color: #0a4ea2;
`;
const Options = styled.div`
  white-space: nowrap;
  user-select: none;
  width: 15%;
  display: flex;
  justify-content: space-evenly;
`;
const CustomLink = styled.div`
  text-decoration: none;
  color: #0a4ea2;
  font-family: Barlow;
  font-size: 14px;
  &:hover {
    cursor: pointer;
    font-weight: 600;
    svg {
      font-size: 1.2em;
    }
  }
`;
const AngleRightIcon = styled(FontAwesomeIcon)`
  color: #0a4ea2;
  margin: 0px 0px 0px 10px;
`;

const SelectedData = styled.div`
  border-radius: 9.5px;
  background-color: #e7eaef;
  width: fit-content;
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  padding: 5px 10px;
  font-weight: 500;
`;
const DataContainer = styled.div`
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const TooltipBackground = styled.span`
  .tooltip {
    position: relative;
    display: inline-block;
  }
  .tooltip:hover {
    font-weight: 600;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(42, 46, 59, 0.2);
    color: #222;
    text-align: start;
    border: 1px solid #d6dce2;
    font-family: Barlow;
    padding: 5px 10px;
    border-radius: 3px;
    position: absolute;
    font-weight: 500;
    z-index: 1;
    margin-left: -10px;
    margin-bottom: 3px;
    top: 30px;
    transform: translateX(-100%);
  }

  .tooltip .tooltiptext::after {
    content: ' ';
    position: absolute;
    top: 15px;
    color: #222;
    right: 100%;
    margin-top: -10px;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;
const TooltipObject = styled.div`
  line-height: normal;
`;

const EmployeeList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loc = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [recipientId, setRecipientId] = useState<number>();
  const { filterLocations } = useSelector(
    (state: RootState) => state.memberProfile,
  );
  const { filterGroups } = useSelector(
    (state: RootState) => state.memberProfile,
  );
  const { filterTitles } = useSelector(
    (state: RootState) => state.memberProfile,
  );
  const { filterName } = useSelector((state: RootState) => state.memberProfile);
  const [showConversationAddModal, setShowConversationAddModal] =
    useState<boolean>(false);
  const { itemIndex } = useSelector((state: RootState) => state.admin);
  const {
    data: { results },
  } = useSelector((state: RootState) => state.organization);
  const organizationId =
    results && typeof itemIndex === 'number' ? results[itemIndex]?.id : null;
  const { filteredProfiles } = useSelector(
    (state: RootState) => state.memberProfile,
  );
  const { data } = filteredProfiles;
  const openUserModal = (userProfile: any) => {
    setSelectedUser(userProfile);
    setShowModal(true);
  };
  const openConversationRequest = (objectId: number) => {
    setRecipientId(objectId);
    setShowConversationAddModal(true);
  };
  const profileOptions = [
    {
      icon: 'envelope',
      title: t('new-conversation-request'),
      action: (objectId: number) => {
        openConversationRequest(objectId);
      },
    },
    {
      icon: 'star',
      title: t('add-to-employer-bank'),
      titleFunc: (objectId: number) => {
        const obj = data.results.find((f) => f.id === objectId);
        if (obj && obj.member_profile_favorite) {
          return t('remove-from-employee-bank');
        }
        return t('add-to-employee-bank');
      },
      action: async (objectId: number) => {
        if (!organizationId) {
          return;
        }
        let error = null;
        const obj = data.results.find((f) => f.id === objectId);
        if (obj && obj.member_profile_favorite) {
          const result = await dispatch(
            deleteMemberProfileFavorite(obj.member_profile_favorite),
          );
          // @ts-ignore
          error = result.error;
        } else {
          const result = await dispatch(
            createMemberProfileFavorite({
              member_profile: objectId,
              organization: organizationId,
            }),
          );
          // @ts-ignore
          error = result.error;
        }
        if (error) {
          const errorObj: {
            [key: string]: { [key: string]: any };
          } = JSON.parse(error.message);
          const errors: Array<string> = [];
          Object.keys(errorObj).map((k: string) => {
            errors.push(`${t(k)}`);
          });
          // eslint-disable-next-line no-undef
          toast.error(
            <>
              <strong>{t('fill-missing-fields')}:</strong>
              {errors.map((e: string) => (
                <p key={e}>
                  <strong>{e.split(' - ')[0]}</strong>
                </p>
              ))}
            </>,
          );
        } else {
          if (obj && obj.member_profile_favorite) {
            toast.success(t('removed-from-employee-bank'));
            dispatch(
              fetchPaginatedEmployees({
                page: 1,
                filters: {
                  professional_titles: filterTitles,
                  professional_title_groups: filterGroups,
                  locations: filterLocations,
                  favorites: loc.pathname.indexOf('/pankki') !== -1,
                  organization: organizationId || undefined,
                  full_name: filterName,
                  only_job_seekers: true,
                },
              }),
            );
            return;
          }
          toast.success(t('added-to-employee-bank'));
          dispatch(
            fetchPaginatedEmployees({
              page: 1,
              filters: {
                professional_titles: filterTitles,
                professional_title_groups: filterGroups,
                locations: filterLocations,
                favorites: loc.pathname.indexOf('/pankki') !== -1,
                organization: organizationId || undefined,
                full_name: filterName,
                only_job_seekers: true,
              },
            }),
          );
        }
      },
    },
  ];
  const renderJobTitle = (memberProfile: FetchPaginatedEmployeesResponse) => {
    const acceptedMemberContact = memberProfile.member_contacts.find(
      (m) => m.status === 'accepted',
    );
    if (acceptedMemberContact && acceptedMemberContact.user_details) {
      const { first_name, last_name } = acceptedMemberContact.user_details;
      return `${first_name} ${last_name}`;
    }
    return `${t('profile')}#${memberProfile.id}`;
  };
  const renderConversationStatus = (
    memberProfile: FetchPaginatedEmployeesResponse,
  ) => {
    const acceptedMemberContact = memberProfile.member_contacts.find(
      (m) => m.status === 'accepted',
    );

    if (acceptedMemberContact) {
      return t('accepted');
    }
    const pending = memberProfile.member_contacts.find(
      (m) => m.status === 'pending',
    );
    if (pending) {
      return t('waiting-for-approve');
    }
    const rejected = memberProfile.member_contacts.find(
      (m) => m.status === 'rejected',
    );
    if (rejected) {
      return t('rejected');
    }
    return null;
  };
  const memberProfiles = data.results.map(
    (memberProfile: FetchPaginatedEmployeesResponse) => {
      return (
        <JobmemberProfile key={memberProfile.id}>
          <JobTitle>{renderJobTitle(memberProfile)}</JobTitle>
          <DataContainer>
            {memberProfile.location_count < 2 ? (
              memberProfile.locations.map((location: MemberProfileLocation) => (
                <SelectedData key={location.id}>{location.name}</SelectedData>
              ))
            ) : (
              <TooltipBackground>
                <SelectedData className="tooltip">
                  {t('multiple')}({memberProfile.location_count})
                  <span className="tooltiptext">
                    {memberProfile.locations.map(
                      (location: MemberProfileLocation) => (
                        <TooltipObject key={location.id}>
                          {location.name}
                        </TooltipObject>
                      ),
                    )}
                  </span>
                </SelectedData>
              </TooltipBackground>
            )}
          </DataContainer>
          <DataContainer>
            {memberProfile.professional_title_group_count < 2 ? (
              <>
                {memberProfile.professional_titles[0] ? (
                  <SelectedData>
                    {
                      memberProfile.professional_titles[0]
                        .professional_title_group.title
                    }
                  </SelectedData>
                ) : null}
              </>
            ) : (
              <TooltipBackground>
                <SelectedData className="tooltip">
                  {t('multiple')}({memberProfile.professional_title_group_count}
                  )
                  <span className="tooltiptext">
                    {memberProfile.professional_titles.map(
                      (professionalTitle: MemberProfileProfessionalTitle) => (
                        <TooltipObject key={professionalTitle.id}>
                          {professionalTitle.professional_title_group.title}
                        </TooltipObject>
                      ),
                    )}
                  </span>
                </SelectedData>
              </TooltipBackground>
            )}
          </DataContainer>
          <DataContainer>
            {memberProfile.professional_title_count < 2 ? (
              memberProfile.professional_titles.map(
                (professionalTitle: MemberProfileProfessionalTitle) => (
                  <SelectedData key={professionalTitle.id}>
                    {professionalTitle.title}
                  </SelectedData>
                ),
              )
            ) : (
              <TooltipBackground>
                <SelectedData className="tooltip">
                  {t('multiple')}({memberProfile.professional_title_count})
                  <span className="tooltiptext">
                    {memberProfile.professional_titles.map(
                      (professionalTitle: MemberProfileProfessionalTitle) => (
                        <TooltipObject key={professionalTitle.id}>
                          {professionalTitle.title}
                        </TooltipObject>
                      ),
                    )}
                  </span>
                </SelectedData>
              </TooltipBackground>
            )}
          </DataContainer>
          <DataContainer>
            {memberProfile.driving_license_count < 2 ? (
              memberProfile.driving_licenses.map(
                (drivingLicense: DrivingLicenseType) => (
                  <SelectedData key={drivingLicense.id}>
                    {drivingLicense.license_type}
                  </SelectedData>
                ),
              )
            ) : (
              <TooltipBackground>
                <SelectedData className="tooltip">
                  {t('multiple')}({memberProfile.driving_license_count})
                  <span className="tooltiptext">
                    {memberProfile.driving_licenses.map(
                      (drivingLicense: DrivingLicenseType) => (
                        <TooltipObject key={drivingLicense.id}>
                          {drivingLicense.license_type}
                        </TooltipObject>
                      ),
                    )}
                  </span>
                </SelectedData>
              </TooltipBackground>
            )}
          </DataContainer>

          <HeadermemberProfile>
            {memberProfile.recommender_count} {t('pcs')}
          </HeadermemberProfile>
          <HeadermemberProfile>
            {renderConversationStatus(memberProfile)}
          </HeadermemberProfile>
          <Options>
            <CustomLink onClick={() => openUserModal(memberProfile)}>
              {memberProfile.member_contacts.some(
                (c) => c.user_details !== null,
              )
                ? t('show-profile')
                : t('show-anonymous-profile')}
              <AngleRightIcon icon={['far', 'angle-right']} />
            </CustomLink>
            <DropdownMenu
              options={profileOptions}
              objectId={memberProfile.id}
            />
          </Options>
        </JobmemberProfile>
      );
    },
  );
  return (
    <>
      {showModal ? (
        <EmployeeDetailsModal
          modalToggle={setShowModal}
          selectedUser={selectedUser}
        />
      ) : null}
      {recipientId ? (
        <ConversationRequestModal
          show={showConversationAddModal}
          onClose={() => {
            setShowConversationAddModal(false);
          }}
          organizationId={results ? results[itemIndex].id : null}
          recipientId={recipientId}
        />
      ) : null}

      <MemberProfilesBackground>
        <MemberProfilesTopComponent>
          <MemberProfilesTop>
            <MemberProfilesTitle>{t('search-results')}:</MemberProfilesTitle>
          </MemberProfilesTop>
        </MemberProfilesTopComponent>
        <MemberProfilesBottomComponent>
          <MemberProfilesContainer>
            <MemberProfilesHeader>
              <HeadermemberProfile>{t('profile')}</HeadermemberProfile>
              <HeadermemberProfile>{t('working-area')}</HeadermemberProfile>
              <HeadermemberProfile>{t('field')}</HeadermemberProfile>
              <HeadermemberProfile>
                {t('professional-titles')}
              </HeadermemberProfile>
              <HeadermemberProfile>{t('driving-license')}</HeadermemberProfile>
              <HeadermemberProfile>{t('recommenders')}</HeadermemberProfile>
              <HeadermemberProfile>
                {t('conversation-request')}
              </HeadermemberProfile>
              <HeaderOptionsBlock />
            </MemberProfilesHeader>
            <MemberProfilesContent>{memberProfiles}</MemberProfilesContent>
          </MemberProfilesContainer>
          <MemberSearchPagination />
        </MemberProfilesBottomComponent>
      </MemberProfilesBackground>
    </>
  );
};
export default EmployeeList;
