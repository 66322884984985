import React from 'react';
import styled from 'styled-components';
import { setSelectedImages, selectedImageIds } from 'Util/trainingUtils';
import { RootState } from 'Store';
import { useSelector } from 'react-redux';
import { ImageType } from 'Features/ImageBank/imageBankSlice';

const PhotoObjectContainer = styled.div`
  margin: 5px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  &:hover {
    cursor: pointer;
  }
  @media screen and ${(props) => props.theme.breakpoints.md} {
    margin: 25px;
  }
`;
const PhotoBackground = styled.div`
  background: #e7eaef;
  height: 86px;
  width: 120px;
  padding: 5px;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    height: 123px;
    width: 170px;
    padding: 10px;
  }
`;
const ActiveBackground = styled(PhotoBackground)`
  background: #a6ceff;
`;
const Photo = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const PhotoObject = ({ photoDetails }: { photoDetails: ImageType }) => {
  const { values } = useSelector((state: RootState) => state.training);

  return (
    <PhotoObjectContainer
      onClick={() => setSelectedImages(values.images, photoDetails)}
    >
      {selectedImageIds(values.images) &&
      selectedImageIds(values.images)?.includes(photoDetails.id) ? (
        <ActiveBackground>
          <Photo style={{ backgroundImage: `url(${photoDetails.url})` }} />
        </ActiveBackground>
      ) : (
        <PhotoBackground>
          <Photo style={{ backgroundImage: `url(${photoDetails.url})` }} />
        </PhotoBackground>
      )}
    </PhotoObjectContainer>
  );
};
export default PhotoObject;
