import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'Store';

import styled from 'styled-components/macro';
import { openLoginModal as setLoginModalOpen } from './authSlice';

interface ModalContainerProps {
  modalOpen: boolean;
}

const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: ${(props: ModalContainerProps) => (props.modalOpen ? '0' : '-100px')};
  background: #33333366;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${(props: ModalContainerProps) =>
    props.modalOpen ? 'visible' : 'hidden'};
  > div {
    opacity: ${(props: ModalContainerProps) => (props.modalOpen ? '1' : '0')};
    pointer-events: ${(props: ModalContainerProps) =>
      props.modalOpen ? 'all' : 'none'};
    transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
`;
const ModalContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  display: flex;
  padding: 20px;
  background: #fff;
  flex-direction: column;
  margin-bottom: 50px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 6px;
  h3 {
    color: #313131;
    font-family: Barlow;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 38px;
    margin: 0;
    margin-bottom: 20px;
    font-weight: 200;
  }
  input {
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 12px;
    color: #000000;
    font-family: Barlow;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 10px;
  }
`;
const CloseButton = styled.button`
  border: none;
  background: #d60c17;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 25px;
  top: 25px;
  color: #fff;
  border-radius: 100%;
  font-size: 16px;
  height: 30px;
  width: 30px;
  line-height: 0;
  outline: none;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;
const LoginFrom = styled.div`
  display: flex;
  flex-direction: column;
`;
const LoginButton = styled.button`
  height: 37px;
  width: 100%;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: Barlow;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  background-color: #d60c17;
  color: #ffffff;
  border: none;
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

const LoginModal = ({
  closeModal,
  modalOpen,
}: {
  closeModal: Function;
  modalOpen: boolean;
}) => {
  const { t } = useTranslation();
  const { openLoginModal } = useSelector((state: RootState) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <ModalContainer modalOpen={modalOpen || openLoginModal}>
      <ModalContent>
        <CloseButton
          onClick={() => {
            closeModal();
            dispatch(setLoginModalOpen(false));
          }}
        >
          &times;
        </CloseButton>
        <h3>Kirjautuminen</h3>
        <LoginFrom>
          <LoginButton
            onClick={() => {
              window.location.href = 'https://easiointi.yap.fi/rakennusliitto';
            }}
          >
            {t('job-seeker-login')}
          </LoginButton>
          <LoginButton onClick={() => history.push('/admin-panel')}>
            {t('employer-login')}
          </LoginButton>
          <LoginButton onClick={() => history.push('/education-panel')}>
            {t('training-provider-login')}
          </LoginButton>
          <LoginButton onClick={() => history.push('/admin-panel')}>
            {t('household-job-employment')}
          </LoginButton>
        </LoginFrom>
      </ModalContent>
    </ModalContainer>
  );
};

export default LoginModal;
