/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import {
  AnyAction,
  AsyncThunk,
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { getAxiosInstance } from 'Api';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

interface UserState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  userData: Array<UserType>;
  currentPage: number;
  selectedFilters: UsersFilters;
  allUserData: Array<UserType>;
  singleUserData: UserType | undefined;
  userCount: number;
  paginatedUserData: Array<UserType>;
}
export type UserType = {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  username: string;
  is_admin: boolean;
  organizations: Array<number>;
  email: string;
};
export type UserPatchType = {
  id: number;
  first_name?: string;
  last_name?: string;
  phone?: string;
  username?: string;
  is_admin?: boolean;
  organizations?: Array<number>;
  email?: string;
};
type UsersFilters = {
  organization_id?: number;
  is_admin?: boolean;
  orderBy: string;
};
type FetchPaginatedUsersRequest = {
  page: number;
  filters: UsersFilters;
};
const initialState: UserState = {
  loading: 'idle',
  userData: [],
  currentPage: 1,
  allUserData: [],
  userCount: 0,
  singleUserData: undefined,
  paginatedUserData: [],
  selectedFilters: { orderBy: '' },
};
const isPendingAction = (action: AnyAction): action is PendingAction => {
  return action.type.endsWith('/pending');
};
const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return action.type.endsWith('/rejected');
};
const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return action.type.endsWith('/fulfilled');
};
const fetchPaginatedUsers = createAsyncThunk(
  'user/paginate_search',
  async (body: FetchPaginatedUsersRequest) => {
    if (body.filters) {
      const { filters } = body;
      const { page } = body;
      const organizationId = filters.organization_id
        ? `&organization_id=${filters.organization_id}`
        : '';
      const isAdmin = filters.is_admin ? `&is_admin=${filters.is_admin}` : '';
      const order =
        filters && filters.orderBy ? `&order_by=${filters.orderBy}` : '';

      const response = await getAxiosInstance().get(
        `/user/?page=${page}${organizationId}${isAdmin}${order}`,
      );
      return { data: response.data, page };
    }
    const { page } = body;
    const response = await getAxiosInstance().get(`/users/?page=${page}`);
    return { data: response.data, page };
  },
);

const createOrganizationUser = createAsyncThunk(
  'user/create',
  async ({ body }: { body: any }) => {
    try {
      const response = await getAxiosInstance().post(`/user/`, body);
      return response.data;
    } catch (err) {
      // @ts-ignore
      throw Error(JSON.stringify(err.response.data));
    }
  },
);

const deleteOrganizationUser = createAsyncThunk(
  'user/destroyOrganizationUser',
  async (id: number) => {
    const response = await getAxiosInstance().delete(`/user/${id}/`);
    return response.data;
  },
);

export const resetAction = createAction('reset-tracked-loading-state');
const fetchSingleUser = createAsyncThunk<UserType, number>(
  'user/fetchSingleUser',
  async (id: number) => {
    const response = await getAxiosInstance().get(`/user/${id}/`);
    return response.data;
  },
);
const patchSingleUser = createAsyncThunk(
  'user/patchSingleUser',
  async (userData: UserPatchType) => {
    try {
      const response = await getAxiosInstance().patch(
        `/user/${userData.id}/`,
        userData,
      );
      return response.data;
    } catch (err) {
      // @ts-ignore
      throw Error(JSON.stringify(err.response.data));
    }
  },
);

const fetchUsers = createAsyncThunk('user/fetch', async (id: number) => {
  const response = await getAxiosInstance().get(`/user/?organization_id=${id}`);
  return response.data;
});
const fetchAllUsers = createAsyncThunk('user/fetchAll', async () => {
  const response = await getAxiosInstance().get(`/user/`);
  return response.data;
});
const manageUserReducer = createSlice({
  name: 'manageUsers',
  initialState,
  reducers: {
    changePage(state, action) {
      return {
        ...state,
        currentPage: action.payload,
      };
    },
    changeSortedBy(state, action) {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          orderBy: action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetAction, () => initialState)
      .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<any>) => {
        state.userData = action.payload.results;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action: PayloadAction<any>) => {
        state.allUserData = action.payload.results;
      })
      .addCase(fetchSingleUser.fulfilled, (state, action) => {
        state.singleUserData = action.payload;
      })
      .addCase(
        fetchPaginatedUsers.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.userCount = action.payload.data.count;
          state.paginatedUserData = action.payload.data.results;
        },
      )
      .addCase(deleteOrganizationUser.fulfilled, (state, action: AnyAction) => {
        state.userData = state.userData.filter(
          (user) => user.id !== action.meta.arg,
        );
      })
      .addMatcher(isPendingAction, (state) => {
        state.loading = 'pending';
      })
      .addMatcher(isRejectedAction, (state) => {
        state.loading = 'rejected';
      })
      .addMatcher(isFulfilledAction, (state) => {
        state.loading = 'fulfilled';
      });
  },
});
export default manageUserReducer.reducer;
export {
  createOrganizationUser,
  fetchUsers,
  deleteOrganizationUser,
  fetchAllUsers,
  fetchPaginatedUsers,
  fetchSingleUser,
  patchSingleUser,
};
