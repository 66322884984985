import React from 'react';
import EducationLayout from 'Components/Layout/EducationLayout';
import styled from 'styled-components';
import Trainings from 'Components/EducationPage/EducationTrainings';
import { upcomingStatus } from 'Features/Training/trainingSlice';

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`;

const AdminTrainings = () => {
  return (
    <EducationLayout>
      <ContentContainer>
        <Trainings status={upcomingStatus} />
      </ContentContainer>
    </EducationLayout>
  );
};
export default AdminTrainings;
