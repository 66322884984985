/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components/macro';
import { LanguageSkills } from 'Features/MemberProfile/memberProfileSlice';
import { useTranslation } from 'react-i18next';
import ProfileDetails from './ModalComponents/ProfileDetails';
import ProfileFeatures from './ModalComponents/ProfileFeatures';
import InformationList from './ModalComponents/InformationList';
import WorkExperienceItem from './ModalComponents/WorkExperienceItem';
import ApplicantDetails from './ModalComponents/ApplicantDetails';
import EducationItem from './ModalComponents/EducationItem';
import RecommendersItem from './ModalComponents/RecommendersItem';

const InformationPanel = styled.div`
  margin-right: 108px;
  margin-left: 32px;
  margin-top: 5px;
  margin-bottom: 5px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    flex-direction: row;
  }
`;
type Locations = {
  id: number;
  name: string;
};
type ApplicantDetailsType = {
  id: number;
  competences: [];
  driving_licenses: [];
  additional_info: string;
  experiences: [];
  job_experiences: [];
  professional_titles: [];
  locations: Locations[];
  educations: [];
  recommenders: [];
  language_skills: [];
};
const ApplicationDetails = ({
  applicantDetails,
  applicantName,
  anonymous,
}: {
  applicantDetails: ApplicantDetailsType;
  applicantName: string;
  anonymous?: boolean;
}) => {
  const { t } = useTranslation();
  const languageProficiencies = [t('basics'), t('good')];
  const applicantCompetences = applicantDetails.competences.map(
    (competence: any) => competence.title,
  );
  const applicantLanguages = applicantDetails.language_skills.map(
    (language: LanguageSkills) => {
      return `${language.language.full_name} - ${
        languageProficiencies[language.level_of_competence]
      }`;
    },
  );
  const applicantDrivingLicenses = applicantDetails.driving_licenses.map(
    (drivingLicense: any) => drivingLicense.license_type,
  );
  const applicantProfessionalTitles = applicantDetails.professional_titles.map(
    (professionalTitle: any) => professionalTitle.title,
  );
  const applicantLocations = applicantDetails.locations.map(
    (location: any) => location.name,
  );
  return (
    <>
      <InformationPanel>
        <ProfileDetails
          applicantName={applicantName}
          applicantId={applicantDetails.id}
          anonymous={Boolean(anonymous)}
        />
      </InformationPanel>
      <InformationPanel>
        <ProfileFeatures title="working-areas" data={applicantLocations} />
      </InformationPanel>
      <InformationPanel>
        <ApplicantDetails additionalInfo={applicantDetails.additional_info} />
      </InformationPanel>
      <InformationPanel>
        <ProfileFeatures
          title="professional-titles"
          data={applicantProfessionalTitles}
        />
        <ProfileFeatures title="competences" data={applicantCompetences} />
        <ProfileFeatures
          title="driving-license-types"
          data={applicantDrivingLicenses}
        />
        <ProfileFeatures
          title="language-competence"
          simpleList
          data={applicantLanguages}
        />
      </InformationPanel>
      <InformationList listTitle="work-experience" />
      <WorkExperienceItem experiences={applicantDetails.job_experiences} />
      <InformationList listTitle="education" />
      <EducationItem educations={applicantDetails.educations} />
      <InformationList listTitle="recommenders" />
      <RecommendersItem
        recommenders={applicantDetails.recommenders}
        anonymous={false}
      />
    </>
  );
};

ApplicationDetails.defaultProps = {
  anonymous: true,
};

export default ApplicationDetails;
