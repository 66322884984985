import React from 'react';
import styled from 'styled-components';
import EducationLayout from 'Components/Layout/EducationLayout';
import NewTraining from 'Components/EducationPage/NewTraining/NewTraining';

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`;

const AdminNewJob = () => {
  return (
    <EducationLayout>
      <ContentContainer>
        <NewTraining />
      </ContentContainer>
    </EducationLayout>
  );
};
export default AdminNewJob;
