import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  parse,
  getYear,
  differenceInMonths,
  differenceInWeeks,
} from 'date-fns';

const InformationPanel = styled.div`
  padding-top: 20px;
  margin-right: 108px;
  margin-left: 32px;
  flex-direction: column;
  display: flex;
  padding-bottom: 17px;
  border-bottom: 1px solid #d6dce2;
`;

const ExperienceElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ExperienceTitle = styled.div`
  color: #0a4ea2;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
`;
const ExperienceDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and ${(props) => props.theme.breakpoints.md} {
    flex-direction: row;
  }
`;
const ElementTitle = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 3px;
`;
const ElementDetail = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
`;
const ExpandDescription = styled.div`
  color: #0a4ea2;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  cursor: pointer;
  user-select: none;
`;
const ExperienceDescription = styled.div`
  margin: 15px;
  width: 100%;
  font-family: Barlow;
  font-size: 14px;
`;
const AngleDownIcon = styled(FontAwesomeIcon)`
  color: #0a4ea2;
  margin: 0px 0px 0px 10px;
`;

const WorkExperienceComponent = ({ experiences }: { experiences: any }) => {
  const { t } = useTranslation();
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const displayDates = (experience: any) => {
    const startDate = parse(experience.start_date, 'yyyy-MM-dd', new Date());
    const endDate = parse(experience.end_date, 'yyyy-MM-dd', new Date());
    const startYear = getYear(startDate);

    if (!experience.end_date) {
      return `${startYear} -`;
    }
    let workDuration = differenceInMonths(endDate, startDate);
    if (workDuration >= 12) {
      const workDurationInYears = workDuration / 12;
      const leftoverMonths =
        workDuration - Math.floor(workDurationInYears) * 12;
      return `${startYear} - ${Math.floor(workDurationInYears)} ${t('years')} ${
        leftoverMonths > 0 ? `${leftoverMonths} ${t('months')}` : ''
      }`;
    }
    if (workDuration < 1) {
      workDuration = differenceInWeeks(endDate, startDate);
      return `${startYear} - ${workDuration} ${t('weeks')} `;
    }
    return `${startYear} - ${workDuration} ${t('months')}`;
  };
  return (
    <>
      {experiences.map((experience: any) => (
        <InformationPanel key={experience}>
          <ExperienceDetails>
            <ExperienceElement>
              <ExperienceTitle>{experience.employer}</ExperienceTitle>
            </ExperienceElement>
            <ExperienceElement>
              <ElementTitle>Alkamisvuosi - kesto</ElementTitle>
              <ElementDetail>{displayDates(experience)}</ElementDetail>
            </ExperienceElement>
            <ExperienceElement>
              <ElementTitle>Aihe</ElementTitle>
              <ElementDetail>{experience.work_assignment}</ElementDetail>
            </ExperienceElement>
            <ExperienceElement>
              <ExpandDescription
                onClick={() => setDescriptionOpen(!descriptionOpen)}
              >
                Työtehtävän kuvaus
                {descriptionOpen ? (
                  <AngleDownIcon icon={['far', 'angle-up']} />
                ) : (
                  <AngleDownIcon icon={['far', 'angle-down']} />
                )}
              </ExpandDescription>
            </ExperienceElement>
          </ExperienceDetails>
          {descriptionOpen ? (
            <ExperienceDescription>
              {experience.description}
            </ExperienceDescription>
          ) : null}
        </InformationPanel>
      ))}
    </>
  );
};

export default WorkExperienceComponent;
