import React from 'react';
import ReactDOM from 'react-dom';
import 'i18n';
import './index.scss';
import App from 'App';
import { store } from 'Store';
import { Provider } from 'react-redux';

import { ThemeProvider } from 'styled-components';
import theme from 'Components/Layout/Theme';
import 'Features/Icon/init';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TokenUtil from 'Util/tokenManager';

import * as serviceWorker from './serviceWorker';

const params = new URLSearchParams(window.location.search);
const token = params.get('token');
if (token) {
  TokenUtil.set(token);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
