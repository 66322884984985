import React from 'react';
import { useSelector } from 'react-redux';
import MainLayout from 'Components/Layout/MainLayout';
import Search from 'Components/Search';
import { JobsAuthenticated, JobsUnauthenticated } from 'Components/Jobs';
import { isJobSeeker } from 'Util/permissions';
import { RootState } from 'Store';

const Jobs = () => {
  const {
    isAuthenticated,
    user: { groups },
  } = useSelector((state: RootState) => state.auth);
  return (
    <MainLayout>
      <Search type="job" />
      {isAuthenticated && groups && isJobSeeker(groups) ? (
        <JobsAuthenticated />
      ) : (
        <JobsUnauthenticated />
      )}
    </MainLayout>
  );
};

export default Jobs;
