import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

interface ImageProps {
  src: string;
}

const ImageContainer = styled.div`
  width: 100%;
  height: 202px;
  background: url(${(props: ImageProps) => props.src});
  background-size: cover;
  background-position: center center;
  margin-bottom: 20px;
`;

const JobImage = ({ images }: { images: Array<any> }) => {
  const [imageIndex, setImageIndex] = useState<number | null>(null);
  useEffect(() => {
    setImageIndex(Math.floor(Math.random() * images.length));
  }, []);
  if (imageIndex === null) {
    return null;
  }
  const currentImage = images[imageIndex];
  if (!currentImage) {
    return null;
  }
  return <ImageContainer src={currentImage.url} />;
};

export default JobImage;
