import React from 'react';
import { BorderlessSuperAdminLayout } from 'Components/Layout/SuperAdminLayout';
import styled from 'styled-components';
import AdminFrontpage from 'Components/SuperAdminPage/SuperAdminFrontpage';

const ContentContainer = styled.div`
  width: 100%;
  overflow: auto;
  flex: 1;
`;
const SuperAdminPanel = () => {
  return (
    <BorderlessSuperAdminLayout>
      <ContentContainer>
        <AdminFrontpage />
      </ContentContainer>
    </BorderlessSuperAdminLayout>
  );
};
export default SuperAdminPanel;
