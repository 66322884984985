import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import Logo from 'Assets/rakennusliitto-logo.png';
import LoginModal from 'Features/Auth/LoginModal';
import { Link, useHistory, withRouter } from 'react-router-dom';
import type { Location } from 'history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Desktop, Mobile, Tablet } from 'Components/Layout/MediaQuery';
import {
  fetchOrganizations,
  genericActions as organizationGeneric,
} from 'Features/Organization/organizationSlice';
import { useTranslation } from 'react-i18next';
import DropdownMenu, { DropdownProps } from 'Components/DropdownMenu';
import { genericActions, userLogout } from 'Features/Auth/authSlice';
import { RootState } from 'Store';
import { isAdmin, isJobSeeker, isOrganizationAdmin } from 'Util/permissions';

interface StyledLinkProps {
  isActive: boolean;
}
interface MobileMenuProps {
  open: boolean;
  height: Number;
}

const NavbarContainer = styled.div`
  max-height: 60px;
  min-height: 60px;
  width: 100%;
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 999;
`;
const NavbarContent = styled.div`
  max-height: 60px;
  min-height: 60px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 2px 0 rgba(42, 46, 59, 0.2);
  img {
    height: 30px;
    width: 30px;
  }
  svg.hamburger {
    position: absolute;
    right: 15px;
    top: calc(50% - 10px);
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
`;
const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 60px;
  margin: -10px;
  border-right: 1px solid #e7eaef;
  background-color: #d60c17;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 196px;
  }
  img {
    margin-right: 10px;
  }
`;

const MobileMenuItems = styled.div`
  background: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: ${(props: MobileMenuProps) =>
    props.open ? `${props.height}px` : '0px'};
  display: flex;
  flex-direction: column;
  transition: max-height 0.3s ease-in-out;
  overflow-y: hidden;
  a {
    font-family: Barlow;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    margin-right: 50px;
    text-decoration: none;
    padding: 10px 20px;
    &.outside-link {
      color: #252525;
    }
  }
`;
const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  &:last-of-type {
    padding-bottom: 10px;
  }
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    &:last-of-type {
      padding-bottom: 10px;
    }
  }
  svg {
    margin: 0 10px 0 10px;
  }
`;
const IconArea = styled.div`
  width: 20px;
  text-align: center;
`;

const LoginLink = styled.div`
  color: #5c5f63;
  font-family: Barlow;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  cursor: ${(props: { cursor: string }) => props.cursor};
`;
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
const StyledLink = styled(({ isActive, ...rest }) => <Link {...rest} />)`
  display: flex;
  ${(props: StyledLinkProps) =>
    props.isActive
      ? `
      font-weight: bold;
      color: #D60C17;
  `
      : 'color: #252525;'};
`;
const LogoText = styled.div`
  color: #ffffff;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  &.bottom {
    font-weight: bold;
    font-size: 11px;
    letter-spacing: 1.63px;
    line-height: 20px;
  }
`;
const LogoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionsTab = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SubMenuItems = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  a {
    font-family: Barlow;
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
    padding: 8px 0px 8px 0px;
    &.outside-link {
      color: white;
    }
  }
`;
const SuperAdminNavbar = ({ location }: { location: Location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOrganizations());
  }, [dispatch]);
  const { user, isAuthenticated } = useSelector(
    (state: RootState) => state.auth,
  );
  const [mobileHeight, setMobileHeight] = useState<Number>(0);
  const [loginOpen, setLoginOpen] = useState<boolean>(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const history = useHistory();
  const userOptions: Array<DropdownProps> = [];
  if (user.groups && isJobSeeker(user.groups)) {
    userOptions.push({
      icon: 'user-circle',
      title: t('user-profile'),
      action: () => {
        history.push('/profile');
      },
    });
  }
  if (user.groups && isAdmin(user.groups)) {
    userOptions.push({
      icon: 'briefcase',
      title: t('super-admin-panel'),
      action: () => {
        history.push('/super-admin-panel');
      },
    });
  }
  if (user.groups && isOrganizationAdmin(user.groups) && user.is_employer) {
    userOptions.push({
      icon: 'briefcase',
      title: t('admin-panel'),
      action: () => {
        history.push('/admin-panel');
      },
    });
  }
  if (user.groups && isOrganizationAdmin(user.groups) && user.is_trainer) {
    userOptions.push({
      icon: 'briefcase',
      title: t('education-panel'),
      action: () => {
        history.push('/education-panel');
      },
    });
  }
  userOptions.push({
    icon: 'sign-out',
    title: t('log-out'),
    action: () => {
      dispatch(userLogout());
      dispatch(genericActions.ResetAction());
      dispatch(organizationGeneric.ResetAction());
    },
  });
  const renderMobileNav = () => (
    <>
      <MobileMenuItems
        ref={(el: HTMLDivElement) => {
          if (!el) {
            return;
          }

          if (mobileHeight !== el.scrollHeight) {
            setMobileHeight(el.scrollHeight);
          }
        }}
        open={isMobileMenuOpen}
        height={mobileHeight}
      >
        <StyledLink
          isActive={location.pathname === '/super-admin-panel'}
          to="/super-admin-panel"
        >
          <IconArea>
            <FontAwesomeIcon icon={['far', 'home']} />
          </IconArea>
          {t('front-page')}
        </StyledLink>
        <StyledLink
          isActive={false}
          to="/super-admin-panel/ilmoitukset/avoimet"
        >
          <IconArea>
            <FontAwesomeIcon icon={['far', 'file-alt']} />
          </IconArea>
          {t('ad-management')}
        </StyledLink>
        <SubMenuItems>
          <StyledLink
            isActive={
              location.pathname === '/super-admin-panel/ilmoitukset/avoimet'
            }
            to="/super-admin-panel/ilmoitukset/avoimet"
          >
            {t('open-subtitle')}
          </StyledLink>
          <StyledLink
            isActive={
              location.pathname === '/super-admin-panel/ilmoitukset/historia'
            }
            to="/super-admin-panel/ilmoitukset/historia"
          >
            {t('history')}
          </StyledLink>
        </SubMenuItems>
        <StyledLink isActive={false} to="/super-admin-panel/trainings">
          <IconArea>
            <FontAwesomeIcon icon={['far', 'file-alt']} />
          </IconArea>
          {t('education-management')}
        </StyledLink>
        <SubMenuItems>
          <StyledLink
            isActive={location.pathname === '/super-admin-panel/trainings'}
            to="/super-admin-panel/trainings"
          >
            {t('upcoming-educations')}
          </StyledLink>
          <StyledLink
            isActive={
              location.pathname === '/super-admin-panel/trainings/nykyiset'
            }
            to="/super-admin-panel/trainings/nykyiset"
          >
            {t('ongoing-trainings')}
          </StyledLink>
          <StyledLink
            isActive={
              location.pathname === '/super-admin-panel/trainings/menneet'
            }
            to="/super-admin-panel/trainings/menneet"
          >
            {t('past-trainings')}
          </StyledLink>
        </SubMenuItems>
        <StyledLink
          isActive={location.pathname === '/super-admin-panel/organisaatiot'}
          to="/super-admin-panel/organisaatiot"
        >
          <IconArea>
            <FontAwesomeIcon icon={['far', 'building']} />
          </IconArea>
          {t('organization-management')}
        </StyledLink>
        <StyledLink
          isActive={location.pathname === '/super-admin-panel/jasenet'}
          to="/super-admin-panel/jasenet"
        >
          <IconArea>
            <FontAwesomeIcon icon={['far', 'hard-hat']} />
          </IconArea>
          {t('member-management')}
        </StyledLink>
        <StyledLink
          isActive={location.pathname === '/super-admin-panel/viestit'}
          to="/super-admin-panel/viestit"
        >
          <IconArea>
            <FontAwesomeIcon icon={['far', 'envelope']} />
          </IconArea>
          {t('messages')}
        </StyledLink>
        <StyledLink isActive={false} to="/super-admin-panel/statistics">
          <IconArea>
            <FontAwesomeIcon icon={['far', 'chart-bar']} />
          </IconArea>
          {t('statistics')}
        </StyledLink>
        <StyledLink
          isActive={false}
          to="/super-admin-panel/content-management/duunimylly"
        >
          <IconArea>
            <FontAwesomeIcon icon={['far', 'chart-bar']} />
          </IconArea>
          {t('content-management')}
        </StyledLink>
        <SubMenuItems>
          <StyledLink
            isActive={
              location.pathname ===
              '/super-admin-panel/content-management/duunimylly'
            }
            to="/super-admin-panel/content-management/duunimylly"
          >
            {t('job-mill')}
          </StyledLink>
          <StyledLink
            isActive={
              location.pathname ===
              '/super-admin-panel/content-management/palkkamylly'
            }
            to="/super-admin-panel/content-management/palkkamylly"
          >
            {t('salary-mill')}
          </StyledLink>
          <StyledLink
            isActive={
              location.pathname ===
              '/super-admin-panel/content-management/taitomylly'
            }
            to="/super-admin-panel/content-management/taitomylly"
          >
            {t('skill-mill')}
          </StyledLink>
          <StyledLink
            isActive={
              location.pathname ===
              '/super-admin-panel/content-management/suuntamylly'
            }
            to="/super-admin-panel/content-management/suuntamylly"
          >
            {t('direction-mill')}
          </StyledLink>
          <StyledLink
            isActive={
              location.pathname === '/super-admin-panel/document-management'
            }
            to="/super-admin-panel/document-management"
          >
            {t('document-management')}
          </StyledLink>
        </SubMenuItems>
        <StyledLink isActive={false} to="/super-admin-panel/asetukset/hallinta">
          <IconArea>
            <FontAwesomeIcon icon={['far', 'hammer']} />
          </IconArea>
          {t('settings')}
        </StyledLink>
        <SubMenuItems>
          <StyledLink
            isActive={
              location.pathname === '/super-admin-panel/asetukset/hallinta'
            }
            to="/super-admin-panel/asetukset/hallinta"
          >
            {t('user-management')}
          </StyledLink>
        </SubMenuItems>
        <ActionsContainer>
          {isAuthenticated ? (
            <>
              <LoginLink cursor="default">
                {t('hey')}, {user.first_name}
              </LoginLink>
              <DropdownMenu
                options={userOptions}
                menuPosition="leftTop"
                objectId={0}
              />
            </>
          ) : (
            <LoginLink cursor="pointer" onClick={() => setLoginOpen(true)}>
              {t('login')}
            </LoginLink>
          )}
        </ActionsContainer>
      </MobileMenuItems>
      <FontAwesomeIcon
        onClick={() => setMobileMenuOpen((state) => !state)}
        icon={['far', 'bars']}
        className="hamburger"
      />
    </>
  );
  return (
    <NavbarContainer>
      <NavbarContent>
        <Desktop>
          <LogoContainer>
            <img src={Logo} alt="Rakennusliitto logo" />
            <LogoTextContainer>
              <LogoText>{t('super-admins')}</LogoText>
              <LogoText className="bottom">{t('control-panel')}</LogoText>
            </LogoTextContainer>
          </LogoContainer>
          <ActionsTab>
            <ActionsContainer>
              {isAuthenticated ? (
                <>
                  <LoginLink cursor="default">Hei, {user.first_name}</LoginLink>
                  <DropdownMenu
                    options={userOptions}
                    menuPosition="leftBottom"
                    objectId={0}
                  />
                </>
              ) : (
                <LoginLink cursor="pointer" onClick={() => setLoginOpen(true)}>
                  Kirjaudu sisään
                </LoginLink>
              )}
            </ActionsContainer>
          </ActionsTab>
        </Desktop>
        <Tablet>{renderMobileNav()}</Tablet>
        <Mobile>{renderMobileNav()}</Mobile>
      </NavbarContent>
      <LoginModal
        modalOpen={loginOpen}
        closeModal={() => setLoginOpen(false)}
      />
    </NavbarContainer>
  );
};

export default withRouter(SuperAdminNavbar);
