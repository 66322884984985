import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import TokenUtil from 'Util/tokenManager';

const AXIOS_TIMEOUT = 60 * 1000; // 1 minute

const axiosOptions: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: AXIOS_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const getAxiosInstance = (): AxiosInstance => {
  if (!TokenUtil.isEmpty() && axiosOptions.headers) {
    axiosOptions.headers.Authorization = `Token ${TokenUtil.get()}`;
  } else if (axiosOptions.headers) {
    delete axiosOptions.headers.Authorization;
  }
  const instance = axios.create(axiosOptions);

  return instance;
};

// eslint-disable-next-line import/prefer-default-export
export { getAxiosInstance };
