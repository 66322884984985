import React from 'react';
import IframeLayout from 'Components/Layout/IframeLayout';
import MemberPalkkaus from 'Components/Palkkaus';

const UserProfile = () => {
  return (
    <IframeLayout>
      <MemberPalkkaus />
    </IframeLayout>
  );
};

export default UserProfile;
