import {
  createSlice,
  AnyAction,
  AsyncThunk,
  createAction,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';
import { getAxiosInstance } from 'Api';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

/* eslint-disable camelcase */
export interface JobGuardFilters {
  free_text_search: string;
  professional_titles: Array<number>;
  employment_types: Array<string>;
  service_providers: Array<number>;
}

interface JobGuardState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  results: Array<any>;
  createdSearch: any;
  createdGuard: any;
}
/* eslint-enable camelcase */

const initialState: JobGuardState = {
  loading: 'idle',
  results: [],
  createdSearch: {},
  createdGuard: {},
};

const fetchJobGuards = createAsyncThunk('jobGuard/fetch', async () => {
  const response = await getAxiosInstance().get(`/job_guard/`);
  return { data: response.data };
});

const createSavedSearch = createAsyncThunk(
  'jobGuard/createSavedSearch',
  async (body: { [key: string]: any }) => {
    try {
      const response = await getAxiosInstance().post(`/saved_search/`, body);
      return { data: response.data };
    } catch (err) {
      // @ts-ignore
      throw Error(JSON.stringify(err.response.data));
    }
  },
);

const createJobGuard = createAsyncThunk(
  'jobGuard/createJobGuard',
  async (body: any) => {
    try {
      const response = await getAxiosInstance().post(`/job_guard/`, body);
      return { data: response.data };
    } catch (err) {
      // @ts-ignore
      throw Error(JSON.stringify(err.response.data));
    }
  },
);

const updateSavedSearch = createAsyncThunk(
  'jobGuard/updateSavedSearch',
  async (body: any) => {
    try {
      const response = await getAxiosInstance().patch(
        `/saved_search/${body.id}/`,
        body,
      );
      return { data: response.data };
    } catch (err) {
      // @ts-ignore
      throw Error(JSON.stringify(err.response.data));
    }
  },
);

const deleteSavedSearch = createAsyncThunk(
  'jobGuard/deleteSavedSearch',
  async (id: number) => {
    try {
      await getAxiosInstance().delete(`/saved_search/${id}/`);
      return;
    } catch (err) {
      // @ts-ignore
      throw Error(JSON.stringify(err.response.data));
    }
  },
);

const isPendingAction = (action: AnyAction): action is PendingAction => {
  return action.type.endsWith('/pending');
};
const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return action.type.endsWith('/rejected');
};
const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return action.type.endsWith('/fulfilled');
};

export const resetAction = createAction('reset-tracked-loading-state');

/* eslint-disable no-param-reassign */
const jobGuardSlice = createSlice({
  name: 'jobGuard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetAction, () => initialState)
      .addCase(
        fetchJobGuards.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.results = action.payload.data.results;
        },
      )
      .addCase(
        createSavedSearch.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.createdSearch = action.payload.data;
        },
      )
      .addCase(
        createJobGuard.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.createdGuard = action.payload.data;
        },
      )
      .addMatcher(isPendingAction, (state) => {
        state.loading = 'pending';
      })
      .addMatcher(isRejectedAction, (state) => {
        state.loading = 'rejected';
      })
      .addMatcher(isFulfilledAction, (state) => {
        state.loading = 'fulfilled';
      });
  },
});
/* eslint-enable no-param-reassign */

export default jobGuardSlice.reducer;
export {
  fetchJobGuards,
  createSavedSearch,
  createJobGuard,
  updateSavedSearch,
  deleteSavedSearch,
};
