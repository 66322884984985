import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ApplicantDetails = styled.div`
  border-left: 2px solid #d60c17;
`;
const ApplicantDetailsContainer = styled.div`
  margin: 20px;
`;
const DetailsTitle = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
`;

const ElementDetail = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 5px;
`;
const ApplicantDetailsComponent = ({
  additionalInfo,
}: {
  additionalInfo: string;
}) => {
  const { t } = useTranslation();
  return (
    <ApplicantDetails>
      <ApplicantDetailsContainer>
        <DetailsTitle>{t('anonymous-profile-additional-info')}</DetailsTitle>
        <ElementDetail>{additionalInfo}</ElementDetail>
      </ApplicantDetailsContainer>
    </ApplicantDetails>
  );
};

export default ApplicantDetailsComponent;
