import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'Store';
import styled from 'styled-components/macro';
import {
  LineChart,
  Line,
  ResponsiveContainer,
  YAxis,
  XAxis,
  Tooltip,
  CartesianGrid,
} from 'recharts';
import {
  fetchGraphDetails,
  fetchStatistics,
} from 'Features/Statistics/statisticsSlice';
import { format, parse, subMonths, formatISO } from 'date-fns';
import { fi } from 'date-fns/locale';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { getAxiosInstance } from 'Api';

registerLocale('fi', fi);

const StatisticsContainer = styled.div`
  h3 {
    color: #ffffff;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    margin: 0;
    margin-bottom: 2rem;
  }
  .summary {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #fff;
    .summary-object {
      padding-right: 3rem;
      p {
        color: #ffffff;
        font-family: Barlow;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 17px;
        margin: 0;
        &:last-of-type {
          font-size: 20px;
          font-weight: 300;
          line-height: 24px;
          margin-bottom: 1rem;
        }
      }
    }
    button {
      height: 37px;
      border-radius: 6px;
      background-color: #d60c17;
      border: none;
      outline: none;
      color: #ffffff;
      font-family: Barlow;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      margin-bottom: 12px;
      cursor: pointer;
    }
  }
  .graph {
    height: calc(
      100vh - 5rem -
        ${({ graphOffset }: { graphOffset: number }) => `${graphOffset}px`}
    );
    h3 {
      margin-top: 2rem;
      margin-bottom: 0;
    }
    .date-pickers {
      display: flex;
      margin: 1rem 0;
      align-items: center;
      color: #fff;
      span {
        margin: 0 0.5rem;
      }
      input {
        padding: 0.25rem 0.5rem;
        border-radius: 6px;
        border: none;
      }
    }
  }
`;

const SuperAdminStatistics = () => {
  const { t } = useTranslation();
  const [graphOffset, setGraphOffset] = useState<number>(0);
  const [selectedRange, setSelectedRange] = useState<{
    start: Date | null;
    end: Date | null;
  }>({ start: subMonths(new Date(), 6), end: new Date() });
  const dispatch = useDispatch();
  const { statistics, graphDetails } = useSelector(
    (state: RootState) => state.statistics,
  );
  const activeAdsKey = t('active-ads');
  const monthData = Object.keys(graphDetails).map((key) => ({
    name: format(parse(key, 'yyyy-MM', new Date()), 'yyyy MMM', {
      locale: fi,
    }),
    [activeAdsKey]: graphDetails[key],
  }));
  useEffect(() => {
    if (selectedRange.start === null || selectedRange.end === null) {
      return;
    }
    dispatch(
      fetchGraphDetails({
        start_date: format(selectedRange.start, 'yyyy-MM-dd'),
        end_date: format(selectedRange.end, 'yyyy-MM-dd'),
      }),
    );
    dispatch(fetchStatistics());
  }, [dispatch, selectedRange]);

  /**
   * Get the organization login excel as a blob and
   * create object URL for it and trigger download by associating
   * the URL to a temporary link element.
   */
  const onLoginExcelButtonClick = useCallback(() => {
    getAxiosInstance()
      .get('/organization/get_organization_login_excel/', {
        responseType: 'blob',
      })
      .then(({ data }) => {
        const linkElement = document.createElement('a');
        document.body.appendChild(linkElement);
        linkElement.href = window.URL.createObjectURL(data);
        linkElement.download = `organization-login-data-${formatISO(
          Date.now(),
          {
            representation: 'date',
          },
        )}.xlsx`;
        linkElement.target = '_blank';
        linkElement.click();
        linkElement.remove();
      });
  }, []);

  return (
    <StatisticsContainer graphOffset={graphOffset}>
      <h3>{t('reporting')}</h3>
      <div className="summary">
        <div className="summary-object">
          <p>{t('active-ads')}</p>
          <p>{statistics.active_ad_count}</p>
        </div>
        <div className="summary-object">
          <p>{t('new-messages')}</p>
          <p>{statistics.created_messages_count}</p>
        </div>
        <div className="summary-object">
          <p>{t('created-trainings')}</p>
          <p>{statistics.created_trainings_count}</p>
        </div>
        <div className="summary-object">
          <p>{t('new-registration-requests')}</p>
          <p>{statistics.new_org_request_count}</p>
        </div>
        <div className="summary-object">
          <p>{t('registered-users')}</p>
          <p>{statistics.user_count}</p>
        </div>
        <button type="button" onClick={onLoginExcelButtonClick}>
          {t('download-login-excel')}
        </button>
      </div>
      <div
        className="graph"
        ref={(ref) => {
          if (!ref) {
            return;
          }
          const { top } = ref.getBoundingClientRect();
          setGraphOffset(top);
        }}
      >
        <h3>{t('active-ads')}</h3>
        <div className="date-pickers">
          <DatePicker
            dateFormat="yyyy MMM"
            locale="fi"
            placeholderText={t('choose-date')}
            selected={selectedRange.start}
            onChange={(value) =>
              !Array.isArray(value)
                ? setSelectedRange({
                    start: value,
                    end: selectedRange.end,
                  })
                : null
            }
          />
          <span>&ndash;</span>
          <DatePicker
            dateFormat="yyyy MMM"
            locale="fi"
            placeholderText={t('choose-date')}
            selected={selectedRange.end}
            onChange={(value) =>
              !Array.isArray(value)
                ? setSelectedRange({
                    start: selectedRange.start,
                    end: value,
                  })
                : null
            }
          />
        </div>
        {graphOffset === 0 ? null : (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={monthData}>
              <YAxis
                stroke="#fff"
                strokeOpacity={0}
                fontSize={13}
                fontFamily="Barlow"
              />
              <XAxis
                stroke="#fff"
                strokeOpacity={0}
                dataKey="name"
                fontSize={13}
                fontFamily="Barlow"
              />
              <Line
                type="monotone"
                dataKey={activeAdsKey}
                stroke="#D66C77"
                strokeWidth="3px"
                isAnimationActive={false}
                dot={{ stroke: '#D60C17', strokeWidth: 6, fill: '#D60C17' }}
              />
              <CartesianGrid vertical={false} strokeOpacity={0.2} />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </StatisticsContainer>
  );
};

export default SuperAdminStatistics;
