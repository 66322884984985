import React, { useEffect } from 'react';
import { fetchStatistics } from 'Features/Statistics/statisticsSlice';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'Store';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const FrontpageBackground = styled.div`
  width: 100%;
  height: 100%;
`;

const FrontpageTopItems = styled.div`
  width: 100%;
  height: 252px;
  display: flex;
`;
const CompanyInformation = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(150.47deg, #32384c 0%, #212634 100%);
`;
const InformationRow = styled.div`
  display: flex;
  flex-direction: column;
  &:last-child {
    height: 35%;
    border-top: 1px solid #d6dce2;
  }
`;
const SummaryTitle = styled.div`
  color: #ffffff;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
`;
const TopSummaryDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 15px;
`;
const BottomSummaryDetails = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  margin-bottom: 15px;
`;
const SummaryObject = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 25px;
`;
const SummaryObjectTitle = styled.div`
  color: #ffffff;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
`;
const SummaryObjectData = styled.div`
  color: #ffffff;
  font-family: Barlow;
  font-size: 18px;
`;
const SmallSummaryObjectData = styled.div`
  color: #ffffff;
  font-family: Barlow;
  font-size: 14px;
`;

const Summary = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(fetchStatistics());
  }, [dispatch]);
  const { statistics } = useSelector((state: RootState) => state.statistics);

  return (
    <FrontpageBackground>
      <FrontpageTopItems>
        <CompanyInformation>
          <InformationRow>
            <SummaryTitle>{t('summary')}</SummaryTitle>
            <TopSummaryDetails>
              <SummaryObject>
                <SummaryObjectTitle>
                  {t('registered-users')}{' '}
                </SummaryObjectTitle>
                <SummaryObjectData>{statistics.user_count}</SummaryObjectData>
              </SummaryObject>
              <SummaryObject>
                <SummaryObjectTitle>{t('active-ads')}</SummaryObjectTitle>
                <SummaryObjectData>
                  {statistics.active_ad_count}
                </SummaryObjectData>
              </SummaryObject>
              <SummaryObject>
                <SummaryObjectTitle>
                  {t('created-trainings')}
                </SummaryObjectTitle>
                <SummaryObjectData>
                  {statistics.created_trainings_count}
                </SummaryObjectData>
              </SummaryObject>
            </TopSummaryDetails>
          </InformationRow>
          <InformationRow>
            <BottomSummaryDetails>
              <SummaryObject>
                <SummaryObjectTitle>
                  {t('new-registration-requests')}
                </SummaryObjectTitle>
                <SmallSummaryObjectData>
                  {statistics.new_org_request_count}
                </SmallSummaryObjectData>
              </SummaryObject>
              <SummaryObject>
                <SummaryObjectTitle>{t('new-messages')}</SummaryObjectTitle>
                <SmallSummaryObjectData>
                  {statistics.created_messages_count}
                </SmallSummaryObjectData>
              </SummaryObject>
            </BottomSummaryDetails>
          </InformationRow>
        </CompanyInformation>
      </FrontpageTopItems>
    </FrontpageBackground>
  );
};

export default Summary;
