/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import actionGenerator from 'Util/actionGenerator';
import { getAxiosInstance } from 'Api';

interface ContentManagementState {
  entities: [];
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  content: ContentType[];
  documents: DocumentType[];
}
export type ContentType = {
  id: number;
  name: string;
  content_text: string;
};

type UpdateContentManagementRequest = {
  id: number;
  body: {
    content_text: string;
  };
};
const initialState: ContentManagementState = {
  entities: [],
  loading: 'idle',
  content: [],
  documents: [],
};
type FetchContentManagementResults = {
  count: number;
  next: null;
  previous: null;
  results: ContentType[];
};
type UploadFileRequest = {
  body: FormData;
};
type FetchDocumentsResults = {
  count: number;
  next: null;
  previous: null;
  results: DocumentType[];
};
export type DocumentType = {
  id: number;
  document: string;
  document_name: string;
};
const fetchContentManagement = createAsyncThunk<
  FetchContentManagementResults,
  undefined
>('content_management/fetch', async () => {
  const response = await getAxiosInstance().get(`content_management/`);
  return response.data;
});
const updateContentManagement = createAsyncThunk(
  'content_management/update',
  async ({ id, body }: UpdateContentManagementRequest) => {
    try {
      const response = await getAxiosInstance().patch(
        `content_management/${id}/`,
        body,
      );
      return response.data;
    } catch (err) {
      // @ts-ignore
      throw Error(JSON.stringify(err.response.data));
    }
  },
);
const fetchDocuments = createAsyncThunk<FetchDocumentsResults, undefined>(
  'document_management/fetch',
  async () => {
    const response = await getAxiosInstance().get(`document_management/`);
    return response.data;
  },
);
const deleteDocument = createAsyncThunk(
  'document_management/delete',
  async (id: number) => {
    const response = await getAxiosInstance().delete(
      `document_management/${id}/`,
    );
    return response.data;
  },
);
const uploadContentFile = createAsyncThunk(
  'content_management/upload',
  async ({ body }: UploadFileRequest) => {
    try {
      const response = await getAxiosInstance().post(
        `document_management/`,
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return response.data;
    } catch (err) {
      // @ts-ignore
      throw Error(JSON.stringify(err.response.data));
    }
  },
);

const genericActions = actionGenerator('content_management');
const contentManagementSlice = createSlice({
  name: genericActions.name,
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder
      .addCase(genericActions.ResetAction, () => initialState)
      .addCase(fetchContentManagement.fulfilled, (state, action) => {
        state.content = action.payload.results;
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        state.documents = action.payload.results;
      })
      .addMatcher(genericActions.isPendingAction, (state) => {
        state.loading = 'pending';
      })
      .addMatcher(genericActions.isRejectedAction, (state) => {
        state.loading = 'rejected';
      })
      .addMatcher(genericActions.isFulfilledAction, (state) => {
        state.loading = 'fulfilled';
      });
  },
});

export default contentManagementSlice.reducer;
export {
  fetchContentManagement,
  updateContentManagement,
  uploadContentFile,
  fetchDocuments,
  deleteDocument,
};
