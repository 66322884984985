import styled from 'styled-components';

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    flex-direction: row;
  }
  .rows {
    padding: 32px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-right: 1px solid #d6dce2;
    @media screen and ${(props) => props.theme.breakpoints.lg} {
      width: 70%;
    }
  }
  .additional-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 0px 0px 0px;
    @media screen and ${(props) => props.theme.breakpoints.lg} {
      padding: 30px 30px 0px 30px;
    }
    button {
      width: 100%;
      height: 37px;
      border-radius: 6px;
      background-color: #d60c17;
      border: none;
      outline: none;
      color: #ffffff;
      font-family: Barlow;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      margin-bottom: 12px;
      cursor: pointer;
    }
    a {
      color: #0a4ea2;
      font-family: Barlow;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      white-space: nowrap;
    }
    .selection-details {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      flex-grow: 1;
      label {
        margin-top: 30px;
        &:first-of-type {
          margin-top: 0;
        }
      }
      .selection {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        span {
          color: #d60c17;
          font-family: Barlow;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 17px;
          text-align: center;
          margin-right: 10px;
        }
        svg {
          color: #a8aeb4;
          font-size: 21px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 21px;
          cursor: pointer;
        }
      }
    }
    .work-exp-side {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;
      span {
        color: #0a4ea2;
        font-family: Barlow;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;
        max-width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      svg {
        color: #a8aeb4;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 21px;
      }
    }
  }
  .checkbox {
    display: flex;
    align-items: center;
    user-select: none;
    margin-bottom: 20px;
    input {
      height: 17px;
      width: 17px;
      border: 1px solid #979797;
      border-radius: 2px;
      margin-right: 10px;
    }
    label {
      color: #252525;
      font-family: Barlow;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
      margin-bottom: 0;
    }
  }
`;
