/* eslint-disable react/no-danger */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Selectable } from 'Components/Survey/FieldComponents/Phase2';
import { format, parseISO } from 'date-fns';
import RakennusliittoLogo from 'Assets/suomi-vaaka-rgb.png';
import {
  fetchTrainingWithId,
  SingleTrainingType,
  modifyTraining,
} from 'Features/Training/trainingSlice';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState, store } from 'Store';
import styled from 'styled-components/macro';
import { setConfirmed } from 'Util/trainingUtils';
import JobImage from './TrainingImage';

const AdvertisementContainer = styled.div`
  .job-container {
    background-color: #ffffff;
    box-shadow: 0 7px 7px -10px rgba(0, 0, 0, 0.41);
    padding: 20px 25px;
    margin-top: 21px;
    .job-info-container {
      display: flex;
      .job-info {
        flex-grow: 1;
        margin-right: 15px;
        h1 {
          color: #2a2a2a;
          font-family: Barlow;
          font-size: 30px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 36px;
          margin: 0;
        }
        .service-provider-info-small {
          display: flex;
          margin-top: 11px;
          align-items: center;
          p {
            margin: 0;
            padding: 0 16px;
            color: #3e3e3e;
            font-family: Barlow;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 1;
            border-right: 1px solid #474747;
            &:first-of-type {
              padding-left: 0;
            }
            &:last-of-type {
              padding-right: 0;
              border-right: none;
            }
          }
        }
        .description {
          padding: 30px 0;
          color: #303030;
          font-family: Barlow !important;
          * {
            font-family: Barlow !important;
          }
          font-size: 16px;
          letter-spacing: 0;
          line-height: 24px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .service-provider-info {
        min-width: 280px;
        margin-bottom: 20px;
        .logo {
          height: 90px;
          background: url(${(props: { training: SingleTrainingType }) =>
            props.training.eventilla_id
              ? RakennusliittoLogo
              : (props.training.organization || {}).logo});
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
        .info-box {
          margin-top: 20px;
          padding: 16px;
          background-color: #f8f8f8;
          p {
            display: flex;
            justify-content: space-between;
            color: #252525;
            font-family: Barlow;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
            margin: 0;
            &:not(:last-of-type) {
              margin-bottom: 5px;
            }
          }
        }
      }
      flex-direction: column;
      @media screen and ${(props) => props.theme.breakpoints.xs} {
        flex-direction: row;
      }
    }
  }
  > a {
    color: #0663b5;
    font-family: Barlow;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    text-decoration: none;
    svg {
      margin-right: 5px;
    }
  }
`;
const Location = styled(Selectable)`
  margin: 0px;
`;
const SignUpContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    justify-content: flex-start;
  }
`;
const SignUpButton = styled.a`
  height: 37px;
  border-radius: 6px;
  background-color: #d60c17;
  border: none;
  outline: none;
  align-items: center;
  color: #ffffff;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  width: 273px;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  padding: 0px 20px 0px 20px;
  position: relative;
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 0px 30px 0px 30px;
  }
`;
const ConfirmButton = styled.a`
  height: 37px;
  border-radius: 6px;
  background-color: #414141;
  border: none;
  outline: none;
  align-items: center;
  color: #ffffff;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  width: 273px;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  padding: 0px 20px 0px 20px;
  margin-left: 20px;
  position: relative;
  @media screen and ${(props) => props.theme.breakpoints.xs} {
    padding: 0px 30px 0px 30px;
  }
`;

const defaultProps = {
  adminPanel: false,
  educationPanel: false,
};
const TrainingAdvertisementDetails = ({
  trainingId,
  adminPanel,
  educationPanel,
}: {
  trainingId: string;
  adminPanel?: boolean;
  educationPanel?: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isMember, setIsMember] = useState(false);
  const { singleTrainingData } = useSelector(
    (state: RootState) => state.training,
  );
  const userOrganizations = useSelector(
    (state: RootState) => state.auth.user.organizations,
  );
  const trainingOrganizationId = singleTrainingData?.organization?.id;

  useEffect(() => {
    if (userOrganizations && singleTrainingData?.organization) {
      if (userOrganizations.includes(trainingOrganizationId as number)) {
        setIsMember(true);
      }
    }
  });

  useEffect(() => {
    dispatch(fetchTrainingWithId(parseInt(trainingId, 10)));
  }, [trainingId]);
  if (
    singleTrainingData &&
    singleTrainingData.id !== parseInt(trainingId, 10)
  ) {
    return <AdvertisementContainer training={singleTrainingData} />;
  }
  const parseDate = (date: string) => {
    if (date == null) {
      return t('continuous');
    }
    return format(parseISO(date), 'dd.MM.yyyy');
  };
  const returnPath = () => {
    if (adminPanel) {
      return '/super-admin-panel/trainings';
    } else if (educationPanel) {
      return '/education-panel/ilmoitukset';
    }
    return '/trainings';
  };

  const handleConfirm = () => {
    setConfirmed();
    const body = { continuous_training: true, confirmed: true };
    if (body!) {
      dispatch(modifyTraining({ body, id: singleTrainingData!.id }));
      store.dispatch({ type: 'newAd/modifyAdvertisement' });
    }
  };

  try {
    // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  } catch (error) {
    // just a fallback for older browsers
    window.scrollTo(0, 0);
  }

  return (
    <>
      {singleTrainingData ? (
        <AdvertisementContainer training={singleTrainingData}>
          <Link to={returnPath()}>
            <FontAwesomeIcon icon={['fal', 'angle-left']} />
            {t('back-to-listing')}
          </Link>
          <div className="job-container">
            <JobImage
              images={
                singleTrainingData.images ? singleTrainingData.images : []
              }
            />
            <div className="job-info-container">
              <div className="job-info">
                <h1>{singleTrainingData.subject}</h1>
                <div className="service-provider-info-small">
                  <p>
                    {singleTrainingData.continuous_training
                      ? `${t('continuous')}`
                      : `${parseDate(
                          singleTrainingData.start_time,
                        )} - ${parseDate(singleTrainingData.end_time)}`}
                  </p>
                  {singleTrainingData.training_location ? (
                    <p>
                      <Location selected={false}>
                        {singleTrainingData.training_location.location.name}
                      </Location>
                    </p>
                  ) : null}
                </div>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: singleTrainingData.description,
                  }}
                />
              </div>
              <div className="service-provider-info">
                <div className="logo" />
                <div className="info-box">
                  {singleTrainingData.training_location ? (
                    <>
                      <p>
                        <strong>{t('location')}:</strong>
                        <span>
                          {singleTrainingData.training_location.location.name}
                        </span>
                      </p>
                      <p>
                        <strong>{t('address')}:</strong>
                        <span>
                          {singleTrainingData.training_location.address}
                        </span>
                      </p>
                    </>
                  ) : null}
                  <p>
                    <strong>{t('time-period')}:</strong>
                    <span>
                      {singleTrainingData.continuous_training
                        ? `${t('continuous')}`
                        : `${parseDate(
                            singleTrainingData.start_time,
                          )} - ${parseDate(singleTrainingData.end_time)}`}
                    </span>
                  </p>
                  <p>
                    <strong>{t('oclock')}:</strong>
                    <span>
                      {format(parseISO(singleTrainingData.start_time), 'HH:mm')}
                    </span>
                  </p>
                  {singleTrainingData.enrollment_deadline ? (
                    <p>
                      <strong>{t('enrollment_deadline')}:</strong>
                      <span>
                        {singleTrainingData.continuous_training
                          ? singleTrainingData.enrollment_deadline
                          : format(
                              parseISO(singleTrainingData.enrollment_deadline),
                              'dd.MM.yyyy',
                            )}
                      </span>
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <SignUpContainer>
              <SignUpButton
                target="_blank"
                href={singleTrainingData.web_link}
                rel="noreferrer"
              >
                {singleTrainingData.eventilla_id
                  ? t('redirect-to-eventilla')
                  : t('enroll-in')}
              </SignUpButton>
              {singleTrainingData.confirmed === false &&
              singleTrainingData.continuous_training &&
              isMember ? (
                <ConfirmButton
                  target="_blank"
                  href={singleTrainingData.web_link}
                  rel="noreferrer"
                  onClick={handleConfirm}
                >
                  {t('confirm-continuous')}
                </ConfirmButton>
              ) : (
                ''
              )}
            </SignUpContainer>
          </div>
        </AdvertisementContainer>
      ) : null}
    </>
  );
};
TrainingAdvertisementDetails.defaultProps = defaultProps;
export default TrainingAdvertisementDetails;
