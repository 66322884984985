import React from 'react';
import { HouseholdLogin as HouseholdLoginComponent } from 'Components/HouseholdLogin';
import MainLayout from 'Components/Layout/MainLayout';

const HouseholdLogin = () => {
  return (
    <MainLayout>
      <HouseholdLoginComponent />
    </MainLayout>
  );
};

export default HouseholdLogin;
