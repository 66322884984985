import React from 'react';
import styled from 'styled-components';
import AdminLayout from 'Components/Layout/AdminLayout';
import AdminDetailsComponent from 'Components/AdminSettings/AdminDetails';

const ContentContainer = styled.div`
  width: 100%;
  margin: 20px;
  overflow: auto;
  flex: 1;
`;

const AdminDetails = () => {
  return (
    <AdminLayout>
      <ContentContainer>
        <AdminDetailsComponent />
      </ContentContainer>
    </AdminLayout>
  );
};
export default AdminDetails;
