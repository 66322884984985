/* eslint-disable camelcase */
import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  AsyncThunk,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';

import { getAxiosInstance } from 'Api';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

export type DrivingLicenseType = {
  created_at: string;
  id: number;
  license_type: string;
  license_alias: string;
  updated_at: string;
};
interface DrivingLicenseState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  data: {
    results: Array<DrivingLicenseType>;
  };
}

const initialState: DrivingLicenseState = {
  loading: 'idle',
  data: {
    results: [],
  },
};

const fetchDrivingLicenses = createAsyncThunk(
  'drivingLicense/fetch',
  async () => {
    const response = await getAxiosInstance().get(`/driving_license/`);
    return { data: response.data };
  },
);

const isPendingAction = (action: AnyAction): action is PendingAction => {
  return action.type.endsWith('/pending');
};
const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return action.type.endsWith('/rejected');
};
const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return action.type.endsWith('/fulfilled');
};

export const resetAction = createAction('reset-tracked-loading-state');

/* eslint-disable no-param-reassign */
const drivingLicenseSlice = createSlice({
  name: 'drivingLicense',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetAction, () => initialState)
      .addCase(
        fetchDrivingLicenses.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.data = action.payload.data;
        },
      )
      .addMatcher(isPendingAction, (state) => {
        state.loading = 'pending';
      })
      .addMatcher(isRejectedAction, (state) => {
        state.loading = 'rejected';
      })
      .addMatcher(isFulfilledAction, (state) => {
        state.loading = 'fulfilled';
      });
  },
});
/* eslint-enable no-param-reassign */

export default drivingLicenseSlice.reducer;
export { fetchDrivingLicenses };
