/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from 'Store';
import { deleteMemberProfile } from 'Features/MemberProfile/memberProfileSlice';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const TransparentBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
`;
const CloseContainer = styled.div`
  display: flex;
  color: #ffffff;
  font-family: Barlow;
  font-size: 14px;
  align-items: center;
  width: 100%;

  justify-content: flex-end;
  min-height: 40px;
  max-height: 40px;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 60%;
  }
`;
const TopCloseButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
`;
const CloseButtonContainer = styled.div`
  display: flex;
  margin: 35px;
  justify-content: flex-end;
`;
const CloseButton = styled.div`
  height: 37px;
  width: 136px;
  border-radius: 6px;
  background-color: #d60c17;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #ffffff;
  font-family: Barlow;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
`;
const RemoveButton = styled(CloseButton)`
  margin-left: 20px;
`;
const CloseIcon = styled(FontAwesomeIcon)`
  color: #ffffff;
  font-size: 1.5em;
  margin-left: 10px;
`;
const ModalBackground = styled.div`
  background: #ffffff;
  width: 100%;
  margin-bottom: 20px;
  overflow: auto;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 60%;
  }
`;
const TopBar = styled.div`
  height: 37px;
  background-color: #d60c17;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    text-align: center;
    color: #ffffff;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
  }
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const DeleteWarning = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  margin: 10px 10px 10px 10px;
`;

const DeleteMemberModal = ({
  modalToggle,
}: {
  modalToggle: (arg0: boolean) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    user: { member_profile },
  } = useSelector((state: RootState) => state.auth);
  const deleteSelectedUser = () => {
    if (member_profile && member_profile.id) {
      dispatch(deleteMemberProfile(member_profile.id)).then(() =>
        history.push('/'),
      );
    }
  };
  return (
    <TransparentBackground>
      <CloseContainer>
        <TopCloseButton onClick={() => modalToggle(false)}>
          <div>{t('close')}</div>
          <CloseIcon icon={['fal', 'times-circle']} />
        </TopCloseButton>
      </CloseContainer>
      <ModalBackground>
        <TopBar>
          <span>{t('delete-user-profile')}</span>
        </TopBar>
        <ModalContent>
          <DeleteWarning>
            {t('member-profile-delete-confirmation')}
          </DeleteWarning>
        </ModalContent>
        <CloseButtonContainer>
          <CloseButton onClick={() => modalToggle(false)}>
            {t('cancel')}
          </CloseButton>
          <RemoveButton onClick={() => deleteSelectedUser()}>
            {t('remove')}
          </RemoveButton>
        </CloseButtonContainer>
      </ModalBackground>
    </TransparentBackground>
  );
};

export default DeleteMemberModal;
