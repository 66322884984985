import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import JobBackground from 'Assets/job-background.jpg';
import TrainingBackground from 'Assets/training-background.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Select, { StylesConfig } from 'react-select';
import { borderlessSelectStyling } from 'Components/Survey/FormComponents/JobExperinceForm';
import { RootState } from 'Store';
import {
  searchJobAdvertisements,
  setSearchParams,
} from 'Features/JobAdvertisement/jobAdvertisementSlice';
import { fetchPaginatedFilteredTrainings } from 'Features/Training/trainingSlice';
import generateJobAdvertisementSearchBody from 'Util/jobAdvertisementSearchBody';

interface SearchProps {
  type: string;
}

interface SearchBackgroundProps {
  background: string | null;
  attachmentType: string;
}

const borderlessStyling: StylesConfig = {
  ...borderlessSelectStyling,
  container: (provided) => ({
    ...provided,
    width: '220px',
  }),
  control: (provided) => ({
    ...provided,
    height: '100%',
    border: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    whiteSpace: 'nowrap',
  }),
};

const SearchBackground = styled.div`
  background: url('${(props: SearchBackgroundProps) => props.background}');
  background-color: #ddd;
  background-attachment: ${(props: SearchBackgroundProps) =>
    props.attachmentType};
  background-position: center 80%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 30vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchBar = styled.div`
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.41);
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    flex-direction: row;
  }
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    max-width: 90%;
    flex-direction: row;
    width: 846px;
  }
  input {
    background: none;
    border: none;
    outline: none;
    padding: 12px;
    color: #000000;
    font-family: Barlow;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    &::placeholder {
      color: #000000;
    }
    &:first-of-type {
      flex-grow: 1;
      border-bottom: 1px solid #ddd;
      @media screen and ${(props) => props.theme.breakpoints.lg} {
        border: none;
        border-right: 1px solid #ddd;
      }
    }
  }
  button {
    position: relative;
    width: 124px;
    height: 48.98px;
    min-width: 0;
    background-color: #d60c17;
    border-radius: 0 0 6px 0;
    border: none;
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.41);
    color: #ffffff;
    font-family: Barlow;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    @media screen and ${(props) => props.theme.breakpoints.lg} {
      border-radius: 0 6px 6px 0;
    }
    &:hover {
      background-color: #b60c17;
    }
    svg {
      position: absolute;
      right: 15px;
      font-size: 16px;
      top: calc(50% - 8px);
    }
  }
`;

export type SearchParams = {
  location: { label: string; value: number } | null;
  search: string;
};

export const Search = ({ type }: SearchProps) => {
  const { auth } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const { filters, selectedFilters, searchParams } = useSelector(
    (state: RootState) => state.jobAdvertisement,
  );
  const { availableFilters } = useSelector(
    (state: RootState) => state.training,
  );
  const locationOptions =
    type === 'job'
      ? filters.locations.map((loc) => ({
          value: loc.id,
          label: loc.name,
        }))
      : availableFilters.locations.map((loc) => ({
          value: loc.id,
          label: loc.name,
        }));
  const { t } = useTranslation();
  let background: string | null = null;
  let attachmentType: string = 'fixed';
  switch (type) {
    case 'job': {
      background = JobBackground;
      attachmentType = 'fixed';
      break;
    }
    case 'training': {
      background = TrainingBackground;
      attachmentType = 'unset';
      break;
    }
    default: {
      background = null;
      attachmentType = 'fixed';
      break;
    }
  }

  let placeholder: string | undefined = '';
  switch (type) {
    case 'job': {
      placeholder = t('search-open-jobs');
      break;
    }
    case 'training': {
      placeholder = t('search-trainings');
      break;
    }
    default: {
      placeholder = t('search');
      break;
    }
  }

  const setSearchInfo = (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;

    const newState = { ...searchParams };
    const key = target.name as keyof SearchParams;
    if (key === 'search') {
      newState[key] = target.value;
    }

    dispatch(setSearchParams(newState));
  };

  const search = () => {
    switch (type) {
      case 'job': {
        const body = generateJobAdvertisementSearchBody(
          searchParams,
          selectedFilters,
        );

        dispatch(searchJobAdvertisements(body));
        break;
      }
      case 'training': {
        const locations = [];
        if (searchParams.location) {
          locations.push(searchParams.location.label);
        }
        dispatch(
          fetchPaginatedFilteredTrainings({
            page: 1,
            filters: {
              free_text_search: searchParams.search,
              locations,
              organizations: [],
              order: 'start_time',
            },
          }),
        );
        break;
      }
      default: {
        placeholder = t('search');
        break;
      }
    }
  };
  return (
    <SearchBackground background={background} attachmentType={attachmentType}>
      {auth.isAuthenticated ||
      window.location.href.indexOf('trainings') !== -1 ? (
        <SearchBar>
          <input
            name="search"
            placeholder={placeholder}
            onChange={(e) => setSearchInfo(e)}
            onKeyDown={(e) => (e.key === 'Enter' ? search() : null)}
          />
          <div>
            <Select
              placeholder="Sijainti, esim. Uusimaa"
              styles={borderlessStyling}
              options={locationOptions}
              isClearable
              onChange={(opt) => {
                const payload = {
                  ...searchParams,
                  location: !opt
                    ? null
                    : (opt as {
                        value: number;
                        label: string;
                      }),
                };
                dispatch(setSearchParams(payload));
              }}
            />
            <button type="button" onClick={search}>
              ETSI <FontAwesomeIcon icon={['fas', 'search']} />
            </button>
          </div>
        </SearchBar>
      ) : null}
    </SearchBackground>
  );
};

export default Search;
