/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import actionGenerator from 'Util/actionGenerator';
import { getAxiosInstance } from 'Api';

interface OrganizationState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  data: FetchOrganizationsDetailsResponse;
  images: Array<ImageType>;
  singleOrganizationData: OrganizationType;
}
type ImageType = {
  id: number;
  name: string;
  url: string;
};
const initialState: OrganizationState = {
  loading: 'idle',
  data: {} as FetchOrganizationsDetailsResponse,
  images: [],
  singleOrganizationData: {} as OrganizationType,
};
export type OfficeType = {
  address: string;
  email: string;
  id: number;
  is_main_office: boolean;
  location: {
    id: number;
    name: string;
  };
  name: string;
  phone_number: string;
};
export type JobAdProviderType = {
  description: string;
  email: string;
  id: number;
  is_employer: boolean;
  is_trainer: boolean;
  logo: string;
  name: string;
  users: Array<number>;
  vat_number: string;
  url: string;
};
export type OrganizationType = {
  created_at: string;
  description: string;
  email: string;
  id: number;
  is_employer: boolean;
  is_trainer: boolean;
  is_household: boolean;
  logo: string;
  name: string;
  offices: Array<OfficeType>;
  updated_at: string;
  vat_number: string;
  url: string;
};
type FetchOrganizationsDetailsResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: OrganizationType[];
};
// Prettie ignore used to ignore indentationlevels for FetchOrganizationsRequest
// prettier-ignore
type FetchOrganizationsRequest =
  | {
      name?: string;
      vat?: string;
    }
  | undefined;

const fetchOrganizations = createAsyncThunk<
  FetchOrganizationsDetailsResponse,
  FetchOrganizationsRequest
>('organization/fetch', async (body) => {
  if (body) {
    const response =
      await getAxiosInstance().get<FetchOrganizationsDetailsResponse>(
        `/organization/`,
        {
          params: {
            limit: 9000,
            name: body.name,
            vat_number: body.vat,
          },
        },
      );
    return response.data;
  }
  const response =
    await getAxiosInstance().get<FetchOrganizationsDetailsResponse>(
      `/organization/`,
      {
        params: {
          limit: 9000,
        },
      },
    );

  return response.data;
});
type FetchOrganizationImagesResponse = ImageType[];
type FetchOrganizationImagesRequest = {
  id: number;
};
const fetchOrganizationImages = createAsyncThunk<
  FetchOrganizationImagesResponse,
  FetchOrganizationImagesRequest
>('organization/images', async ({ id }: FetchOrganizationImagesRequest) => {
  const response =
    await getAxiosInstance().get<FetchOrganizationImagesResponse>(
      `/organization/${id}/images/`,
    );
  return response.data;
});
type DeleteOrganizationImageRequest = {
  id: number;
  image_pk: number;
};
type DeleteOrganizationImageResponse = string;
const deleteOrganizationImage = createAsyncThunk<
  DeleteOrganizationImageResponse,
  DeleteOrganizationImageRequest
>(
  'organization/delete_image',
  async ({ id, image_pk }: DeleteOrganizationImageRequest) => {
    const response =
      await getAxiosInstance().delete<DeleteOrganizationImageResponse>(
        `/organization/${id}/images/${image_pk}/`,
      );
    return response.data;
  },
);

type UploadOrganizationImageResponse = ImageType;
type UploadOrganizationImageRequest = {
  id: number;
  body: FormData;
};
const uploadOrganizationImage = createAsyncThunk<
  UploadOrganizationImageResponse,
  UploadOrganizationImageRequest
>(
  'organization/upload_image',
  async ({ id, body }: UploadOrganizationImageRequest) => {
    const response =
      await getAxiosInstance().post<UploadOrganizationImageResponse>(
        `/organization/${id}/upload_image/`,
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
    return response.data;
  },
);
type FetchSingleOrganizationResponse = OrganizationType;
const fetchSingleOrganization = createAsyncThunk<
  FetchSingleOrganizationResponse,
  number
>('organization/fetch_single', async (id: number) => {
  const response =
    await getAxiosInstance().get<FetchSingleOrganizationResponse>(
      `/organization/${id}/`,
    );
  return response.data;
});
const deleteOrganization = createAsyncThunk<
  FetchSingleOrganizationResponse,
  number
>('organization/delete', async (id: number) => {
  const response =
    await getAxiosInstance().delete<FetchSingleOrganizationResponse>(
      `/organization/${id}/`,
    );
  return response.data;
});
export type OrganizationRequestType = {
  name: string;
  vat_number: string;
  description: string;
  url: string;
  logo: string;
};
type PatchOrganizationRequest = {
  id: number;
  body: FormData;
};
const patchOrganization = createAsyncThunk<unknown, PatchOrganizationRequest>(
  'office/patchOffice',
  async ({ id, body }: PatchOrganizationRequest) => {
    const response = await getAxiosInstance().patch(
      `/organization/${id}/`,
      body,
    );
    return { data: response.data };
  },
);
const genericActions = actionGenerator('organization');

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder
      .addCase(genericActions.ResetAction, () => initialState)
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchSingleOrganization.fulfilled, (state, action) => {
        state.singleOrganizationData = action.payload;
      })
      .addCase(fetchOrganizationImages.fulfilled, (state, action) => {
        state.images = action.payload;
      })
      .addCase(deleteOrganizationImage.fulfilled, (state, action) => {
        state.images = state.images.filter(
          (image) => image.id !== action.meta.arg.image_pk,
        );
      })
      .addCase(deleteOrganization.fulfilled, (state, action) => {
        state.data.results = state.data.results.filter(
          (organization) => organization.id !== action.meta.arg,
        );
      })
      .addCase(uploadOrganizationImage.fulfilled, (state, action) => {
        state.images.push(action.payload);
      })
      .addMatcher(genericActions.isPendingAction, (state) => {
        state.loading = 'pending';
      })
      .addMatcher(genericActions.isRejectedAction, (state) => {
        state.loading = 'rejected';
      })
      .addMatcher(genericActions.isFulfilledAction, (state) => {
        state.loading = 'fulfilled';
      });
  },
});
export default organizationSlice.reducer;
export {
  fetchOrganizations,
  fetchOrganizationImages,
  deleteOrganizationImage,
  uploadOrganizationImage,
  fetchSingleOrganization,
  deleteOrganization,
  patchOrganization,
  genericActions,
};
