import LoadingIndicator from 'Components/LoadingIndicator';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'Store';
import styled from 'styled-components/macro';
import JobAdvertisementDetails from '../JobAdvertisementDetails';
import JobSearchFilters from './JobSearchFilters';
import JobSearchResults from './JobSearchResults';

const JobSearchContainer = styled.div`
  width: 100%;
  background-color: #f4f5f7;
  border-top: 1px solid #d6dce2;
  .job-search-content {
    display: flex;
    width: 100%;
    max-width: 1440px;
    margin: auto;
    padding: 15px;
    @media screen and ${(props) => props.theme.breakpoints.lg} {
      padding: 30px;
    }
    > div:first-of-type {
      width: 20%;
    }
    > div:last-of-type {
      width: 100%;
      @media screen and ${(props) => props.theme.breakpoints.lg} {
        width: 80%;
        padding-left: 20px;
      }
    }
  }
`;

const JobSearch = ({ jobId }: { jobId: string | null | undefined }) => {
  const { loading } = useSelector((state: RootState) => state.jobAdvertisement);
  return (
    <JobSearchContainer>
      <div className="job-search-content">
        <JobSearchFilters />
        {jobId ? (
          <JobAdvertisementDetails jobId={jobId} />
        ) : (
          <JobSearchResults />
        )}
      </div>
      {loading === 'pending' && <LoadingIndicator />}
    </JobSearchContainer>
  );
};

export default JobSearch;
