/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PermissionProvider from 'Features/Auth/PermissionProvider';
import { RootState } from 'Store';
import { GroupItem } from './permissions';

interface Props extends RouteProps {
  component: React.ComponentType<any>;
  permissionFunction?: (groups: Array<GroupItem>) => boolean;
}

const PrivateRoute = ({
  component: Component,
  permissionFunction,
  ...rest
}: Props) => {
  const {
    isAuthenticated,
    user: { tos_agreed: tosAgreed },
  } = useSelector((state: RootState) => state.auth);
  const RenderedComponent = permissionFunction ? (
    <PermissionProvider permissionCheckFunction={permissionFunction}>
      <Component />
    </PermissionProvider>
  ) : (
    <Component />
  );
  if (isAuthenticated && !tosAgreed) {
    return (
      <Route
        render={(props) => (
          <Redirect
            to={{
              pathname: '/agree-tos/',
              state: { from: props.location },
            }}
          />
        )}
        {...rest}
      />
    );
  }
  return (
    <Route
      render={(props) =>
        isAuthenticated === false ? (
          <Redirect
            to={{
              pathname: '/admin-login/',
              state: { from: props.location },
            }}
          />
        ) : (
          RenderedComponent
        )
      }
      {...rest}
    />
  );
};

PrivateRoute.defaultProps = {
  permissionFunction: undefined,
};

export default PrivateRoute;
