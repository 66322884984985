/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import {
  fetchPaginatedJobAdvertisements,
  deleteAd,
  adPartialUpdate,
  ListableAd,
} from 'Features/JobAdvertisements/advertisementSlice';
import LocationTooltip from 'Components/AdminPage/AdminActiveJobs/LocationTooltip';
import ObjectTableStyles from 'Components/ObjectTable';
import { useSelector } from 'react-redux';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import DropdownMenu from 'Components/DropdownMenu';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from 'Store';

const {
  ObjectTableBackground,
  ObjectTableContainer,
  ObjectTableTitle,
  ObjectTableTopComponent,
  ObjectTableBottomComponent,
  ObjectTableHeader,
  ObjectTableContent,
  ObjectTableItem,
  ObjectTableFooter,
  ObjectTableHeaderItem,
  ObjectTableHeaderOptionsBlock,
  ObjectTableObjectTitle,
  ObjectTableJobStatus,
  ObjectTableTop,
  ObjectTableApplicationNumber,
  ObjectTableOptions,
  ObjectTableCustomLink,
  ObjectTableSortCaretIcon,
  ObjectTableAngleRightIcon,
} = ObjectTableStyles;

const DisabledStatusIcon = styled(ObjectTableStyles.ObjectTableStatusIndicator)`
  color: #818181;
`;

const ArchivedJobsComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { itemIndex } = useSelector((state: RootState) => state.admin);
  const { advertisementData } = useSelector(
    (state: RootState) => state.advertisement,
  );
  const { data } = useSelector((state: RootState) => state.organization);
  const { results } = data;
  const fetchPaginagedAdvertisements = (resultsIndex: number) => {
    const body = {
      page: 1,
      organizationId: results[resultsIndex].id,
      status: 'archived',
      orderBy: 'title',
    };
    dispatch(fetchPaginatedJobAdvertisements(body));
  };
  useEffect(() => {
    if (results) {
      fetchPaginagedAdvertisements(itemIndex);
    }
  }, [dispatch, itemIndex, results]);
  const activeJobsAdvertisements = advertisementData.results
    ? advertisementData.results.map(
        ({
          id,
          title,
          locations,
          ad_status,
          search_end_date,
          search_start_date,
          organization,
          job_applications,
        }: ListableAd) => ({
          id,
          title,
          locations,
          ad_status,
          search_end_date: format(
            parse(search_end_date, 'yyyy-mm-dd', new Date()),
            'dd.mm.yyyy',
          ),
          search_start_date: format(
            parse(search_start_date, 'yyyy-mm-dd', new Date()),
            'dd.mm.yyyy',
          ),
          organization,
          job_applications,
        }),
      )
    : undefined;
  const [listOrder, setListOrder] = useState({
    title: 1,
    office: 1,
    search_start_date: 1,
    search_end_date: 1,
    job_applications: 1,
  });
  const [sortedBy, setSortedBy] = useState('title');
  type ColumnType =
    | 'title'
    | 'office'
    | 'search_start_date'
    | 'search_end_date'
    | 'job_applications';

  const sortList = (column: ColumnType) => {
    setSortedBy(column);
    setListOrder({ ...listOrder, [column]: listOrder[column] * -1 });
  };
  type CaretProps = {
    columnName:
      | 'title'
      | 'office'
      | 'search_start_date'
      | 'search_end_date'
      | 'job_applications';
  };
  const CaretIcon = ({ columnName }: CaretProps) => {
    if (sortedBy === columnName) {
      if (listOrder[columnName] === 1) {
        return <ObjectTableSortCaretIcon icon={['fas', 'caret-up']} />;
      }
      return <ObjectTableSortCaretIcon icon={['fas', 'caret-down']} />;
    }
    return null;
  };
  const history = useHistory();
  const advertisementOptions = [
    {
      icon: 'eye',
      title: t('show-application'),
      action: (objectId: number) => {
        history.push(`/admin-panel/ilmoitukset/${objectId}`);
      },
    },
    {
      icon: 'undo',
      title: t('undo'),
      action: (objectId: number) => {
        dispatch(
          adPartialUpdate({ id: objectId, bodyData: { archived: false } }),
        ).then(() => fetchPaginagedAdvertisements(itemIndex));
      },
    },
    {
      icon: 'trash',
      title: t('remove'),
      action: (objectId: number) => {
        dispatch(deleteAd(objectId)).then(() =>
          fetchPaginagedAdvertisements(itemIndex),
        );
      },
    },
  ];

  const archivedAds =
    activeJobsAdvertisements && results
      ? activeJobsAdvertisements.map((item) => {
          return (
            <ObjectTableItem key={item.id}>
              <ObjectTableObjectTitle>{item.title}</ObjectTableObjectTitle>
              <LocationTooltip locations={item.locations} />
              <ObjectTableHeaderItem>
                {item.search_start_date}
              </ObjectTableHeaderItem>
              <ObjectTableHeaderItem>
                {item.search_end_date}
              </ObjectTableHeaderItem>
              <ObjectTableJobStatus>
                <DisabledStatusIcon icon={['fas', 'circle']} />
                {t('archived')}
              </ObjectTableJobStatus>
              <ObjectTableApplicationNumber>
                {item.job_applications?.length}
              </ObjectTableApplicationNumber>
              <ObjectTableOptions>
                <ObjectTableCustomLink
                  to={`/admin-panel/ilmoitukset/${item.id}`}
                >
                  {t('open')}
                  <ObjectTableAngleRightIcon icon={['far', 'angle-right']} />
                </ObjectTableCustomLink>
                <DropdownMenu
                  options={advertisementOptions}
                  objectId={item.id}
                />
              </ObjectTableOptions>
            </ObjectTableItem>
          );
        })
      : [];

  return (
    <ObjectTableBackground>
      <ObjectTableTopComponent>
        <ObjectTableTop>
          <ObjectTableTitle>Arkistoidut ilmoitukset</ObjectTableTitle>
        </ObjectTableTop>
      </ObjectTableTopComponent>
      <ObjectTableBottomComponent>
        <ObjectTableContainer>
          <ObjectTableHeader>
            <ObjectTableHeaderItem onClick={() => sortList('title')}>
              {t('ad')}
              <CaretIcon columnName="title" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderItem onClick={() => sortList('office')}>
              {t('location')}
              <CaretIcon columnName="office" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderItem
              onClick={() => sortList('search_start_date')}
            >
              {t('publish-date')}
              <CaretIcon columnName="search_start_date" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderItem onClick={() => sortList('search_end_date')}>
              {t('search-ends')}
              <CaretIcon columnName="search_end_date" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderItem>Status</ObjectTableHeaderItem>
            <ObjectTableHeaderItem onClick={() => sortList('job_applications')}>
              {t('applications')}
              <CaretIcon columnName="job_applications" />
            </ObjectTableHeaderItem>
            <ObjectTableHeaderOptionsBlock />
          </ObjectTableHeader>
          <ObjectTableContent>{archivedAds}</ObjectTableContent>
          <ObjectTableFooter />
        </ObjectTableContainer>
      </ObjectTableBottomComponent>
    </ObjectTableBackground>
  );
};

export default ArchivedJobsComponent;
