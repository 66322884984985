import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'Store';
import {
  ConversationDetailsPayload,
  fetchDetailedConversations,
} from 'Features/Conversation/conversationSlice';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { fetchJobApplications } from 'Features/JobApplication/jobApplicationSlice';
import Conversation from 'Components/AdminPage/AdminMessages/Conversation';
import LoadingIndicator from 'Components/LoadingIndicator';

const ConversationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f9f9f9;
  margin-top: -35px;
  margin-bottom: -30px;
  .conversation {
    display: flex;
    border-radius: 7px;
    background-color: #ffffff;
    box-shadow: 0 7px 7px -7px rgba(37, 37, 37, 0.5);
    padding: 15px;
    color: #0a4ea2;
    font-family: Barlow;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    cursor: pointer;
    &:not(:first-of-type) {
      margin-top: 15px;
    }
    span {
      width: calc(100% / 3);
      &:last-of-type {
        padding-left: 15px;
      }
    }
  }
  .conversation-container {
    visibility: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
    .conversation-messages {
      max-height: 0;
      overflow-y: auto;
      border-top: 1px solid #ddd;
      transition: max-height 0.3s ease-in-out;
    }
    &.open {
      visibility: visible;
      max-height: 800px;
      .conversation-messages {
        max-height: 400px;
      }
    }
  }
  .headers {
    display: flex;
    padding: 15px;
    span {
      width: calc(100% / 3);
      color: #252525;
      font-family: Barlow;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
    }
  }
  .status {
    text-align: center;
    color: #252525;
    font-family: Barlow;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    margin: 0;
  }
`;

const Phase4 = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [openedConversations, setOpenedConversations] = useState<Array<number>>(
    [],
  );
  const {
    detailedData: { results },
    loading,
  } = useSelector((state: RootState) => state.conversation);
  const {
    list: { results: applicationResults },
  } = useSelector((state: RootState) => state.jobApplication);
  const loadData = async () => {
    dispatch(fetchDetailedConversations());
    dispatch(fetchJobApplications({ limit: 9999 }));
  };
  useEffect(() => {
    loadData();
  }, []);
  const getAdvertisement = (conversation: ConversationDetailsPayload) => {
    const { targeted_audience: targetedAudience } = conversation;
    const application = applicationResults.find((app) =>
      targetedAudience.job_advertisements?.includes(app.job_advertisement),
    );
    if (application) {
      return application.job_advertisement_title;
    }
    return '-';
  };
  return (
    <ConversationsContainer>
      {loading === 'pending' && <LoadingIndicator />}
      {results.length ? (
        <>
          <div className="headers">
            <span>{t('subject')}</span>
            <span>{t('ad')}</span>
            <span>{t('messages')}</span>
          </div>
          {results.map((c) => (
            <React.Fragment key={c.id}>
              <div
                className="conversation"
                onClick={() =>
                  setOpenedConversations((state) => {
                    if (state.includes(c.id)) {
                      return state.filter((c2) => c2 !== c.id);
                    }
                    return [...state, c.id];
                  })
                }
              >
                <span>{c.subject}</span>
                <span>{getAdvertisement(c)}</span>
                <span>{c.messages.length}</span>
              </div>
              <div
                className={`conversation-container${
                  openedConversations.includes(c.id) ? ' open' : ''
                }`}
              >
                {openedConversations.includes(c.id) ? (
                  <Conversation conversation={c} onMessageSent={loadData} />
                ) : null}
              </div>
            </React.Fragment>
          ))}
        </>
      ) : (
        <p className="status">
          {loading === 'pending' ? t('loading') : t('no-messages')}
        </p>
      )}
    </ConversationsContainer>
  );
};

export default Phase4;
