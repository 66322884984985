/* eslint-disable no-alert */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  createOrganizationUser,
  fetchUsers,
} from 'Features/UserManagement/userManagementSlice';
import { toast } from 'react-toastify';
import { Tooltip } from 'Components/Blocks';

const TransparentBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
`;
const CloseContainer = styled.div`
  display: flex;
  color: #ffffff;
  font-family: Barlow;
  font-size: 14px;
  align-items: center;
  width: 100%;

  justify-content: flex-end;
  min-height: 40px;
  max-height: 40px;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 60%;
    height: 60%;
  }
`;
const TopCloseButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
`;
const CloseButtonContainer = styled.div`
  display: flex;
  margin: 35px;
  justify-content: flex-end;
`;
const CancelButton = styled.div`
  height: 37px;
  width: 175px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: Barlow;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
  color: #bcc2c6;
`;
const AddUserButtom = styled(CancelButton)`
  margin-left: 20px;
  background-color: #d60c17;
  color: #ffffff;
`;
const CloseIcon = styled(FontAwesomeIcon)`
  color: #ffffff;
  font-size: 1.5em;
  margin-left: 10px;
`;
const ModalBackground = styled.div`
  background: #ffffff;
  width: 100%;
  margin-bottom: 20px;
  overflow: auto;
  @media screen and ${(props) => props.theme.breakpoints.lg} {
    width: 60%;
  }
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  label {
    color: #252525;
    font-weight: 600;
    font-family: Barlow;
    font-size: 14px;
    margin-bottom: 10px;
  }
  span {
    color: red;
  }
  .form-select {
    border: 1px solid #d6dce2;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 30px;
    outline: none;
  }
`;
const Title = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 20px;
  margin-bottom: 30px;
`;
const OrganizationName = styled.div`
  color: #252525;
  font-family: Barlow;
  font-size: 14px;
`;
const NewUserForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const AddNewUserModal = ({
  modalToggle,
  companyName,
  companyId,
}: {
  modalToggle: (arg0: boolean) => void;
  companyName: string;
  companyId: number;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [newUser, setNewUser] = useState<{ [key: string]: any }>({
    username: '',
    first_name: '',
    last_name: '',
    is_admin: false,
    organizations: [companyId],
  });

  const handleSubmission = () => {
    dispatch<any>(
      createOrganizationUser({ body: JSON.stringify(newUser) }),
    ).then(({ error }: any) => {
      if (error) {
        const errorObj: {
          [key: string]: { [key: string]: any };
        } = JSON.parse(error.message);
        const errors: Array<string> = [];
        Object.entries(errorObj).map(([key, value]) => {
          const errorKey = `registration-${key}`;
          errors.push(`${t(errorKey)} - ${value}`);
        });
        toast.error(
          <>
            <strong>{t('fill-missing-fields')}:</strong>
            {errors.map((e: string) => (
              <p key={e}>
                <strong>{e.split(' . ')[0]}</strong>
              </p>
            ))}
          </>,
        );
      } else {
        dispatch(fetchUsers(companyId));
        modalToggle(false);
        toast.success(
          <>
            <strong>{t('user-creation-successful')}</strong>
          </>,
        );
      }
    });
  };
  const setUserValues = (key: string, value: string) => {
    const newState = { ...newUser };
    newState[key] = value;
    setNewUser(newState);
  };
  return (
    <TransparentBackground>
      <CloseContainer>
        <TopCloseButton onClick={() => modalToggle(false)}>
          <div>{t('close')}</div>
          <CloseIcon icon={['fal', 'times-circle']} />
        </TopCloseButton>
      </CloseContainer>
      <ModalBackground>
        <ModalContent>
          <Title> {t('add-new-user')}</Title>
          <NewUserForm>
            <label>
              {t('email')} <span>*</span>
            </label>
            <input
              className="form-select"
              size={30}
              value={newUser.username}
              placeholder={t('email')}
              onChange={(e) => setUserValues('username', e.target.value)}
            />
            <label>
              {t('phone')} <Tooltip tooltipText="phone-number-tooltip" />
            </label>
            <input
              className="form-select"
              size={30}
              value={newUser.phone}
              placeholder={t('phone-placeholder')}
              onChange={(e) => setUserValues('phone', e.target.value)}
            />
            <label>{t('first-name')}</label>
            <input
              className="form-select"
              size={30}
              value={newUser.first_name}
              placeholder={t('first-name')}
              onChange={(e) => setUserValues('first_name', e.target.value)}
            />
            <label>{t('last-name')}</label>
            <input
              className="form-select"
              size={30}
              value={newUser.last_name}
              placeholder={t('last-name')}
              onChange={(e) => setUserValues('last_name', e.target.value)}
            />
            <label>
              {t('organization')} <span>*</span>
              <Tooltip tooltipText="informant-information" />
            </label>
            <OrganizationName>{companyName}</OrganizationName>
          </NewUserForm>
        </ModalContent>
        <CloseButtonContainer>
          <CancelButton onClick={() => modalToggle(false)}>
            {t('cancel')}
          </CancelButton>
          <AddUserButtom onClick={() => handleSubmission()}>
            {t('add-new-user')}
          </AddUserButtom>
        </CloseButtonContainer>
      </ModalBackground>
    </TransparentBackground>
  );
};

export default AddNewUserModal;
