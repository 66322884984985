import React from 'react';
import styled from 'styled-components';
import { ImageType } from 'Features/ImageBank/imageBankSlice';
import PhotoObject from './PhotoObject';
import { Fields } from '../styles';

const PhotoGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Phase2 = ({ imageObjects }: { imageObjects: Array<ImageType> }) => {
  return (
    <Fields style={{ flexDirection: 'column' }}>
      <PhotoGrid>
        {imageObjects
          ? imageObjects.map((item: ImageType) => (
              <PhotoObject key={item.id} photoDetails={item} />
            ))
          : null}
      </PhotoGrid>
    </Fields>
  );
};

export default Phase2;
